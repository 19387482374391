import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NavigationMenu from "../LandingPage/NavMenu";
import Button from "../Buttons/Button";
import ButtonText from "../Buttons/ButtonText";
import HamburgerButton from "../Buttons/HamburgerButton";
import Link from "../Links/Link";
import ProfileButton from "../Buttons/ProfileButton";
import { useViewport } from "../../hooks/ViewportProvider";
import { selectUser } from "../../redux/reducers/userReducer";

const StyledHeader = styled.header<{ isMobile: any; viewport: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ isMobile, viewport }) =>
		isMobile ? "24px" : viewport === "laptop" ? "24px 64px" : "24px 112px"};
  position: sticky;
  width: ${({ isMobile, viewport }) => isMobile ? (
		"calc(100% - 48px)") : (
		viewport === "laptop" ? (
			"calc(100% - 128px)") : (
			"calc(100% - 224px)"
		)
	)};
  z-index: 50;
`;

const LeftSection = styled.div``;

const RightSection = styled.div`
  display: flex;
  z-index: 2;
  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const StyledLink = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 500;
`;

const StyledLoginButton = styled(ButtonText)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 500;
`;

const StyledSignUpButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 500;
`;

const Header = () => {
	const user = useSelector(selectUser);
	const viewport = useViewport();
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const isMobile = viewport === "mobile" || viewport === "tablet";
	// if the user zooms out to a larger viewport, close the nav menu.
	useEffect(() => {
		if (isMobile) return;
		else {
			setIsOpen(false);
		}
	}, [isMobile]);

	const goToLogin = () => navigate("/login");
	const goToSignup = () => navigate("/sign-up");

	const renderNavOptions = () => {
		if (!user) {
			return (
				<>
					<StyledLoginButton onClick={goToLogin}>
            Log In
					</StyledLoginButton>
					<StyledSignUpButton onClick={goToSignup}>
            Sign Up
					</StyledSignUpButton>
				</>
			);
		} else {
			return <ProfileButton />;
		}
	};

	return (
		<>
			<StyledHeader isMobile={isMobile} viewport={viewport}>
				<LeftSection onClick={() => setIsOpen(false)} >
					<Link icon="logo" iconPosition="left" to="/" />
				</LeftSection>
				<RightSection>
					{!isMobile ? (
						<>
							<StyledLink to="/about" text="About Us" useActiveUnderline />
							<StyledLink to="/contact" text="Contact Us" useActiveUnderline />
							<StyledLink to="/faqs" text="FAQ" useActiveUnderline />
							{renderNavOptions()}
						</>
					) : (
						<HamburgerButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
					)}
				</RightSection>
			</StyledHeader>
			<NavigationMenu isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile} />
		</>
	);
};

export default Header;
