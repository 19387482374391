import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "./AppRouter";
import ScrollToTop from "./utils/scrollToTop";
import Snackbar from "./components/Shared/Snackbar";
import { ViewportProvider } from "./hooks/ViewportProvider";
import { store, persistor } from "./redux/store";

const App = () => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ViewportProvider>
					<Router>
						<ScrollToTop />
						<AppRouter />
						<Snackbar />
					</Router>
				</ViewportProvider>
			</PersistGate>
		</Provider>
	);
};

export default App;