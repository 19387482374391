import React, { useState, useRef } from "react";
import styled from "@emotion/styled";
import Icon from "../Icon";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

export interface TooltipProps {
  children: React.ReactNode;
  infoMargin?: string;
  placement?: "top" | "right" | "bottom" | "left" | "bottom-left" | "bottom-right" | "mobile";
}

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ContentContainer = styled.div<{ isVisible: boolean, placement: string, margin?: string }>`
  position: absolute;
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.containerBase};
  border-radius: 4px;
  width: ${({ placement }) => placement === "mobile" ? "auto" : "290px"};
  padding: 16px;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.7);
  transition: all 0.2s ease-in-out;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  animation: ${({ isVisible }) => (isVisible ? "popoverbounce 0.2s forwards" : "none")};

  ${({ margin }) => margin && `margin: ${margin};`}

  ${({ placement }) => {
		switch (placement) {
		case "mobile":
			return "position: fixed; top: 132px; left: 10px; right: 20px;";
		case "top":
			return "bottom: 100%; left: 50%; transform: translateX(-50%);";
		case "right":
			return "left: 100%; top: 50%; transform: translateY(-50%);";
		case "left":
			return "right: 100%; top: 50%; transform: translateY(-50%);";
		case "bottom-left":
			return "top: 100%; left: -25%; transform: translateX(-50%);";
		case "bottom-right":
			return "top: 100%; left: 90%; transform: translateX(-50%);";
		case "bottom":
		default:
			return "top: 100%; left: 50%; transform: translateX(-50%);";
		}
	}}

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;

    ${({ placement, theme }) => {
		// Top, right and left placements will need adjustment for future use
		switch (placement) {
		case "mobile":
			return `
            border-width: 0 12px 20px 12px;
            border-color: transparent transparent ${theme.colors.containerBase} transparent;
            border-radius: 4px;
            top: -18px;
            left: 192px;
            transform: translateX(-50%);
          `;
		case "top":
			return `
            border-width: 12px 12px 0 12px;
            border-color: ${theme.colors.containerBase} transparent transparent transparent;
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%);
          `;
		case "right":
			return `
            border-width: 12px 0 12px 12px;
            border-color: transparent transparent transparent ${theme.colors.containerBase};
            left: -12px;
            top: 50%;
            transform: translateY(-50%);
          `;
		case "left":
			return `
            border-width: 12px 12px 12px 0;
            border-color: transparent ${theme.colors.containerBase} transparent transparent;
            right: -12px;
            top: 50%;
            transform: translateY(-50%);
          `;
		case "bottom-right":
		case "bottom-left":
		case "bottom":
		default:
			return `
            border-width: 0 12px 20px 12px;
            border-color: transparent transparent ${theme.colors.containerBase} transparent;
            border-radius: 4px;
            top: -18px;
            left: 24px;
            transform: translateX(-50%);
          `;
		}
	}}

    @keyframes popoverbounce {
      0% {
        transform: scale(0.7);
        opacity: 0;
      }
      60% {
        transform: scale(1.05);
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
`;

const IconWrapper = styled.div`
  padding: 8px;
  height: 16px;
`;

const Tooltip: React.FC<TooltipProps> = ({
	children,
	infoMargin,
	placement = "bottom-left",
	...rest
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const isTouchDevice = "ontouchstart" in window;
	const ref = useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, () => setIsVisible(false));

	return (
		<TooltipContainer {...rest}>
			<IconWrapper
				onMouseEnter={() => !isTouchDevice && setIsVisible(true)}
				onMouseLeave={() => !isTouchDevice && setIsVisible(false)}
				onClick={() => isTouchDevice && setIsVisible(!isVisible)}
				ref={ref}
			>
				<Icon icon="info" />
			</IconWrapper>
			<ContentContainer
				isVisible={isVisible}
				margin={infoMargin}
				placement={placement}>
				{children}
			</ContentContainer>
		</TooltipContainer>
	);
};

export default Tooltip;

