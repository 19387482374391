import React from "react";
import styled from "@emotion/styled";
import LoadingSpinner from "../Animations/LoadingSpinner";

export interface ButtonProps {
  backgroundColor?: string;
  borderColor?: string;
  children: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  height?: string;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  textColor?: string;
  type?: "button" | "submit" | "reset" | undefined;
  width?: string;
  variant?: "primary" | "secondary";
}

const StyledButton = styled.button<ButtonProps>`
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 20px;
  font-weight: 500;
  padding: 14px 40px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease;

  ${({ theme, variant }) =>
		variant === "primary"
			? `
    background: ${theme.gradients.primary};
    color: ${theme.colors.white};
  `
			: `
    color: ${theme.colors.white};
    background: transparent;
  `};

  ${({ textColor }) => textColor && `color: ${textColor};`};
  ${({ height }) => height && `min-height: ${height};`};
  ${({ backgroundColor }) =>
		backgroundColor && `background: ${backgroundColor};`};
  ${({ fullWidth, width }) => (fullWidth ? "width: 100%;" : `width: ${width || "auto"};`)}

  &:hover {
    box-shadow: 0px 4px 30px 0px rgba(204, 28, 210, 0.5);
  }

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    box-shadow: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    border: 4px solid transparent;
    background: ${({ borderColor, theme }) =>
		borderColor || theme.gradients.primary}
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
`;

const Button: React.FC<ButtonProps> = ({
	backgroundColor,
	borderColor,
	children,
	disabled,
	fullWidth,
	height,
	loading,
	onClick,
	textColor,
	type = "button",
	width,
	variant = "primary",
	...rest
}) => {
	return (
		<StyledButton
			backgroundColor={backgroundColor}
			borderColor={borderColor}
			disabled={loading || disabled}
			fullWidth={fullWidth}
			height={height}
			width={width}
			onClick={onClick}
			textColor={textColor}
			type={type}
			variant={variant}
			{...rest}
		>
			{loading ? <LoadingSpinner /> : children}
		</StyledButton>
	);
};

export default Button;
