import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import { setError, setLoading, setContactCard } from "../reducers/contactCardReducer";

export const fetchContactCard = createAsyncThunk(
	"contactCard/fetchContactCard",
	async (_, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoading(true));
			const response = await axiosInstance.get("/contact-card", { withCredentials: true });
			thunkAPI.dispatch(setContactCard(response.data));
			return response.data;
		} catch (error) {
			if (error instanceof Error) {
				thunkAPI.dispatch(setError(error.message));
				return thunkAPI.rejectWithValue(error.message);
			} else {
				const errorMessage = "An unknown error occurred";
				thunkAPI.dispatch(setError(errorMessage));
				return thunkAPI.rejectWithValue(errorMessage);
			}
		} finally {
			thunkAPI.dispatch(setLoading(false));
		}
	}
);