import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import Button from "../../Buttons/Button";
import Checkbox from "../../Inputs/Checkbox";
import Typography from "../../Typography";
import { User } from "../../../types/User";
import { updateUserPreferenceData } from "../../../redux/actions/userActions";
import { showSnackbar } from "../../../redux/reducers/snackbarReducer";
import type { AppDispatch } from "../../../redux/store";

const TabContainer = styled.div`
  padding: 40px 32px;
  box-sizing: border-box;
  width: 100%;
  max-width: 463px;
`;

const CheckboxContainer = styled.div`
  margin: 40px 0;
`;

const GenderPreferenceTab: React.FC<{ user: User}> = ({ user }) => {
	const dispatch: AppDispatch = useDispatch();
	const [genderPreference, setGenderPreference] = useState(user?.preferences?.genderPreference || []);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [formError, setFormError] = useState("");

	const handlePreferenceChange = (value: string, checked: boolean) => {
		if (checked) {
			setGenderPreference(prev => ([...prev, value]));
		} else {
			setGenderPreference(prev => (prev.filter(pref => pref !== value)));
		}
	};

	const validateFormData = (genderPreference: string[]) => {
		if (!genderPreference.length) {
			return "Match preference is required.";
		}

		return "";
	};

	const handleSubmit = async () => {
		if (!user || !user.id) return;
		const error = validateFormData(genderPreference);
  
		if (error) {
			setFormError(error);
			return;
		}

		const data = {
			...user?.preferences,
			genderPreference: genderPreference,
		};

		dispatch(updateUserPreferenceData({ data }))
			.unwrap()
			.then(() => {
				dispatch(showSnackbar({ message: "Gender preferences saved successfully!", duration: 3000 }));
			})
			.catch(error => {
				console.error("Error updating user preferences: ", error);
			});
	};

	return (
		<TabContainer>
			<Typography
				fontFamily="body"
				fontSize={18}
				lineHeight="24px"
			>
        I’m interested in
			</Typography>
			<CheckboxContainer>
				<Checkbox
					checked={genderPreference.includes("male")} 
					label="Men" 
					onChange={handlePreferenceChange}
					value="male"
				/>
				<Checkbox
					checked={genderPreference.includes("female")} 
					label="Women"
					onChange={handlePreferenceChange}
					value="female" 
				/>
				<Checkbox
					checked={genderPreference.includes("non-binary")}
					label="Non-binary"
					onChange={handlePreferenceChange}
					value="non-binary"
				/>
			</CheckboxContainer>
			<Button onClick={handleSubmit}>
        Apply Preferences
			</Button>
		</TabContainer>
	);
};

export default GenderPreferenceTab;