import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import Button from "../../Buttons/Button";
import Checkbox from "../../Inputs/Checkbox";
import InputText from "../../Inputs/InputText";
import Slider from "../../Inputs/Slider";
import ToggleSwitch from "../../Inputs/Toggle";
import Typography from "../../Typography";
import { updateUserPreferenceData } from "../../../redux/actions/userActions";
import { showSnackbar } from "../../../redux/reducers/snackbarReducer";
import { User } from "../../../types/User";
import type { AppDispatch } from "../../../redux/store";

const TabContainer = styled.div`
  padding: 40px 32px;
  max-width: 463px;
  width: 100%;
  box-sizing: border-box;
`;

const LocationInputContainer = styled.div`
  margin: 40px 0;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MaxDistanceInput = styled(InputText)`
  margin: 32px 0 33px;
  max-width: 220px;
`;

const Dealbreaker = styled.div`
  margin-top: 32px;
`;

const LocationPreferenceTab: React.FC<{ user: User }> = ({ user }) => {
	const dispatch: AppDispatch = useDispatch();
	const defaultMaxDistance = user?.preferences?.locationRange?.max || 25;
	const defaultDealbreaker = user?.preferences?.locationRange?.dealbreaker;
	const [maxDistance, setMaxDistance] = useState<number>(
		defaultMaxDistance > 200 ? 5000 : defaultMaxDistance
	);
	const [prevMax, setPrevMax] = useState<number>(defaultMaxDistance > 200 ? 25 : defaultMaxDistance);
	const [openToAny, setOpenToAny] = useState<boolean>(defaultMaxDistance > 200);
	const [dealbreaker, setDealbreaker] = useState(defaultDealbreaker || false);

	const handleOpenToAny = () => {
		setOpenToAny((prev) => {
			setMaxDistance((prevMaxDistance) => {
				if (!prev) {
					setPrevMax(prevMaxDistance);
					return 5000;
				} else {
					return prevMax;
				}
			});
			return !prev;
		});
	};

	const handleSubmit = async () => {
		if (!user || !user.id) return;
		const data = {
			...user?.preferences,
			locationRange: {
				max: maxDistance,
				dealbreaker: dealbreaker,
			},
		};

		dispatch(updateUserPreferenceData({ data }))
			.unwrap()
			.then(() => {
				dispatch(showSnackbar({ message: "Location preferences saved successfully!", duration: 3000 }));
			})
			.catch(error => {
				console.error("Error updating user preferences: ", error);
			});
	};

	return (
		<TabContainer>
			<Typography
				fontFamily="body"
				fontSize={18}
				lineHeight="24px"
				margin="0 0 4px"
			>
        Maximum Distance
			</Typography>
			<Typography
				color="secondaryLightGray"
				fontFamily="body"
				fontSize={16}
				fontWeight={400}
				lineHeight="22px"
			>
        Enter maximum distance
			</Typography>
			<LocationInputContainer>
				<ToggleContainer>
					<ToggleSwitch
						checked={openToAny}
						onChange={handleOpenToAny}
					/>
					<Typography
						color="secondaryLightGray"
						fontFamily="body"
						fontSize={16}
						fontWeight={300}
						margin="0 16px"
					>
            I’m open to any distance
					</Typography>
				</ToggleContainer>
				<MaxDistanceInput
					disabled={openToAny}
					isNumeric
					label="Maximum distance"
					min={1}
					max={200}
					onChange={(event) => {
						const value = event.target.value;
						setMaxDistance(Number(value));
					}}
					value={maxDistance > 200 ? 200 : maxDistance}
				/>
				<Slider
					disabled={openToAny}
					min={1}
					max={200}
					value={maxDistance > 200 ? 200 : maxDistance}
					onChange={(value: number) => setMaxDistance(value)}
				/>
				<Dealbreaker>
					<Checkbox
						checked={dealbreaker}
						label="This is a deal breaker"
						onChange={() => setDealbreaker((prev) => !prev)}
						value="dealbreaker"
					/>
				</Dealbreaker>
			</LocationInputContainer>
			<Button onClick={handleSubmit}>
        Apply Preferences
			</Button>
		</TabContainer>
	);
};

export default LocationPreferenceTab;
