import styled from "@emotion/styled";
import BackgroundImage from "../../assets/images/background1.webp";

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        to bottom,
        #030512,
        rgba(13, 15, 39, 0.3) 20%,
        rgba(13, 15, 39, 0.3) 80%,
        #030512
      ),
      url(${BackgroundImage});
    background-size: cover;
    background-position: center;
    opacity: 0.6;
  }
`;

export default Background;
