import React from "react";
import styled from "@emotion/styled";
import AnimatedHeart from "../components/Animations/AnimatedHeart";
import Background from "../components/Shared/Background";
import Typography from "../components/Typography";

const LoadingPageContainer = styled.div`
position: relative;
height: 100vh;
width: 100%;
overflow: hidden;
`;

const LoadingText = styled.div`
position:absolute;
max-width: 100%;
height: auto;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 999;
`;

const LoadingPage = () => {
	const hearts = Array.from({ length: 100 });

	return (
		<LoadingPageContainer>
			<Background />
			<LoadingText>
				<Typography 
					variant="h1"
					color="white"
					fontSize={48}
				>
          Loading...
				</Typography>
			</LoadingText>
			{hearts.map((_, index) => (
				<AnimatedHeart key={index} />
			))}
		</LoadingPageContainer>
	);
};

export default LoadingPage;