import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import Button from "../../Buttons/Button";
import Checkbox from "../../Inputs/Checkbox";
import InputText from "../../Inputs/InputText";
import Slider from "../../Inputs/Slider";
import Typography from "../../Typography";
import ToggleSwitch from "../../Inputs/Toggle";
import { updateUserPreferenceData } from "../../../redux/actions/userActions";
import { showSnackbar } from "../../../redux/reducers/snackbarReducer";
import { User } from "../../../types/User";
import type { AppDispatch } from "../../../redux/store";

const TabContainer = styled.div`
  padding: 40px 32px;
  max-width: 463px;
  width: 100%;
  box-sizing: border-box;
`;

const AgeInputContainer = styled.div`
  margin: 40px 0;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AgeRangeInput = styled.div`
  margin: 32px 0;
`;

const TextInputGroupWrapper = styled.div`
  display: grid;
  row-gap: 20px;
  column-gap: 24px;
  grid-template-columns: calc(50% - 13px) calc(50% - 13px);
  margin-bottom: 32px;
`;

const Dealbreaker = styled.div`
  margin-top: 32px;
`;

const AgePreferenceTab: React.FC<{ user: User }> = ({ user }) => {
	const dispatch: AppDispatch = useDispatch();
	const defaultMinAge = user?.preferences?.ageRange?.min;
	const defaultMaxAge = user?.preferences?.ageRange?.max;
	const defaultDealbreaker = user?.preferences?.ageRange?.dealbreaker;
	const openToAnyDefault = defaultMinAge === 18 && defaultMaxAge === 99;
	const [ageRange, setAgeRange] = useState<[number, number]>([
		defaultMinAge || 18,
		defaultMaxAge || 99,
	]);
	const [prevAgeRange, setPrevAgeRange] = useState<[number, number]>([18, 99]);
	const [openToAny, setOpenToAny] = useState(openToAnyDefault);
	const [dealbreaker, setDealbreaker] = useState(defaultDealbreaker || false);

	const handleOpenToAny = () => {
		setOpenToAny((prev) => {
			setAgeRange((prevRange) => {
				if (!prev) {
					setPrevAgeRange(prevRange);
					return [18, 99];
				} else {
					return prevAgeRange;
				}
			});
			return !prev;
		});
	};

	const handleSubmit = async () => {
		const data = {
			...user?.preferences,
			ageRange: {
				min: ageRange[0],
				max: ageRange[1],
				dealbreaker: dealbreaker,
			},
		};

		dispatch(updateUserPreferenceData({ data }))
			.unwrap()
			.then(() => {
				dispatch(showSnackbar({ message: "Age preferences saved successfully!", duration: 3000 }));
			})
			.catch((error) => {
				console.error("Error updating user preferences: ", error);
			});
	};

	return (
		<TabContainer>
			<Typography
				fontFamily="body"
				fontSize={18}
				fontWeight={700}
				lineHeight="24px"
				margin="0 0 4px"
				variant="h2"
			>
        Age Range
			</Typography>
			<Typography
				color="secondaryLightGray"
				fontFamily="body"
				fontSize={16}
				fontWeight={400}
				lineHeight="22px"
				margin="0 0 32px"
			>
        Select age range (years)
			</Typography>
			<AgeInputContainer>
				<ToggleContainer>
					<ToggleSwitch
						checked={openToAny}
						onChange={handleOpenToAny}
					/>
					<Typography
						color="secondaryLightGray"
						fontFamily="body"
						fontWeight={400}
						margin="0 16px"
					>
            I’m open to all ages
					</Typography>
				</ToggleContainer>
				<AgeRangeInput>
					<TextInputGroupWrapper>
						<InputText
							disabled={openToAny}
							isNumeric
							label="Minimum age"
							min={18}
							max={99}
							onChange={(event) => {
								const value = event.target.value;
								setAgeRange((prev) => [Number(value), prev[1]]);
							}}
							value={ageRange[0]}
						/>
						<InputText
							disabled={openToAny}
							isNumeric
							label="Maximum age"
							min={ageRange[0] + 1}
							max={99}
							onChange={(event) => {
								const value = event.target.value;
								setAgeRange((prev) => [prev[0], Number(value)]);
							}}
							value={ageRange[1]}
						/>
					</TextInputGroupWrapper>
					<Slider
						disabled={openToAny}
						min={18}
						max={99}
						minRange={5}
						value={ageRange}
						onChange={(value: number | readonly number[]) => {
							setAgeRange(value as [number, number]);
						}}
					/>
				</AgeRangeInput>
				<Dealbreaker>
					<Checkbox
						checked={dealbreaker}
						label="This is a deal breaker"
						onChange={() => setDealbreaker((prev) => !prev)}
						value="dealbreaker"
					/>
				</Dealbreaker>
			</AgeInputContainer>
			<Button onClick={handleSubmit}>Apply Preferences</Button>
		</TabContainer>
	);
};

export default AgePreferenceTab;
