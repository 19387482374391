import React from "react";
import styled from "@emotion/styled";
import Typography from "../Typography";

export interface TextAreaProps {
  disabled?: boolean;
  error?: string | null;
  fullWidth?: boolean;
  label?: string;
  margin?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  value: string;
  rows?: number;
  cols?: number;
  maxLength?: number;
}

const InputOuterWrapper = styled.div<{ disabled?: boolean, fullWidth?: boolean, margin?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ fullWidth }) => fullWidth && "width: 100%;"};
  ${({ margin }) => margin && `margin: ${margin};`};

  ${({ disabled }) => disabled && 
    "opacity: 0.5; pointer-events: none;"}
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledLabel = styled.label<{ isVisible: boolean }>`
  position: absolute;
  top: 8px;
  left: 12px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightGray};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const StyledTextArea = styled.textarea<{ hasValue: boolean, isError: boolean }>`
  width: calc(100% - 24px);
  border: 1px solid ${({ theme, isError }) => isError ? "red" : theme.colors.darkGray};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.containerBase};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ hasValue }) => hasValue ? "24px 12px 24px 12px" : "16px 12px 24px"};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 400;
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.accentViolet};
    box-shadow: 0px 0px 30px 0px rgba(204, 28, 210, 0.50);
  }
`;

const CharacterCount = styled.div`
  color: ${({ theme }) => theme.colors.lightGray};
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 400;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.containerBase};
  padding: 0 4px;
`;

const ErrorMessage = styled(Typography)`
  position: absolute;
  left: 0;
  bottom: -24px;
  min-height: 20px;
`;

const TextArea: React.FC<TextAreaProps> = ({
	disabled,
	error,
	fullWidth,
	label,
	margin,
	onChange,
	placeholder,
	value,
	rows,
	cols,
	maxLength,
	...props
}) => {
	return (
		<InputOuterWrapper disabled={disabled} fullWidth={fullWidth} margin={margin} {...props}>
			<InputWrapper>
				{label && (
					<StyledLabel isVisible={!!value}>{label}</StyledLabel>
				)}
				<StyledTextArea
					hasValue={!!value && !!label}
					isError={!!error}
					onChange={onChange}
					placeholder={!value ? placeholder : ""}
					value={value}
					rows={rows}
					cols={cols}
				/>
				{maxLength && (
					<CharacterCount>
						{value.length}/{maxLength}
					</CharacterCount>
				)}
			</InputWrapper>
			{error && (
				<ErrorMessage
					color="errorRed"
					fontFamily="secondary"
					fontSize={14}
					fontWeight={400}
					margin="12px 0 0"
				>
					{error}
				</ErrorMessage>
			)}
		</InputOuterWrapper>
	);
};

export default TextArea;
