import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate, useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Buttons/Button";
import ButtonText from "../Buttons/ButtonText";
import Link from "../Links/Link";
import Typography from "../Typography";
import { logoutUser } from "../../redux/actions/userActions";
import { selectUser } from "../../redux/reducers/userReducer";

import type { AppDispatch } from "../../redux/store";

export interface NavMenuProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
}

const NavMenu = styled.div<{ isOpen?: boolean, isMobile?: boolean }>`
  position: fixed;
  left: ${({ isOpen }) => (isOpen ? 0 : "-100%")};
  top: 4rem;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.colors.primaryMidnight};
  border-radius: 10px;
  text-align: center;
  transition: 0.3s;
  z-index: 999;
  cursor: pointer;

  ${({ isMobile }) => !isMobile && "display: none;"}
`;

const StyledLoginButton = styled(ButtonText)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 500;
`;

const StyledSignUpButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 500;
  width: 90%;
  margin: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NavItem = styled.div`
  display: flex;
  padding: 20px 24px;
  overflow: hidden;
  :hover {
    background: ${({ theme }) => theme.colors.borderGray};
  }
`;

const StyledLink = styled(Link)<{ onClick: React.Dispatch<React.SetStateAction<boolean>> }>`
  display: block;
`;

const LogoutButton = styled(ButtonText)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 500;
  margin-top: 24px;
  text-align: center;
  width: 100%;
`;

const NavigationMenu: React.FC<NavMenuProps> = ({ isOpen, setIsOpen, isMobile }) => {
	const dispatch: AppDispatch = useDispatch();
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	const aboutMatch = useMatch("/about");
	const contactMatch = useMatch("/contact");
	const faqsMatch = useMatch("/faqs");

	useEffect(() => {
		if (isOpen) {
			document.body.classList.add("no-scroll");
		} else {
			document.body.classList.remove("no-scroll");
		}

		return () => {
			document.body.classList.remove("no-scroll");
		};
	}, [isOpen]);

	return (
		<NavMenu isOpen={isOpen} isMobile={isMobile}>
			<StyledLink onClick={() => setIsOpen(false)} to="/about">
				<NavItem>
					<Typography
						fontSize={16}
						fontWeight={500}
						fontFamily="body"
						color={aboutMatch ? "primaryPink" : "white"}
					>
            About Us
					</Typography>
				</NavItem>
			</StyledLink>
			<StyledLink onClick={() => setIsOpen(false)} to="/contact">
				<NavItem>
					<Typography
						fontSize={16}
						fontWeight={500}
						fontFamily="body"
						color={contactMatch ? "primaryPink" : "white"}
					>
            Contact
					</Typography>
				</NavItem>
			</StyledLink>
			<StyledLink onClick={() => setIsOpen(false)} to="/faqs">
				<NavItem>
					<Typography
						fontSize={16}
						fontWeight={500}
						fontFamily="body"
						color={faqsMatch ? "primaryPink" : "white"}
					>
            FAQ
					</Typography>
				</NavItem>
			</StyledLink>
			<ButtonContainer>
				{!user ? (
					<>
						<StyledSignUpButton 
							fullWidth 
							onClick={() => {
								navigate("/sign-up");
								setIsOpen(false);
							}}>
              Sign Up
						</StyledSignUpButton>
						<StyledLoginButton 
							onClick={() => {
								navigate("/login");
								setIsOpen(false);
							}}>
              Log In
						</StyledLoginButton>
					</>
				) : (
					<LogoutButton onClick={() => dispatch(logoutUser())}>Log Out</LogoutButton>
				)}
			</ButtonContainer>
		</NavMenu>
	);
};

export default NavigationMenu;
