import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import Button from "../components/Buttons/Button";
import Icon from "../components/Icon";
import Typography from "../components/Typography";
import Link from "../components/Links/Link";
import theme from "../theme";
import { useViewport } from "../hooks/ViewportProvider";

const CongratsContent = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${({ isMobile }) => (isMobile ? "24px 24px 48px" : "80px 0 0")};
`;

const ListWrapper = styled.div``;

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const IconContainer = styled.div`
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
`;

const CongratsContainer = styled.div<{
  viewport: any;
  isMobile: boolean;
}>`
  display: block;
  background: ${({ theme }) => theme.colors.containerBase};
  border: 1px solid ${({ theme }) => theme.colors.white}70;
  border-radius: 12px;
  box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
    0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  max-width: ${({ viewport, isMobile }) =>
		viewport === "laptop" || viewport === "tablet" || isMobile
			? "calc(100vw - 48px)"
			: "800px"};
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  margin: ${({ viewport }) => (viewport === "laptop" ? "0px 24px" : 0)};
`;

const ButtonWrapper = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  align-items: center;
  margin-top: 48px;
  width: ${({ isMobile }) => (isMobile ? "100%" : "auto")};
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
`;

const StyledTypography = styled(Typography)`
  text-align: center;
`;

const CongratsPage = () => {
	const viewport = useViewport();
	const navigate = useNavigate();
	const isMobile = viewport === "mobile";

	return (
		<>
			<CongratsContent isMobile={isMobile}>
				<Icon
					style={{ marginBottom: "24px" }}
					icon="complete-check"
					color="accentCyan"
					width={80}
					height={80}
				/>
				<Typography fontSize={40} fontWeight={700} margin={"0px 0px 20px 0px"}>
          Congrats!
				</Typography>
				<StyledTypography
					fontSize={20}
					fontWeight={700}
					margin={"0px 0px 32px 0px"}
				>
          Get Exclusive Early Access
				</StyledTypography>
				<CongratsContainer viewport={viewport} isMobile={isMobile}>
					<Typography
						fontSize={20}
						fontWeight={700}
						margin={"0px 0px 16px 0px"}
					>
            Here’s what’s next:
					</Typography>
					<ListWrapper>
						<ListItemWrapper>
							<IconContainer style={{ marginRight: "12px" }}>
								<Icon height={20} width={20} icon="heart" />
							</IconContainer>
							<Typography
								color="secondaryLightGray"
								fontSize={20}
								fontWeight={400}
								fontFamily="body"
								isInline
								lineHeight="22px"
							>
								<Typography
									fontSize={20}
									fontWeight={700}
									fontFamily="body"
									isInline
									lineHeight="22px"
									variant="span"
								>
                  Priority Matchmaking:
								</Typography>{" "}
                You’ll notified when we’re live and be first in line for our
                advanced matching algorithm.
							</Typography>
						</ListItemWrapper>
						<ListItemWrapper>
							<IconContainer style={{ marginRight: "12px" }}>
								<Icon height={20} width={20} icon="gift" />
							</IconContainer>
							<Typography
								color="secondaryLightGray"
								fontSize={20}
								fontWeight={400}
								fontFamily="body"
								isInline
								lineHeight="22px"
							>
								<Typography
									fontSize={20}
									fontWeight={700}
									fontFamily="body"
									isInline
									lineHeight="22px"
									variant="span"
								>
                  Special Launch Deal:
								</Typography>{" "}
                Access a unique offer when we launch - our thanks for your
                support.
							</Typography>
						</ListItemWrapper>
						<ListItemWrapper>
							<IconContainer style={{ marginRight: "12px" }}>
								<Icon height={20} width={20} icon="eye" />
							</IconContainer>
							<Typography
								color="secondaryLightGray"
								fontSize={20}
								fontWeight={400}
								fontFamily="body"
								isInline
								lineHeight="22px"
							>
								<Typography
									fontSize={20}
									fontWeight={700}
									fontFamily="body"
									isInline
									lineHeight="22px"
									variant="span"
								>
                  Sneak Peeks:
								</Typography>{" "}
                Enjoy exclusive previews of app features and updates.
							</Typography>
						</ListItemWrapper>
						<ListItemWrapper>
							<IconContainer style={{ marginRight: "12px" }}>
								<Icon height={20} width={20} icon="message-bubble" />
							</IconContainer>
							<Typography
								color="secondaryLightGray"
								fontSize={20}
								fontWeight={400}
								fontFamily="body"
								isInline
								lineHeight="22px"
							>
								<Typography
									fontSize={20}
									fontWeight={700}
									fontFamily="body"
									isInline
									lineHeight="22px"
									variant="span"
								>
                  Direct Input:
								</Typography>{" "}
                Your feedback will play a crucial role in refining and enhancing
                the app.{" "}
								<StyledLink color={theme.colors.primaryPink} to="/contact">
                  Click here
								</StyledLink>{" "}
                to gives us your thoughts on your experience so far. We value
                your input!
							</Typography>
						</ListItemWrapper>
					</ListWrapper>
				</CongratsContainer>
				<ButtonWrapper isMobile={isMobile}>
					<Button onClick={() => navigate("/")} fullWidth={isMobile}>
            Back to Home
					</Button>
				</ButtonWrapper>
			</CongratsContent>
		</>
	);
};

export default CongratsPage;
