export const RELIGIONS = [
	"Agnostic",
	"Atheist",
	"Buddhist",
	"Catholic",
	"Christian",
	"Hindu",
	"Jewish",
	"Muslim",
	"Sikh",
	"Spiritual",
	"Other",
];