import React from "react";
import styled from "@emotion/styled";

const OrbitContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 80vw;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  z-index: -1;
`;

const GlowBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(min(85vw, 85vh));
  height: calc(min(85vw, 85vh));
  background: ${({ theme }) => `radial-gradient(circle, ${theme.colors.primaryPink}70, ${theme.colors.accentViolet}40 40%, transparent 70%)`};
  transform: translate(-50%, -50%);
  filter: blur(120px);
  z-index: -2;
`;

const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.large {
    &:before {
      content: "";
      display: block;
      width: calc(min(80vw, 80vh));
      height: calc(min(80vw, 80vh));
      border: 2px solid currentColor;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.1;
    }
  }

  &.medium {
    &:before {
      content: "";
      display: block;
      width: calc(0.78 * min(80vw, 80vh));  // 78% of the large size
      height: calc(0.78 * min(80vw, 80vh));
      border: 2px solid currentColor;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.15;
    }
  }

  &.small {
    &:before {
      content: "";
      display: block;
      width: calc(0.55 * min(80vw, 80vh));  // 55% of the large size
      height: calc(0.55 * min(80vw, 80vh));
      border: 2px solid currentColor;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.2;
    }
  }
`;

const Orbit = (props: any) => {
	return (
		<OrbitContainer {...props}>
			<GlowBackground />

			{/* Large Circle */}
			<Circle className="large" />

			{/* Medium Circle */}
			<Circle className="medium" />

			{/* Small Circle */}
			<Circle className="small" />

		</OrbitContainer>
	);
};

export default Orbit;