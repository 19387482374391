import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { useSpring, animated } from "react-spring";

interface AnimatedStarsProps {
  isVisible: boolean;
}

interface StarProps {
  size: number;
  opacity: number;
  left: number;
  top: number;
  animationDuration: number;
}

const twinkleAnimation = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
`;

const StarsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 100%;
  overflow: hidden;
`;

const generateStar = (): StarProps => {
	const size = Math.random() * 2 + 1;
	const opacity = Math.random() * 0.7 + 0.3;
	const left = Math.random() * 100;
	const top = Math.random() * 30;
	const animationDuration = Math.random() * 3 + 0.5;
	return { size, opacity, left, top, animationDuration };
};

const Star = styled.div<StarProps>`
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, ${({ opacity }) => opacity});
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  left: ${({ left }) => left}%;
  top: ${({ top }) => top}%;
  animation: ${twinkleAnimation} ${({ animationDuration }) => animationDuration}s infinite alternate;
`;

const AnimatedStars: React.FC<AnimatedStarsProps> = ({ isVisible }) => {
	const [stars, setStars] = useState<StarProps[]>([]);

	useEffect(() => {
		const generatedStars = Array.from({ length: 50 }).map(generateStar);
		setStars(generatedStars);
	}, []);

	const fadeIn = useSpring({
		opacity: isVisible ? 1 : 0,
		from: { opacity: 0 },
		config: {
			duration: 2000,
			tension: 280,
			friction: 60
		}
	});

	return (
		<animated.div style={fadeIn}>
			<StarsWrapper>
				<animated.div style={fadeIn}>
					{stars.map((star, idx) => <Star key={idx} {...star} />)}
				</animated.div>
			</StarsWrapper>
		</animated.div>
	);
};

export default AnimatedStars;
