import React, {
	createContext,
	useState,
	useEffect,
	ReactNode,
	useContext,
} from "react";
import debounce from "lodash/debounce";

interface ViewportContextType {
  width: number;
}

type Viewport = "mobile" | "tablet" | "laptop" | "desktop" | "largeDesktop";

const ViewportContext = createContext<ViewportContextType | undefined>(
	undefined
);

interface ViewportProviderProps {
  children: ReactNode;
}

const ViewportProvider: React.FC<ViewportProviderProps> = ({
	children,
}: ViewportProviderProps): React.ReactElement | null => {
	const [width, setWidth] = useState<number>(window.innerWidth);

	const handleWindowResize = debounce(() => {
		setWidth(window.innerWidth);
	}, 100);

	useEffect(() => {
		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, []);

	return (
		<ViewportContext.Provider value={{ width }}>
			{children}
		</ViewportContext.Provider>
	);
};

const useViewport = (): Viewport => {
	const context = useContext(ViewportContext);

	if (context === undefined) {
		throw new Error("useViewport must be used within a ViewportProvider");
	}

	const { width } = context;

	if (width < 576) {
		return "mobile";
	} else if (width >= 576 && width < 768) {
		return "tablet";
	} else if (width >= 768 && width < 992) {
		return "laptop";
	} else if (width >= 992 && width < 1200) {
		return "desktop";
	} else {
		return "largeDesktop";
	}
};

export default useViewport;

export { ViewportProvider, useViewport };
