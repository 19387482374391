import React, { useState } from "react";
import styled from "@emotion/styled";
import Button from "../Buttons/Button";
import ToggleSwitch from "../Inputs/Toggle";
import Typography from "../Typography";

const AppearanceTabContainer = styled.div`
  padding: 40px 32px;
  box-sizing: border-box;
`;

const ToggleGroup = styled.div`
  margin: 40px 0;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &last-child {
    margin-bottom: 0;
  }
`;

const AppearanceTab = () => {
	const [appearanceMode, setAppearanceMode] = useState(false);

	return (
		<AppearanceTabContainer>
			<Typography
				fontSize={24}
				fontWeight={700}
			>
        Appearances
			</Typography>
			<ToggleGroup>
				<ToggleContainer>
					<Typography
						color="secondaryLightGray"
						fontFamily="body"
						fontWeight={400}
						margin="0 12px 0 0"
					>
            Dark Mode
					</Typography>
					<ToggleSwitch
						checked={appearanceMode}
						onChange={() => setAppearanceMode(prev => !prev)}
					/>
					<Typography
						color="secondaryLightGray"
						fontFamily="body"
						fontWeight={400}
						margin="0 12px"
					>
            Light Mode
					</Typography>
				</ToggleContainer>
			</ToggleGroup>
			<Button>
        Save Changes
			</Button>
		</AppearanceTabContainer>
	);
};

export default AppearanceTab;