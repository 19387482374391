export const formatDate = (date: Date) => {
	return new Date(date).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
};

export const getOrdinalSuffix = (date: Date) => {
	const day = date.getDate();
	if (day > 3 && day < 21) return "th";
	switch (day % 10) {
	case 1: return "st";
	case 2: return "nd";
	case 3: return "rd";
	default: return "th";
	}
};