export const FAQS = [
  {
    question: "What is Heart 2 Heart?",
    answer:
      "Heart 2 Heart is an innovative online dating platform. Unlike traditional dating apps, there is no swiping or texting. Instead, we prioritize making genuine connection through meaningful conversation using our unique one-on-one chatrooms as the primary means of communication.",
  },

  {
    question: "How does the matching algorithm work?",
    answer:
      "Our matching algorithm uses the information provided during the onboarding process, which includes your passions, interests, values, and preferences. This ensures that you are paired with someone with whom you share compatibility and have a better likelihood of forming a connection with.",
  },
  {
    question: "Can I customize my profile?",
    answer:
      'Yes! Once on the dashboard, you can edit your settings and create a personalized "contact card" which can be shared with others. This card is like a calling card, allowing users to connect outside the app.',
  },
  {
    question: "How do I get matched with other users?",
    answer:
      'From your dashboard, you’ll find the "Go Live" feature. Once activated, you’ll be paired with another user in a one-on-one chatroom based on your mutual interests and compatibility.',
  },
  {
    question: "Can I filter who I get matched with?",
    answer:
      "Yes, during the onboarding process, you’ll provide preferences such as age, location, interests, and more. Our algorithm uses this information to match you with compatible users.",
  },
  {
    question: "Is my personal information safe?",
    answer:
      "Absolutely. Protecting your data is our top priority. We have stringent data protection policies in place to ensure your information is secure and never shared without your consent.",
  },
  {
    question: "What if I want to connect with someone outside the app?",
    answer:
      'If a conversation goes well, you can choose to share your personalized "contact card" with the other user. This allows for connection outside of the Heart 2 Heart platform.',
  },
  //Taking this out until we come up with something
  // {
  //   question: "Do I have to pay to use Heart 2 Heart?",
  //   answer:
  //     "[You’ll need to provide details here based on your app’s monetization strategy. For instance, if it’s free with in-app purchases, you can mention that basic features are free, but certain premium features might be available for purchase.]",
  // },
  {
    question: "How do I report or block someone?",
    answer:
      "We prioritize user safety. If you ever feel uncomfortable or find someone behaving inappropriately, you can easily report or block them through the app interface. Our team will review the report promptly.",
  },
  {
    question: "Can I deactivate or delete my account?",
    answer:
      "Yes, you can. Go to settings in your dashboard and follow the instructions to either deactivate or permanently delete your account.",
  },
];
