import styled from "@emotion/styled";
import BackgroundImage from "../../assets/images/background2.webp";

const Background2 = styled.div`
  position: absolute;
  top: 70px; // Accounts for the NavBar
  left: 0;
  width: 100%;
  height: calc(100vh - 70px); //Accounts for the NavBar
  z-index: -1;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image:
    linear-gradient(180deg, #030512 0%, rgba(3, 5, 18, 0.00) 73.97%, #030512 100%),
      url(${BackgroundImage});
    background-size: cover;
    background-position: center;
  }
`;

export default Background2;