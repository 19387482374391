import React from "react";
import styled from "@emotion/styled";
import Typography from "../Typography";

export interface InputTextProps {
  disabled?: boolean;
  error?: string | null;
  fullWidth?: boolean;
  label?: string;
  isNumeric?: boolean;
  margin?: string;
  min?: number;
  max?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  value: string | number;
}

const InputOuterWrapper = styled.div<{
  disabled?: boolean;
  fullWidth?: boolean;
  margin?: string;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  ${({ fullWidth }) => fullWidth && "width: 100%;"};
  ${({ margin }) => margin && `margin: ${margin};`};

  ${({ disabled }) =>
		disabled &&
    `
    opacity: 0.5; 
    pointer-events: none;
  `}
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledLabel = styled.label<{ isVisible: boolean }>`
  position: absolute;
  top: 8px;
  left: 12px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightGray};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  width: calc(100% - 12px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const StyledInput = styled.input<{ hasValue: boolean; isError: boolean }>`
  width: calc(100% - 24px);
  border: 1px solid
    ${({ theme, isError }) => (isError ? "red" : theme.colors.darkGray)};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.containerBase};
  color: ${({ disabled, theme }) =>
		disabled ? theme.colors.secondaryLightGray : theme.colors.white};
  padding: ${({ hasValue }) => (hasValue ? "24px 12px 8px 12px" : "16px 12px")};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.accentViolet};
    box-shadow: 0px 0px 30px 0px rgba(204, 28, 210, 0.5);
  }
`;

const ErrorMessage = styled(Typography)`
  position: absolute;
  left: 8px;
  bottom: -24px;
  min-height: 20px;
`;

const InputText: React.FC<InputTextProps> = ({
	disabled,
	error,
	fullWidth,
	label,
	margin,
	min,
	max,
	onChange,
	placeholder,
	type,
	value,
	isNumeric,
	...props
}) => {
	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (isNumeric) {
			const keyValue = event.key;
			const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];
			const numericInput = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
			if (keyValue === "ArrowUp" || keyValue === "ArrowDown") {
				event.preventDefault(); // prevent the default behavior
				const currentValue = Number(event.currentTarget.value);
				const increment = keyValue === "ArrowUp" ? 1 : -1;
				let newValue = currentValue + increment;
				if (min !== undefined && newValue < min) {
					newValue = min;
				}
				if (max !== undefined && newValue > max) {
					newValue = max;
				}
				const newValueAsString = newValue.toString();
				onChange({
					target: { value: newValueAsString },
				} as React.ChangeEvent<HTMLInputElement>); // trigger the onChange handler
			} else if (
				!numericInput.includes(keyValue) &&
        !allowedKeys.includes(keyValue)
			) {
				event.preventDefault(); // prevent the default behavior
			}
		}
	};

	return (
		<InputOuterWrapper
			disabled={disabled}
			fullWidth={fullWidth}
			margin={margin}
			{...props}
		>
			<InputWrapper>
				{label && <StyledLabel isVisible={!!value}>{label}</StyledLabel>}
				<StyledInput
					hasValue={!!value && !!label}
					min={min}
					max={max}
					isError={!!error}
					autoComplete="new-password"
					onChange={onChange}
					onKeyDown={handleKeyDown}
					placeholder={!value ? placeholder : ""}
					type={type || "text"}
					value={value}
				/>
			</InputWrapper>
			{error && (
				<ErrorMessage
					color="errorRed"
					fontFamily="body"
					fontSize={14}
					fontWeight={400}
					margin="12px 0 0"
				>
					{error}
				</ErrorMessage>
			)}
		</InputOuterWrapper>
	);
};

export default InputText;
