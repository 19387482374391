import React, { useState } from "react";
import styled from "@emotion/styled";
import Button from "../Buttons/Button";
import ToggleSwitch from "../Inputs/Toggle";
import Typography from "../Typography";

const NotificationTabContainer = styled.div`
  padding: 40px 32px;
  box-sizing: border-box;
`;

const ToggleGroup = styled.div`
  margin: 40px 0;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &last-child {
    margin-bottom: 0;
  }
`;

const NotificationTab = () => {
	const [emailNotifications, setEmailNotifications] = useState(false);
	const [textNotifications, setTextNotifications] = useState(false);

	return (
		<NotificationTabContainer>
			<Typography
				fontSize={24}
				fontWeight={700}
			>
        Notifications
			</Typography>
			<ToggleGroup>
				<ToggleContainer>
					<ToggleSwitch
						checked={emailNotifications}
						onChange={() => setEmailNotifications(prev => !prev)}
					/>
					<Typography
						color="secondaryLightGray"
						fontFamily="body"
						fontWeight={400}
						margin="0 12px"
					>
            Email
					</Typography>
				</ToggleContainer>
				<ToggleContainer>
					<ToggleSwitch
						checked={textNotifications}
						onChange={() => setTextNotifications(prev => !prev)}
					/>
					<Typography
						color="secondaryLightGray"
						fontFamily="body"
						fontWeight={400}
						margin="0 12px"
					>
            Text Message
					</Typography>
				</ToggleContainer>
			</ToggleGroup>
			<Button>
        Save Changes
			</Button>
		</NotificationTabContainer>
	);
};

export default NotificationTab;

