import React from "react";
import styled from "@emotion/styled";
import { Link as RouterLink, useMatch } from "react-router-dom";
import Icon from "../Icon";
import { CustomTheme } from "../../theme";

export interface LinkProps {
  children?: React.ReactNode;
  color?: string;
  icon?: string;
  iconColor?: keyof CustomTheme["colors"];
  iconPosition?: "left" | "right";
  onClick?: () => void;
	state?: any;
  to: string;
  text?: string;
  useActiveColor?: boolean;
  useActiveUnderline?: boolean;
}

const IconWrapper = styled.span<{ iconPosition: "left" | "right" }>`
  ${(props) =>
		props.iconPosition === "left" ? "margin-right: 4px;" : "margin-left: 4px;"}
`;

const Link: React.FC<LinkProps> = ({
	color,
	icon,
	iconColor,
	iconPosition = "right",
	text,
	to,
	useActiveColor = false,
	useActiveUnderline = false,
	children,
	...props
}) => {
	const isActive = !!useMatch(to);

	const StyledLink = styled(RouterLink)`
    align-items: center;
    color: ${({ theme }) =>
		isActive && useActiveColor
			? theme.colors.primaryPink
			: color
				? color
				: theme.colors.white};
    display: inline-flex;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 700;
    text-decoration: ${isActive && useActiveUnderline
		? "solid underline white 2px"
		: "none"};

    &:hover {
      color: ${({ theme }) => theme.colors.primaryPink};
      text-decoration: ${({ theme }) =>
		isActive && useActiveUnderline
			? `solid underline ${theme.colors.primaryPink} 2px`
			: "none"};
    }
  `;

	return (
		<StyledLink to={to} {...props}>
			{icon && iconPosition === "left" && (
				<IconWrapper iconPosition="left">
					<Icon color={iconColor} icon={icon} />
				</IconWrapper>
			)}
			<span>
				{text}
				{children}
			</span>
			{icon && iconPosition === "right" && (
				<IconWrapper iconPosition="right">
					<Icon color={iconColor} icon={icon} />
				</IconWrapper>
			)}
		</StyledLink>
	);
};

export default Link;
