import React from "react";
import styled from "@emotion/styled";
import ContactCardImage from "../../assets/images/contact-card.webp";
import GradientHeart from "../Shared/GradientHeart";
import StaticOrbit from "../Shared/StaticOrbit";
import ScrollFadeIn from "../Animations/ScrollFadeIn";
import { useViewport } from "../../hooks/ViewportProvider";

const FourthSectionContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ isMobile }) => (isMobile ? "120px 24px" : "175px 112px")};
  position: relative;
`;

const StyledOrbit = styled(StaticOrbit)`
  opacity: 0.5;
`;

const Headline = styled.h1<{ isMobile: boolean }>`
  color: ${({ theme }) => theme.colors.primaryPink};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 12px;
  text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
`;

const SubHeadline = styled.h1<{ isMobile: boolean }>`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ isMobile }) => (isMobile ? "24px" : "32px")};
  font-weight: 500;
  margin: 0;
  text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
`;

const BodyText = styled.p<{ isMobile: boolean }>`
  color: ${({ theme }) => theme.colors.lightGray};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 400;
  margin: 16px auto 32px;
  max-width: 600px;
  text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
`;

const ContactCard = styled.img`
  width: 100%;
  height: auto;
  max-width: 676px;
  max-height: 395px;
`;

const LargeHeart = styled.div`
  position: absolute;
  top: 20px;
  left: 15%;
`;

const FourthSection = () => {
  const viewport = useViewport();
  const isMobile = viewport === "mobile" || viewport === "tablet";
  const isMobileSmall = viewport === "mobile";

  return (
    <FourthSectionContainer isMobile={isMobile}>
      <StyledOrbit />
      <LargeHeart>
        <GradientHeart scale={2.5} />
      </LargeHeart>
      <ScrollFadeIn
        delay={100}
        direction="down"
        disableOnMobile={isMobileSmall}
        once
      >
        <Headline isMobile={isMobile}>WHAT HAPPENS NEXT</Headline>
        <SubHeadline isMobile={isMobile}>
          Introducing the Contact Card
        </SubHeadline>
        <BodyText isMobile={isMobile}>
          An exciting and creative way to stay in touch with your matches
          outside the app. Fully customizable with intricate designs allowing
          you to stay connected in style.
        </BodyText>
        <ContactCard src={ContactCardImage} alt="Heart 2 Heart contact card" />
      </ScrollFadeIn>
    </FourthSectionContainer>
  );
};

export default FourthSection;
