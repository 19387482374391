import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import type { ContactCard } from "../../types/ContactCard";

interface ContactCardState {
  contactCard: ContactCard | null;
  error: string | null;
  isLoading: boolean;
}

const initialState: ContactCardState = {
	contactCard: null,
	error: null,
	isLoading: false
};

export const contactCardSlice = createSlice({
	name: "contactCard",
	initialState,
	reducers: {
		setContactCard: (state, action: PayloadAction<ContactCard | null>) => {
			state.contactCard = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setError: (state, action: PayloadAction<string | null>) => {
			state.error = action.payload;
		}
	}
});

export const { setContactCard, setError, setLoading } = contactCardSlice.actions;
export const selectContactCard = (state: RootState) => state.contactCard.contactCard;
export const selectError = (state: RootState) => state.user.error;
export const selectIsLoading = (state: RootState) => state.user.isLoading;

export default contactCardSlice.reducer;