import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../pages/LoadingPage";
import { fetchProfile } from "../redux/actions/userActions";
import { AppDispatch } from "../redux/store";

const AuthSuccess: React.FC<{ setToken: (token: string) => void }> = ({ setToken }) => {
	const navigate = useNavigate();
	const dispatch: AppDispatch = useDispatch();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get("token");

		if (token) {
			localStorage.setItem("token", token);
			setToken(token);
		}
		dispatch(fetchProfile()).
			then(() => navigate("/"));

	}, [navigate]);

	return (
		<LoadingPage />
	);
};

export default AuthSuccess;

