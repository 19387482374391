import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Button from "./Button";
import Icon from "../Icon";
import Typography from "../Typography";
import { logoutUser } from "../../redux/actions/userActions";
import { avatarProfileData, areAvatarProfileDataEqual } from "../../redux/selectors/userSelectors";

import type { AppDispatch } from "../../redux/store";

export interface ButtonProps {
  profilePictureUrl?: string;
}

const Container = styled.div`
  margin: 24px;
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.containerBase};
  cursor: pointer;
`;

const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BottomContent = styled.div<{ isOpen: boolean }>`
  height: ${({ isOpen }) => isOpen ? "100%" : "0"};
  transition: height .2s east-in-out;
  overflow: hidden;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 10px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.primaryPink};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  width: 32px;
  border-radius: 10px;
  aspect-ratio: 1 / 1;
`;

const StyledIcon = styled(Icon)<{ open?: boolean }>`
  margin-left: 10px;
  ${({ open }) => open && "transform: rotate(180deg);"}
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const MobileProfileButton: React.FC<ButtonProps> = () => {
	const dispatch: AppDispatch = useDispatch();
	const userData = useSelector(avatarProfileData, areAvatarProfileDataEqual);
	const [isOpen, setIsOpen] = useState(false);

	const avatar = userData?.avatarCropped;

	const getUserInitials = () => {
		const firstInitial = userData?.first_name?.charAt(0) as string;
		const lastInitial = userData?.last_name?.charAt(0) as string;
		return firstInitial + lastInitial;
	};

	return (
		<Container onClick={() => setIsOpen(prev => !prev)}>
			<TopContent>
				<LeftSection>
					<Avatar>
						{avatar ? (
							<StyledImg src={avatar} />
						) : (
							<Typography
								color="white"
								fontFamily="body"
								fontSize={14}
								fontWeight={400}
							>
								{getUserInitials()}
							</Typography>
						)}
					</Avatar>
					<Typography
						fontFamily="body"
						fontSize={16}
						fontWeight={400}
						margin="0 0 0 8px"
					>
						{userData?.first_name}
					</Typography>
				</LeftSection>
				<StyledIcon icon="caret-down" color="white" open={isOpen} />
			</TopContent>
			<BottomContent isOpen={isOpen}>
				<StyledButton fullWidth onClick={() => dispatch(logoutUser())}>
          Log Out
				</StyledButton>
			</BottomContent>
		</Container>
	);
};

export default MobileProfileButton;