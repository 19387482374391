import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Typography from "../Typography";

type TimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

type CountdownTimerProps = {
  targetDate: string;
  fontSize?: number;
  colonSpacing?: string;
};

const StyledTypography = styled(Typography)`
  text-align: center;
  line-height: 120%;
`;

const CountdownContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TimeDisplay = styled.div<{ colonSpacing: string }>`
  display: "inline-block";
  margin-right: ${({ colonSpacing }) => colonSpacing};
  text-align: center;
  &:last-child {
    margin-right: 0;
  }
`;

const Countdown: React.FC<CountdownTimerProps> = ({
	targetDate,
	fontSize,
	colonSpacing,
}) => {
	const calculateTimeLeft = (): TimeLeft => {
		const difference = +new Date(targetDate) - +new Date();
		let timeLeft: TimeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		}

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearTimeout(timer);
	});

	const _fontSize = fontSize || 64;

	const renderTime = (time: number, label: string) => {
		return (
			<TimeDisplay colonSpacing={colonSpacing || "48px"}>
				<Typography
					fontSize={fontSize}
					fontWeight={500}
					color="white"
					margin={"0px 0px 12px 0px"}
				>
					{time.toString().padStart(2, "0")}
				</Typography>
				<Typography fontSize={16} fontWeight={500} color="white">
					{label}
				</Typography>
			</TimeDisplay>
		);
	};

	return (
		<CountdownContainer>
			{renderTime(timeLeft.days, "Days")}
			<StyledTypography
				fontSize={_fontSize - 16}
				fontWeight={500}
				color="white"
				margin={`0px ${colonSpacing} 24px 0px`}
			>
        :
			</StyledTypography>
			{renderTime(timeLeft.hours, "Hours")}
			<StyledTypography
				fontSize={_fontSize - 16}
				fontWeight={500}
				color="white"
				margin={`0px ${colonSpacing} 24px 0px`}
			>
        :
			</StyledTypography>
			{renderTime(timeLeft.minutes, "Minutes")}
			<StyledTypography
				fontSize={_fontSize - 16}
				fontWeight={500}
				color="white"
				margin={`0px ${colonSpacing} 24px 0px`}
			>
        :
			</StyledTypography>
			{renderTime(timeLeft.seconds, "Seconds")}
		</CountdownContainer>
	);
};

export default Countdown;
