import React from "react";
import styled from "@emotion/styled";

export interface HamburgerButtonProps {
  isOpen: boolean;
  onClick: () => void;
}

const ButtonIcon = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  justify-content: center;
`;

const MenuWrapper = styled.div<{ isExpanded: boolean }>`
  span:first-of-type {
    transform: translateY(0) rotateZ(0);
    transition: transform 400ms ease-in-out;
    ${({ isExpanded }) =>
		isExpanded &&
      `
      transform: translateY(5px) rotateZ(45deg);
    transition: transform 400ms ease-in-out;
  `}
  }
  span:nth-last-of-type(2) {
    width: 16px;
    transform: translateX(0);
    transition: all 400ms;
    ${({ isExpanded }) =>
		isExpanded &&
      `
    width: 0;
    transform: translateX(8px);
    transition: all 400ms;
  `}
  }
  span:last-child {
    transform: translateY(0) rotateZ(0);
    transition: transform 400ms ease-in-out;
    margin-bottom: 0;
    ${({ isExpanded }) =>
		isExpanded &&
      `
      transform: translateY(-5px) rotateZ(-45deg);
    transition: transform 400ms ease-in-out;
  `}
  }
`;

const HamburgerLine = styled.span<{ isExpanded: boolean }>`
  display: block;
  width: 16px;
  height: 2px;
  border-radius: 2px;
  opacity: 1;
  margin-bottom: 3px;
  background-color: ${({ theme }) => theme.colors.primaryPink};
`;

const HamburgerButton: React.FC<HamburgerButtonProps> = ({ isOpen: isExpanded, onClick }) => {

	const onHamburgerButtonClick = () => {
		onClick && onClick();
	};

	return (
		<ButtonIcon onClick={() => onHamburgerButtonClick()}>
			<MenuWrapper isExpanded={isExpanded}>
				<HamburgerLine isExpanded={isExpanded} />
				<HamburgerLine isExpanded={isExpanded} />
				<HamburgerLine isExpanded={isExpanded} />
			</MenuWrapper>
		</ButtonIcon>
	);
};

export default HamburgerButton;
