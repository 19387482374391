import React from "react";
import styled from "@emotion/styled";
import Typography from "../Typography";
import { useViewport } from "../../hooks/ViewportProvider";

interface Step {
  step: number;
  label: string;
}
export interface StepperProps {
  steps: Step[];
  currentStep: number;
  furthestStep: number;
  isSticky?: boolean;
  setCurrentStep: (step: number) => void;
}

interface PillProps {
  active: boolean;
  previous: boolean;
}

const StepperWrapper = styled.div<{ isSticky?: boolean }>`
  width: 100%;
  transition: background-color 0.5s ease;

  ${({ isSticky, theme }) =>
		isSticky &&
    `
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: ${theme.colors.primaryMidnight};
  `}
`;

const StepperContent = styled.div<{ isSticky?: boolean; isMobile: boolean }>`
  transition: padding 0.5s ease;
  max-width: ${({ isMobile }) => (isMobile ? "calc(100vw - 48px)" : "676px")};
  margin: 0 auto;
  padding: ${({ isSticky }) => (isSticky ? "40px 0" : "0")};
`;

const StepCounter = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: flex-start;
`;

const PillsWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;

const PillWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 24px;
  flex-grow: 1;
  margin-right: 12px;
  cursor: pointer;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }
`;

const Pill = styled.div<PillProps>`
  background: ${({ active, previous, theme }) =>
		active
			? theme.gradients.primary
			: previous
				? theme.colors.primaryPink
				: theme.colors.lightGray};
  border-radius: ${({ active }) => (active ? "12px" : "17px")};
  height: ${({ active }) => (active ? "6px" : "2px")};
  flex-grow: 1;
`;

const StyledLabel = styled(Typography)`
  position: absolute;
  bottom: -20px;
`;

const Stepper: React.FC<StepperProps> = ({
	steps,
	currentStep,
	furthestStep,
	isSticky,
	setCurrentStep,
}) => {
	const viewport = useViewport();
	const isMobile = viewport === "mobile" || viewport === "tablet";

	const pills = Array(steps.length)
		.fill(0)
		.map((_, index) => (
			<PillWrapper
				key={index}
				onClick={() => {
					if (index + 1 <= furthestStep) {
						setCurrentStep(index + 1);
					}
				}}
			>
				<Pill
					active={index + 1 === currentStep}
					previous={index + 1 < currentStep}
					key={index}
				/>
				{!isMobile && (
					<StyledLabel
						color="secondaryLightGray"
						fontFamily="body"
						fontSize={12}
						fontWeight={300}
						margin="4px 0 0"
					>
						{steps[index].label}
					</StyledLabel>
				)}
			</PillWrapper>
		));
	return (
		<StepperWrapper isSticky={isSticky}>
			<StepperContent isMobile={isMobile} isSticky={isSticky}>
				<StepCounter>
					<Typography
						variant="h3"
						color="primaryPink"
						fontSize={24}
						fontWeight={500}
						margin="0 4px 0 0"
					>{`${currentStep}`}</Typography>
					<Typography
						variant="h3"
						color="lightGray"
						fontWeight={500}
					>{`/${steps.length}`}</Typography>
				</StepCounter>
				<PillsWrapper>{pills}</PillsWrapper>
			</StepperContent>
		</StepperWrapper>
	);
};

export default Stepper;
