import React, { useRef } from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import ButtonText from "../Buttons/ButtonText";
import Icon from "../Icon";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

export interface ModalProps {
  content: React.ReactNode;
  trigger?: React.ReactElement;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ModalContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 25;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.22s;

  ${({ isOpen }) => isOpen &&  `
    visibility: visible;
    opacity: 1;
  `}
`;

const ContentContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 1005;
  top: 50%;
  left: 50%;
  background-color: ${({ theme }) => theme.colors.containerBase};
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 6px;
  width: 80%;
  max-width: 327px;
  padding: 40px 20px;
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.7);

  @keyframes modalbounce {
    0% {
      transform: translate(-50%, -50%) scale(0.7);
      opacity: 0;
    }
    60% {
      transform: translate(-50%, -50%) scale(1.05);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  ${({ isOpen }) => isOpen &&  `
    animation: modalbounce 0.2s forwards;
    visibility: visible;
  `}
`;

const StyledButton = styled(ButtonText)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const Modal: React.FC<ModalProps> = ({
	content,
	trigger,
	isOpen,
	setIsOpen,
	...rest
}) => {
	const modalRef = useRef(null);

	useOnClickOutside(modalRef, () => {
		setIsOpen(false);
	});

	const triggerWithHandler = trigger
		? React.cloneElement(trigger, {
			onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				if (trigger.props.onClick) {
					trigger.props.onClick(e);
				}
				setIsOpen(true);
			},
		})
		: null;

	const modalContent = (
		<>
			<Overlay isOpen={isOpen} onClick={() => setIsOpen(false)} />
			<ContentContainer isOpen={isOpen} ref={modalRef}>
				<StyledButton onClick={() => setIsOpen(false)}>
					<Icon color="white" icon="x" />
				</StyledButton>
				{content}
			</ContentContainer>
		</>
	);

	const modalRoot = document.getElementById("modal-root");

	return (
		<>
			{triggerWithHandler && <ModalContainer {...rest}>{triggerWithHandler}</ModalContainer>}
			{isOpen && modalRoot && ReactDOM.createPortal(modalContent, modalRoot)}
		</>
	);
};

export default Modal;