import React, { useState } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import Footer from "../components/Shared/Footer";
import Banner from "../components/Shared/Banner";
import Button from "../components/Buttons/Button";
import Icon from "../components/Icon";
import InputText from "../components/Inputs/InputText";
import TextArea from "../components/Inputs/TextArea";
import { useViewport } from "../hooks/ViewportProvider";
import { selectUser } from "../redux/reducers/userReducer";

interface ContactFormStateProps {
  name: string;
  email: string;
  subject: string;
  message: string;
}

interface ContactFormErrors {
  name?: string;
  email?: string;
  subject?: string;
  message?: string;
}

const ContactContainer = styled.div<{ isMobile: boolean }>`
  background: radial-gradient(
    circle at 100% 30%,
    ${({ theme }) => `${theme.colors.accentCyan}25`},
    transparent 50%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: ${({ isMobile }) => (isMobile ? "77px" : "94.5px")};
`;

const Heading = styled.h1<{
  isMobile: boolean;
}>`
  font-size: ${({ isMobile }) => (isMobile ? "24px" : "40px")};
  font-weight: 500;
  margin: 0;
  text-align: center;
`;

const SubHeading = styled.p`
  color: ${({ theme }) => `${theme.colors.white}70`};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 18px;
  font-weight: 400;
  margin: 12px 0 32px;
  text-align: center;
`;

const ContactContent = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${({ isMobile }) => (isMobile ? "48px 24px" : "80px 0 16px")};
`;

const FormContainer = styled.div<{
  viewport: any;
}>`
  display: block;
  background: linear-gradient(
    48deg,
    rgba(217, 2, 185, 0.1) 0%,
    rgba(217, 2, 185, 0) 100%
  );
  border: 1px solid ${({ theme }) => theme.colors.white}70;
  border-radius: 12px;
  box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
    0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  max-width: ${({ viewport }) =>
    viewport === "laptop" ? "calc(100vw - 48px)" : "800px"};
  padding: 32px;
  width: 100%;
  box-sizing: border-box;
  margin: ${({ viewport }) => (viewport === "laptop" ? "0px 24px" : 0)};
`;

const MobileFormContainer = styled.div`
  width: 100%;
`;

const NameEmail = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  width: ${({ isMobile }) => (isMobile ? "100%" : "auto")};
`;

const ButtonWrapper = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column-reverse" : "row")};
  align-items: center;
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "flex-end")};
  margin-top: 32px;
  width: 100%;
`;

const SubmitError = styled.p`
  color: ${({ theme }) => theme.colors.errorRed};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 18px;
  font-weight: 400;
  margin-right: 24px;
`;

const StyledButton = styled(Button)``;

const ConfirmationContainer = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  box-sizing: border-box;
  margin: ${({ isMobile }) => (isMobile ? "0px 24px" : 0)};
`;

const ConfirmationTitle = styled.h2`
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 12px;
`;

const ConfirmationSubtitle = styled.p<{
  isMobile: boolean;
}>`
  color: ${({ theme }) => `${theme.colors.white}70`};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 18px;
  font-weight: 400;
`;

const ContactPage = () => {
  const user = useSelector(selectUser);
  const isLoggedIn = !!user;
  const viewport = useViewport();
  const isMobile = viewport === "mobile" || viewport === "tablet";
  const [formData, setFormData] = useState<ContactFormStateProps>({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState<ContactFormErrors>({});
  const [submitError, setSubmitError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const inputMarginBottom = "0px 0px 32px 0px";

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateFormData = (formData: ContactFormStateProps) => {
    const { name, email, subject, message } = formData;
    const errors: ContactFormErrors = {};

    if (!name) {
      errors.name = "Name is required";
    }

    if (!email) {
      errors.email = "Email is required";
    } else if (!validateEmail(email)) {
      errors.email = "Email is not valid";
    }

    if (!subject) {
      errors.subject = "Subject is required";
    }

    if (!message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleSubmit = async () => {
    setSubmitError("");
    const errors = validateFormData(formData);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/contact`, formData, {
        withCredentials: true,
      });

      setLoading(false);
      setIsFormSubmitted(true);
    } catch (error) {
      setLoading(false);
      setSubmitError("Message failed to send. Please try again.");
      console.error("Error sending sending message: ", error);
    }
  };

  const Container = isMobile ? MobileFormContainer : FormContainer;

  return (
    <ContactContainer isMobile={isMobile}>
      {submitError && (
        <Banner variant="error" onClose={() => setSubmitError("")}>
          &#9888; {submitError} Hello there is an error
        </Banner>
      )}
      {!isFormSubmitted ? (
        <ContactContent isMobile={isMobile}>
          <Heading isMobile={isMobile}>We’d love to hear from you.</Heading>
          <SubHeading>
            Contact us regarding any concerns or inquiries.
          </SubHeading>
          <Container viewport={viewport}>
            <NameEmail isMobile={isMobile}>
              <InputText
                error={formErrors.name}
                fullWidth
                label="Name"
                margin={isMobile ? inputMarginBottom : "0 24px 0 0"}
                placeholder="Name"
                value={formData.name}
                onChange={(e) => {
                  setFormData({ ...formData, name: e.target.value });
                  setFormErrors({ ...formErrors, name: undefined });
                  setSubmitError("");
                }}
              />
              <InputText
                error={formErrors.email}
                fullWidth
                label="Email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value });
                  setFormErrors({ ...formErrors, email: undefined });
                  setSubmitError("");
                }}
              />
            </NameEmail>
            <InputText
              error={formErrors.subject}
              fullWidth
              label="Subject"
              margin="0 0 32px 0px"
              placeholder="Subject"
              value={formData.subject}
              onChange={(e) => {
                setFormData({ ...formData, subject: e.target.value });
                setFormErrors({ ...formErrors, subject: undefined });
                setSubmitError("");
              }}
            />
            <TextArea
              error={formErrors.message}
              maxLength={500}
              onChange={(e) => {
                setFormData({ ...formData, message: e.target.value });
                setFormErrors({ ...formErrors, message: undefined });
                setSubmitError("");
              }}
              placeholder="Message"
              rows={5}
              value={formData.message}
            />
            <ButtonWrapper isMobile={isMobile}>
              {submitError && <SubmitError>{submitError}</SubmitError>}
              <StyledButton
                loading={loading}
                onClick={handleSubmit}
                fullWidth={viewport === "mobile"}
              >
                Send Message
              </StyledButton>
            </ButtonWrapper>
          </Container>
        </ContactContent>
      ) : (
        <ConfirmationContainer isMobile={isMobile}>
          <Icon icon="complete-check" color="accentCyan" />
          <ConfirmationTitle>Message Sent</ConfirmationTitle>
          <ConfirmationSubtitle isMobile={isMobile}>
            Thank you for your message. We’ll be in touch soon.
          </ConfirmationSubtitle>
        </ConfirmationContainer>
      )}
      {!isLoggedIn && <Footer isVisible={false} />}
    </ContactContainer>
  );
};

export default ContactPage;
