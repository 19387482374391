import React, { useState, useRef } from "react";
import styled from "@emotion/styled";
import Icon from "../Icon";
import Typography from "../Typography";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

export interface SelectDropdownProps {
  label?: string;
  margin?: string;
  onChange: (value: string | number) => void;
  options: Array<{ label: string | number; value: string | number }>;
  placeholder?: string;
  value: string | number;
}

const SelectWrapper = styled.div<{ margin: string | undefined }>`
  cursor: pointer;
  position: relative;
  height: 100%;
  width: 100%;

  ${({ margin }) => margin && `margin: ${margin};`};
`;

const DropdownItems = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  max-height: 210px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.accentViolet};
  background-color: ${({ theme }) => theme.colors.containerBase};
  box-shadow: 0px 4px 14px 0px rgba(255, 255, 255, 0.10);
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  overflow-y: auto;
  z-index: 999;
`;

const DropdownItem = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  &:hover {
    background-color: ${({ theme }) => theme.colors.borderGray};
  }
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};

  &:last-child {
    border-bottom: none;
  }
`;

const StyledLabel = styled.label<{ isVisible: boolean }>`
  position: absolute;
  top: 8px;
  left: 12px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.lightGray};
  font-family: ${({ theme }) => theme.fonts.body};
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledSelect = styled.div<{ hasValue: boolean }>`
  width: calc(100% - 24px);
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.containerBase};
  padding: ${({ hasValue }) => (hasValue ? "24px 12px 8px 12px" : "16px 12px")};
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.body};
  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.accentViolet};
    box-shadow: 0px 0px 30px 0px rgba(204, 28, 210, 0.5);
  }
`;

const SelectDropdown: React.FC<SelectDropdownProps> = ({
	label,
	margin,
	onChange,
	options,
	placeholder,
	value,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggleDropdown = () => setIsOpen(!isOpen);
	const ref = useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, () => setIsOpen(false));

	const handleSelection = (val: string | number) => {
		onChange(val);
		setIsOpen(false);
	};

	const selectedLabel = options.find((option) => option.value === value)?.label;

	return (
		<SelectWrapper margin={margin} ref={ref}>
			{label && <StyledLabel isVisible={!!value}>{label}</StyledLabel>}
			<StyledSelect
				onClick={toggleDropdown}
				hasValue={!!value && !!label}
			>
				<Typography fontFamily="body" fontWeight={400}>
					{selectedLabel || placeholder}
				</Typography>
				<IconWrapper>
					<Icon icon="caret-down" />
				</IconWrapper>
			</StyledSelect>
			<DropdownItems isOpen={isOpen}>
				{options.map((option, idx) => (
					<DropdownItem key={`${option.value}-${idx}`} onClick={() => handleSelection(option.value)}>
						{option.label}
					</DropdownItem>
				))}
			</DropdownItems>
		</SelectWrapper>
	);
};

export default SelectDropdown;
