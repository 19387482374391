import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import contactCardReducer from "./reducers/contactCardReducer";
import interestsReducer from "./reducers/interestsReducer";
import onboardingReducer from "./reducers/onboardingReducer";
import snackbarReducer from "./reducers/snackbarReducer";
import userReducer from "./reducers/userReducer";
import { paymentMethodsApi } from "./features/paymentMethodsApi";
import { subscriptionsApi } from "./features/subscriptionsApi";

const rootReducer = combineReducers({
	contactCard: contactCardReducer,
	interests: interestsReducer,
	onboarding: onboardingReducer,
	snackbar: snackbarReducer,
	user: userReducer,
	[paymentMethodsApi.reducerPath]: paymentMethodsApi.reducer,
	[subscriptionsApi.reducerPath]: subscriptionsApi.reducer
});

const persistConfig = {
	key: "root",
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => 
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ["persist/PERSIST", "persist/REHYDRATE", "persist/PURGE"],
			},
		})
			.concat(paymentMethodsApi.middleware)
			.concat(subscriptionsApi.middleware),
});

export const persistor = persistStore(store);

// Uncomment the following line to purge the persisted state
// persistor.purge();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;