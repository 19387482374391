import React from "react";
import styled from "@emotion/styled";

interface PillProps {
  label: string;
  value: any;
  selected: boolean;
  disabled?: boolean;
  onClick: (value: any, selected: boolean) => void;
}

const StyledPill = styled.button<Pick<PillProps, "selected">>`
  border-radius: 20px;
  border: 1px solid;
  padding: 7px 12px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  margin: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  background: ${({ selected, theme }) =>
		selected ? `${theme.colors.accentViolet}40` : theme.colors.containerBase};
  border-color: ${({ selected, theme }) =>
		selected ? theme.colors.accentViolet : theme.colors.borderGray};
  ${({ selected }) =>
		selected && "box-shadow: 0px 0px 30px 0px rgba(204, 28, 210, 0.50);"}
`;

const Pill: React.FC<PillProps> = ({
	label,
	value,
	selected,
	disabled,
	onClick,
	...props
}) => {
	const handleClick = () => onClick(value, !selected);

	return (
		<StyledPill
			selected={selected}
			disabled={!selected && disabled}
			onClick={handleClick}
			{...props}
		>
			{label}
		</StyledPill>
	);
};

export default Pill;
