import React from "react";
import styled from "@emotion/styled";
import DefaultAvatar from "../../assets/images/avatar.png";
import GradientHeart from "../Shared/GradientHeart";
import Icon from "../Icon";
import Pill from "../Inputs/Pill";
import Typography from "../Typography";
import theme, { CustomTheme } from "../../theme";
import Pattern1 from "../../assets/images/pattern1.png";
import Pattern2 from "../../assets/images/pattern2.png";
import PatternLogo from "../../assets/images/pattern-logo.png";
import BlackPattern from "../../assets/images/black-pattern-3.svg";
import BluePattern from "../../assets/images/blue-pattern-3.svg";
import PinkPattern from "../../assets/images/pink-pattern-3.svg";
import { Interest } from "../../types/Interest";

const colorConfigs = {
	pink: {
		primary: theme.colors.primaryPink,
		secondary: theme.colors.mediumGray,
		borderColor: theme.colors.primaryPink,
		iconColor: "primaryPink",
		thirdStyleBg: PinkPattern,
	},
	cyan: {
		primary: theme.colors.accentCyan,
		secondary: theme.colors.mediumGray,
		borderColor: theme.colors.accentCyan,
		iconColor: "accentCyan",
		thirdStyleBg: BluePattern,
	},
	blackAndWhite: {
		primary: theme.colors.mediumGray,
		secondary: theme.colors.white,
		borderColor: theme.colors.white,
		iconColor: "white",
		thirdStyleBg: BlackPattern,
	}
};

interface ContactCardProps {
  age?: number;
  avatar?: string;
  birthday?: string;
  cardStyle?: 1 | 2 | 3;
  colorScheme: "pink" | "cyan" | "blackAndWhite";
  email?: string;
  facebook?: string;
  headline?: string;
  instagram?: string;
  interests?: Interest[];
  job_title?: string;
  location?: string;
  name: string;
  phoneNumber?: string;
  scale?: number;
  snapchat?: string;
  tiktok?: string;
  twitter?: string;
  website?: string;
  zodiac?: string;
}

const ContactCardContainer = styled.div<{ scale?: number }>`
  background: ${theme.colors.containerBase};
  border-radius: 12px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.20), 0px 3px 14px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px 0px rgba(0, 0, 0, 0.14);
  padding: 40px 32px;
  width: 676px;
  height: 382px;
  box-sizing: border-box;
  position: relative;

  transform: scale(${({ scale }) => scale});
  transform-origin: top left;
`;

const SideBackground = styled.div<{ cardStyle: 1 | 2 | 3; color1: string; color2: string}>`
  background: ${({ cardStyle, color1 }) => cardStyle === 1 ? `${color1}50` : `${color1}`};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 12%;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 11;
  ${({ cardStyle, color2}) => cardStyle === 2 && `border-left: 2rem solid ${color2}`};
`;

const Pattern1Image = styled.img`
  height: 100%;
`;

const Pattern2Image = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 12px;
  width: 100%;
  z-index: 9;
`;

const Pattern3Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  z-index: 12;
`;

const PatterLogoImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`;

const SocialLinks = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;;
  top: 0;
  bottom: 0;
  right: 56px;
  z-index: 16;
`;

const SocialLink = styled.a`
  background: linear-gradient(135deg, #373B4F 0%, #1C1E2A 100%);
  box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.25), 2px 2px 16px 0px rgba(0, 0, 0, 0.10), -2px -2px 16px 0px rgba(255, 255, 255, 0.10), 4px 4px 14px 0px rgba(255, 255, 255, 0.05) inset, 4px 4px 2px 0px rgba(217, 218, 223, 0.10) inset, -9px -13px 29px 0px rgba(48, 54, 86, 0.20) inset, -9px -13px 14px 0px rgba(48, 54, 86, 0.20) inset;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 48px;
  height: 48px;
  margin: 8px 0;
  border-radius: 50%;
`;

const AvatarNameContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 40px;
  z-index: 15;
`;

const Info1Container = styled.div`
  max-width: 500px;
`;

const Info2Container = styled.div<{ isThirdCardStyle: boolean }>`
  margin-bottom: 40px;
  ${({ isThirdCardStyle }) => isThirdCardStyle && "margin-left: 80px;"}
`;

const InfoFlex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  max-width: 350px;
`;

const Avatar = styled.div<{ borderColor: string, img?: string }>`
  background-image: ${({ img }) => img ? `url(${img});` : `url(${DefaultAvatar});`}
  background-size: cover;
  background-position: center;
  border: 3px solid ${({ borderColor }) => borderColor};
  border-radius: 50%;
  margin-right: 16px;
  position: relative;
  height: 106px;
  width: 106px;
`;

const HeartWrapper = styled.div<{ top: string, left: string }>`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
  z-index: 15;
`;

const InterestsContainer = styled.div<{ isThirdCardStyle: boolean }>`
  display: flex;
  ${({ isThirdCardStyle }) => isThirdCardStyle ? "margin-left: 72px; max-width: 450px; flex-wrap: wrap;" : "margin-left: -4px;"}
  button {
    z-index: 15;
  }
`;

const ContactCard: React.FC<ContactCardProps> = ({
	age,
	avatar,
	birthday,
	cardStyle,
	colorScheme,
	email,
	facebook,
	headline,
	instagram,
	interests,
	job_title,
	location,
	name,
	phoneNumber,
	scale = 1,
	snapchat,
	tiktok,
	twitter,
	zodiac,
}) => {
	const iconColor = colorConfigs[colorScheme]?.iconColor;

	return (
		<ContactCardContainer scale={scale}>
			{cardStyle === 2 && (
				<Pattern2Image src={Pattern2} />
			)}
			{cardStyle === (1 || 3) && (
				<PatterLogoImage src={PatternLogo} />
			)}
			<SideBackground 
				cardStyle={cardStyle as 1 | 2 | 3}
				color1={colorConfigs[colorScheme]?.secondary}
				color2={colorConfigs[colorScheme]?.primary}

			>
				{cardStyle === 1 && (
					<Pattern1Image src={Pattern1} />
				)}
			</SideBackground>
			{cardStyle === 3 && (
				<Pattern3Image src={colorConfigs[colorScheme].thirdStyleBg} />
			)}
			<SocialLinks>
				{facebook && (
					<SocialLink href={facebook} target="_blank">
						<Icon color={iconColor as keyof CustomTheme["colors"]}  icon="facebook-logo-3" />
					</SocialLink>
				)}
				{instagram && (
					<SocialLink href={instagram} target="_blank">
						<Icon color={iconColor as keyof CustomTheme["colors"]}  icon="instagram-logo" />
					</SocialLink>
				)}
				{twitter && (
					<SocialLink href={twitter} target="_blank">
						<Icon color={iconColor as keyof CustomTheme["colors"]}  icon="twitter-logo" />
					</SocialLink>
				)}
				{snapchat && (
					<SocialLink href={snapchat} target="_blank">
						<Icon color={iconColor as keyof CustomTheme["colors"]}  icon="snapchat-logo" />
					</SocialLink>
				)}
				{tiktok && (
					<SocialLink href={tiktok} target="_blank">
						<Icon color={iconColor as keyof CustomTheme["colors"]}  icon="tiktok-logo" />
					</SocialLink>
				)}
			</SocialLinks>
			<AvatarNameContainer>
				<HeartWrapper top="-22px" left="8px">
					<GradientHeart scale={1.2} />
				</HeartWrapper>
				<HeartWrapper top="-10px" left="-19px">
					<GradientHeart scale={0.9} />
				</HeartWrapper>
				<HeartWrapper top="6px" left="0">
					<GradientHeart scale={0.6} />
				</HeartWrapper>
				<Avatar borderColor={colorConfigs[colorScheme]?.borderColor} img={avatar} />
				<Info1Container>
					<Typography
						fontFamily="body"
						fontSize={20}
						lineHeight="28px"
					>
						{name} {age && `(${age})`}
					</Typography>
					{(job_title || location) && (
						<StyledTypography
							color="secondaryLightGray"
							fontFamily="body"
							fontSize={16}
							fontWeight={400}
							lineHeight="22px"
						>
							{job_title && <>{job_title}&nbsp;</>}{location && (<><Icon color={iconColor as keyof CustomTheme["colors"]}  icon="location" />&nbsp;{location}</>) }
						</StyledTypography>
					)}
					{headline && (
						<Typography
							fontFamily="body"
							fontSize={16}
							fontWeight={400}
							lineHeight="22px"
						>
							{headline}
						</Typography>
					)}
				</Info1Container>
			</AvatarNameContainer>
			<Info2Container isThirdCardStyle={cardStyle === 3}>
				<InfoFlex>
					{phoneNumber && (
						<StyledTypography
							color="secondaryLightGray"
							fontFamily="body"
							fontSize={16}
							fontWeight={400}
							lineHeight="22px"
							margin="0 12px 0 0"
						>
							<Icon color={iconColor as keyof CustomTheme["colors"]}  icon="phone" />
              &nbsp;
							{phoneNumber}
						</StyledTypography>
					)}
					{birthday && (
						<StyledTypography
							color="secondaryLightGray"
							fontFamily="body"
							fontSize={16}
							fontWeight={400}
							lineHeight="22px"
						>
							<Icon color={iconColor as keyof CustomTheme["colors"]}  icon="gift" />
              &nbsp;
							{birthday}
						</StyledTypography>
					)}
				</InfoFlex>
				<InfoFlex>
					{email && (
						<StyledTypography
							color="secondaryLightGray"
							fontFamily="body"
							fontSize={16}
							fontWeight={400}
							lineHeight="22px"
							margin="0 12px 0 0"
						>
							<Icon color={iconColor as keyof CustomTheme["colors"]}  width={16} height={16} icon="mail" />
              &nbsp;
							{email}
						</StyledTypography>
					)}
					{zodiac && (
						<StyledTypography
							color="secondaryLightGray"
							fontFamily="body"
							fontSize={16}
							fontWeight={400}
							lineHeight="22px"
						>
							<Icon color={iconColor as keyof CustomTheme["colors"]}  icon={zodiac.toLowerCase()} />
              &nbsp;
							{zodiac}
						</StyledTypography>
					)}
				</InfoFlex>
			</Info2Container>
			<InterestsContainer isThirdCardStyle={cardStyle === 3}>
				{interests?.map((interest) => {
					return (
						<Pill
							key={interest.id}
							label={interest.name}
							value={interest.id.toString()}
							onClick={() => undefined}
							selected={true}
						/>
					);
				})}
			</InterestsContainer>
		</ContactCardContainer>
	);
};

export default ContactCard;