export const GENDER_OPTIONS = [
	{ label: "Male", value: "male" },
	{ label: "Female", value: "female" },
	{ label: "Non-Binary", value: "non-binary" },
];

export const GENDER_OPTIONS_2 = [
	{ label: "Guy", value: "male" },
	{ label: "Girl", value: "female" },
	{ label: "Non-Binary", value: "non-binary" },
];

export const DAY_OPTIONS = Array.from({ length: 31 }, (_, i) => {
	const day = i + 1;
	return { label: String(day), value: day };
});

export const MONTH_OPTIONS = [
	{ label: "January", value: 1 },
	{ label: "February", value: 2 },
	{ label: "March", value: 3 },
	{ label: "April", value: 4 },
	{ label: "May", value: 5 },
	{ label: "June", value: 6 },
	{ label: "July", value: 7 },
	{ label: "August", value: 8 },
	{ label: "September", value: 9 },
	{ label: "October", value: 10 },
	{ label: "November", value: 11 },
	{ label: "December", value: 12 },
];

export const birthYearOptions = () => {
	const currentYear = new Date().getFullYear();
	const endYear = currentYear - 18;
	const startYear = currentYear - 100;
	const length = endYear - startYear + 1;

	return Array.from({ length }, (_, i) => {
		const year = endYear - i;
		return { label: String(year), value: year };
	});
};

export const ZODIAC_SIGNS = [
	{ label: "Aries", value: "Aries" },
	{ label: "Taurus", value: "Taurus" },
	{ label: "Gemini", value: "Gemini" },
	{ label: "Cancer", value: "Cancer" },
	{ label: "Leo", value: "Leo" },
	{ label: "Virgo", value: "Virgo" },
	{ label: "Libra", value: "Libra" },
	{ label: "Scorpio", value: "Scorpio" },
	{ label: "Sagittarius", value: "Sagittarius" },
	{ label: "Capricorn", value: "Capricorn" },
	{ label: "Aquarius", value: "Aquarius" },
	{ label: "Pisces", value: "Pisces" },
];
