import React, { useState } from "react";
import styled from "@emotion/styled";
import Footer from "../components/Shared/Footer";
import Background from "../components/Shared/Background";
import FirstSection from "../components/LandingPage/FirstSection";
import CountdownSection from "../components/LandingPage/CountdownSection";
import SecondSection from "../components/LandingPage/SecondSection";
import ThirdSection from "../components/LandingPage/ThirdSection";
import FourthSection from "../components/LandingPage/FourthSection";
import FifthSection from "../components/LandingPage/FifthSection";
import StickyFooter from "../components/Shared/StickyFooter";

const LandingPageContainer = styled.div`
  overflow: hidden;
`;

const LandingPage = () => {
	const hasSubscribed = localStorage.getItem("hasSubscribed");
	const [isVisible, setIsVisible] = useState(hasSubscribed !== "true");

	return (
		<LandingPageContainer>
			<Background />
			<FirstSection />
			<CountdownSection />
			<SecondSection />
			<ThirdSection />
			<FourthSection />
			<FifthSection />
			<Footer isVisible={isVisible} />
			<StickyFooter isVisible={isVisible} setIsVisible={setIsVisible} />
		</LandingPageContainer>
	);
};
export default LandingPage;
