import React from "react";
import styled from "@emotion/styled";

interface ToggleSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const ToggleSwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.containerBase};
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 24px;
  transition: background-color 0.2s;
`;

const ToggleSwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ToggleSwitchSlider = styled.span<{ checked: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background-color: ${({ theme }) => theme.colors.containerBase};
  border-radius: 22px;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: 0px;
    background-color: ${({ checked, theme }) => checked ? theme.colors.primaryPink : theme.colors.borderGray};
    transition: 0.4s;
    border-radius: 50%;
    transform: ${({ checked }) => checked ? "translateX(22px)" : "none"};
  }
`;

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ checked, onChange }) => {

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.checked);
	};

	return (
		<ToggleSwitchWrapper>
			<ToggleSwitchInput 
				type="checkbox"
				checked={checked}
				onChange={handleChange}
			/>
			<ToggleSwitchSlider checked={checked} />
		</ToggleSwitchWrapper>
	);
};

export default ToggleSwitch;
