import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import Button from "../../../Buttons/Button";
import ButtonText from "../../../Buttons/ButtonText";
import Typography from "../../../Typography";
import { useUpgradeSubscriptionMutation } from "../../../../redux/features/subscriptionsApi";
import { showSnackbar } from "../../../../redux/reducers/snackbarReducer";
import SavedPaymentMethods from "../SavedPaymentMethods";
import { SubscriptionPlan } from "../../../../types/stripeTypes";
import { formatPrice } from "../../../../utils/formatPrice";

const Logo = styled.img`
  height: 26px;
`;

const Separator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  width: 100%;
  height: 1px;
`;

const SavedPaymentMethodsWrapper = styled.div`
	width: 100%;
`;

interface SubscriptionStepTwoProps {
  plan: SubscriptionPlan;
  onBack: () => void;
  refetch: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubscriptionStepTwo: React.FC<SubscriptionStepTwoProps> = ({
	plan,
	onBack,
	refetch,
	setIsOpen,
}) => {
	const dispatch = useDispatch();
	const [upgradeSubscription, { isLoading }] = useUpgradeSubscriptionMutation();
	const [loading, setLoading] = useState(false);
	const [activePaymentMethod, setActivePaymentMethod] = useState<string | null>(null);
	const cost = `${formatPrice(plan.price || 0)}`;

	const handleSubscription = async (event: any) => {
		event.preventDefault();
		setLoading(true);

		try {
			if (activePaymentMethod) {
				await upgradeSubscription({
					plan_id: plan.id,
					payment_method: activePaymentMethod,
				}).unwrap();
				setIsOpen(false);
				dispatch(showSnackbar({ message: "Subscription started successfully!", duration: 3000 }));
				refetch();
			}
		} catch (error) {
			const errorMessage = (error as Error).message;
			console.error("Subscription failed:", errorMessage);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Typography
				fontFamily="primary"
				fontSize={24}
				fontWeight={700}
				margin="0 0 12px"
				textAlign="center"
			>
        You’re almost done!
			</Typography>
			<Typography
				color="secondaryLightGray"
				fontFamily="body"
				fontSize={12}
				fontWeight={400}
				textAlign="center"
			>
        Select payment method to complete your subscription.
			</Typography>
			<Logo src={plan?.images[0]} />
			<Typography fontFamily="primary" fontSize={40} fontWeight={500} textAlign="center">
				{cost}
				<Typography
					variant="span"
					fontFamily="body"
					fontWeight={400}
					color="secondaryLightGray"
					fontSize={20}
					isInline
				>
          /month
				</Typography>
			</Typography>
			<Separator />
			<SavedPaymentMethodsWrapper>
				<SavedPaymentMethods
					activePaymentMethod={activePaymentMethod}
					setActivePaymentMethod={setActivePaymentMethod}
				/>
			</SavedPaymentMethodsWrapper>

			<Button
				fullWidth
				onClick={handleSubscription}
				loading={loading || isLoading}
				disabled={!activePaymentMethod}
			>
        Subscribe
			</Button>
			<ButtonText onClick={onBack}>
				<Typography color="primaryPink" fontFamily="body" fontWeight={500}>
            Go Back
				</Typography>
			</ButtonText>
		</>
	);
};

export default SubscriptionStepTwo;
