import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthButtons from "../components/Shared/AuthButtons";
import Background from "../components/Shared/Background";
import Link from "../components/Links/Link";
// import PhoneNumberForm from "../components/Shared/PhoneNumberForm";
import TermsAndPrivacy from "../components/Shared/TermsAndPrivacy";
// import Typography from "../components/Typography";
import useViewport from "../hooks/ViewportProvider";
import { selectUser } from "../redux/reducers/userReducer";

const LoginPageContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ isMobile }) => isMobile ? `
    padding: 64px 24px;
  `: 
		`
    padding: 120px 0 0;
    height: calc(100vh - 120px);
    align-items: center;
  `}
`;

const LogoTitle = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isMobile }) => isMobile && "margin-bottom: 64px;"}
`;

const SignupFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 486px;
  margin: auto 0;
  width: 100%;
`;

const FormTitle = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 12px;
  text-align: center;
`;

// const OrContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: calc(100% - 24px);
// `;

// const StyledLine = styled.hr`
//   flex-grow: 1;
//   border: none;
//   border-top: 1px solid ${(props) => props.theme.colors.lightGray};
// `;

const LoginPage = () => {
	const user = useSelector(selectUser);
	const viewport = useViewport();
	const isMobile = viewport === "mobile";
	const navigate = useNavigate();

	useEffect(() => {
		if (user) {
			navigate("/");
		}
	}, [user, navigate]);

	return (
		<LoginPageContainer isMobile={isMobile}>
			<Background />
			<LogoTitle isMobile={isMobile}>
				<Link to="/" icon="logo" iconPosition="left" />
			</LogoTitle>
			<SignupFormContainer>
				<FormTitle>Welcome Back</FormTitle>
				<AuthButtons />
				{/* <OrContainer>
					<StyledLine />
					<Typography
						color="white"
						fontFamily="body"
						fontSize={16}
						fontWeight={400}
						margin="0 34px"
					>
            Or
					</Typography>
					<StyledLine />
				</OrContainer>
				<PhoneNumberForm /> */}
			</SignupFormContainer>
			<TermsAndPrivacy />
		</LoginPageContainer>
	);
};

export default LoginPage;