import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Background2 from "../components/Shared/Background2";
import Typography from "../components/Typography";
import GoLiveButton from "../components/Buttons/GoLiveButton";
import useViewport from "../hooks/ViewportProvider";
import { selectUser } from "../redux/reducers/userReducer";
// import { useSocket } from "../utils/hooks/useSocket";

const DashboardContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ isMobile }) => isMobile ? "calc(100vh - 126px)" : "calc(100vh - 118px)"};
  position: relative;
  padding: 24px;
`;

const DashboardPage = () => {
	const user = useSelector(selectUser);
	// const socket = useSocket();
	const navigate = useNavigate();
	const viewport = useViewport();
	const isMobile = viewport === "mobile";
	const isTablet = viewport === "tablet";
	const isMobileOrTablet = isMobile || isTablet;

	const handleGoLive = () => {
		if (user) {
			if (user) {
				// socket.emit("go-live", user._id);
				navigate("/congrats");
			}
		}
	};

	return (
		<>
			<Background2 />
			<DashboardContainer isMobile={isMobileOrTablet}>
				<Typography
					fontSize={isMobile ? 32 : isTablet ? 48 : 64}
					margin="48px 0 16px 0"
					textAlign="center"
				>
          Ready to Chat?
				</Typography>
				<Typography
					fontFamily="body"
					fontSize={18}
					margin="0 0 40px"
				>
          Click “Go Live” to find your match!
				</Typography>
				<GoLiveButton onClick={handleGoLive} />
			</DashboardContainer>
		</>
	);
};

export default DashboardPage;