import { selectUser } from "../reducers/userReducer";
import { RootState } from "../store";

export const avatarProfileData = (state: RootState) => {
	const user = selectUser(state);
	const { profile_avatar, first_name, last_name } = user || {};
	let cropped_image_url = "";
	if (profile_avatar && profile_avatar.cropped_image_url) {
		cropped_image_url = profile_avatar.cropped_image_url;
	}
	return { cropped_image_url, first_name, last_name };
};

export const areAvatarProfileDataEqual = (prev: ReturnType<typeof avatarProfileData>, next: ReturnType<typeof avatarProfileData>) => {
	return prev.cropped_image_url === next.cropped_image_url &&
		prev.first_name === next.first_name &&
		prev.last_name === next.last_name;
};