import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Buttons/Button";
import ButtonText from "../Buttons/ButtonText";
import Typography from "../Typography";
import { StepProps } from "../../types/Step";
import { updateUserData } from "../../redux/actions/userActions";
import { selectUser } from "../../redux/reducers/userReducer";
import { selectStep, setStep } from "../../redux/reducers/onboardingReducer";

import type { AppDispatch } from "../../redux/store";

const Step3Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LocationIconWrapper = styled.div`
  width: 80px
  height: 80px;
  margin-bottom: 24px;
  text-align: center;
`;

const LocationIcon = () => (
	<LocationIconWrapper>
		<svg
			width="80"
			height="80"
			viewBox="0 0 80 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Icons">
				<g id="Vector">
					<path
						d="M65.7574 34.5455C65.7574 53.6364 41.212 70 41.212 70C41.212 70 16.6665 53.6364 16.6665 34.5455C16.6665 28.0356 19.2525 21.7924 23.8557 17.1892C28.4589 12.586 34.7021 10 41.212 10C47.7218 10 53.9651 12.586 58.5682 17.1892C63.1714 21.7924 65.7574 28.0356 65.7574 34.5455Z"
						stroke="#00C6FB"
						strokeWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M41.212 42.7273C45.7307 42.7273 49.3938 39.0641 49.3938 34.5455C49.3938 30.0268 45.7307 26.3636 41.212 26.3636C36.6933 26.3636 33.0301 30.0268 33.0301 34.5455C33.0301 39.0641 36.6933 42.7273 41.212 42.7273Z"
						stroke="#00C6FB"
						strokeWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</g>
		</svg>
	</LocationIconWrapper>
);

const BackButton = styled(ButtonText)`
  margin-top: 24px;
`;

const Step3: React.FC<StepProps> = () => {
	const dispatch: AppDispatch = useDispatch();
	const user = useSelector(selectUser);
	const step = useSelector(selectStep);
	const [loading, setLoading] = useState(false);
	const [locationError, setLocationError] = useState("");

	const updateUserLocation = async (latitude: number, longitude: number) => {
		if (!user || !user.id) return;
		const currentStep = user?.onboarding_step || 0;
		let newStep = 3;
		if (currentStep > 3) {
			newStep = currentStep;
		}

		const data = { 
			latitude,
			longitude,
			onboarding_step: newStep,
		};

		dispatch(updateUserData({ data }))
			.unwrap()
			.then(() => {
				setLoading(false);
				dispatch(setStep(step + 1));
			})
			.catch(() => {
				setLoading(false);
				setLocationError("Error updating user location");
			});
	};

	const fetchUserLocation = async () => {
		await navigator.geolocation.getCurrentPosition(
			(position) => {
				const { latitude, longitude } = position.coords;
				updateUserLocation(latitude, longitude);
			},
			() => {
				setLoading(false);
			}
		);
	};

	const handleEnableLocation = () => {
		setLocationError("");
		if (navigator.geolocation) {
			setLoading(true);
			fetchUserLocation();
		}
	};

	return (
		<Step3Container>
			<LocationIcon />
			<Typography fontSize={20} lineHeight="110%" textAlign="center">
        Enable Location Services
			</Typography>
			<Typography
				color="lightGray"
				fontFamily="body"
				fontSize={18}
				fontWeight={400}
				margin="12px 0 48px"
				textAlign="center"
			>
        We use your location to match you with people near you
			</Typography>
			<Button
				loading={loading}
				onClick={handleEnableLocation}
				width="320px"
				height="52px"
			>
        Enable Location Services
			</Button>
			{locationError && (
				<Typography
					color="errorRed"
					fontFamily="secondary"
					fontSize={14}
					fontWeight={400}
					margin="12px 0 0"
				>
					{locationError}
				</Typography>
			)}
			<BackButton onClick={() => dispatch(setStep(2))}>
				<Typography color="primaryPink" fontFamily="body" fontWeight={500}>
          Go Back
				</Typography>
			</BackButton>
		</Step3Container>
	);
};

export default Step3;
