import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import { selectUser, setUser, setError, setLoading } from "../reducers/userReducer";
import { setStep } from "../reducers/onboardingReducer";
import { RootState } from "../store";
import { User } from "../../types/User";

interface UpdateUserDataParams {
  data: any;
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const fetchProfile = createAsyncThunk(
	"profile/fetchProfile",
	async (_, thunkAPI) => {
		try {
			thunkAPI.dispatch(setLoading(true));
			const response = await axiosInstance.get("/profile", { withCredentials: true });
			thunkAPI.dispatch(setStep(response.data.onboarding_step + 1));
			thunkAPI.dispatch(setUser({ ...response.data }));
			await delay(2000);
			return response.data;
		} catch (error) {
			if (error instanceof Error) {
				thunkAPI.dispatch(setError(error.message));
				return thunkAPI.rejectWithValue(error.message);
			} else {
				const errorMessage = "An unknown error occurred";
				thunkAPI.dispatch(setError(errorMessage));
				return thunkAPI.rejectWithValue(errorMessage);
			}
		} finally {
			thunkAPI.dispatch(setLoading(false));
		}
	}
);

export const updateUserData = createAsyncThunk(
	"user/updateUserData",
	async ({ data }: UpdateUserDataParams, thunkAPI) => {
		try {
			const response = await axiosInstance.patch(
				"/profile",
				data,
				{ withCredentials: true }
			);

			const userData = response.data;
			thunkAPI.dispatch(setUser(userData));
		} catch (error) {
			console.error("Error updating user: ", error);
		}
	}
);

export const updateUserPreferenceData = createAsyncThunk(
	"user/updateUserPreferenceData",
	async ({ data }: UpdateUserDataParams, thunkAPI) => {
		try {
			const response = await axiosInstance.patch(
				"/profile",
				{ preferences: data },
				{ withCredentials: true }
			);

			const preferenceData = response.data.preferences;
			const currentState = thunkAPI.getState() as RootState;
			const currentUser = selectUser(currentState);
			const updatedUser = {
				...currentUser,
				preferences: {
					...preferenceData,
				},
			};
			thunkAPI.dispatch(setUser(updatedUser as User));
		} catch (error) {
			console.error("Error updating user preferences: ", error);
		}
	}
);

export const updateUserStatus = createAsyncThunk(
	"user/updateUserStatus",
	async ({ userId, status, discoveryMode }: { userId: string, status: string, discoveryMode: boolean }, thunkAPI) => {
		try {
			await axiosInstance.patch(
				`/api/users/${userId}/status`,
				{ status, discoveryMode },
				{ withCredentials: true }
			);

			// Optionally refetch the user data or update the user state here
		} catch (error) {
			if (error instanceof Error) {
				thunkAPI.dispatch(setError(error.message));
			}
		}
	}
);

export const sendPhoneVerification = createAsyncThunk(
	"user/sendPhoneVerification",
	async (phoneNumber: string, thunkAPI) => {
		try {
			const response = await axiosInstance.post("/api/users/auth/phone/start", { phone: phoneNumber });
			// Handle response if needed
			return response.data;
		} catch (error) {
			// Handle error
			return thunkAPI.rejectWithValue("Phone verification failed");
		}
	}
);

export const verifyPhoneNumber = createAsyncThunk(
	"user/verifyPhoneNumber",
	async ({ phoneNumber, code }: { phoneNumber: string; code: string }, thunkAPI) => {
		try {
			const response = await axiosInstance.post("/api/users/auth/phone/verify", { phone: phoneNumber, code });
			const { token } = response.data;
			return token;
		} catch (error) {
			// Handle error
			return thunkAPI.rejectWithValue("Phone number verification failed");
		}
	}
);

export const logoutUser = createAsyncThunk(
	"user/logout",
	async (_, thunkAPI) => {
		try {
			localStorage.removeItem("token");
			delete axiosInstance.defaults.headers.common["Authorization"];
			thunkAPI.dispatch(setUser(null));
			thunkAPI.dispatch(setStep(1));
		} catch (error) {
			if (error instanceof Error) {
				thunkAPI.dispatch(setError(error.message));
			}
		}
	}
);