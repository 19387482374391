import React, { useState } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import Typography from "../../components/Typography";
import InputText from "../Inputs/InputText";
import Icon from "../Icon";
import Button from "../Buttons/Button";
import LoadingSpinner from "../Animations/LoadingSpinner";
import { useViewport } from "../../hooks/ViewportProvider";

interface ContactFormStateProps {
  email: string;
}

interface ContactFormErrors {
  email?: string;
}

export interface StickyFooterProps {
  isVisible: boolean;
  setIsVisible: any;
}

const Footer = styled.div<{
  isMobile: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
  viewport: any;
  isVisible: boolean;
}>`
  display: flex;
  border-radius: ${({ isMobile }) => !isMobile && "12px"};
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  justify-content: ${({ viewport }) =>
		viewport === "mobile" || viewport === "largeDesktop"
			? "space-between"
			: "flex-end"};
  position: fixed;
  height: auto;
  align-items: ${({ isMobile }) => !isMobile && "center"};
  bottom: ${({ isMobile }) => (isMobile ? 0 : "52px")};
  left: ${({ isMobile }) => (isMobile ? 0 : "64px")};
  padding: ${({ isMobile }) => (isMobile ? "16px 24px 24px" : "20px 20px 24px")};
  width: ${({ isMobile }) => (isMobile ? "100%" : "682px")};
  background-color: ${({ theme }) => theme.colors.containerBase};
  box-sizing: border-box;
  transition: transform 0.5s ease-in-out;
  transform: ${({ isMobile, isVisible }) =>
		isMobile
			? `translateY(${isVisible ? "0" : "120%"})`
			: `translateX(${isVisible ? "0" : "-120%"})`};
`;

const Overlay = styled.div<{ isMobile: boolean }>`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  border-radius: ${({ isMobile }) => !isMobile && "12px"};
  background-color: ${({ theme }) => theme.colors.containerBase};
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const LeftSection = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
`;

const StyledTypography = styled(Typography)`
  vertical-align: top;
  width: fit-content;
`;

const SubtextContainer = styled.div<{ viewport: any }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: ${({ theme }) => `solid ${theme.colors.darkGray} 1px`};
`;

const ButtonIcon = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const JoinButton = styled(Button)`
  padding: 14px 17px;
`;

const TextContainer = styled.div<{
  isMobile: boolean;
  viewport: any;
  isDesktop: boolean;
}>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  transition: flex-grow 0.3s ease-in-out;
  margin-right: ${({ isDesktop }) => isDesktop && "12px"};
`;

const InputContainer = styled.div<{ isMobile: boolean; viewport: any }>`
  display: flex;
  flex-direction: row;
  max-width: ${({ viewport }) =>
		viewport === "mobile" ? "100%" : viewport === "tablet" ? "60%" : "80%"};
  height: 52px;
`;

const SubmittedContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SubmittedIcon = styled(Icon)``;

const StyledIcon = styled(Icon)<{ open?: boolean }>``;

const SubmittedTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const SubmitError = styled.p`
  color: ${({ theme }) => theme.colors.errorRed};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  bottom: -8px;
  margin-left: 8px;
`;

const StyledForm = styled.form`
  display: flex;
`;

const StyledInput = styled(InputText)<{ isMobile: boolean }>`
  display: flex;
  flex: 1;
  margin-right: 12px;
`;

const StickyFooter = (props: StickyFooterProps) => {
	const { isVisible, setIsVisible } = props;

	const [formData, setFormData] = useState<ContactFormStateProps>({
		email: "",
	});
	const [formErrors, setFormErrors] = useState<ContactFormErrors>({});
	const [submitError, setSubmitError] = useState("");
	const [loading, setLoading] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const viewport = useViewport();
	const isDesktop = viewport === "laptop" || viewport === "desktop";
	const isLargeDesktop = viewport === "largeDesktop";
	const isMobile = viewport === "mobile" || viewport === "tablet";
	const validateEmail = (email: string) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	const validateFormData = (formData: ContactFormStateProps) => {
		const { email } = formData;
		const errors: ContactFormErrors = {};

		if (!email) {
			errors.email = "Email is required";
		} else if (!validateEmail(email)) {
			errors.email = "Email is not valid";
		}

		return errors;
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		setSubmitError("");
		const errors = validateFormData(formData);

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		setLoading(true);
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/subscribe`, { email: formData.email });

			if (response.status === 200) {
				setIsFormSubmitted(true);
				localStorage.setItem("hasSubscribed", "true");
			} else if (response.status === 400) {
				setSubmitError(response.data.message || "Email already subscribed.");
			} else{
				setSubmitError(response.data.message || "Error subscribing. Please try again.");
			}

			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			setSubmitError(error?.response?.data?.message || "An issue occured. Please try again.");
			console.error("Error sending message: ", error);
		}
	};

	return (
		<Footer
			isMobile={isMobile}
			isDesktop={isDesktop}
			isLargeDesktop={isLargeDesktop}
			viewport={viewport}
			isVisible={isVisible}
		>
			{loading && (
				<Overlay isMobile={isMobile}>
					<LoadingSpinner />
				</Overlay>
			)}
			{!isFormSubmitted ? (
				<StyledForm onSubmit={handleSubmit}>
					<LeftSection isMobile={isMobile}>
						<TextContainer
							viewport={viewport}
							isMobile={isMobile}
							isDesktop={isDesktop}
						>
							<SubtextContainer viewport={viewport}>
								<StyledTypography
									color="secondaryLightGray"
									fontSize={16}
									fontWeight={400}
									fontFamily="body"
									lineHeight="22px"
									margin="0px 16px 12px 0px"
								>
                  Join our email list to get notified of new updates, connect
                  with us, and influence our app’s evolution through your
                  feedback!
								</StyledTypography>
								{isMobile && (
									<IconContainer>
										<ButtonIcon type="button" onClick={() => setIsVisible(false)}>
											<StyledIcon color="primaryPink" icon="x" />
										</ButtonIcon>
									</IconContainer>
								)}
							</SubtextContainer>
						</TextContainer>
						<InputContainer viewport={viewport} isMobile={isMobile}>
							<StyledInput
								isMobile={isMobile}
								error={formErrors.email}
								fullWidth
								label="Email"
								placeholder="Email"
								value={formData.email}
								onChange={(e) => {
									setFormData({ ...formData, email: e.target.value });
									setFormErrors({ ...formErrors, email: undefined });
									setSubmitError("");
								}}
							/>
							{submitError && <SubmitError>{submitError}</SubmitError>}
							<JoinButton type="submit" height="52px">
								{isMobile ? (
									<StyledIcon color="white" icon="arrow-right" />
								) : (
									"Join the Waitlist"
								)}
							</JoinButton>
						</InputContainer>
					</LeftSection>
					{!isMobile && (
						<IconContainer>
							<ButtonIcon type="button" onClick={() => setIsVisible(false)}>
								<StyledIcon color="primaryPink" icon="x" />
							</ButtonIcon>
						</IconContainer>
					)}
				</StyledForm>
			) : (
				<SubmittedContainer>
					<SubmittedTextContainer>
						<SubmittedIcon
							style={{ marginRight: "12px" }}
							icon="complete-check"
							color="accentCyan"
							width={40}
							height={40}
						/>
						<StyledTypography
							color="secondaryLightGray"
							fontSize={16}
							fontWeight={400}
							fontFamily="body"
							lineHeight="22px"
						>
              You’re in! You should receive a confirmation email shortly.
						</StyledTypography>
					</SubmittedTextContainer>
					<IconContainer>
						<ButtonIcon type="button" onClick={() => setIsVisible(false)}>
							<StyledIcon color="primaryPink" icon="x" />
						</ButtonIcon>
					</IconContainer>
				</SubmittedContainer>
			)}
		</Footer>
	);
};

export default StickyFooter;
