import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import useViewport from "../../hooks/ViewportProvider";

export interface InputCodeProps {
  error?: string | null;
  onChange: (code: string) => void;
}

const InputWrapper = styled.div`
  display: flex;
`;

const DigitInput = styled.input<{ isError: boolean; isMobile: boolean }>`
  background: ${({ theme }) => theme.colors.containerBase};
  border: 1px solid ${({ theme, isError }) => isError ? "red" : theme.colors.darkGray};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.body};
  text-align: center;

  font-size: ${({ isMobile }) => isMobile ? "calc(1vw + 16px)" : "32px"};
  padding: ${({ isMobile }) => isMobile ? "calc(0.5vw + 4px) calc(0.5vw + 6px)" : "8px 12px"};
  margin: ${({ isMobile }) => isMobile ? "0 calc(0.5vw + 5px)" : "0 10px"};
  height: ${({ isMobile }) => isMobile ? "calc(2vw + 20px)" : "40px"};
  width: ${({ isMobile }) => isMobile ? "calc(2vw + 20px)" : "40px"};

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.accentViolet};
    box-shadow: 0px 0px 30px 0px rgba(204, 28, 210, 0.50);
  }
`;


const InputCode: React.FC<InputCodeProps> = ({ error, onChange }) => {
	const viewport = useViewport();
	const isMobile = viewport === "mobile";
	const [digits, setDigits] = useState(["", "", "", "", "", ""]);
	const inputsRef = Array(6).fill(0).map(() => useRef<HTMLInputElement>(null));

	useEffect(() => {
		onChange(digits.join(""));
	}, [digits, onChange]);

	const handleChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setDigits(prevDigits => {
			const newDigits = [...prevDigits];
			newDigits[index] = e.target.value;
  
			// Focus the next input
			if (index < 5 && e.target.value !== "") {
				const nextInput = inputsRef[index + 1];
				nextInput.current && nextInput.current.focus();
			}
  
			return newDigits;
		});
	};
  
	const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Backspace" && e.currentTarget.value === "") {
			// Focus the previous input
			if (index > 0) {
				const prevInput = inputsRef[index - 1];
				prevInput.current && prevInput.current.focus();
			}
		}
	};

	return (
		<InputWrapper>
			{digits.map((digit, index) => (
				<DigitInput
					key={index}
					ref={inputsRef[index]}
					value={digit}
					onChange={handleChange(index)}
					onKeyDown={handleKeyDown(index)}
					maxLength={1}
					isError={!!error}
					isMobile={isMobile}  // <-- This line
				/>
			))}
		</InputWrapper>
	);
};

export default InputCode;