import { css, Theme } from "@emotion/react";

export const globalStyles = (theme: Theme) => css`
// For layout troubleshooting
// * {
//   outline: 1px solid blue;
// }
  body {
    background-color: ${theme.colors.primaryMidnight};
    color: #FFFFFF;
    font-family: 'Exo', sans-serif;
    font-weight: 700;
    margin: 0;
    overflow-x: hidden;
  }

  .no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
`;