import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Buttons/Button";
import ButtonText from "../../Buttons/ButtonText";
import Checkbox from "../../Inputs/Checkbox";
import InputText from "../../Inputs/InputText";
import Slider from "../../Inputs/Slider";
import Typography from "../../Typography";
import ToggleSwitch from "../../Inputs/Toggle";
import { PreferenceProps } from "../../../types/Step";
import useViewport from "../../../hooks/ViewportProvider";
import { selectUser } from "../../../redux/reducers/userReducer";
import { updateUserPreferenceData } from "../../../redux/actions/userActions";

import type { AppDispatch } from "../../../redux/store";

const PreferenceWrapper = styled.div``;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MaxDistanceInput = styled(InputText)`
  margin: 22px 0 33px;
  width: 302px;
`;

const Dealbreaker = styled.div`
  margin-top: 32px;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  width: 100%;
`;

const MobileSubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  gap: 32px;
`;

const SubmitButton = styled(Button)<{ isMobile?: boolean }>`
  ${({ isMobile }) => isMobile ? "width: 100%;" : "margin-left: 24px;"};
`;

const Preference1: React.FC<PreferenceProps> = ({ next, back }) => {
	const user = useSelector(selectUser);
	const dispatch: AppDispatch = useDispatch();
	const viewport = useViewport();
	const isMobile = viewport === "mobile";
	const defaultMaxDistance = user?.preferences?.locationRange?.max;
	const defaultDealbreaker = user?.preferences?.locationRange?.dealbreaker;
	const [maxDistance, setMaxDistance] = useState<number>(
		defaultMaxDistance || 15
	);
	const [prevMax, setPrevMax] = useState<number>(15);
	const [openToAny, setOpenToAny] = useState(false);
	const [dealbreaker, setDealbreaker] = useState(defaultDealbreaker || false);

	const handleSubmit = async () => {
		if (!user || !user.id) return;
		const data = {
			...user?.preferences,
			locationRange: {
				max: maxDistance,
				dealbreaker: dealbreaker,
			},
		};

		dispatch(updateUserPreferenceData({ data }))
			.unwrap()
			.then(() => {
				next();
			})
			.catch((error) => {
				console.error("Error updating user preferences: ", error);
			});
	};

	return (
		<PreferenceWrapper>
			<Typography
				fontSize={20}
				fontWeight={700}
				lineHeight="110%"
				margin="0 0 4px"
				variant="h2"
			>
        What is your max location range for potential matches?
			</Typography>
			<Typography
				color="secondaryLightGray"
				fontFamily="body"
				fontSize={16}
				fontWeight={400}
				lineHeight="22px"
				margin="0 0 32px"
			>
        Enter maximum distance
			</Typography>
			<ToggleContainer>
				<ToggleSwitch
					checked={openToAny}
					onChange={() => {
						setOpenToAny((prev) => {
							setMaxDistance((prevMaxDistance) => {
								if (!prev) {
									setPrevMax(prevMaxDistance);
									return 200;
								} else {
									return prevMax;
								}
							});
							return !prev;
						});
					}}
				/>
				<Typography
					color="secondaryLightGray"
					fontFamily="body"
					fontSize={16}
					fontWeight={400}
					margin="0 16px"
				>
          I’m open to any distance
				</Typography>
			</ToggleContainer>
			<MaxDistanceInput
				disabled={openToAny}
				isNumeric
				label="Maximum distance"
				min={1}
				max={200}
				onChange={(event) => {
					const value = event.target.value;
					setMaxDistance(Number(value));
				}}
				value={maxDistance > 200 ? 200 : maxDistance}
			/>
			<Slider
				disabled={openToAny}
				min={1}
				max={200}
				value={maxDistance > 200 ? 200 : maxDistance}
				onChange={(value: number) => setMaxDistance(value)}
			/>
			<Dealbreaker>
				<Checkbox
					checked={dealbreaker}
					label="This is a deal breaker"
					onChange={() => setDealbreaker((prev) => !prev)}
					value="dealbreaker"
				/>
			</Dealbreaker>
			{isMobile ? (
				<MobileSubmitButtonWrapper>
					<SubmitButton isMobile={isMobile} onClick={handleSubmit}>Continue (1/4)</SubmitButton>
					<ButtonText onClick={back}>
						<Typography color="primaryPink" fontFamily="body" fontWeight={500}>
              Go Back
						</Typography>
					</ButtonText>
				</MobileSubmitButtonWrapper>
			) : (
				<SubmitButtonWrapper>
					<ButtonText onClick={back}>
						<Typography color="primaryPink" fontFamily="body" fontWeight={500}>
              Go Back
						</Typography>
					</ButtonText>
					<SubmitButton onClick={handleSubmit}>Continue (1/4)</SubmitButton>
				</SubmitButtonWrapper>
			)}
		</PreferenceWrapper>
	);
};

export default Preference1;
