import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import Background from "../components/Shared/Background";
import Button from "../components/Buttons/Button";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const ComingSoonPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from || "unknown";
	return (
		<div>
			<Background />
			<PageContainer>
				{from === "facebook" ? (
					<h2>Facebook login is coming soon!</h2>
				) : (
					<h2>Apple login is coming soon!</h2>
				)}
				<Button onClick={() => navigate(-1)}>Go Back</Button>
			</PageContainer>
		</div>
	);
};

export default ComingSoonPage;
