import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useMatch } from "react-router-dom";
import DashboardNavMenu from "./DashboardNavMenu";
import DiscoveryToggle from "../Shared/DiscoveryToggle";
import HamburgerButton from "../Buttons/HamburgerButton";
import ProfileButton from "../Buttons/ProfileButton";
import Link from "../Links/Link";
import useViewport from "../../hooks/ViewportProvider";

const StyledHeader = styled.header<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ isMobile }) => isMobile ? "24px" : "0 32px"};
  height: ${({ isMobile }) => isMobile ? "none" : "69px"};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderGray}`};
  box-shadow: 0px 6px 20px 0px rgba(15, 21, 91, 0.10);
  position: sticky;
  z-index: 50;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
`;

const RightSection = styled.div`
  display: flex;
  z-index: 99;
  
  & > *:not(:last-child) {
    margin-right: 40px;
  }
`;

const LinkWrapper = styled.div<{ activeStyle: boolean }>`
  ${({ theme, activeStyle }) => activeStyle && `border-bottom: 4px solid ${theme.colors.primaryPink};`}
`;

const StyledLink = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 500;
  padding: 23px 20px;
  height: 100%;

`;

const Navbar = () => {
	const viewport = useViewport();
	const isMobile = viewport === "mobile" || viewport === "tablet";
	const [isOpen, setIsOpen] = useState(false);

	const matchRoot = useMatch("/");
	const matchChatHistory = useMatch("/chat-history");
	const matchContactCard = useMatch("/contact-card");
	const matchSettings = useMatch("/settings");

	useEffect(() => {
		if (isMobile) return;
		else {
			setIsOpen(false);
		}
	}, [isMobile]);

	if (isMobile) {
		return (
			<StyledHeader isMobile={isMobile}>
				<Link icon="logo" iconPosition="left" onClick={() => setIsOpen(false)} to="/" />
				<HamburgerButton isOpen={isOpen} onClick={() => setIsOpen(prev => !prev)} />
				<DashboardNavMenu isOpen={isOpen} setIsOpen={setIsOpen} />
			</StyledHeader>
		);
	}

	return (
		<StyledHeader>
			<LeftSection>
				<Link icon="logo" iconPosition="left" to="/" />
				<LinkWrapper activeStyle={!!matchRoot}>
					<StyledLink 
						to="/" 
						text="Go Live"
					/>
				</LinkWrapper>
				<LinkWrapper activeStyle={!!matchChatHistory}> 
					<StyledLink 
						to="/chat-history" 
						text="Chat History"
					/>
				</LinkWrapper>
				<LinkWrapper activeStyle={!!matchContactCard}>
					<StyledLink
						to="/contact-card" 
						text="Contact Card"  
					/>
				</LinkWrapper>
				<LinkWrapper activeStyle={!!matchSettings}>
					<StyledLink 
						to="/settings" 
						text="Settings"
					/>
				</LinkWrapper>
			</LeftSection>
			<RightSection>
				<DiscoveryToggle />
				<ProfileButton />
			</RightSection>
		</StyledHeader>
	);
};

export default Navbar;
