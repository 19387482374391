import React from "react";
import styled from "@emotion/styled";

const ChatHistoryContainer = styled.div`
  display: flex;
`;

const ChatHistoryPanel = styled.div`
  padding: 40px 32px;
  height: calc(100vh - 150px);
  border-right: 1px solid ${({ theme }) => theme.colors.borderGray};
`;

const PanelHeader = styled.h2`
  width: 294px;
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 20px;
`;

const ChatHistoryContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  width: 100%;
`;

const ChatHistoryPage = () => {

	return (
		<ChatHistoryContainer>
			<ChatHistoryPanel>
				<PanelHeader>Chat History</PanelHeader>
			</ChatHistoryPanel>
			<ChatHistoryContent>Coming Soon!</ChatHistoryContent>
		</ChatHistoryContainer>
	);
};

export default ChatHistoryPage;
