import React from "react";
import styled from "@emotion/styled";
import Typography from "../components/Typography";
import Footer from "../components/Shared/Footer";

import { useViewport } from "../hooks/ViewportProvider";

const PageContainer = styled.div<{ isMobile: boolean; isLaptop: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: ${({ isMobile, isLaptop }) =>
    isMobile ? "0px 24px 112px 24px" : isLaptop ? "0px 64px" : "0px 112px"};
  max-width: 1200px;
`;

const StyledUL = styled.ul`
  padding-left: 20px;
  margin: 0;
  list-style-position: outside;
  text-align: start;
`;

const StyledTypography = styled(Typography)`
  vertical-align: top;
`;

const StyledLI = styled.li``;

const PrivacyPolicy = () => {
  const viewport = useViewport();
  const isMobile = viewport === "mobile" || viewport === "tablet";
  const isLaptop = viewport === "laptop";
  return (
    <PageContainer isMobile={isMobile} isLaptop={isLaptop}>
      <Typography fontSize={32} fontWeight={500} margin={"0px 0px 0p 20px"}>
        Privacy Policy
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        OVERVIEW
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        Welcome to the “Heart 2 Heart” Privacy Policy. Your privacy and data
        security are our top priorities. This policy outlines how we collect,
        use, protect, and manage your personal information when you use our
        platform. Heart 2 Heart is the data controller responsible for your
        personal information. You can contact us through the provided contact
        information for privacy-related inquiries and concerns.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        At Heart 2 Heart, we understand that your privacy is of utmost
        importance. We have implemented measures to safeguard your data, and we
        respect your choices when it comes to sharing and managing your personal
        information. In this Privacy Policy, we outline:
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        By using Heart 2 Heart, you agree to the practices outlined in this
        Privacy Policy. We encourage you to read this Privacy Policy carefully
        to understand how we handle your data. If you have any questions or
        concerns about your privacy or data, please don’t hesitate to contact
        us.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        INFORMATION COLLECTION AND USE
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          1. Types of Information Collected:
        </Typography>{" "}
        When you use Heart 2 Heart, we collect various types of information to
        provide and improve our services. This information may include:
      </Typography>
      <StyledUL>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Registration Information:
            </StyledTypography>{" "}
            When you create an account, we collect your email address, phone
            number, birthday (for age verification), first name, location,
            gender, sexual orientation, dating goals, political preferences,
            religious beliefs, and interests.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              User-Generated Content:
            </StyledTypography>{" "}
            We collect the content you create and share on the app, including
            messages, digital contact cards, and any other information you
            choose to provide.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Device Information:
            </StyledTypography>{" "}
            We automatically collect information about your device, including
            device type, operating system, browser type, and IP address.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 48px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Usage Data:
            </StyledTypography>{" "}
            We collect data about how you interact with the app, including the
            features you use, your chat history, and your interactions with
            other users.
          </StyledTypography>
        </StyledLI>
      </StyledUL>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          2. Purpose of Information Collection:
        </Typography>{" "}
        We use the collected information for the following purposes:
      </Typography>
      <StyledUL>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              User Matching:
            </StyledTypography>{" "}
            To create meaningful connections between users based on their
            preferences and interests.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Service Enhancement:
            </StyledTypography>{" "}
            To improve and customize the app’s functionality, features, and user
            experience.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Safety and Security:
            </StyledTypography>{" "}
            To ensure a safe and secure environment by identifying and
            addressing potentially harmful or abusive behavior.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Communication:
            </StyledTypography>{" "}
            To communicate with you, provide support, respond to inquiries, and
            send important notifications or updates.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 48px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Analytics:
            </StyledTypography>{" "}
            To analyze user behavior and trends, helping us make informed
            decisions and improvements.
          </StyledTypography>
        </StyledLI>
      </StyledUL>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          3. User Control:
        </Typography>{" "}
        You have control over the information you share on Heart 2 Heart. You
        can edit your profile, adjust settings, and choose the level of detail
        you provide on your digital contact card. You can also control your
        communication preferences.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          4. Third-Party Services:
        </Typography>{" "}
        You have control over the information you share on Heart 2 Heart. You
        can edit your profile, adjust settings, and choose the level of detail
        you provide on your digital contact card. You can also control your
        communication preferences.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        HOW WE USE YOUR INFORMATION
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        At Heart 2 Heart, we use the information we collect for specific
        purposes to enhance your experience on our app and provide you with a
        secure and enjoyable environment. This section outlines how we utilize
        your data:
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          1. User Matching:
        </Typography>{" "}
        We use the information you provide during registration and onboarding,
        such as your location, gender, sexual orientation, dating goals,
        political preferences, religious beliefs, and interests, to match you
        with other users who share similar preferences and interests. This helps
        facilitate meaningful connections and conversations.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          2. Service Enhancement:
        </Typography>{" "}
        Your data is used to improve and customize our app’s functionality and
        features. By analyzing usage patterns and user behavior, we can identify
        areas for enhancement and optimize your overall experience.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          3. Safety and Security:
        </Typography>{" "}
        We employ your data to maintain a safe and secure environment for all
        users. This includes identifying and addressing potentially harmful or
        abusive behavior, ensuring compliance with our Terms of Service, and
        taking measures to protect the integrity of our platform.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          4. Communication:
        </Typography>{" "}
        We use your information to communicate with you effectively. This may
        include responding to inquiries, providing support, sending important
        notifications, and delivering updates about the app. We may also use
        your email address to send periodic newsletters and updates, but you can
        opt out of these communications.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          5.Analytics:
        </Typography>{" "}
        Your data plays a crucial role in helping us understand user behavior
        and preferences. We use analytics to gain insights into app usage
        trends, user engagement, and feature popularity. This information guides
        our decision-making process and allows us to make informed improvements
        to the app.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        Please note that while we use your data for these purposes, we do not
        sell your information to third parties. Your privacy and data security
        are of utmost importance to us, and we take measures to protect your
        personal information as outlined in our Privacy Policy.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        By using Heart 2 Heart, you consent to the practices described in this
        “How We Use Your Information” clause. We are dedicated to providing a
        safe and enjoyable experience as you connect with others on our
        platform.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        DATA SHARING AND DISCLOSURE
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        Please note that while we use your data for these purposes, we do not
        sell your information to third parties. Your privacy and data security
        are of utmost importance to us, and we take measures to protect your
        personal information as outlined in our Privacy Policy.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          1. Third-Party Service Providers:
        </Typography>{" "}
        We may engage trusted third-party service providers to assist us in
        delivering and improving our services. These providers may have access
        to your data only to the extent necessary to perform their services,
        such as hosting, maintenance, data analysis, and customer support. We
        require these providers to adhere to strict data protection standards
        and confidentiality agreements.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          2. User Matching:
        </Typography>{" "}
        To facilitate user matching, some of your information, such as location,
        gender, sexual orientation, dating goals, political preferences,
        religious beliefs, and interests, may be shared with other users who
        have similar preferences and interests. This is done to enable
        meaningful connections within the app.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          3. Digital Contact Cards:
        </Typography>{" "}
        Users have the option to create digital contact cards and share them
        with other users they match with. The content on these contact cards is
        determined by the user and may include information such as email
        addresses, phone numbers, full names, social media profiles, and star
        signs. However, the inclusion of this information is entirely optional
        and at the user’s discretion.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          4. Legal Requirements:
        </Typography>{" "}
        To facilitate user matching, some of your information, such as location,
        gender, sexual orientation, dating goals, political preferences,
        religious beliefs, and interests, may be shared with other users who
        have similar preferences and interests. This is done to enable
        meaningful connections within the app.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          5. Business Transfers:
        </Typography>{" "}
        In the event of a merger, acquisition, or sale of all or a portion of
        our assets, user data may be transferred as part of the transaction. We
        will notify you via email or through a prominent notice on the app in
        such circumstances.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          6. Safety and Security:
        </Typography>{" "}
        To ensure the safety and security of our users and the app, we may share
        data with law enforcement agencies, legal authorities, or other entities
        to investigate, prevent, or address potential security concerns,
        violations of our Terms of Service, or other misconduct.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          7. User Consent:
        </Typography>{" "}
        If you choose to share your data with other users on the app, such as
        through digital contact cards, you do so voluntarily and at your own
        discretion. Please exercise caution and consider your privacy
        preferences when sharing information with other users.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        We have put in place necessary measures to ensure that any third parties
        with access to your data adhere to strict data protection and security
        standards. Your privacy and data security are important to us, and we
        are dedicated to protecting your personal information as outlined in our
        Privacy Policy.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        YOUR PRIVACY CHOICES
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        At Heart 2 Heart, we believe in empowering you to make informed
        decisions about your personal information and privacy. This section
        outlines the choices and control you have over your data:
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          1. Profile Editing:
        </Typography>{" "}
        You have the ability to edit your profile information at any time. This
        includes details such as your name, location, gender, sexual
        orientation, dating goals, political preferences, religious beliefs, and
        interests. Feel free to update your profile to reflect your preferences
        accurately.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          2. Communication Preferences:
        </Typography>{" "}
        You can control the frequency and type of communications you receive
        from Heart 2 Heart. You have the option to manage your email
        preferences, notifications, and communication settings within the app.
        We respect your choices regarding the messages and updates you wish to
        receive.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          3. Digital Contact Cards:
        </Typography>{" "}
        Users have the option to create digital contact cards that can be shared
        with other users they match with. What information you choose to include
        on your contact card is entirely up to you. You have full control over
        the content you share, whether it’s email addresses, phone numbers, full
        names, social media profiles, or other details.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          4. Data Deletion:
        </Typography>{" "}
        If you decide to discontinue your use of Heart 2 Heart, you can request
        the deletion of your account and associated data. Upon receiving such a
        request, we will promptly delete your account information, including
        user-generated content. Some data may be retained for legal or
        operational purposes, as outlined in our data retention policy.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          5. Opting Out of Communications:
        </Typography>{" "}
        You have the option to opt out of promotional emails and newsletters by
        clicking the unsubscribe link in the email or adjusting your email
        preferences in your account settings.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          6. Data Access and Portability:
        </Typography>{" "}
        You can request access to the personal data we have collected about you.
        If you wish to obtain a copy of your data or transfer it to another
        service, please contact us through the provided contact information. We
        will respond to your request promptly.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          7. Limiting Information Sharing:
        </Typography>{" "}
        While we facilitate user matching, the amount of information you share
        with other users is entirely at your discretion. You can control the
        level of detail you provide on your digital contact card and exercise
        caution when sharing personal information with other users.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        We respect your privacy choices and are committed to facilitating your
        control over your data. Your decisions regarding your data preferences
        are important to us, and we are here to assist you in managing your
        privacy within the Heart 2 Heart app.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        DATA SECURITY
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        At Heart 2 Heart, we prioritize the security and protection of your
        personal information. We have implemented robust security measures to
        safeguard your data from unauthorized access, breaches, and other
        potential threats. This section outlines our commitment to data
        security:
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          1. Encryption:
        </Typography>{" "}
        We use industry-standard encryption protocols to secure the transmission
        of data between your device and our servers. This ensures that your
        information remains confidential and protected during transmission.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          2. Access Control:
        </Typography>{" "}
        Access to your personal data is limited to authorized personnel who
        require it to fulfill their duties. Our employees and contractors
        undergo training in data security best practices and are bound by
        confidentiality agreements.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          3. Data Encryption:
        </Typography>{" "}
        Sensitive information, such as passwords, is stored using strong
        encryption methods to ensure that even in the event of unauthorized
        access to our systems, your data remains protected.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          4. Regular Security Audits:
        </Typography>{" "}
        We conduct regular security audits and assessments to identify and
        address potential vulnerabilities in our systems and infrastructure.
        These audits help us maintain a high level of data security.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          5. Protection Against Unauthorized Access:
        </Typography>{" "}
        We implement multi-factor authentication and other security measures to
        protect against unauthorized access to your account. Your login
        credentials are securely stored and hashed.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          6. Data Backups:
        </Typography>{" "}
        We perform regular data backups to prevent data loss due to unforeseen
        events, such as system failures or data corruption. These backups ensure
        the integrity and availability of your information.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          7. Incident Response:
        </Typography>{" "}
        In the unlikely event of a data breach or security incident, we have an
        incident response plan in place. This plan includes immediate
        identification, mitigation, and notification processes to address the
        situation promptly and in accordance with applicable laws.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          8. User Responsibility:
        </Typography>{" "}
        While we take extensive measures to protect your data, your account
        security also depends on you. We encourage you to use strong, unique
        passwords, keep your login credentials confidential, and exercise
        caution when sharing personal information with other users.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          9. Third-Party Security:
        </Typography>{" "}
        We work with third-party service providers who adhere to strict security
        standards. While we trust our partners, we encourage you to review their
        security practices when using integrated services.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          10. Continuous Improvement:
        </Typography>{" "}
        We are dedicated to continuously improving our data security practices
        to adapt to emerging threats and protect your information effectively.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          11. Reporting Security Concerns:
        </Typography>{" "}
        If you believe you’ve identified a security vulnerability in our app or
        have concerns about data security, please contact us immediately so that
        we can take appropriate action.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        Please know that Your data security is paramount to us, and we are
        committed to employing the best practices and technologies to safeguard
        your personal information. Our goal is to provide a safe and enjoyable
        experience on the Heart 2 Heart app while maintaining the highest
        standards of data security.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        DATA SECURITY
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        At Heart 2 Heart, we believe in responsible data management. This
        section outlines our data retention practices and how long we retain
        your personal information:
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          1. Data Retention Period:
        </Typography>{" "}
        We retain your personal data only for as long as it is necessary to
        fulfill the purposes outlined in this Privacy Policy, in accordance with
        applicable laws and regulations.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          2. Account Deletion:
        </Typography>{" "}
        If you choose to delete your Heart 2 Heart account, we will promptly
        initiate the deletion process. Your account information, including
        user-generated content, will be deleted from our active databases.
        Please note that it may take some time to remove all residual data from
        our backup systems, as it is a routine part of our data management
        process.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          3. Retained Data:
        </Typography>{" "}
        Some data may be retained for specific purposes, such as:
      </Typography>
      <StyledUL>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Compliance with Legal Obligations:
            </StyledTypography>{" "}
            We may retain certain data as required by applicable laws and
            regulations, including financial and transaction records.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Dispute Resolution:
            </StyledTypography>{" "}
            Data related to user interactions, chat history, and reported
            incidents may be retained to facilitate dispute resolution or
            address potential violations of our Terms of Service.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Safety and Security:
            </StyledTypography>{" "}
            To ensure the safety and security of our users and the app, we may
            retain data related to security incidents, investigations, or
            threats.
          </StyledTypography>
        </StyledLI>
      </StyledUL>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          4. Inactive Accounts:
        </Typography>{" "}
        If your account remains inactive (no login or app activity) for an
        extended period, we may implement data retention practices to reduce the
        storage of unnecessary data while still complying with applicable laws.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          5. Notification of Data Deletion:
        </Typography>{" "}
        Upon account deletion or data retention periods expiring, we will notify
        you via email or through a prominent notice on the app, where
        applicable.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          6. Data Portability:
        </Typography>{" "}
        If you wish to obtain a copy of your data or transfer it to another
        service, please contact us through the provided contact information. We
        will respond to your request promptly.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        We are committed to maintaining data integrity and ensuring that your
        personal information is retained only for legitimate purposes. Our data
        retention practices align with industry standards and legal
        requirements, and we regularly review and update our retention policies
        to reflect changing needs and regulations.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        CHILDREN’S PRIVACY
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        Heart 2 Heart is intended for use by individuals who are at least 18
        years old or the legal age of majority in their jurisdiction. We are
        committed to protecting the privacy of all users, including minors. This
        section addresses our approach to children’s privacy:
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          1. Age Restriction:
        </Typography>{" "}
        Our app is not intended for or directed toward individuals under the age
        of 18 or the legal age of majority in their jurisdiction. We do not
        knowingly collect or solicit personal information from minors.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          2. Parental Consent:
        </Typography>{" "}
        If you are a parent or legal guardian and believe that your child has
        provided personal information to Heart 2 Heart without your consent,
        please contact us immediately through the provided contact information.
        We will take prompt steps to investigate and, if necessary, delete the
        information.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          3. Data Deletion:
        </Typography>{" "}
        If we become aware that we have inadvertently collected personal
        information from a minor, we will delete that information as soon as
        possible. Please note that we may retain certain information for the
        purpose of ensuring that it is not collected again in the future.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          4. Educational Content:
        </Typography>{" "}
        While we aim to provide a safe and enjoyable experience for all users,
        if you are a minor, we encourage you to seek parental guidance when
        using online services and apps. If you have any questions about your
        privacy or data, feel free to contact us or involve a parent or
        guardian.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          5. Parental Control Tools:
        </Typography>{" "}
        We recommend that parents and legal guardians use available parental
        control tools and discuss safe online practices with their children to
        ensure their online experiences are both enjoyable and secure.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          6. Notification of Changes:
        </Typography>{" "}
        We may update our Privacy Policy to reflect changes in our data
        practices. In the event of significant changes affecting children’s
        privacy, we will provide appropriate notice and seek parental consent
        where required by applicable laws.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        In summary the privacy and safety of children are of utmost importance
        to us, and we are committed to complying with applicable laws and
        regulations regarding children’s privacy protection. If you have any
        concerns or questions regarding children’s privacy or data, please do
        not hesitate to contact us.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        UPDATE AND NOTIFICATION
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        At Heart 2 Heart, we are committed to keeping you informed about changes
        to our Privacy Policy and important updates related to your privacy and
        data. This section outlines how we handle policy updates and
        notifications:
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          1. Policy Updates:
        </Typography>{" "}
        We may periodically update this Privacy Policy to reflect changes in our
        data practices, services, or applicable laws and regulations. When we
        make significant updates to the Privacy Policy, we will notify you via
        email or through a prominent notice on the app.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          2. Reviewing Changes:
        </Typography>{" "}
        It is important to review any updates to the Privacy Policy to
        understand how they may impact your data rights and privacy choices. By
        continuing to use Heart 2 Heart after such updates, you indicate your
        acceptance of the revised Privacy Policy.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          3. Notification Frequency:
        </Typography>{" "}
        We aim to keep you informed about changes to our Privacy Policy without
        overwhelming your inbox. Notifications of updates will be sent as needed
        and in accordance with legal requirements.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          4. Policy Version:
        </Typography>{" "}
        Each version of the Privacy Policy will be clearly marked with an
        effective date to help you identify the most recent version.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          5. Contacting Us:
        </Typography>{" "}
        If you have any questions or concerns about changes to the Privacy
        Policy, please do not hesitate to contact our support team at
        support@heart-2-heart.app. We are here to address any inquiries you may
        have.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          6. Opting Out:
        </Typography>{" "}
        If you do not agree with the updated Privacy Policy, you have the option
        to discontinue using the Heart 2 Heart app. By doing so, you can request
        the deletion of your account and associated data, as outlined in our
        Data Retention policy.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        We are dedicated to ensuring that you are well-informed about how your
        data is handled and the choices available to you. Our goal is to
        maintain transparency in our data practices and to provide you with a
        secure and enjoyable experience on Heart 2 Heart.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        CONTACT INFORMATION
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        If you have any questions, concerns, or inquiries related to your
        privacy, data, or our Privacy Policy, please do not hesitate to contact
        us. We are here to assist you and address any issues you may have. You
        can reach us through the following contact information: Support Email:
        support@heart-2-heart.app
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 0px 0px"
      >
        Please allow us a reasonable time to respond to your inquiries, and we
        will make every effort to provide you with the information or assistance
        you need. Your privacy matters to us, and we are dedicated to
        maintaining open lines of communication to ensure a safe and enjoyable
        experience on Heart 2 Heart.
      </Typography>
      <Footer isVisible />
    </PageContainer>
  );
};

export default PrivacyPolicy;
