import React from "react";
import styled from "@emotion/styled";
import Button from "../../Buttons/Button";
import Icon from "../../Icon";
import Modal from "../../Shared/Modal";
import Typography from "../../Typography";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../redux/reducers/snackbarReducer";
import { useRenewSubscriptionMutation } from "../../../redux/features/subscriptionsApi";

interface RenewSubscriptionModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  width: 100%;
  height: 1px;
`;

const ButtonGroup = styled.div`
  margin-top: 8px;
	width: 100%;
`;

const RenewSubscriptionModal: React.FC<RenewSubscriptionModalProps> = ({
	isOpen,
	setIsOpen,
	refetch,
}) => {
	const dispatch = useDispatch();
	const [renewSubscription, { isLoading }] = useRenewSubscriptionMutation();

	const handleRenew = async (event: any) => {
		event.preventDefault();
		try {
			await renewSubscription().unwrap();
			setIsOpen(false);
			dispatch(showSnackbar({ message: "Subscription renewed successfully!", duration: 3000 }));
			refetch();
		} catch (error) {
			const errorMessage = (error as Error).message;
			console.error("Subscription renewal failed:", errorMessage);
			dispatch(showSnackbar({ message: "Subscription renewal failed. Please try again.", duration: 3000 }));
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			content={
				<ModalContainer>
					<Icon color="primaryPink" icon="refresh" />
					<Typography
						fontSize={24}
						fontWeight={500}
						lineHeight="110%"
						textAlign="center"
					>
            Renew your Premium Heart subscription with Heart 2 Heart
					</Typography>
					<Divider />
					<Typography
						color="secondaryLightGray"
						fontFamily="body"
						fontSize={14}
						fontWeight={400}
						textAlign="center"
					>
						Your subcription is set to cancel at the end of the billing cycle. Click below to renew your subscription to access all your premium benefits!
					</Typography>
					<ButtonGroup>
						<Button 
							fullWidth
							onClick={handleRenew}
							loading={isLoading}
						>
              Renew My Subscription
						</Button>
					</ButtonGroup>
				</ModalContainer>
			}
		/>
	);
};

export default RenewSubscriptionModal;
