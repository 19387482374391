import React, { useState } from "react";
import styled from "@emotion/styled";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Button from "../../Buttons/Button";
import RadioButton from "../../Inputs/RadioButton";
import Typography from "../../Typography";
import theme from "../../../theme";
import { handleCardSubmission } from "../../../utils/paymentHelpers";

enum PaymentMethodType {
  Card = "Card",
  ApplePay = "ApplePay",
  GooglePay = "GooglePay",
}

interface SetupFormProps {
  clientSecret: string;
  onSave: (setupIntent: any) => void;
}

const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: theme.colors.white,
			background: theme.colors.darkGray,
			fontFamily: theme.fonts.body,
			fontSmoothing: "antialiased",
			fontSize: "16px",
			"::placeholder": {
				color: theme.colors.white
			},
			padding: "10px 12px",
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a"
		}
	},
};

const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: end;
	margin: 20px 0;
`;

const SubmitButton = styled(Button)`
	padding: 12px 32px;
	font-size: 16px;
`;

const InputWrapper = styled.div`
	background: ${({ theme }) => theme.colors.containerBase};
	border-radius: 6px;
	padding: 16px 12px;
`;

const TabButtonWrapper = styled.div<{ isActive: boolean }>`
	width: 100%;
	padding: 20px 0;
	${({ isActive, theme }) => !isActive && `border-bottom: 1px solid ${theme.colors.borderGray};`}
`;

const TabButton = styled.button`
	display: flex;
	align-items: center;
  border: none;
  background: none;
  cursor: pointer;
	padding: 0;
`;

const TabContent = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => isActive ? "block" : "none"};
	padding-bottom: 24px;
	border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderGray}`};
`;

const SetupForm: React.FC<SetupFormProps> = ({ onSave, clientSecret }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [isLoading, setIsLoading] = useState(false);
	const [activePaymentMethod, setActivePaymentMethod] = useState<PaymentMethodType>(PaymentMethodType.Card);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!stripe || !elements) {
			console.error("Stripe.js has not fully loaded yet.");
			return;
		}

		setIsLoading(true);

		switch (activePaymentMethod) {
		case PaymentMethodType.Card: {
			await handleCardSubmission(stripe, elements, clientSecret, onSave);
			setIsLoading(false);
			break;
		}
		case PaymentMethodType.ApplePay: {
			// await handleApplePaySubmission(stripe, onSave);
			break;
		}
		case PaymentMethodType.GooglePay: {
			// await handleGooglePaySubmission(stripe, onSave);
			break;
		}
		default: {
			console.error("Unknown payment method selected.");
		}}
	};

	return (
		<form onSubmit={handleSubmit}>
			<TabButtonWrapper isActive={activePaymentMethod === PaymentMethodType.Card}>
				<TabButton
					onClick={() => setActivePaymentMethod(PaymentMethodType.Card)}
					type="button"
				>
					<RadioButton
						checked={activePaymentMethod === PaymentMethodType.Card}
					/>
					<Typography
						fontFamily="body"
						fontSize={16}
						fontWeight={400}
						margin="0 0 0 8px"
					>
					Debit or Credit Card
					</Typography>
				</TabButton>
			</TabButtonWrapper>
			<TabContent isActive={activePaymentMethod === PaymentMethodType.Card}>
				<InputWrapper>
					<CardElement options={CARD_ELEMENT_OPTIONS} />
				</InputWrapper>
			</TabContent>
			<TabButtonWrapper isActive={activePaymentMethod === PaymentMethodType.ApplePay}>
				<TabButton
					onClick={() => setActivePaymentMethod(PaymentMethodType.ApplePay)}
					type="button"
				>
					<RadioButton
						checked={activePaymentMethod === PaymentMethodType.ApplePay}
					/>
					<Typography
						fontFamily="body"
						fontSize={16}
						fontWeight={400}
						margin="0 0 0 8px"
					>
					Apple Pay
					</Typography>
				</TabButton>
			</TabButtonWrapper>
			<TabContent isActive={activePaymentMethod === PaymentMethodType.ApplePay}>
					Apple Pay is coming soon.
			</TabContent>
			<TabButtonWrapper isActive={activePaymentMethod === PaymentMethodType.GooglePay}>
				<TabButton
					onClick={() => setActivePaymentMethod(PaymentMethodType.GooglePay)}
					type="button"
				>
					<RadioButton
						checked={activePaymentMethod === PaymentMethodType.GooglePay}
					/>
					<Typography
						fontFamily="body"
						fontSize={16}
						fontWeight={400}
						margin="0 0 0 8px"
					>
					Google Pay
					</Typography>
				</TabButton>
			</TabButtonWrapper>
			<TabContent isActive={activePaymentMethod === PaymentMethodType.GooglePay}>
					Google Pay is coming soon.
			</TabContent>
			<ButtonWrapper>
				<SubmitButton
					type="submit"
					disabled={!stripe || isLoading}
				>
					Add Payment
				</SubmitButton>
			</ButtonWrapper>
		</form>
	);
};

export default SetupForm;
