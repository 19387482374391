import React from "react";
import styled from "@emotion/styled";
import Typography from "../Typography";

export interface CheckboxProps {
  label?: string;
  checked: boolean;
  onChange: (value: string, checked: boolean) => void;
  value: string;
  inverted?: boolean;
  disabled?: boolean;
}

const CheckboxWrapper = styled.label<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  font-size: 16px;
  user-select: none;
`;

const Checkmark = styled.span<{ inverted: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: ${({ theme, inverted }) =>
		inverted ? theme.colors.neutralGray : theme.colors.containerBase};
  border: 2px solid
    ${({ theme, inverted }) => (inverted ? "none" : theme.colors.borderGray)};
  box-sizing: border-box;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid ${({ theme }) => theme.colors.primaryPink};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const StyledCheckbox = styled.input<{ inverted: boolean; disabled: boolean }>`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: ${({ theme, inverted }) =>
		inverted ? theme.colors.neutralGray : theme.colors.containerBase};
    border: 2px solid
      ${({ theme, inverted }) =>
		inverted ? theme.colors.neutralGray : theme.colors.containerBase};
  }

  &:checked ~ span:after {
    display: block;
  }

  &:hover ~ span {
    border: 2px solid
      ${({ theme, disabled }) =>
		disabled ? "none" : theme.colors.accentViolet};
    box-shadow: ${({ disabled }) =>
		disabled ? "none" : "0px 0px 30px 0px rgba(204, 28, 210, 0.5)"};
    box-sizing: border-box;
  }
`;

const Checkbox: React.FC<CheckboxProps> = ({
	label,
	checked,
	onChange,
	value,
	inverted,
	disabled,
}) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(value, e.target.checked);
	};

	return (
		<CheckboxWrapper disabled={!!disabled}>
			<Typography
				color="secondaryLightGray"
				fontFamily="body"
				fontSize={18}
				fontWeight={300}
			>
				{label}
			</Typography>
			<StyledCheckbox
				disabled={!!disabled}
				inverted={!!inverted}
				type="checkbox"
				checked={checked}
				onChange={handleChange}
			/>
			<Checkmark inverted={!!inverted} />
		</CheckboxWrapper>
	);
};

export default Checkbox;
