import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { useSpring, animated } from "react-spring";

interface ScrollFadeInProps {
  delay?: number;
  duration?: number;
  easing?: string;
  onInView?: () => void;
  onOutOfView?: () => void;
  once?: boolean;
  direction?: "left" | "right" | "up" | "down";
  disableOnMobile?: boolean;
  placeholder?: React.ReactNode;
  children: React.ReactNode;
  width?: string | undefined;
}

const FadeInWrapper = styled.div<{ width: string | undefined }>`
  display: flex;
  ${({ width }) => width && `width: ${width};`}ß
`;

const ScrollFadeIn: React.FC<ScrollFadeInProps> = ({
	delay = 0,
	duration = 500,
	onInView,
	onOutOfView,
	once = false,
	direction = "up",
	disableOnMobile = false,
	placeholder,
	width,
	children,
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const hasAnimated = useRef(false);
	const ref = useRef(null);

	const props = useSpring({
		config: {
			duration: duration,
		},
		delay,
		opacity: isVisible ? 1 : 0,
		transform: isVisible
			? "translate3d(0, 0, 0)"
			: direction === "up"
				? "translate3d(0, -25%, 0)"
				: direction === "down"
					? "translate3d(0, 25%, 0)"
					: direction === "left"
						? "translate3d(-25%, 0, 0)"
						: "translate3d(25%, 0, 0)",
	});

	useEffect(() => {
		if (disableOnMobile && window.innerWidth <= 768) {
			setIsVisible(true);
			return;
		}

		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					if (!once || (once && !hasAnimated.current)) {
						setIsVisible(true);
						hasAnimated.current = true;
						onInView && onInView();
					}
				} else {
					if (!once) {
						setIsVisible(false);
						onOutOfView && onOutOfView();
					}
				}
			},
			{
				threshold: 0.8,
			}
		);
    
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [once, onInView, onOutOfView, disableOnMobile]);

	return (
		<FadeInWrapper ref={ref} width={width}>
			{isVisible || !placeholder ? (
				<animated.div style={{ ...props}}>{children}</animated.div>
			) : (
				placeholder
			)}
		</FadeInWrapper>
	);
};

export default ScrollFadeIn;

