import React from "react";
import styled from "@emotion/styled";
import Typography from "../Typography";

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  checked: boolean;
  value?: string | number;
  onChange?: () => void;
}

const RadioButtonWrapper = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const RadioButtonLabel = styled.div`
  margin-left: 10px;
`;

const RadioButtonOuterCircle = styled.div<{ checked: boolean }>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: ${({ checked, theme }) => (checked ? `solid 2px ${theme.colors.primaryPink}` : "solid 2px white")};
  box-sizing: border-box;
`;

const RadioButtonInnerCircle = styled.div<{ checked: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;

  ${({ checked, theme }) =>
		checked &&
    `
    background: ${theme.colors.primaryPink};
  `}
`;

const RadioButtonInput = styled.input`
  display: none;
`;

const RadioButton: React.FC<RadioButtonProps> = ({
	label,
	checked,
	value,
	...props
}) => {
	return (
		<RadioButtonWrapper>
			<RadioButtonOuterCircle checked={checked}>
				<RadioButtonInnerCircle checked={checked} />
			</RadioButtonOuterCircle>
			<RadioButtonInput
				readOnly
				value={value}
				type="radio"
				checked={checked}
				{...props}
			/>
			{label && (
				<RadioButtonLabel>
					<Typography
						color="white"
						fontFamily="primary"
						fontSize={16}
						fontWeight={400}
					>
						{label}
					</Typography>
				</RadioButtonLabel>
			)}
		</RadioButtonWrapper>
	);
};

export default RadioButton;
