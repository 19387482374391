import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import Button from "../Buttons/Button";
import GradientHeart from "../Shared/GradientHeart";
import ScrollFadeIn from "../Animations/ScrollFadeIn";
import { useViewport } from "../../hooks/ViewportProvider";

const FifthSectionContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ isMobile }) =>
		isMobile ? "0px 24px 175px 24px" : "0px 112px 175px 112px"};
  position: relative;
  margin-top: 120px;
`;

const Headline = styled.h1<{ isMobile: boolean }>`
  color: ${({ theme }) => theme.colors.primaryPink};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 12px;
  text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
`;

const SubHeadline = styled.h1<{ isMobile: boolean }>`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ isMobile }) => isMobile ? "24px" : "32px"};
  font-weight: 500;
  margin: 0;
  text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
`;

const BodyText = styled.p<{ isMobile: boolean }>`
  color: ${({ theme }) => theme.colors.lightGray};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 18px;
  font-weight: 400;
  margin: 16px 0 32px;
  max-width: 600px;
  text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
`;

const ButtonWrapper = styled.div<{ isMobile: boolean }>`
  text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
`;

const Glow = styled.div`
  border-radius: 300px;
  opacity: 0.8;
  background: ${({ theme }) =>
		`radial-gradient(circle, ${theme.colors.primaryPink}60, ${theme.colors.accentViolet}25 50%, transparent 70%)`};
  position: absolute;
  top: 50%;
  left: 15%;
  width: calc(min(85vw, 85vh));
  height: calc(min(85vw, 85vh));
  transform: translate(-50%, -50%);
  filter: blur(200px);
  z-index: -2;
`;

const BigHeart = styled.div<{ isMobile: boolean }>`
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? "-50px" : 0)};
  left: 10%;
`;

const MediumHeart = styled.div`
  position: absolute;
  top: -150px;
  right: 10%;
`;

const LittleHeart = styled.div`
  position: absolute;
  bottom: 100px;
  left: 20%;
`;

const FifthSection = () => {
	const navigate = useNavigate();
	const viewport = useViewport();
	const isMobile = viewport === "mobile" || viewport === "tablet";
	const isMobileSmall = viewport === "mobile";

	return (
		<FifthSectionContainer isMobile={isMobile}>
			<Glow />
			<BigHeart isMobile={isMobile}>
				<GradientHeart scale={2} />
			</BigHeart>
			<MediumHeart>
				<GradientHeart scale={1.4} />
			</MediumHeart>
			<LittleHeart>
				<GradientHeart scale={1} />
			</LittleHeart>
			<ScrollFadeIn
				delay={100}
				direction="down"
				disableOnMobile={isMobileSmall}
				once
			>
				<Headline isMobile={isMobile}>HOW TO GET STARTED</Headline>
				<SubHeadline isMobile={isMobile}>Embrace the Journey</SubHeadline>
				<BodyText isMobile={isMobile}>
          Finding love is an exciting adventure, and we’re here to support you
          every step of the way. Our personalized matches are just the
          beginning; you have the power to create lasting connections that have
          the potential to redefine your life.
				</BodyText>
				<ButtonWrapper isMobile={isMobile}>
					<Button onClick={() => navigate("/sign-up")} variant="primary">
            Join Our Community
					</Button>
				</ButtonWrapper>
			</ScrollFadeIn>
		</FifthSectionContainer>
	);
};

export default FifthSection;
