import React from "react";
import styled from "@emotion/styled";
import theme from "../../theme";

type BannerVariant = "info" | "warning" | "error" | "success";

interface BannerProps {
  variant?: BannerVariant;
  onClose?: () => void;
  children: React.ReactNode;
}

const bannerColors = {
	info: theme.colors.accentCyan,
	warning: "#ff9800",
	error: theme.colors.errorRed,
	success: theme.colors.accentAquamarine,
};

const StyledBanner = styled.div<{ variant: BannerVariant }>`
  background-color: ${({ variant }) => bannerColors[variant]};
  color: white;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  position: relative;
  text-align: center;
  height: 56px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  &:hover {
    opacity: 0.8;
  }
`;

const Banner: React.FC<BannerProps> = ({ children, variant = "info", onClose }) => {
	return (
		<StyledBanner variant={variant}>
			{children}
			{onClose && <CloseButton onClick={onClose}>&times;</CloseButton>}
		</StyledBanner>
	);
};

export default Banner;
