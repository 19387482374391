import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Buttons/Button";
import ButtonText from "../Buttons/ButtonText";
import Checkbox from "../Inputs/Checkbox";
import RadioButton from "../Inputs/RadioButton";
import Typography from "../Typography";
import { StepProps } from "../../types/Step";
import { GENDER_OPTIONS } from "../../data/options";
import { updateUserData } from "../../redux/actions/userActions";
import { selectUser } from "../../redux/reducers/userReducer";
import { selectStep, setStep} from "../../redux/reducers/onboardingReducer";
import { useViewport } from "../../hooks/ViewportProvider";

import type { AppDispatch } from "../../redux/store";

interface Step2StateProps {
  gender: string;
  genderPreference: string[];
}

interface Step2Errors {
  gender?: string;
  genderPreference?: string;
}

const InputWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 28px 0;
  position: relative;
  flex-wrap: ${({ isMobile }) => (isMobile ? "wrap" : "nowrap")};
  max-width: ${({ isMobile }) => isMobile && "300px"};
  row-gap: 12px;
`;

const RadioButtonWrapper = styled.div<{ checked: boolean; isMobile: boolean }>`
  display: flex;
  flex: 1;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.containerBase};
  border-radius: 6px;
  border: ${({ checked, theme }) =>
		checked
			? `solid 2px ${theme.colors.accentViolet}`
			: `solid 1px ${theme.colors.lightGray}`};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  max-width: ${({ isMobile }) => isMobile && "140px"};
  margin: 0 16px 0 0;
  &:last-of-type {
    margin: 0;
  }

  ${({ checked }) => checked && "0px 0px 30px 0px rgba(204, 28, 210, 0.50);"}
`;

const PreferenceWrapper = styled.div`
  position: relative;
  margin: 24px 0 48px;
`;

const PreferenceError = styled.div`
  position: absolute;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  width: 100%;
`;

const MobileSubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  gap: 32px;
`;

const SubmitButton = styled(Button)<{ isMobile?: boolean }>`
  ${({ isMobile }) => (isMobile ? "width: 100%;" : "margin-left: 24px;")};
`;

const Step2: React.FC<StepProps> = () => {
	const dispatch: AppDispatch = useDispatch();
	const user = useSelector(selectUser);
	const step = useSelector(selectStep);
	const viewport = useViewport();
	const isMobile = viewport === "mobile";
	const defaultGender = user?.gender || localStorage.getItem("gender");
	const preferenceFromInput = localStorage.getItem("genderPreference");
	let inputPref;
	if (preferenceFromInput === "all") {
		inputPref = ["male", "female", "non-binary"];
	} else if (preferenceFromInput) {
		inputPref = [preferenceFromInput];
	}
	const defaultPreference = user?.preferences?.genderPreference?.length
		? user?.preferences?.genderPreference
		: inputPref;

	const [formData, setFormData] = useState<Step2StateProps>({
		gender: defaultGender || "male",
		genderPreference: defaultPreference || [],
	});
	const [formErrors, setFormErrors] = useState<Step2Errors>({});

	const validateFormData = (formData: Step2StateProps) => {
		const { genderPreference } = formData;
		const errors: Step2Errors = {};

		if (!genderPreference.length) {
			errors.genderPreference = "Match preference is required.";
		}

		return errors;
	};

	const handleSubmit = async () => {
		if (!user || !user.id) return;
		const errors = validateFormData(formData);

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		const currentStep = user?.onboarding_step || 0;
		let newStep = 2;
		if (currentStep > 2) {
			newStep = currentStep;
		}

		const data = {
			gender: formData.gender,
			preferences: { ...user.preferences, genderPreference: formData.genderPreference},
			onboarding_step: newStep,
		};

		dispatch(updateUserData({ data }))
			.unwrap()
			.then(() => {
				localStorage.removeItem("gender");
				localStorage.removeItem("genderPreference");
				dispatch(setStep(step + 1));
			})
			.catch(error => {
				console.error("Error updating user: ", error);
			});
	};

	const handleGenderChange = (event: React.MouseEvent<HTMLDivElement>) => {
		const value = event?.currentTarget?.dataset?.value || "male";
		setFormData({ ...formData, gender: value });
	};

	const handlePreferenceChange = (value: string, checked: boolean) => {
		setFormData((prevData) => {
			const newPreferences = checked
				? [...prevData.genderPreference, value]
				: prevData.genderPreference.filter((pref) => pref !== value);
        
			return {
				...prevData,
				genderPreference: newPreferences,
			};
		});
	};

	return (
		<>
			<Typography fontSize={20}>I am...</Typography>
			<InputWrapper isMobile={isMobile}>
				{GENDER_OPTIONS.map((option) => (
					<RadioButtonWrapper
						isMobile={isMobile}
						data-value={option.value}
						key={option.value}
						checked={formData.gender === option.value}
						onClick={handleGenderChange}
					>
						<RadioButton
							checked={formData.gender === option.value}
							label={option.label}
							value={option.value}
						/>
					</RadioButtonWrapper>
				))}
			</InputWrapper>
			<Typography fontSize={20} lineHeight="110%">
        Looking to be matched with...
			</Typography>
			<Typography
				color="lightGray"
				fontFamily="body"
				fontSize={18}
				fontWeight={400}
				lineHeight="24px"
				margin="4px 0 0 0"
			>
        (Select all that apply)
			</Typography>
			<PreferenceWrapper>
				<Checkbox
					checked={formData.genderPreference.includes("male")}
					label="Men"
					onChange={handlePreferenceChange}
					value="male"
				/>
				<Checkbox
					checked={formData.genderPreference.includes("female")}
					label="Women"
					onChange={handlePreferenceChange}
					value="female"
				/>
				<Checkbox
					checked={formData.genderPreference.includes("non-binary")}
					label="Non-binary"
					onChange={handlePreferenceChange}
					value="non-binary"
				/>
				<PreferenceError>
					{formErrors.genderPreference && (
						<Typography
							color="errorRed"
							fontFamily="body"
							fontSize={14}
							fontWeight={400}
						>
							{formErrors.genderPreference}
						</Typography>
					)}
				</PreferenceError>
			</PreferenceWrapper>
			{isMobile ? (
				<MobileSubmitButtonWrapper>
					<SubmitButton isMobile={isMobile} onClick={handleSubmit}>
            Continue
					</SubmitButton>
					<ButtonText onClick={() => dispatch(setStep(1))}>
						<Typography color="primaryPink" fontFamily="body" fontWeight={500}>
              Go Back
						</Typography>
					</ButtonText>
				</MobileSubmitButtonWrapper>
			) : (
				<SubmitButtonWrapper>
					<ButtonText onClick={() => dispatch(setStep(1))}>
						<Typography color="primaryPink" fontFamily="body" fontWeight={500}>
              Go Back
						</Typography>
					</ButtonText>
					<SubmitButton onClick={handleSubmit}>Continue</SubmitButton>
				</SubmitButtonWrapper>
			)}
		</>
	);
};

export default Step2;
