import React from "react";
import styled from "@emotion/styled";
import Link from "../Links/Link";
import Typography from "../Typography";

export interface TermsAndPrivacyProps {
  signUp?: boolean;
}

const TermsAndPrivacyPolicy = styled.div`
  text-align: center;
  margin-bottom: 80px;
`;

const StyledLink = styled(Link)`
  display: inline;
  margin: 0 4px;
`;

const TermsAndPrivacy: React.FC<TermsAndPrivacyProps> = ({ signUp }) => {
	return (
		<TermsAndPrivacyPolicy>
			{signUp && (
				<Typography
					variant="span"
					color="lightGray"
					fontFamily="secondary"
					fontSize={16}
					fontWeight={400}
				>
          By signing up you’re agreeing to our&nbsp;
				</Typography>
			)}
			<StyledLink to="/terms-of-service">
				<Typography
					variant="span"
					color="primaryPink"
					fontFamily="secondary"
					fontSize={16}
					fontWeight={400}
				>
          Terms
				</Typography>
			</StyledLink>
			<Typography
				variant="span"
				color="lightGray"
				fontFamily="secondary"
				fontSize={16}
				fontWeight={400}
			>
        &nbsp;and&nbsp;
			</Typography>
			<StyledLink to="/privacy-policy">
				<Typography
					variant="span"
					color="primaryPink"
					fontFamily="secondary"
					fontSize={16}
					fontWeight={400}
				>
          Privacy Policy
				</Typography>
			</StyledLink>
		</TermsAndPrivacyPolicy>
	);
};

export default TermsAndPrivacy;

