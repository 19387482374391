import React from "react";
import styled from "@emotion/styled";
import FaqCollapse from "../components/Shared/FaqCollapse";
import Footer from "../components/Shared/Footer";
import { FAQS } from "../data/faqs";
import { useViewport } from "../hooks/ViewportProvider";

const FAQPageContainer = styled.div<{ isMobile: boolean }>`
  background: radial-gradient(circle at 100% 30%, 
    ${({ theme }) => `${theme.colors.accentCyan}25`}, transparent 50%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: ${({ isMobile }) => isMobile ? "77px" : "94.5px"};
`;

const Heading = styled.h1`
  font-size: 40px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 32px;
`;

const FAQPageContent = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: ${({ isMobile }) => isMobile ? "24px" : "80px"};
`;

const FAQsContainer = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => !isMobile && "width: 80vw;"}
`;

const FAQPage = () => {
	const viewport = useViewport();
	const isMobileOrTablet = viewport === "mobile" || viewport === "tablet";
	const isMobile = viewport === "mobile";

	return (
		<FAQPageContainer isMobile={isMobile}>
			<FAQPageContent isMobile={isMobileOrTablet}>
				<Heading>FAQs</Heading>
				<FAQsContainer isMobile={isMobileOrTablet}>
					{FAQS.map((faq, idx) => (
						<FaqCollapse
							key={`${faq.question}-${idx}`}
							question={faq.question}
							answer={faq.answer}
							isMobile={isMobile}
						/>
					))}
				</FAQsContainer>
			</FAQPageContent>
			<Footer isVisible={false} />
		</FAQPageContainer>
	);
};

export default FAQPage;
