/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AgePreferenceTab from "./PreferenceTabs/AgePreferenceTab";
import GenderPreferenceTab from "./PreferenceTabs/GenderPreferenceTab";
import LocationPreferenceTab from "./PreferenceTabs/LocationPreferenceTab";
import PoliticsPreferenceTab from "./PreferenceTabs/PoliticsPreferenceTab";
import ReligionPreferenceTab from "./PreferenceTabs/ReligionPreferenceTab";
import Icon from "../Icon";
import Typography from "../Typography";
import useViewport from "../../hooks/ViewportProvider";
import { selectUser } from "../../redux/reducers/userReducer";
import { RELIGIONS } from "../../data/religions";
import { POLITICS } from "../../data/politics";

type PreferenceKey =
  | "genderPreference"
  | "locationRange"
  | "ageRange"
  | "religiousBeliefs"
  | "politicalBeliefs";

const PREFERENCE_TABS = [
	{
		title: "I'm interested in",
		tab: "genderPreference" as PreferenceKey,
		param: "gender",
		component: GenderPreferenceTab,
	},
	{
		title: "Maximum Distance",
		tab: "locationRange" as PreferenceKey,
		param: "location",
		component: LocationPreferenceTab,
	},
	{
		title: "Age Range",
		tab: "ageRange" as PreferenceKey,
		param: "age",
		component: AgePreferenceTab,
	},
	{
		title: "Religion",
		tab: "religiousBeliefs" as PreferenceKey,
		param: "religion",
		component: ReligionPreferenceTab,
	},
	{
		title: "Politics",
		tab: "politicalBeliefs" as PreferenceKey,
		param: "politics",
		component: PoliticsPreferenceTab,
	},
];

const PreferencesTabContainer = styled.div`
  display: flex;
`;

const PreferencePanel = styled.div`
  padding: 40px 32px;
  width: 463px;
  box-sizing: border-box;
  border-right: ${({ theme }) => `1px solid ${theme.colors.borderGray}`};
`;

const PanelTabWrapper = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.colors.borderGray}`};

  &:last-child {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderGray}`};
  }
`;

const PanelTab = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 65px;
  padding: 20px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 8px 0;

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.primaryPink};
    }
  }

  ${({ isActive, theme }) =>
		isActive &&
    `
    background: ${theme.colors.white}20;
  `}
`;

const BackToSettingsButton = styled.button`
  width: 100%;
  border: none;
  outline: none;
  margin: 0;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.containerBase};
  cursor: pointer;
  text-align: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.primaryPink};
    }
  }
`;

const PanelTextWrapper = styled.div``;

const StyledTypography = styled(Typography)`
  text-transform: capitalize;
`;

const StyledIcon = styled(Icon)`
  transform: scale(0.8334) rotate(270deg);
`;

const getTabFromURL = (search: string): string | null => {
	const params = new URLSearchParams(search);
	return params.get("preference");
};

const PreferencesTab = () => {
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	const viewport = useViewport();
	const isMobile = viewport === "mobile" || viewport === "laptop";
	const preferences = user?.preferences;
	const [activeTab, setActiveTab] = useState(
		getTabFromURL(location.search) || (isMobile ? null : "gender")
	);

	useEffect(() => {
		setActiveTab(
			getTabFromURL(location.search) || (isMobile ? null : "gender")
		);
	}, [location.search]);

	const handleTabChange = (tab: string) => {
		navigate(`/settings?tab=preferences&preference=${tab}`);
	};

	const locationRangeDisplay = preferences?.locationRange?.max
		&& preferences?.locationRange?.max > 200
		? "Any" : `${preferences?.locationRange?.max} miles` || "None";

	const ageRangeDisplay = 
    preferences?.ageRange?.min === 18 && preferences?.ageRange?.max === 99
    	? "Any" : preferences?.ageRange?.min && preferences?.ageRange?.max
    		? `${preferences?.ageRange?.min}-${preferences?.ageRange?.max} years` : "Any";

	const religiousBeliefsDisplay = preferences?.religiousBeliefs?.beliefs?.length === RELIGIONS.length
		? "Any" : preferences?.religiousBeliefs?.beliefs?.join(", ") || "None";

	const politicalBeliefsDisplay = preferences?.politicalBeliefs?.beliefs?.length === POLITICS.length
		? "Any" : preferences?.politicalBeliefs?.beliefs?.join(", ") || "None";

	const activeVal = {
		genderPreference: preferences?.genderPreference?.join(", "),
		locationRange: locationRangeDisplay,
		ageRange: ageRangeDisplay,
		religiousBeliefs: religiousBeliefsDisplay,
		politicalBeliefs: politicalBeliefsDisplay,
	};

	const activeTabComponent =
    PREFERENCE_TABS.find((tab) => tab.param === activeTab)?.component ||
    (isMobile ? null : GenderPreferenceTab);

	const renderPanelContent = () => {
		return (
			<>
				<Typography fontSize={24} fontWeight={700} margin="24px 24px">
          Dating Preferences
				</Typography>
				{PREFERENCE_TABS.map((preference, idx) => {
					const isActive = preference.param === activeTab;
					return (
						<PanelTabWrapper key={`${preference.tab}-${idx}`}>
							<PanelTab
								isActive={isActive}
								onClick={() => handleTabChange(preference.param)}
							>
								<PanelTextWrapper>
									<Typography
										fontFamily="body"
										fontWeight={400}
										margin="0 0 8px"
									>
										{preference.title}
									</Typography>
									<StyledTypography
										color="secondaryLightGray"
										fontFamily="body"
										fontSize={12}
										fontWeight={400}
									>
										{activeVal[preference.tab]}
									</StyledTypography>
								</PanelTextWrapper>
								<StyledIcon
									color={isActive ? "white" : "primaryPink"}
									icon="caret-down"
								/>
							</PanelTab>
						</PanelTabWrapper>
					);
				})}
			</>
		);
	};

	if (isMobile) {
		return (
			<>
				{activeTabComponent ? (
					<>
						{viewport === "laptop" && (
							<BackToSettingsButton
								onClick={() => navigate("/settings?tab=preferences")}
							>
								<Icon icon="arrow-left" />
								<Typography
									fontFamily="body"
									fontSize={16}
									fontWeight={500}
									margin="0 0 0 4px"
								>
                  Back
								</Typography>
							</BackToSettingsButton>
						)}
						{activeTabComponent && user &&
              React.createElement(activeTabComponent, { user })}
					</>
				) : (
					renderPanelContent()
				)}
			</>
		);
	}

	return (
		<PreferencesTabContainer>
			<PreferencePanel>
				<Typography fontSize={24} fontWeight={700} margin="0 0 20px">
          Dating Preferences
				</Typography>
				{PREFERENCE_TABS.map((preference, idx) => {
					const isActive = preference.param === activeTab;
					return (
						<PanelTabWrapper key={`${preference.tab}-${idx}`}>
							<PanelTab
								isActive={isActive}
								onClick={() => handleTabChange(preference.param)}
							>
								<PanelTextWrapper>
									<Typography
										fontFamily="body"
										fontWeight={400}
										margin="0 0 8px"
									>
										{preference.title}
									</Typography>
									<StyledTypography
										color="secondaryLightGray"
										fontFamily="body"
										fontSize={12}
										fontWeight={400}
									>
										{activeVal[preference.tab]}
									</StyledTypography>
								</PanelTextWrapper>
								<StyledIcon
									color={isActive ? "white" : "primaryPink"}
									icon="caret-down"
								/>
							</PanelTab>
						</PanelTabWrapper>
					);
				})}
			</PreferencePanel>
			{activeTabComponent && user && React.createElement(activeTabComponent, { user })}
		</PreferencesTabContainer>
	);
};

export default PreferencesTab;
