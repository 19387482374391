import React from "react";
import styled from "@emotion/styled";
import { CustomTheme } from "../../theme";

export interface ButtonProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  textColor?: keyof CustomTheme["colors"];
}

const StyledButton = styled.button<ButtonProps>`
  background: transparent;
  border: none;
  color: ${({ textColor, theme }) => textColor ? theme.colors[textColor] : theme.colors.primaryPink};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 20px;
  font-weight: 700;
  position: relative;
`;

const ButtonText: React.FC<ButtonProps> = ({
	children,
	onClick,
	textColor,
	...props
}) => {
  
	return (
		<StyledButton
			onClick={onClick}
			textColor={textColor}
			{...props}
		>
			{children}
		</StyledButton>
	);
};

export default ButtonText;