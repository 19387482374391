export const calculateAge = (birthDate: Date) => {
	const today = new Date();
	const birthDateObj = new Date(birthDate);

	let age = today.getFullYear() - birthDateObj.getFullYear();
	const monthDifference = today.getMonth() - birthDateObj.getMonth();

	// If the birthday hasn't occurred yet this year, then subtract 1 from the age.
	if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
		age--;
	}

	return age;
};

export const formatBirthday = (birthDate: Date): string => {
	const monthNames = [
		"January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];

	const birthday = new Date(birthDate);

	const day = birthday.getDate();
	const monthIndex = birthday.getMonth();

	return `${monthNames[monthIndex]} ${day}`;
};