import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { SpringRef, animated, useSpring } from "react-spring";
import AnimatedStars from "../Animations/AnimatedStars";
import GradientHeart from "../Shared/GradientHeart";
import LoadingSpinner from "../Animations/LoadingSpinner";

export interface GoLiveButtonProps {
  loading?: boolean;
  onClick?: () => void;
}

const heartOneKeyframes = [
	{ opacity: 0.7, scale: 1, config: { duration: 300 } },
	{ opacity: 1, scale: 1.5, config: { duration: 300 } },
	{ opacity: 0.5, scale: 1, config: { duration: 300 } },
];

const heartTwoKeyframes = [
	{ opacity: 0.5, scale: 0.7, config: { duration: 300 } },
	{ opacity: 0.7, scale: 0.7, config: { duration: 300 } },
	{ opacity: 1, scale: 1.15, config: { duration: 300 } },
];

const heartThreeKeyframes = [
	{ opacity: 1, scale: 1.5, config: { duration: 300 } },
	{ opacity: 0.7, scale: 1, config: { duration: 300 } },
];

const StyledSpan = styled.span`
  position: relative;
  z-index: 1;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 700;
  font-size: 24px;
  display: block;
  color: ${({ theme }) => theme.colors.white};
  transition: opacity 0.4s;
`;

const ButtonWrapper = styled.div`
  position: relative;
  border-radius: 100px;
  padding: 5px;
  transform: scale(var(--generate-button-scale, 1)) translateZ(0);
  transition: transform 0.3s;
  overflow: hidden;
  box-shadow: 4px 38px 62px 0px rgba(0, 0, 0, 0.5),
    -3px -4px 7px 0px rgba(255, 255, 255, 0.15) inset;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    --generate-button-scale: 1.2;
    box-shadow: 0px 0px 30px ${({ theme }) => theme.colors.accentCyan}70;

    button {
      color: #fff;
      background: linear-gradient(239deg, #9c00a0 0%, #d902b9 100%);

      &:before {
        content: "";
        position: absolute;
        width: 270px;
        height: 270px;
        background: ${({ theme }) => `
          linear-gradient(217deg, #550af7, #550af705 70.71%),
          linear-gradient(127deg, #550af7, #550af705  70.71%),
          linear-gradient(336deg, ${theme.colors.white}, ${theme.colors.white}05 70.71%)
        `};
        animation: rotate 1.5s linear infinite;
      }

      &:after {
        content: "";
        position: absolute;
        background: linear-gradient(239deg, #9c00a0 0%, #d902b9 100%);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 100px;
      }

      ${StyledSpan} {
        &::before,
        &::after {
          content: attr(data-text);
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.8;
          z-index: -1;
        }

        &::before {
          animation: glitch-it 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
            infinite;
          color: #00ffff;
          z-index: -1;
        }

        &::after {
          animation: glitch-it 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse
            both infinite;
          color: #ff00ff;
          z-index: -2;
        }
      }
    }

    &:active {
      --generate-button-scale: 1.05;
    }

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @keyframes glitch-it {
      0% {
        transform: translate(0);
      }
      20% {
        transform: translate(-2px, 2px);
      }
      40% {
        transform: translate(-2px, -2px);
      }
      60% {
        transform: translate(2px, 2px);
      }
      80% {
        transform: translate(2px, -2px);
      }
      to {
        transform: translate(0);
      }
    }
  }
`;

const StyledGoLiveButton = styled.button<GoLiveButtonProps>`
  height: 76px;
  width: 224.13px;
  appearance: none;
  outline: none;
  border: none;
  padding: 24px 52px;
  border-radius: 100px;
  margin: 0;
  background: linear-gradient(239deg, #9c00a0 0%, #d902b9 100%);
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s, color 0.3s;

  svg {
    display: block;
    overflow: visible;
    pointer-events: none;

    &.dots {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      fill: white;
      opacity: var(--generate-button-dots-opacity);
    }
  }
`;

const TripleHeartWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 27px;
  margin-right: 12px;
  z-index: 10;
`;

const GoLiveButton: React.FC<GoLiveButtonProps> = ({ loading, onClick }) => {
	const animatingRef = useRef(true);
	const animationInterval = useRef<number | null>(null);
	const [hovering, setHovering] = useState(false);
	const totalDurationOfLongestSequence = heartTwoKeyframes.reduce(
		(acc, curr) => acc + (curr.config?.duration || 0),
		0
	);

	const [heartOneProps, setHeartOne] = useSpring(() => ({
		opacity: 0.5,
		scale: 1,
	}));

	const [heartTwoProps, setHeartTwo] = useSpring(() => ({
		opacity: 1,
		scale: 1,
	}));

	const [heartThreeProps, setHeartThree] = useSpring(() => ({
		opacity: 0.7,
		scale: 1,
	}));

	const executeKeyframes = (setFunc: SpringRef<{ opacity: number; scale: number; }>, keyframes: any, delay = 0) => {
		let frameIndex = 0;

		const nextFrame = () => {
			if (!animatingRef.current) return;

			if (frameIndex < keyframes.length) {
				const currentKeyframe = keyframes[frameIndex];
				const { config, ...restProps } = currentKeyframe;

				setFunc.start({
					...restProps,
					config: config || {},
					onRest: nextFrame,
				});

				frameIndex++;
			}
		};

		setTimeout(() => {
			nextFrame();
		}, delay);
	};

	const handleMouseEnter = () => {
		// Heart Animations
		animatingRef.current = true;
		setHovering(true);

		// Clear any ongoing intervals
		if (animationInterval.current !== null) {
			clearInterval(animationInterval.current);
		}

		const animateHearts = () => {
			executeKeyframes(setHeartOne, heartOneKeyframes);
			executeKeyframes(setHeartTwo, heartTwoKeyframes);
			executeKeyframes(setHeartThree, heartThreeKeyframes);
		};

		animateHearts();

		// Only create a new interval if one doesn't already exist
		if (!animationInterval.current) {
			animationInterval.current = setInterval(
				animateHearts,
				totalDurationOfLongestSequence + 100
			) as unknown as number;
		}
	};

	const handleMouseLeave = () => {
		animatingRef.current = false;
		setHovering(false);

		// Clear any ongoing intervals
		if (animationInterval.current) {
			clearInterval(animationInterval.current);
			animationInterval.current = null;
		}

		setHeartOne({ opacity: 0.5, scale: 1 });
		setHeartTwo({ opacity: 1, scale: 1 });
		setHeartThree({ opacity: 0.7, scale: 1 });
	};

	useEffect(() => {
		return () => {
			if (animationInterval.current) {
				clearInterval(animationInterval.current);
			}
		};
	}, []);

	return (
		<>
			<AnimatedStars isVisible={hovering} />
			<ButtonWrapper>
				<StyledGoLiveButton
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					onClick={onClick}
				>
					<TripleHeartWrapper>
						<animated.div
							style={{
								position: "absolute",
								top: "-7px",
								left: "-7px",
								opacity: heartOneProps.opacity,
								transform: heartOneProps.scale.to((scale) => `scale(${scale})`),
							}}
						>
							<GradientHeart scale={0.4} />
						</animated.div>
						<animated.div
							style={{
								position: "absolute",
								top: "0px",
								left: "7px",
								opacity: heartTwoProps.opacity,
								transform: heartTwoProps.scale.to((scale) => `scale(${scale})`),
							}}
						>
							<GradientHeart scale={0.7} />
						</animated.div>
						<animated.div
							style={{
								position: "absolute",
								top: "11px",
								left: "-4px",
								opacity: heartThreeProps.opacity,
								transform: heartThreeProps.scale.to(
									(scale) => `scale(${scale})`
								),
							}}
						>
							<GradientHeart scale={0.5} />
						</animated.div>
					</TripleHeartWrapper>
					<StyledSpan data-text={loading ? "Loading" : "Go Live"}>
						{loading ? <LoadingSpinner /> : "Go Live"}
					</StyledSpan>
				</StyledGoLiveButton>
			</ButtonWrapper>
		</>
	);
};

export default GoLiveButton;
