import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
// import SocialLinksGroup from "./SocialLinksGroup";
import { useLocation } from "react-router-dom";
import Link from "../Links/Link";
import Icon from "../Icon";
import { useViewport } from "../../hooks/ViewportProvider";

export interface FooterProps {
  isVisible: boolean;
}

const StyledFooter = styled.footer<{ isMobile: boolean; isVisible: boolean }>`
  padding: ${({ isMobile, isVisible }) =>
    isMobile && isVisible
      ? "48px 24px 176px 24px"
      : isMobile && !isVisible
      ? "48px 24px 48px 24px"
      : "64px 112px 64px 112px"};
  transition: padding 0.3s ease-in-out;
`;

const LinkSection = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  ${({ isMobile }) =>
    !isMobile &&
    `
      align-items: center;
      justify-content: center;
  `}
  margin-bottom: 40px;
`;

const StyledLine = styled.hr`
  margin: 48px 24px 0px 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

const LinkWrapper = styled.div<{ isMobile?: boolean }>`
  margin: ${({ isMobile }) => (isMobile ? "0px 0px 32px 0px" : "0px 16px")};
`;

const StyledLink = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
`;

const LegalLinkSection = styled.div`
  display: flex;
  align-items: center;
`;

const PinkText = styled.span`
  color: ${({ theme }) => theme.colors.primaryPink};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 400;
`;

const Divider = styled.span`
  color: ${({ theme }) => theme.colors.lightGray};
  margin: 0 8px;
`;

const Copyright = styled.p`
  color: ${({ theme }) => theme.colors.lightGray};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 400;
`;

// const SocialLinksContainer = styled.div<{ isMobile: boolean }>`
//   display: flex;
//   ${({ isMobile }) => !isMobile && "justify-content: center"};
//   margin-bottom: 18px;
// `;

const TermsAndTrademarkContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  ${({ isMobile }) => !isMobile && "justify-content: space-between;"};
`;

const Footer = (props: FooterProps) => {
  const { isVisible = false } = props;
  const theme = useTheme();
  const viewport = useViewport();
  const isMobile = viewport === "mobile" || viewport === "tablet";
  const location = useLocation();
  return (
    <>
      {isMobile && <StyledLine />}
      <StyledFooter isMobile={isMobile} isVisible={isVisible}>
        {isMobile && (
          <Icon style={{ margin: "0px 0px 32px 0px" }} icon="logo-solo" />
        )}
        <LinkSection isMobile={isMobile}>
          <LinkWrapper isMobile={isMobile}>
            <StyledLink to="/" text="Home" useActiveColor />
          </LinkWrapper>
          <LinkWrapper isMobile={isMobile}>
            <StyledLink to="/about" text="About Us" useActiveColor />
          </LinkWrapper>
          {!isMobile && (
            <Icon style={{ margin: "0px 16px" }} icon="logo-solo" />
          )}
          <LinkWrapper isMobile={isMobile}>
            <StyledLink to="/contact" text="Contact" useActiveColor />
          </LinkWrapper>
          <LinkWrapper isMobile={isMobile}>
            <StyledLink to="/faqs" text="FAQ" useActiveColor />
          </LinkWrapper>
        </LinkSection>
        {/* Commenting this out for now until we get actual Social Media pages */}
        {/* <SocialLinksContainer isMobile={isMobile}>
          <SocialLinksGroup />
        </SocialLinksContainer> */}
        <TermsAndTrademarkContainer isMobile={isMobile}>
          <LegalLinkSection>
            {location?.pathname !== "/terms-of-service" && (
              <Link color={theme.colors.primaryPink} to="/terms-of-service">
                <PinkText>Terms and Conditions</PinkText>
              </Link>
            )}
            {location?.pathname !== "/privacy-policy" &&
              location?.pathname !== "/terms-of-service" && (
                <Divider>|</Divider>
              )}
            {location?.pathname !== "/privacy-policy" && (
              <Link color={theme.colors.primaryPink} to="/privacy-policy">
                <PinkText>Privacy Policy</PinkText>
              </Link>
            )}
          </LegalLinkSection>
          <Copyright>
            © 2024 M & D Interactive Inc | All Rights Reserved
          </Copyright>
        </TermsAndTrademarkContainer>
      </StyledFooter>
    </>
  );
};

export default Footer;
