import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Background from "../components/Shared/Background";
import Button from "../components/Buttons/Button";
import ButtonText from "../components/Buttons/ButtonText";
import Link from "../components/Links/Link";
import InputCode from "../components/Inputs/InputCode";
import Typography from "../components/Typography";
import useViewport from "../hooks/ViewportProvider";
import { sendPhoneVerification, verifyPhoneNumber } from "../redux/actions/userActions";
import { selectError, setError } from "../redux/reducers/userReducer";
import { AppDispatch } from "../redux/store";

const VerifyPageContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ isMobile }) => isMobile ? `
    padding: 64px 24px;
  `: 
		`
    padding: 120px 0 0;
    height: calc(100vh - 120px);
    align-items: center;
  `}
`;

const LogoTitle = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isMobile }) => isMobile && "margin-bottom: 64px;"}
`;

const VerifyFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  width: 100%;
`;

const InputWrapper = styled.div<{ isError: boolean }>`
  margin: ${({ isError }) => isError ? "48px 0 16.5px" : "48px 0"};
`;

const StyledButton = styled(Button)<{ isMobile?: boolean }>`
  ${({ isMobile }) => isMobile ? "width: 100%" : "width: 496px"};
`;

const ResendCodeContainer = styled.div`
  padding: 20px 0;
  margin-top: 24px;
`;

const VerifyPage = () => {
	const error = useSelector(selectError);
	const dispatch: AppDispatch = useDispatch();
	const viewport = useViewport();
	const isMobile = viewport === "mobile";
	const location = useLocation();
	const navigate = useNavigate();
	const phoneNumber = location?.state?.phoneNumber;
	const [verificationCode, setVerificationCode] = useState<string>("");

	const handleVerifyPhoneNumber = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (verificationCode.length === 6) {
			await dispatch(verifyPhoneNumber({ phoneNumber, code: verificationCode }))
				.unwrap()
				.then((token) => {
					if (token) {
						navigate(`/auth/success?token=${token}`);
					}
				})
				.catch(error => {
					console.error("Error:", error);
					dispatch(setError("Wrong verification code"));
				});
		}
	};

	const resendCode = async () => {
		try {
			await dispatch(setError(null));
			setVerificationCode("");
			await dispatch(sendPhoneVerification(phoneNumber));
		} catch (error) {
			console.error("Failed to resend verification code", error);
		}
	};

	return (
		<VerifyPageContainer isMobile={isMobile}>
			<Background />
			<LogoTitle isMobile={isMobile}>
				<Link to="/" icon="logo" iconPosition="left" />
			</LogoTitle>
			<VerifyFormContainer onSubmit={handleVerifyPhoneNumber}>
				<Typography
					fontFamily="secondary"
					fontSize={32}
					fontWeight={600}
					variant="h2"
				>
          Enter Verification Code
				</Typography>
				<InputWrapper isError={!!error}>
					<InputCode
						error={error}
						onChange={(val) => {
							setVerificationCode(val);
							if (val.length < 6) {
								dispatch(setError(null));
							}
						}}
					/>
					{error && (
						<Typography 
							color="errorRed"
							fontFamily="secondary"
							fontWeight={400}
							margin="12px 0 0"
							textAlign="center"
						>
							{error}
						</Typography>
					)}
				</InputWrapper>
				<StyledButton isMobile={isMobile} type="submit">
          Next
				</StyledButton>
				<ResendCodeContainer>
					<ButtonText onClick={resendCode}>
						<Typography
							color="primaryPink"
							fontFamily="secondary"
							fontSize={20}
							fontWeight={700}
						>
              Resend Code
						</Typography>
					</ButtonText>
				</ResendCodeContainer>
			</VerifyFormContainer>
		</VerifyPageContainer>
	);
};

export default VerifyPage;