import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Buttons/Button";
import InputText from "../Inputs/InputText";
import SelectDropdown from "../Inputs/SelectDropdown";
import Typography from "../Typography";
import { StepProps } from "../../types/Step";
import {
	birthYearOptions,
	DAY_OPTIONS,
	MONTH_OPTIONS,
} from "../../data/options";
import { updateUserData } from "../../redux/actions/userActions";
import { selectUser, setError } from "../../redux/reducers/userReducer";
import { selectStep, setStep } from "../../redux/reducers/onboardingReducer";
import { useViewport } from "../../hooks/ViewportProvider";

import type { AppDispatch } from "../../redux/store";

interface Step1StateProps {
  first_name: string;
  last_name: string;
  email: string;
  day: number;
  month: number;
  year: number;
}

interface Step1Errors {
  first_name?: string;
  last_name?: string;
  email?: string;
  day?: string;
  month?: string;
  year?: string;
  gender?: string;
}

const InputWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  align-items: center;
  justify-content: space-between;
  margin: 28px 0;
  position: relative;
`;

const BirthdateError = styled.div`
  position: absolute;
  bottom: -24px;
`;

const SubmitButtonWrapper = styled.div<{ isMobile: boolean; viewport: any }>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "flex-end")};
  width: 100%;
`;

const Step1: React.FC<StepProps> = () => {
	const dispatch: AppDispatch = useDispatch();
	const user = useSelector(selectUser);
	const step = useSelector(selectStep);
	const { dob } = user || {};
	let defaultDay = 0;
	let defaultMonth = 0;
	let defaultYear = 0;

	if (dob) {
		const dobObject = new Date(dob);
		defaultDay = dobObject.getUTCDate();
		defaultMonth = dobObject.getUTCMonth() + 1;
		defaultYear = dobObject.getUTCFullYear();
	}

	const viewport = useViewport();
	const isMobile = viewport === "mobile" || viewport === "tablet";

	const [formData, setFormData] = useState<Step1StateProps>({
		first_name: user?.first_name || "",
		last_name: user?.last_name || "",
		email: user?.email || "",
		day: defaultDay,
		month: defaultMonth,
		year: defaultYear,
	});
	const [formErrors, setFormErrors] = useState<Step1Errors>({});

	const validateEmail = (email: string) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	const validateFormData = (formData: Step1StateProps) => {
		const { first_name, last_name, email, day, month, year } = formData;
		const errors: Step1Errors = {};

		if (!first_name) {
			errors.first_name = "First Name is required";
		}

		if (!last_name) {
			errors.last_name = "Last Name is required";
		}

		if (!email) {
			errors.email = "Email is required";
		} else if (!validateEmail(email)) {
			errors.email = "Email is not valid";
		}

		if (!day || !month || !year) {
			errors.day = "Date of Birth is required";
		} else {
			const dob = new Date(year, month - 1, day);
			if (isNaN(dob.getTime())) {
				errors.day = "Date of Birth is not valid";
			}
		}

		return errors;
	};

	const handleSubmit = async () => {
		if (!user || !user.id) return;
		const errors = validateFormData(formData);

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		const dob = new Date(formData.year, formData.month - 1, formData.day);
		const currentStep = user?.onboarding_step || 0;
		let newStep = 1;
		if (currentStep > 1) {
			newStep = currentStep;
		}

		const data = {
			first_name: formData.first_name,
			last_name: formData.last_name,
			email: formData.email,
			dob: dob.toISOString(),
			onboarding_step: newStep,
		};

		dispatch(updateUserData({ data }))
			.unwrap()
			.then(() => {
				dispatch(setStep(step + 1));
			})
			.catch(error => {
				console.error("Error updating user: ", error);
				dispatch(setError(error.message || "An error occurred while updating user data."));
			});
	};

	const mobileMargin = "0 0 24px 0";
	const dropdownMobileMargin = "0 0 12px 0";

	return (
		<>
			<Typography fontSize={20}>Personal Information</Typography>
			<InputWrapper isMobile={isMobile}>
				<InputText
					error={formErrors.first_name}
					fullWidth
					label="First Name"
					margin={isMobile ? mobileMargin : "0 24px 0 0"}
					placeholder="First Name"
					value={formData.first_name}
					onChange={(e) => {
						setFormData({ ...formData, first_name: e.target.value });
						setFormErrors({ ...formErrors, first_name: undefined });
					}}
				/>
				<InputText
					error={formErrors.last_name}
					fullWidth
					label="Last Name"
					placeholder="Last Name"
					value={formData.last_name}
					onChange={(e) => {
						setFormData({ ...formData, last_name: e.target.value });
						setFormErrors({ ...formErrors, last_name: undefined });
					}}
				/>
			</InputWrapper>
			<InputText
				disabled={!!user?.email}
				error={formErrors.email}
				fullWidth
				label="Email"
				margin={isMobile ? "0 0 48px" : "0 0 24px"}
				placeholder="Email"
				value={formData.email}
				onChange={(e) => setFormData({ ...formData, email: e.target.value })}
			/>
			<Typography fontSize={20}>Date of Birth</Typography>
			<InputWrapper isMobile={isMobile}>
				<SelectDropdown
					label="Day"
					margin={isMobile ? dropdownMobileMargin : "0 16px 0 0"}
					onChange={(value) => {
						setFormData({ ...formData, day: value as number });
					}}
					options={DAY_OPTIONS}
					placeholder="Day"
					value={formData.day}
				/>
				<SelectDropdown
					label="Month"
					margin={isMobile ? dropdownMobileMargin : "0 16px 0 0"}
					onChange={(value) => {
						setFormData({ ...formData, month: value as number });
					}}
					options={MONTH_OPTIONS}
					placeholder="Month"
					value={formData.month}
				/>
				<SelectDropdown
					label="Year"
					onChange={(value) => {
						setFormData({ ...formData, year: value as number });
					}}
					options={birthYearOptions()}
					margin={isMobile ? "0 0 48px" : "0"}
					placeholder="Year"
					value={formData.year}
				/>
				{formErrors.day && (
					<BirthdateError>
						<Typography
							color="errorRed"
							fontFamily="secondary"
							fontSize={14}
							fontWeight={400}
						>
							{formErrors.day}
						</Typography>
					</BirthdateError>
				)}
			</InputWrapper>
			<SubmitButtonWrapper isMobile={isMobile} viewport={viewport}>
				<Button
					onClick={handleSubmit}
					fullWidth={viewport === "mobile"}
					width={viewport === "tablet" ? "276px" : "auto"}
				>
          Continue
				</Button>
			</SubmitButtonWrapper>
		</>
	);
};

export default Step1;
