import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Buttons/Button";
import ButtonText from "../../Buttons/ButtonText";
import Checkbox from "../../Inputs/Checkbox";
import Pill from "../../Inputs/Pill";
import Typography from "../../Typography";
import ToggleSwitch from "../../Inputs/Toggle";
import { PreferenceProps } from "../../../types/Step";
import { RELIGIONS } from "../../../data/religions";
import useViewport from "../../../hooks/ViewportProvider";
import { selectUser } from "../../../redux/reducers/userReducer";
import { updateUserPreferenceData } from "../../../redux/actions/userActions";

import type { AppDispatch } from "../../../redux/store";

const PreferenceWrapper = styled.div``;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BeliefsWrapper = styled.div`
  position: relative;
  margin-top: 20px;
`;

const PillGroup = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 676px;
  width: 100%;
  box-sizing: border-box;
  margin: -4px;

  ${({ disabled }) => disabled && "opacity: 0.5; pointer-events: none;"}
`;

const Dealbreaker = styled.div`
  margin-top: 32px;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  width: 100%;
`;

const MobileSubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  gap: 32px;
`;

const SubmitButton = styled(Button)<{ isMobile?: boolean }>`
  ${({ isMobile }) => isMobile ? "width: 100%;" : "margin-left: 24px;"};
`;

const Preference3: React.FC<PreferenceProps> = ({ next, back }) => {
	const user = useSelector(selectUser);
	const dispatch: AppDispatch = useDispatch();
	const viewport = useViewport();
	const isMobile = viewport === "mobile";
	const defaultReligiousBeliefs = user?.preferences?.religiousBeliefs?.beliefs;
	const defaultDealbreaker = user?.preferences?.religiousBeliefs?.dealbreaker;
	const [beliefs, setBeliefs] = useState(defaultReligiousBeliefs || []);
	const [prevBeliefs, setPrevBeliefs] = useState<string[]>([]);
	const [openToAny, setOpenToAny] = useState(false);
	const [dealbreaker, setDealbreaker] = useState(defaultDealbreaker || false);

	const onPillClick = (value: string, selected: boolean) => {
		if (selected) {
			setBeliefs((prevBeliefs) => [...prevBeliefs, value]);
		} else {
			setBeliefs((prevBeliefs) => prevBeliefs.filter((belief) => belief !== value));
		}
	};

	const handleSubmit = async () => {
		if (!user || !user.id) return;
		const data = {
			...user?.preferences,
			religiousBeliefs: {
				beliefs: beliefs,
				dealbreaker: dealbreaker
			} 
		};

		dispatch(updateUserPreferenceData({ data }))
			.unwrap()
			.then(() => {
				next();
			})
			.catch((error) => {
				console.error("Error updating user preferences: ", error);
			});
	};

	return(
		<PreferenceWrapper>
			<Typography
				color="white"
				fontSize={20}
				fontWeight={700}
				margin="0 0 4px"
				variant="h2"
			>
      Religious Beliefs
			</Typography>
			<Typography
				color="secondaryLightGray"
				fontFamily="body"
				fontSize={16}
				fontWeight={400}
				margin="0 0 32px"
			>
      Select all that apply
			</Typography>
			<ToggleContainer>
				<ToggleSwitch
					checked={openToAny}
					onChange={() => {
						setOpenToAny(prev => {
							setBeliefs(prevRelBeliefs => {
								if (!prev) {
									setPrevBeliefs(prevRelBeliefs);
									return [];
								} else {
									return prevBeliefs;
								}
							});
							return !prev;
						});
					}}
				/>
				<Typography
					color="secondaryLightGray"
					fontFamily="body"
					fontSize={16}
					fontWeight={400}
					margin="0 16px"
				>
        I’m open to any religious beliefs
				</Typography>
			</ToggleContainer>
			<BeliefsWrapper>
				<PillGroup disabled={openToAny}>
					{RELIGIONS.map((belief: string) => (
						<Pill
							key={belief}
							label={belief}
							value={belief}
							onClick={onPillClick}
							selected={openToAny || beliefs.includes(belief)}
						/>
					))}
				</PillGroup>
			</BeliefsWrapper>
			<Dealbreaker>
				<Checkbox
					checked={dealbreaker} 
					label="This is a deal breaker" 
					onChange={() => setDealbreaker(prev => !prev)}
					value="dealbreaker"
				/>
			</Dealbreaker>
			{isMobile ? (
				<MobileSubmitButtonWrapper>
					<SubmitButton isMobile={isMobile} onClick={handleSubmit}>Continue (3/4)</SubmitButton>
					<ButtonText onClick={back}>
						<Typography color="primaryPink" fontFamily="body" fontWeight={500}>
              Go Back
						</Typography>
					</ButtonText>
				</MobileSubmitButtonWrapper>
			) : (
				<SubmitButtonWrapper>
					<ButtonText onClick={back}>
						<Typography color="primaryPink" fontFamily="body" fontWeight={500}>
              Go Back
						</Typography>
					</ButtonText>
					<SubmitButton onClick={handleSubmit}>Continue (3/4)</SubmitButton>
				</SubmitButtonWrapper>
			)}
		</PreferenceWrapper>
	);
};

export default Preference3;