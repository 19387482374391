import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { hideSnackbar } from "../../redux/reducers/snackbarReducer";
import useViewport from "../../hooks/ViewportProvider"; // Adjust path as necessary
import theme from "../../theme";

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const SnackbarContainer = styled.div<{ isMobile: boolean, index: number }>`
  position: fixed;
  bottom: ${({ isMobile, index }) => isMobile ? `${index * 50}px` : `${24 + index * 50}px`};
  left: ${({ isMobile }) => isMobile ? "0" : "24px"};
  width: ${({ isMobile }) => (isMobile ? "100%" : "auto")};
  min-width: 288px;
  max-width: 568px;
  background-color: ${theme.colors.containerBase};
  color: white;
  padding: 14px;
  font-family: ${theme.fonts.body};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  border-radius: 2px;
  animation: ${slideIn} 0.5s ease;
  z-index: 1000;
  margin-top: 10px;
`;

const Snackbar = () => {
	const dispatch = useDispatch();
	const snackbars = useSelector((state: RootState) => state.snackbar.snackbars);
	const viewport = useViewport();
	const isMobile = viewport === "mobile" || viewport === "tablet";

	useEffect(() => {
		if (snackbars?.length > 0) {
			snackbars.forEach(snackbar => {
				const timer = setTimeout(() => {
					dispatch(hideSnackbar(snackbar.id));
				}, snackbar.duration);
				return () => clearTimeout(timer);
			});
		}

	}, [snackbars, dispatch]);

	return (
		<>
			{snackbars && snackbars.map((snackbar, index) => (
				<SnackbarContainer
					key={snackbar.id}
					isMobile={isMobile}
					index={index}
				>
					{snackbar.message}
				</SnackbarContainer>
			))}
		</>
	);
};

export default Snackbar;
