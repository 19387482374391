import React, { useEffect } from "react";
import { Link, useMatch } from "react-router-dom";
import styled from "@emotion/styled";
import DiscoveryToggle from "../Shared/DiscoveryToggle";
import MobileProfileButton from "../Buttons/MobileProfileButton";
import Typography from "../Typography";
import theme from "../../theme";

interface DashboardNavMenuProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavMenu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: ${({ isOpen }) => (isOpen ? 0 : "-100%")};
  top: 4rem;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 48px);
  background: ${({ theme }) => theme.colors.primaryMidnight};
  border-radius: 10px;
  transition: 0.3s;
  z-index: 999;
`;

const NavItem = styled.div`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 500;
  padding: 20px 24px;
  :hover {
    background: ${({ theme }) => theme.colors.borderGray};
  }
`;

const ContactUsLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 60px;
`;

const DashboardNavMenu: React.FC<DashboardNavMenuProps> = ({ isOpen, setIsOpen }) => {
	const matchRoot = !!useMatch("/");
	const matchChatHistory = !!useMatch("/chat-history");
	const matchContactCard = !!useMatch("/contact-card");
	const matchSettings = !!useMatch("/settings");

	useEffect(() => {
		if (isOpen) {
			document.body.classList.add("no-scroll");
		} else {
			document.body.classList.remove("no-scroll");
		}

		return () => {
			document.body.classList.remove("no-scroll");
		};
	}, [isOpen]);

	const getLinkStyle = (match: boolean) => ({
		color: match ? theme.colors.primaryPink : theme.colors.white,
		textDecoration: "none"
	});

	return (
		<NavMenu isOpen={isOpen}>
			<DiscoveryToggle isMobile />
			<Link
				onClick={() => setIsOpen(false)}
				style={getLinkStyle(matchRoot)}
				to="/"
			>
				<NavItem>
          Go Live
				</NavItem>
			</Link>
			<Link
				onClick={() => setIsOpen(false)}
				style={getLinkStyle(matchChatHistory)}
				to="/chat-history"
			>
				<NavItem>
          Chat History
				</NavItem>
			</Link>
			<Link 
				onClick={() => setIsOpen(false)}
				style={getLinkStyle(matchContactCard)}
				to="/contact-card"
			>
				<NavItem>
          Contact Card
				</NavItem>
			</Link>
			<Link 
				onClick={() => setIsOpen(false)}
				style={getLinkStyle(matchSettings)}
				to="/settings"
			>
				<NavItem>
          Settings
				</NavItem>
			</Link>
			<MobileProfileButton />
			<ContactUsLink>
				<Typography
					fontFamily="body"
					fontSize={16}
					fontWeight={400}
					isInline
					margin="0 8px 0 0"
				>
          Need support?
				</Typography>
				<Link
					onClick={() => setIsOpen(false)}
					style={{ color: theme.colors.primaryPink, textDecoration: "none" }}
					to="/contact"
				>
          Contact us
				</Link>
			</ContactUsLink>
		</NavMenu>
	);
};

export default DashboardNavMenu;