import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

interface SnackbarMessage {
  id: string;
  message: string;
  duration: number;
}

interface SnackbarState {
  snackbars: SnackbarMessage[];
}

const initialState: SnackbarState = {
	snackbars: [],
};

export const snackbarSlice = createSlice({
	name: "snackbar",
	initialState,
	reducers: {
		showSnackbar: (state, action: PayloadAction<{ message: string; duration?: number }>) => {
			state.snackbars.push({
				id: uuidv4(),
				message: action.payload.message,
				duration: action.payload.duration || 3000
			});
		},
		hideSnackbar: (state, action: PayloadAction<string>) => {
			state.snackbars = state.snackbars.filter(snack => snack.id !== action.payload);
		},
	},
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
