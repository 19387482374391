import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Buttons/Button";
import AvatarUpload from "../Inputs/AvatarUpload";
import InputText from "../Inputs/InputText";
import Pill from "../Inputs/Pill";
import SelectDropdown from "../Inputs/SelectDropdown";
import TextArea from "../Inputs/TextArea";
import Typography from "../Typography";
import useViewport from "../../hooks/ViewportProvider";
import { ZODIAC_SIGNS } from "../../data/options";
import { fetchInterests } from "../../redux/actions/interestsActions";
import { selectInterests } from "../../redux/reducers/interestsReducer";
import { selectUser } from "../../redux/reducers/userReducer";
import { updateUserData } from "../../redux/actions/userActions";
import { showSnackbar } from "../../redux/reducers/snackbarReducer";
import type { AppDispatch } from "../../redux/store";

interface ProfileStateProps {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  headline: string;
  job_title: string;
	zodiac_sign: string;
	social_profile: {
		facebook: string;
		instagram: string;
		linkedin: string;
		snapchat: string;
		tiktok: string;
		x_account: string;
	}
}

interface ProfileErrors {
  first_name?: string;
  last_name?: string ;
  email?: string;
}

const ProfileTabContainer = styled.div``;

const Section = styled.div`
  padding: 40px 32px;
  max-width: 1027px;
  box-sizing: border-box;
`;

const InputContainer = styled.div<{ isMobile: boolean }>`
  display: grid;
  row-gap: 20px;
  column-gap: 24px;
  margin: 20px 0;
  grid-template-columns: calc(50% - 13px) calc(50% - 13px);

  ${({ isMobile }) => isMobile && "grid-template-columns: 100%;"}
`;

const PillGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: -4px;
`;

const InterestError = styled.div`
  position: absolute;
`;

const ButtonWrapper= styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
`;

const ProfileTab = () => {
	const user = useSelector(selectUser);
	const categories = useSelector(selectInterests);
	const dispatch: AppDispatch = useDispatch();
	const viewport = useViewport();
	const isMobile = viewport === "mobile";
	const [formData, setFormData] = useState({
		first_name: user?.first_name || "",
		last_name: user?.last_name || "",
		email: user?.email || "",
		phone_number: user?.phone_number || "",
		headline: user?.headline || "",
		job_title: user?.job_title || "",
		zodiac_sign: user?.zodiac_sign || "",
		social_profile: {
			facebook: user?.social_profile?.facebook || "",
			instagram: user?.social_profile?.instagram || "",
			linkedin: user?.social_profile?.linkedin || "",
			snapchat: user?.social_profile?.snapchat || "",
			tiktok: user?.social_profile?.tiktok || "",
			x_account: user?.social_profile?.x_account || "",
		}
	});
	const [formErrors, setFormErrors] = useState<ProfileErrors>({});
	const [selectedInterests, setSelectedInterests] = useState<number[]>(
		user?.interests?.map(interest => interest.id) || []
	);
	const [interestsError, setInterestsError] = useState("");

	useEffect(() => {
		dispatch(fetchInterests());
	}, []);

	// Profile data functions

	const validateEmail = (email: string) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	const validateFormData = (formData: ProfileStateProps) => {
		const { first_name, last_name, email } = formData;
		const errors: ProfileErrors = {};

		if (!first_name) {
			errors.first_name = "First Name is required";
		}

		if (!last_name) {
			errors.last_name = "Last Name is required";
		}

		if (!email) {
			errors.email = "Email is required";
		} else if (!validateEmail(email)) {
			errors.email = "Email is not valid";
		}

		return errors;
	};

	const handleProfileInfoSubmit = async () => {
		if (!user || !user.id) return;
		const errors = validateFormData(formData);

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		dispatch(updateUserData({ data: formData }))
			.unwrap()
			.then(() => {
				dispatch(showSnackbar({ message: "Profile info saved successfully!", duration: 3000 }));
			})
			.catch(error => {
				console.error("Error updating user: ", error);
			});
	};

	// Interests Form Functions

	const onPillClick = (interestId: number, selected: boolean) => {
		setSelectedInterests(prev => selected ? [...prev, interestId] : prev.filter(id => id !== interestId));
	};

	const handleInterestsSubmit = async () => {
		if (!user || !user.id) return;
		if (selectedInterests.length < 5) {
			setInterestsError("Please select at least 5 interests");
			return;
		} else if (selectedInterests.length > 20) {
			setInterestsError("Please select no more than 20 interests");
			return;
		}

		const data = {
			interest_ids: selectedInterests,
		};

		dispatch(updateUserData({ data }))
			.unwrap()
			.then(() => {
				dispatch(showSnackbar({ message: "Interests saved successfully!", duration: 3000 }));
			})
			.catch(error => {
				console.error("Error updating user interests: ", error);
			});
	};
  
	return (
		<ProfileTabContainer>
			<Section>
				<Typography
					fontSize={24}
					fontWeight={700}
					lineHeight="110%"
					margin="0 0 40px"
				>
          Edit Profile
				</Typography>
				{user && (
					<AvatarUpload user={user} />
				)}
				<InputContainer isMobile={isMobile}>
					<InputText
						error={formErrors.first_name}
						fullWidth
						label="First Name"
						margin="0 24px 0 0"
						placeholder="First Name"
						value={formData.first_name}
						onChange={(e) => {
							setFormData({ ...formData, first_name: e.target.value });
							setFormErrors({ ...formErrors, first_name: undefined });
						}}
					/>
					<InputText
						error={formErrors.last_name}
						fullWidth
						label="Last Name"
						placeholder="Last Name"
						value={formData.last_name}
						onChange={(e) => {
							setFormData({ ...formData, last_name: e.target.value });
							setFormErrors({ ...formErrors, last_name: undefined });
						}}
					/>
					<InputText
						// error={formErrors.phoneNumber}
						fullWidth
						label=""
						placeholder="Phone"
						value={formData.phone_number}
						onChange={(e) => {
							setFormData({ ...formData, phone_number: e.target.value });
							// setFormErrors({ ...formErrors, phoneNumber: undefined });
						}}
					/>
					<InputText
						disabled={!!user?.email}
						error={formErrors.email}
						fullWidth
						label="Email"
						placeholder="Email"
						value={formData.email}
						onChange={(e) => {
							setFormData({ ...formData, email: e.target.value });
							setFormErrors({ ...formErrors, email: undefined });
						}}
					/>
					<InputText
						fullWidth
						label="Job Title"
						placeholder="Job Title"
						value={formData.job_title}
						onChange={(e) => setFormData({ ...formData, job_title: e.target.value })}
					/>
					<SelectDropdown
						label="Zodiac Sign"
						onChange={(value) => setFormData({ ...formData, zodiac_sign: value as string})}
						options={ZODIAC_SIGNS}
						placeholder="Zodiac Sign"
						value={formData.zodiac_sign}
					/>

				</InputContainer>
				<Typography
					fontFamily="body"
					fontSize={18}
					lineHeight="24px"
				>
          Social
				</Typography>
				<InputContainer isMobile={isMobile}>
					<InputText
						fullWidth
						label="Facebook"
						placeholder="Facebook"
						value={formData.social_profile.facebook}
						onChange={(e) => setFormData({ 
							...formData,
							social_profile: {
								...formData.social_profile,
								facebook: e.target.value
							}
						})}
					/>
					<InputText
						fullWidth
						label="X"
						placeholder="X"
						value={formData.social_profile.x_account}
						onChange={(e) => setFormData({
							...formData,
							social_profile: {
								...formData.social_profile,
								x_account: e.target.value 
							}
						})}
					/>
					<InputText
						fullWidth
						label="Instagram"
						placeholder="Instagram"
						value={formData.social_profile.instagram}
						onChange={(e) => setFormData({
							...formData,
							social_profile: {
								...formData.social_profile,
								instagram: e.target.value 
							}
						})}
					/>
					<InputText
						fullWidth
						label="Tik Tok"
						placeholder="Tik Tok"
						value={formData.social_profile.tiktok}
						onChange={(e) => setFormData({
							...formData,
							social_profile: {
								...formData.social_profile,
								tiktok: e.target.value
							}
						})}
					/>
					<InputText
						fullWidth
						label="Snapchat"
						placeholder="Snapchat"
						value={formData.social_profile.snapchat}
						onChange={(e) => setFormData({
							...formData,
							social_profile: {
								...formData.social_profile,
								snapchat: e.target.value
							}
						})}
					/>
					<InputText
						fullWidth
						label="LinkedIn"
						placeholder="LinkedIn"
						value={formData.social_profile.linkedin}
						onChange={(e) => setFormData({
							...formData,
							social_profile: {
								...formData.social_profile,
								linkedin: e.target.value
							}
						})}
					/>
				</InputContainer>
				<Typography
					fontFamily="body"
					fontSize={18}
					lineHeight="24px"
				>
          Headline
				</Typography>
				<TextArea
					fullWidth
					margin="20px 0 40px"
					maxLength={200}
					onChange={(e) => setFormData({ ...formData, headline: e.target.value })}
					rows={4}
					value={formData.headline}
				/>
				<Button onClick={handleProfileInfoSubmit}>
          Save Changes
				</Button>  
			</Section>
			<Section>
				{categories.map((category) => (
					<div key={category.id}>
						<Typography
							fontFamily="body"
							fontSize={16}
							margin="32px 0 22px"
							variant="h3"
						>
							{category.name}
						</Typography>
						<PillGroup>
							{category.interests.map((interest) => (
								<Pill
									key={interest.id}
									label={interest.name}
									value={interest.id.toString()}
									selected={selectedInterests.includes(interest.id)}
									onClick={() => onPillClick(interest.id, !selectedInterests.includes(interest.id))}
									disabled={selectedInterests.length >= 20 && !selectedInterests.includes(interest.id)}
								/>
							))}
						</PillGroup>
					</div>
				))}
				<InterestError>
					{interestsError && (
						<Typography
							color="errorRed"
							fontFamily="secondary"
							fontSize={14}
							fontWeight={400}
							margin="12px 0 0"
						>
							{interestsError}
						</Typography>
					)}
				</InterestError>
				<ButtonWrapper>
					<Button onClick={handleInterestsSubmit}>
              Save Interests {selectedInterests.length}/20
					</Button>
				</ButtonWrapper>
			</Section>
		</ProfileTabContainer>
	);
};

export default ProfileTab;