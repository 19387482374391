import React from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 auto;
  animation: ${rotate} 2s linear infinite;
`;

const LoadingSpinner = () => {
	return (
		<Wrapper>
			<svg 
				id="Layer_1" 
				xmlns="http://www.w3.org/2000/svg"  
				x="0px" 
				y="0px"
				viewBox="0 0 32 32"
			>
				<g id="symbols">
					<g transform="translate(-135.000000, -10.000000)">
						<g id="loading-icon" transform="translate(135.000000, 10.000000)">
							<path 
								id="Path" 
								className="st0" 
								d="M22.3,2.8c0.4-0.6,1.2-0.9,1.8-0.5l-0.1,0c0.6,0.4,0.9,1.2,0.5,1.9L22,8.7c-0.4,0.6-1.2,0.9-1.8,0.5l0.1,0c-0.6-0.4-0.9-1.2-0.5-1.9L22.3,2.8z"
								fillRule="evenodd"
								clipRule="evenodd"
								fill="#FFFFFF"
								fillOpacity={0.75}
							/>
							<path 
								className="st1" 
								d="M14.9,1.3c0-0.7,0.6-1.3,1.3-1.3l0,0c0.7,0,1.3,0.6,1.3,1.3v5.4c0,0.7-0.6,1.3-1.3,1.3l0,0c-0.7,0-1.3-0.6-1.3-1.3V1.3z"
								fillRule="evenodd"
								clipRule="evenodd"
								fill="#FFFFFF"
								fillOpacity={0.7}
							/>
							<path 
								className="st2" 
								d="M28.1,7.6c0.6-0.4,1.5-0.1,1.8,0.5l0-0.1c0.4,0.7,0.1,1.5-0.5,1.9l-4.5,2.6c-0.6,0.4-1.5,0.1-1.8-0.5l0,0.1c-0.4-0.7-0.1-1.5,0.5-1.9L28.1,7.6z"
								fillRule="evenodd"
								clipRule="evenodd"
								fill="#FFFFFF"
								fillOpacity={0.2}
							/>
							<path 
								className="st3" 
								d="M30.6,14.7c0.7,0,1.3,0.6,1.3,1.3l0,0c0,0.7-0.6,1.3-1.3,1.3h-5.2c-0.7,0-1.3-0.6-1.3-1.3l0,0c0-0.7,0.6-1.3,1.3-1.3H30.6z"
								fillRule="evenodd"
								clipRule="evenodd"
								fill="#FFFFFF"
								fillOpacity={0.25}
							/>
							<path 
								className="st4" 
								d="M29.4,22.2c0.6,0.4,0.9,1.2,0.5,1.9l0-0.1c-0.4,0.7-1.2,0.9-1.8,0.5l-4.5-2.6c-0.6-0.4-0.9-1.2-0.5-1.9l0,0.1c0.4-0.7,1.2-0.9,1.8-0.5L29.4,22.2z"
								fillRule="evenodd"
								clipRule="evenodd"
								fill="#FFFFFF"
								fillOpacity={0.3}
							/>
							<path 
								className="st5" 
								d="M24.6,28.1c0.4,0.6,0.1,1.5-0.5,1.9l0.1,0c-0.6,0.4-1.5,0.2-1.8-0.5l-2.6-4.6c-0.4-0.6-0.1-1.5,0.5-1.9l-0.1,0c0.6-0.4,1.5-0.2,1.8,0.5L24.6,28.1z"
								fillRule="evenodd"
								clipRule="evenodd"
								fill="#FFFFFF"
								fillOpacity={0.35}
							/>
							<path 
								className="st6" 
								d="M16.2,24L16.2,24c0.7,0,1.3,0.6,1.3,1.3v5.4c0,0.7-0.6,1.3-1.3,1.3h0c-0.7,0-1.3-0.6-1.3-1.3v-5.4C14.9,24.6,15.5,24,16.2,24z"
								fillRule="evenodd"
								clipRule="evenodd"
								fill="#FFFFFF"
								fillOpacity={0.4}
							/>
							<path 
								className="st7" 
								d="M10.1,29.5c-0.4,0.6-1.2,0.9-1.8,0.5l0.1,0c-0.6-0.4-0.9-1.2-0.5-1.9l2.6-4.6c0.4-0.6,1.2-0.9,1.8-0.5l-0.1,0c0.6,0.4,0.9,1.2,0.5,1.9L10.1,29.5z"
								fillRule="evenodd"
								clipRule="evenodd"
								fill="#FFFFFF"
								fillOpacity={0.45}
							/>
							<path 
								className="st8" 
								d="M4.4,24.5C3.8,24.9,3,24.7,2.6,24l0,0.1c-0.4-0.7-0.1-1.5,0.5-1.9l4.5-2.6c0.6-0.4,1.5-0.1,1.8,0.5l0-0.1c0.4,0.7,0.1,1.5-0.5,1.9L4.4,24.5z"
								fillRule="evenodd"
								clipRule="evenodd"
								fill="#FFFFFF"
								fillOpacity={0.5}
							/>
							<path 
								className="st9" 
								d="M1.7,17.4c-0.7,0-1.3-0.6-1.3-1.3l0,0c0-0.7,0.6-1.3,1.3-1.3h5.2c0.7,0,1.3,0.6,1.3,1.3l0,0c0,0.7-0.6,1.3-1.3,1.3H1.7z"
								fillRule="evenodd"
								clipRule="evenodd"
								fill="#FFFFFF"
								fillOpacity={0.55}
							/>
							<path 
								className="st10" 
								d="M3.1,9.9C2.5,9.5,2.3,8.7,2.6,8l0,0.1C3,7.4,3.8,7.2,4.4,7.6l4.5,2.6c0.6,0.4,0.9,1.2,0.5,1.9l0-0.1c-0.4,0.7-1.2,0.9-1.8,0.5L3.1,9.9z"
								fillRule="evenodd"
								clipRule="evenodd"
								fill="#FFFFFF"
								fillOpacity={0.6}
							/>
							<path 
								className="st11" 
								d="M7.9,4.1C7.5,3.5,7.7,2.6,8.3,2.3l-0.1,0c0.6-0.4,1.5-0.2,1.8,0.5l2.6,4.6c0.4,0.6,0.1,1.5-0.5,1.9l0.1,0c-0.6,0.4-1.5,0.2-1.8-0.5L7.9,4.1z"
								fillRule="evenodd"
								clipRule="evenodd"
								fill="#FFFFFF"
								fillOpacity={0.65}
							/>
						</g>
					</g>
				</g>
			</svg>
		</Wrapper>
	);
};

export default LoadingSpinner;

