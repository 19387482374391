import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import Typography from "../Typography";

interface SinglePointProps {
  disabled?: boolean;
  min: number;
  max: number;
  value: number;
  onChange: (newValue: number) => void;
  showValues?: boolean;
  step?: number;
}

interface DualPointProps {
  disabled?: boolean;
  min: number;
  max: number;
  minRange?: number;
  value: [number, number];
  onChange: (newValue: [number, number]) => void;
  showValues?: boolean;
  step?: number;
}

type SliderProps = SinglePointProps | DualPointProps;

const SliderOuterContainer = styled.div`
  margin: 11px 0 16px;
`;

const SliderContainer = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.neutralGray};
  position: relative;
  ${({ disabled }) => disabled && "opacity: none;"}
`;

const SliderHandle = styled.div<{ disabled?: boolean }>`
  width: 28px;
  height: 28px;
  background: ${({ theme }) => theme.colors.primaryPink};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 999;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const SelectedRange = styled.div<{ disabled?: boolean }>`
  position: absolute;
  height: 6px;
  background: ${({ theme }) => theme.colors.primaryPink};
  border-radius: 5px;
  top: 0;
  user-select: none;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const SliderLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin-top: 12px;
  user-select: none;
`;

const Slider: React.FC<SliderProps> = (props) => {
	const { disabled, min, max, onChange, showValues = true, value, step = 1, ...rest } = props;
	const isDualPoint = Array.isArray(value);
	const [activeHandle, setActiveHandle] = useState<number | null>(null);
	const [localValue, setLocalValue] = useState(value);
	const sliderRef = useRef<HTMLDivElement | null>(null);

	const handleDrag = (clientX: number) => {
		const { left, width } = sliderRef.current?.getBoundingClientRect() || {};
		const percent = (clientX - (left || 0)) / (width || 1);
		let newValue = Math.round((percent * (max - min) + min) / step) * step; // Adjust step size as needed
  
		// Ensure newValue is within min and max
		newValue = Math.max(min, Math.min(max, newValue));
  
		if (isDualPoint && activeHandle != null) {
			const values = localValue as [number, number];
			const otherValue = values[1 - activeHandle];
			// Ensure newValue is within range of the other handle
			if ("minRange" in props) {
				const minRange = props.minRange as number;
				newValue = activeHandle === 0 
					? Math.min(otherValue - minRange, newValue) 
					: Math.max(otherValue + minRange, newValue);
			}
			values[activeHandle] = newValue;
			setLocalValue([...values]);
  
			// Call the onChange function with the new values
			(onChange as (newValue: [number, number]) => void)([...values]);
		} else {
			setLocalValue(newValue as number);
  
			// Call the onChange function with the new value
			(onChange as (newValue: number) => void)(newValue as number);
		}
	};

	useEffect(() => {
		setLocalValue(value);
	}, [value]);

	useEffect(() => {
		const handleMouseMove = (e: MouseEvent) => {
			if (activeHandle !== null) {
				handleDrag(e.clientX);
			}
		};

		const handleTouchMove = (e: TouchEvent) => {
			if (activeHandle !== null && e.touches.length > 0) {
				handleDrag(e.touches[0].clientX);
			}
		};

		if (activeHandle !== null) {
			window.addEventListener("mousemove", handleMouseMove);
			window.addEventListener("touchmove", handleTouchMove);
		}

		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("touchmove", handleTouchMove);
		};
	}, [activeHandle, localValue]);
  
	useEffect(() => {
		const handleMouseUp = () => {
			setActiveHandle(null);
		};

		const handleTouchEnd = () => {
			setActiveHandle(null);
		};

		window.addEventListener("mouseup", handleMouseUp);
		window.addEventListener("touchend", handleTouchEnd);

		return () => {
			window.removeEventListener("mouseup", handleMouseUp);
			window.removeEventListener("touchend", handleTouchEnd);
		};
	}, []);

	const handleWidthPercent = 4.1; // (28 * 676) * 100 this is set for 676px viewport

	const percentages = isDualPoint
		? (localValue as [number, number]).map(v => ((v - min) / (max - min)) * 100)
		: [((localValue as number - min) / (max - min)) * 100];

	return (
		<SliderOuterContainer {...rest}>
			<SliderContainer ref={sliderRef}>
				{percentages.map((percentage, index) => (
					<SliderHandle
						disabled={disabled}
						key={index}
						style={{ 
							left: `${Math.max(Math.min(percentage, 100 - handleWidthPercent / 2), handleWidthPercent / 2)}%`
						}}
						onMouseDown={() => {
							if(!disabled) {
								setActiveHandle(index);
							}
						}}
						onTouchStart={() => {
							if (!disabled) {
								setActiveHandle(index);
							}
						}}
					/>
				))}
				<SelectedRange
					disabled={disabled}
					style={{
						left: `${isDualPoint ? percentages[0] : "0"}%`,
						width: `${isDualPoint ? percentages[1] - percentages[0] : percentages[0]}%`
					}}
				/>
			</SliderContainer>
			{showValues && (
				<SliderLabel>
					<Typography
						color="secondaryLightGray"
						fontFamily="body"
						fontSize={16}
						fontWeight={300}
					>{min}</Typography>
					<Typography
						color="secondaryLightGray"
						fontFamily="body"
						fontSize={16}
						fontWeight={300}
					>{max}</Typography>
				</SliderLabel>
			)}
		</SliderOuterContainer>
	);
};

export default Slider;
