import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../utils/axiosInstance";
import Button from "../components/Buttons/Button";
import ButtonText from "../components/Buttons/ButtonText";
import Checkbox, { CheckboxProps } from "../components/Inputs/Checkbox";
import ContactCard from "../components/Cards/ContactCard";
import Icon from "../components/Icon";
import Link from "../components/Links/Link";
import Pill from "../components/Inputs/Pill";
import RadioButton from "../components/Inputs/RadioButton";
import Typography from "../components/Typography";
import useViewport from "../hooks/ViewportProvider";
import { calculateAge, formatBirthday } from "../utils/formatDob";
import { formatPhoneNumber } from "../utils/formatPhoneNumber";
import { ContactCard as ContactCardType } from "../types/ContactCard";
import { selectUser } from "../redux/reducers/userReducer";
import { fetchContactCard } from "../redux/actions/contactCardActions";
import { setContactCard, selectContactCard } from "../redux/reducers/contactCardReducer";
import { showSnackbar } from "../redux/reducers/snackbarReducer";
import { Interest } from "../types/Interest";
import type { AppDispatch } from "../redux/store";

const InfoTypes = [
	{ label: "Profile Picture", value: "avatar" },
	{ label: "Last Name", value: "last_name" },
	{ label: "Phone Number", value: "phoneNumber" },
	{ label: "Email", value: "email" },
	{ label: "Birthday", value: "birthday" },
	{ label: "Age", value: "age" },
	{ label: "Zodiac Sign", value: "zodiac" },
	{ label: "Job Title", value: "job_title" },
	{ label: "Location", value: "location" },
	{ label: "Facebook", value: "facebook" },
	{ label: "Instagram", value: "instagram" },
	{ label: "Twitter", value: "twitter" },
	{ label: "Snapchat", value: "snapchat" },
	{ label: "TikTok", value: "tiktok" },
	{ label: "Website", value: "website" },
];

const SelectCardStyles = [
	{ label: "One", value: 1 },
	{ label: "Two", value: 2 },
	{ label: "Three", value: 3 },
];

const SelectColorScheme = [
	{ label: "Pink", value: "pink" },
	{ label: "Cyan", value: "cyan" },
	{ label: "Black & White", value: "blackAndWhite" },
];

interface InfoCheckboxProps extends CheckboxProps {
  key: string;
}

type ContactCardKeys = keyof ContactCardType;

const ContactCardPageContainer = styled.div`
  display: flex;
  height: calc(100vh - 70px);
`;

const MobileContactCardPage = styled.div`
  padding: 48px 24px;
`;

const MobileContactCardWrapper = styled.div`
  margin: 24px 0;
  width: 100%;
`;

const ContactCardPanel = styled.div`
  padding: 40px 32px;
  width: 444px;
  height: calc(100vh - 150px);
  border-right: 1px solid ${({ theme }) => theme.colors.borderGray};
  overflow-y: scroll;
  overflow-x: hidden;
`;

const PanelHeader = styled.h2`
  width: 294px;
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 20px;
`;

const ContactCardContent = styled.div`
  width: 100%;
  padding: 32px;
	overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
`;

const CheckboxWrapper = styled.div`
  margin-top: 20px;
`;

const InputSection = styled.div<{ marginTop?: number }>`
  margin-top: ${({ marginTop }) => marginTop ? `${marginTop}px` : "48px"};
`;

const PillGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 676px;
  width: 100%;
  margin-top: 20px;
  margin-left: -4px;
`;

const RadioButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;

const RadioButtonWrapper = styled.div<{ checked: boolean }>`
  background: ${({ theme }) => theme.colors.containerBase};
  border-radius: 6px;
  border: ${({ checked, theme }) =>
		checked
			? `solid 2px ${theme.colors.accentViolet}`
			: `solid 1px ${theme.colors.lightGray}`};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px;
  margin: 0 16px 0 0;

  &:last-of-type {
    margin: 0;
  }

  ${({ checked }) => checked && "0px 0px 30px 0px rgba(204, 28, 210, 0.50);"}
`;

const InlineLink = styled(Link)`
  display: inline-block;
`;

const LinkText = styled(Typography)`
  text-decoration: underline;
`;

const CardSettings = styled.div<{ marginTop: number }>`
  margin-top: ${({ marginTop }) => marginTop ? `${marginTop}px` : "24px"};
`;

const StyledButtonText = styled(ButtonText)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

`;

const Settings = styled.div<{ isOpen: boolean }>`
  height: 0;
  transition: height 2s ease-in-out;
  overflow: hidden;

  ${({ isOpen }) => isOpen && `
    height: auto;
    margin-top: 24px;
  `}
`;

const StyledButton = styled(Button)`
  margin-bottom: 48px;
`;

const InfoCheckbox: React.FC<InfoCheckboxProps> = ({
	label,
	checked,
	onChange,
	value,
	...props
}) => {

	return (
		<CheckboxWrapper>
			<Checkbox
				checked={checked}
				label={label}
				onChange={onChange}
				value={value}
				{...props}
			/>
		</CheckboxWrapper>
	);
};

const TitleGroup = () => {
	return (
		<>
			<PanelHeader>Contact Card</PanelHeader>
			<Typography
				color="secondaryLightGray"
				fontFamily="body"
				fontSize={16}
				fontWeight={400}
				lineHeight="22px"
				isInline
			>
        Customize your contact card by selecting a style and information
        you’d like to share. Information is populated from your profile
        information,&nbsp;
				<InlineLink to="/settings?tab=profile">
					<LinkText
						color="secondaryLightGray"
						fontFamily="body"
						fontSize={16}
						fontWeight={400}
						lineHeight="22px"
						isInline
						variant="span"
					>
            edit here.
					</LinkText>
				</InlineLink>
			</Typography>
		</>
	);
};

const ContactCardPage = () => {
	const dispatch: AppDispatch = useDispatch();
	const user = useSelector(selectUser);
	const contactCard = useSelector(selectContactCard);
	const viewport = useViewport();
	const isMobile = viewport === "mobile" || viewport === "tablet";
	if (!user) return;
	const initialFormData: string[] = contactCard
		? (Object.keys(contactCard) as ContactCardKeys[])
			.filter(
				(key: ContactCardKeys) =>
					typeof contactCard[key] === "boolean" && contactCard[key]
			)
			.map((key) => key as string)
		: ["avatar", "birthday", "email", "job_title", "location", "phoneNumber"];

	const initialInterests: Interest[] = contactCard?.interests || [];
	const initialCardStyle: number = contactCard?.card_style || 1;
	const initialColorScheme: string = contactCard?.color_scheme || "pink";

	const [formData, setFormData] = useState<string[]>(initialFormData);
	const [interests, setInterests] = useState<Interest[]>(initialInterests);
	const [belowCardMargin, setBelowCardMargin] = useState<number>(isMobile ? 24 : 48);
	const [cardScale, setCardScale] = useState(1);
	const [cardStyle, setCardStyle] = useState(initialCardStyle);
	const [colorScheme, setColorScheme] = useState(initialColorScheme);
	const [openSettings, setOpenSettings] = useState(false);

	const cardContainerRef = useRef<HTMLDivElement | null>(null);

	const calculateScale = () => {
		if (cardContainerRef.current) {
			const originalGap = isMobile ? 24 : 48;
			const parentWidth = cardContainerRef.current.offsetWidth;
			const cardWidthIncludingPadding = isMobile ? 676 : 768;
			const originalCardHeight = 382;

			if (parentWidth < cardWidthIncludingPadding) {
				const factor = parentWidth / cardWidthIncludingPadding;
				const newGap = (originalCardHeight * factor) - originalCardHeight + originalGap;
				setCardScale(factor);
				setBelowCardMargin(newGap);
			} else {
				setCardScale(1);
				setBelowCardMargin(originalGap);
			}
		}
	};

	useEffect(() => {
		dispatch(fetchContactCard());
	}, []);

	useEffect(() => {
		calculateScale();
		window.addEventListener("resize", calculateScale);

		return () => {
			window.removeEventListener("resize", calculateScale);
		};
	}, [cardContainerRef, isMobile]);

	const handleChange = (value: string, checked: boolean) => {
		if (checked) {
			setFormData((prevData) => [...prevData, value]);
		} else {
			setFormData((prevData) => prevData.filter((pref) => pref !== value));
		}
	};

	const isChecked = (infoType: string) => {
		return formData.includes(infoType);
	};

	const onPillClick = (clickedInterest: any) => {
		setInterests(prevInterests => {
			const isAlreadySelected = prevInterests.find(interest => interest.id === clickedInterest.id);

			if (isAlreadySelected) {
				// Remove the interest from the array if it's already selected
				return prevInterests.filter(interest => interest.id !== clickedInterest.id);
			} else {
				// Add the interest to the array if it's not already selected, but only if less than 5
				return prevInterests.length < 5 ? [...prevInterests, clickedInterest] : prevInterests;
			}
		});
	};


	const handleCardStyleChange = (event: React.MouseEvent<HTMLDivElement>) => {
		const value = event?.currentTarget?.dataset?.value;
		setCardStyle(parseInt(value as string));
	};

	const handleColorSchemeChange = (event: React.MouseEvent<HTMLDivElement>) => {
		const value = event?.currentTarget?.dataset?.value;
		setColorScheme(value as string);
	};

	const handleSubmit = async () => {
		const booleanMapping: { [key: string]: boolean } = {};

		InfoTypes.forEach((info) => {
			booleanMapping[info.value] = false;
		});

		formData.forEach((key) => {
			booleanMapping[key] = true;
		});

		const interestIds = interests.map(interest => interest.id);

		const payload = {
			...booleanMapping,
			interest_ids: interestIds,
			card_style: cardStyle,
			color_scheme: colorScheme,
		};

		try {
			const response = await axiosInstance.patch(
				"/contact-card",
				payload,
				{ withCredentials: true }
			);

			const contactCardData = response.data;
			if (contactCard) {
				dispatch(setContactCard(contactCardData));
			}
			dispatch(showSnackbar({ message: "Contact card info saved successfully!", duration: 3000 }));
		} catch (error) {
			console.error("Error updating contact card: ", error);
		}
	};

	const renderCheckboxGroup = () => {
		return InfoTypes.map((infoType, idx) => (
			<InfoCheckbox
				key={`${infoType.value}-${idx}`}
				checked={isChecked(infoType.value)}
				onChange={handleChange}
				label={infoType.label}
				value={infoType.value}
			/>
		));
	};

	const renderContactCard = () => {
		return (
			<ContactCard
				age={isChecked("age") && user?.dob ? calculateAge(user?.dob) : undefined}
				avatar={isChecked("avatar") ? user?.profile_avatar?.cropped_image_url : ""}
				birthday={isChecked("birthday") && user?.dob ? formatBirthday(user?.dob) : ""}
				cardStyle={cardStyle as 1 | 2 | 3}
				colorScheme={colorScheme as "pink" | "cyan" | "blackAndWhite"}
				email={isChecked("email") ? user?.email : ""}
				facebook={isChecked("facebook") ? user?.social_profile?.facebook : ""}
				headline={user?.headline}
				instagram={isChecked("instagram") ? user?.social_profile?.instagram : ""}
				interests={interests}
				job_title={isChecked("job_title") ? user?.job_title : ""}
				location={isChecked("location") ? "Buffalo, NY" : ""}
				name={
					isChecked("last_name") ? `${user?.first_name} ${user?.last_name}`
						: user?.first_name || ""
				}
				phoneNumber={
					isChecked("phoneNumber")
						? formatPhoneNumber(user?.phone_number || "")
						: ""
				}
				scale={cardScale}
				snapchat={isChecked("snapchat") ? user?.social_profile?.snapchat : ""}
				tiktok={isChecked("tiktok") ? user?.social_profile?.tiktok : ""}
				twitter={isChecked("twitter") ? user?.social_profile?.x_account : ""}
				zodiac={isChecked("zodiac") ? user?.zodiac_sign : ""}
			/>
		);
	};

	if (isMobile) {
		return (
			<>
				<MobileContactCardPage>
					<TitleGroup />
					<MobileContactCardWrapper ref={cardContainerRef}>
						{renderContactCard()}
						<CardSettings marginTop={belowCardMargin}>
							<StyledButtonText onClick={() => setOpenSettings((prev) => !prev)}>
								<Typography
									color="primaryPink"
									fontFamily="body"
									fontWeight={500}
									textAlign="center"
								>
									Edit Contact Card <Icon icon="edit" />
								</Typography> 
							</StyledButtonText>
							<Settings isOpen={openSettings}>
								<Typography
									fontFamily="body"
									fontSize={18}
								>
									Select what information to share
								</Typography>
								{renderCheckboxGroup()}
								<Typography
									fontFamily="body"
									fontSize={18}
									margin="48px 0 0 0"
								>
									Select Interests to Share
								</Typography>
								<Typography
									color="secondaryLightGray"
									fontFamily="body"
									fontSize={16}
									fontWeight={400}
									lineHeight="22px"
								>
									Select up to 5
								</Typography>
								<PillGroup>
									{user?.interests?.map((interest) => {
										return (
											<Pill
												key={interest.id}
												label={interest.name}
												value={interest}
												onClick={() => onPillClick(interest)}
												selected={interests.includes(interest)}
											/>
										);
									})}
								</PillGroup>

								<InputSection>
									<Typography fontSize={20} lineHeight="110%" margin="0 0 24px">
										Select Card Style
									</Typography>
									<RadioButtonGroup>
										{SelectCardStyles.map((option, idx) => {
											return (
												<RadioButtonWrapper
													data-value={option.value}
													key={`${option.value}-${idx}`}
													checked={cardStyle === option.value}
													onClick={handleCardStyleChange}
												>
													<RadioButton
														checked={cardStyle === option.value}
														label={option.label}
														value={option.value}
													/>
												</RadioButtonWrapper>
											);
										})}
									</RadioButtonGroup>
								</InputSection>
								<InputSection>
									<Typography fontSize={20} lineHeight="110%" margin="0 0 24px">
										Select Color Scheme
									</Typography>
									<RadioButtonGroup>
										{SelectColorScheme.map((option, idx) => {
											return (
												<RadioButtonWrapper
													data-value={option.value}
													key={`${option.value}-${idx}`}
													checked={colorScheme === option.value}
													onClick={handleColorSchemeChange}
												>
													<RadioButton
														checked={colorScheme === option.value}
														label={option.label}
														value={option.value}
													/>
												</RadioButtonWrapper>
											);
										})}
									</RadioButtonGroup>
								</InputSection>
								<InputSection>
									<StyledButton 
										fullWidth={viewport === "mobile"} 
										onClick={handleSubmit}
									>
										Save Changes
									</StyledButton>
								</InputSection>
							</Settings>
						</CardSettings>
					</MobileContactCardWrapper>
				</MobileContactCardPage>
			</>
		);
	}

	return (
		<>
			<ContactCardPageContainer>
				<ContactCardPanel>
					<TitleGroup />
					{renderCheckboxGroup()}
				</ContactCardPanel>
				<ContactCardContent ref={cardContainerRef}>
					{renderContactCard()}
					<InputSection marginTop={belowCardMargin}>
						<Typography fontFamily="body" fontSize={18} lineHeight="24px">
              Select Interests to Share
						</Typography>
						<Typography
							color="secondaryLightGray"
							fontFamily="body"
							fontSize={16}
							fontWeight={400}
							lineHeight="22px"
						>
              Select up to 5
						</Typography>
						<PillGroup>
							{user?.interests?.map((interest) => {
								const isSelected = interests.some(item => item.id === interest.id);

								return (
									<Pill
										key={interest.id}
										label={interest.name}
										value={interest}
										onClick={onPillClick}
										selected={isSelected}
									/>
								);
							})}
						</PillGroup>
					</InputSection>
					<InputSection>
						<Typography fontSize={20} lineHeight="110%" margin="0 0 24px">
              Select Card Style
						</Typography>
						<RadioButtonGroup>
							{SelectCardStyles.map((option, idx) => {
								return (
									<RadioButtonWrapper
										data-value={option.value}
										key={`${option.value}-${idx}`}
										checked={cardStyle === option.value}
										onClick={handleCardStyleChange}
									>
										<RadioButton
											checked={cardStyle === option.value}
											label={option.label}
											value={option.value}
										/>
									</RadioButtonWrapper>
								);
							})}
						</RadioButtonGroup>
					</InputSection>
					<InputSection>
						<Typography fontSize={20} lineHeight="110%" margin="0 0 24px">
              Select Color Scheme
						</Typography>
						<RadioButtonGroup>
							{SelectColorScheme.map((option, idx) => {
								return (
									<RadioButtonWrapper
										data-value={option.value}
										key={`${option.value}-${idx}`}
										checked={colorScheme === option.value}
										onClick={handleColorSchemeChange}
									>
										<RadioButton
											checked={colorScheme === option.value}
											label={option.label}
											value={option.value}
										/>
									</RadioButtonWrapper>
								);
							})}
						</RadioButtonGroup>
					</InputSection>
					<InputSection>
						<Button onClick={handleSubmit}>Save Changes</Button>
					</InputSection>
				</ContactCardContent>
			</ContactCardPageContainer>
		</>
	);
};

export default ContactCardPage;
