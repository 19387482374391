import React from "react";
import styled from "@emotion/styled";
import Button from "../../Buttons/Button";
import ButtonText from "../../Buttons/ButtonText";
import Icon from "../../Icon";
import Modal from "../../Shared/Modal";
import Typography from "../../Typography";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../redux/reducers/snackbarReducer";
import { useCancelSubscriptionMutation } from "../../../redux/features/subscriptionsApi";
import { CustomTheme } from "../../../theme";

interface CancelSubscriptionModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	refetch: () => void;
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const IconWrapper = styled.div<{ color: keyof CustomTheme["colors"] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  background: ${({ color, theme }) => theme.colors[color]}20;
  border-radius: 50%;
  border: 1px solid ${({ color, theme }) => theme.colors[color]};
`;

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  width: 100%;
  height: 1px;
`;

const ButtonGroup = styled.div`
  margin-top: 8px;
	width: 100%;
`;

const KeepMySubButton = styled(ButtonText)`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  width: 100%;
  text-align: center;
  margin-top: 12px;
  padding: 14px 40px 10px;
`;

const CancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> = ({
	isOpen,
	setIsOpen,
	refetch
}) => {
	const dispatch = useDispatch();
	const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();

	const handleCancel = async (event: any) => {
		event.preventDefault();
		try {
			await cancelSubscription().unwrap();
			setIsOpen(false);
			dispatch(showSnackbar({ message: "Subscription cancelled successfully!", duration: 3000 }));
			refetch();
		} catch (error) {
			const errorMessage = (error as Error).message;
			console.error("Subscription cancellation failed:", errorMessage);
			dispatch(showSnackbar({ message: "Subscription cancellation failed. Please try again.", duration: 3000 }));
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			content={
				<ModalContainer>
					<IconWrapper color="errorRed">
						<Icon color="errorRed" icon="trash" />
					</IconWrapper>
					<Typography
						fontSize={24}
						fontWeight={500}
						lineHeight="110%"
						textAlign="center"
					>
						Are you sure you want to cancel your subscription?
					</Typography>
					<Divider />
					<Typography
						color="secondaryLightGray"
						fontFamily="body"
						fontSize={14}
						fontWeight={400}
						textAlign="center"
					>
					Deleting your account will permanently erase chat history and
					contact card information.
					</Typography>
					<ButtonGroup>
						<Button 
							fullWidth
							onClick={handleCancel}
							loading={isLoading}
						>
							Cancel My Subscription
						</Button>
						<KeepMySubButton onClick={() => setIsOpen(false)}>
							Keep My Subscription
						</KeepMySubButton>
					</ButtonGroup>
				</ModalContainer>
			}
		/>
	);
};

export default CancelSubscriptionModal;