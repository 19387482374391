import React, { useState, useMemo } from "react";
import { useSpring, animated } from "react-spring";
import Icon from "../Icon";
import styled from "@emotion/styled";

const Heart = styled(animated.div)`
  position: absolute;
  width: 30px;
  height: 30px;
  // For filled in heart use this
  // background-color: ${({theme}) => theme.colors.primaryPink};
	// clip-path: path("M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z");
`;

// Function to generate random animation targets
function getRandomTo() {
	return {
		top: `${Math.random() * 100}vh`,
		left: `${Math.random() * 100}vw`,
		rotate: Math.random() * 360,
		scale: Math.random() * 2,
		opacity: Math.random(),
	};
}

// Function to generate random spring config
function getRandomConfig() {
	return {
		tension: Math.random() * 100 + 100, // 100 to 200
		friction: Math.random() * 20 + 20, // 20 to 40
	};
}

const AnimatedHeart = () => {
	const [to, setTo] = useState(getRandomTo);
	const config = useMemo(() => getRandomConfig(), []);

	const springProps = useSpring({
		from: {
			top: `${Math.random() * 100}vh`,
			left: `${Math.random() * 100}vw`,
			rotate: Math.random() * 360,
			scale: Math.random(),
			opacity: Math.random(),
		},
		to,
		config,
		onRest: () => setTo(getRandomTo()),
	});

	return (
		<Heart
			style={{
				top: springProps.top,
				left: springProps.left,
				opacity: springProps.opacity,
				transform: springProps.rotate.to(rot => springProps.scale.to(scale => `rotate(${rot}deg) scale(${scale})`)) as any
			}}
		>
			<Icon icon="heart" width={32} height={32} />
		</Heart>
	);
};

export default AnimatedHeart;
