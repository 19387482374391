import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import Button from "../../Buttons/Button";
import Checkbox from "../../Inputs/Checkbox";
import Pill from "../../Inputs/Pill";
import Typography from "../../Typography";
import ToggleSwitch from "../../Inputs/Toggle";
import { updateUserPreferenceData } from "../../../redux/actions/userActions";
import { showSnackbar } from "../../../redux/reducers/snackbarReducer";
import { User } from "../../../types/User";
import { RELIGIONS } from "../../../data/religions";
import type { AppDispatch } from "../../../redux/store";

const TabContainer = styled.div`
  padding: 40px 32px;
  max-width: 463px;
  width: 100%;
  box-sizing: border-box;
`;

const ReligionInputContainer = styled.div`
  margin: 40px 0;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BeliefsWrapper = styled.div`
  position: relative;
  margin: 32px 0;
`;

const PillGroup = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -4px;

  ${({ disabled }) => disabled && "opacity: 0.5; pointer-events: none;"}
`;

const Dealbreaker = styled.div`
  margin-top: 32px;
`;

const ReligionPreferenceTab: React.FC<{ user: User }> = ({ user }) => {
	const dispatch: AppDispatch = useDispatch();
	const defaultReligiousBeliefs = user?.preferences?.religiousBeliefs?.beliefs || [];
	const defaultDealbreaker = user?.preferences?.religiousBeliefs?.dealbreaker;
	const allReligionsSelected = RELIGIONS.length === defaultReligiousBeliefs.length;
	const [beliefs, setBeliefs] = useState(defaultReligiousBeliefs || []);
	const [prevBeliefs, setPrevBeliefs] = useState<string[]>([]);
	const [openToAny, setOpenToAny] = useState(allReligionsSelected);
	const [dealbreaker, setDealbreaker] = useState(defaultDealbreaker || false);

	const onPillClick = (value: string, selected: boolean) => {
		if (selected) {
			setBeliefs((prevBeliefs) => [...prevBeliefs, value]);
		} else {
			setBeliefs((prevBeliefs) => prevBeliefs.filter((belief) => belief !== value));
		}
	};

	const handleOpenToAny = () => {
		setOpenToAny(prev => {
			setBeliefs(prev ? prevBeliefs : RELIGIONS);
			if (!prev) setPrevBeliefs(beliefs);
			return !prev;
		});
	};

	const handleSubmit = async () => {
		if (!user || !user.id) return;
		const data = {
			...user?.preferences,
			religiousBeliefs: {
				beliefs: beliefs,
				dealbreaker: dealbreaker
			} 
		};

		dispatch(updateUserPreferenceData({ data }))
			.unwrap()
			.then(() => {
				dispatch(showSnackbar({ message: "Religious preferences saved successfully!", duration: 3000 }));
			})
			.catch(error => {
				console.error("Error updating user preferences: ", error);
			});
	};

	return(
		<TabContainer>
			<Typography
				fontFamily="body"
				fontSize={18}
				fontWeight={700}
				lineHeight="24px"
				margin="0 0 4px"
				variant="h2"
			>
      Religious Beliefs
			</Typography>
			<ReligionInputContainer>
				<ToggleContainer>
					<ToggleSwitch
						checked={openToAny}
						onChange={handleOpenToAny}
					/>
					<Typography
						color="secondaryLightGray"
						fontFamily="body"
						fontSize={16}
						fontWeight={300}
						margin="0 16px"
					>
          I’m open to any religious beliefs
					</Typography>
				</ToggleContainer>
				<BeliefsWrapper>
					<PillGroup disabled={openToAny}>
						{RELIGIONS.map((belief: string) => (
							<Pill
								key={belief}
								label={belief}
								value={belief}
								onClick={onPillClick}
								selected={openToAny || beliefs.includes(belief)}
							/>
						))}
					</PillGroup>
				</BeliefsWrapper>
				<Dealbreaker>
					<Checkbox
						checked={dealbreaker} 
						label="This is a deal breaker" 
						onChange={() => setDealbreaker(prev => !prev)}
						value="dealbreaker"
					/>
				</Dealbreaker>
			</ReligionInputContainer>
			<Button
				onClick={handleSubmit}
			>
      Apply Preferences
			</Button>
		</TabContainer>
	);
};

export default ReligionPreferenceTab;