import React from "react";
import { createRoot } from "react-dom/client";
import { Global, ThemeProvider } from "@emotion/react";
import WebFont from "webfontloader";
import App from "./App";
import theme from "./theme";
import { globalStyles } from "./globalStyles";

WebFont.load({
	google: {
		families: ["Exo:300,400,500,600,700", "Inter:300,400,500,600,700", "Ubuntu:300,400,500,600,700"],
	},
});

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<ThemeProvider theme={theme}>
		<Global styles={globalStyles(theme)} />
		<App />
	</ThemeProvider>
);
