export interface CustomTheme {
  colors: {
    primaryMidnight: string;
    primaryPink: string;
    accentLavender: string;
    accentCyan: string;
    accentViolet: string;
    accentAquamarine: string;
    containerBase: string;
    errorRed: string;
    lightGray: string;
    secondaryLightGray: string;
    neutralGray: string;
    mediumGray: string;
    darkGray: string;
    borderGray: string;
    black: string;
    white: string;
		secondaryCyan: string;
  };
  fonts: {
    primary: string;
    secondary: string;
    body: string;
  };
  gradients: {
    primary: string;
    secondary: string;
  };
}

declare module "@emotion/react" {
  export interface Theme extends CustomTheme {}
}

const theme: CustomTheme = {
	colors: {
		// Primary
		primaryMidnight: "#030512",
		primaryPink: "#D902B9",

		// Accent
		accentLavender: "#6C68FD",
		accentCyan: "#1FB9FA",
		accentViolet: "#AC28DF",
		accentAquamarine: "#00C6FB",

		// Base
		containerBase: "#1C1E2A",

		// Error
		errorRed: "#FF3D00",

		// Neutral
		lightGray: "#AAABB3",
		secondaryLightGray: "#BCBCC3",
		neutralGray: "#D0D8DE",
		mediumGray: "#6D7494",
		darkGray: "#494B5D",
		borderGray: "#353741",
		black: "#000000",
		white: "#FFFFFF",

		// Other

		secondaryCyan: "#168cd7"
	},
	fonts: {
		primary: "Exo",
		secondary: "Inter",
		body: "Ubuntu",
	},
	gradients: {
		primary: "linear-gradient(90deg, #6C68FD 0%, #AC28DF 53.13%, #D902B9 98.96%)",
		secondary: "linear-gradient(180deg, #7D6DFD 0%, #5340E9 100%)",
	},
};

export default theme;
