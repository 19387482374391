import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import Tooltip from "./Tooltip";
import Typography from "../Typography";
import { selectUser, setUser } from "../../redux/reducers/userReducer";
import { showSnackbar } from "../../redux/reducers/snackbarReducer";

const DiscoveryToggleContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 20px;

  ${({ isMobile }) =>
    isMobile &&
    `
    flex-direction: row-reverse;
    justify-content: start;
    padding-left: 24px;
  `}
`;

const ToggleSwitchWrapper = styled.label<{
  checked: boolean;
  isMobile?: boolean;
}>`
  position: relative;
  display: inline-block;
  width: 34px;
  min-width: 34px;
  height: 16px;
  background-color: ${({ checked, theme }) =>
    checked ? `${theme.colors.primaryPink}50` : theme.colors.containerBase};
  border-radius: 24px;
  transition: background-color 0.2s;

  ${({ isMobile }) => isMobile && "margin-right: 12px;"}
`;

const ToggleSwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ToggleSwitchSlider = styled.span<{ checked: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -2px;
  right: 2px;
  bottom: -2px;
  border-radius: 22px;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: 0px;
    background-color: ${({ checked, theme }) =>
      checked ? theme.colors.primaryPink : theme.colors.borderGray};
    transition: 0.4s;
    border-radius: 50%;
    transform: ${({ checked }) => (checked ? "translateX(18px)" : "none")};
  }
`;

const DiscoveryToggle: React.FC<{ isMobile?: boolean }> = ({ isMobile }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  if (!user) return null;
  const [checked, setChecked] = useState(!!user.discoveryMode);

  const handleChange = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/users/${user?.id}/status`,
        {
          discoveryMode: !checked,
        },
        { withCredentials: true }
      );

      const userData = response.data;
      dispatch(setUser(userData.user));
      setChecked(!!userData.user.discoveryMode);
      if (userData.user.discoveryMode) {
        dispatch(
          showSnackbar({ message: "Discovery mode turned on.", duration: 3000 })
        );
      } else {
        dispatch(
          showSnackbar({
            message: "Discovery mode turned off.",
            duration: 3000,
          })
        );
      }
    } catch (error) {
      console.error("Error updating user: ", error);
    }
  };

  return (
    <>
      <DiscoveryToggleContainer isMobile={isMobile}>
        <Tooltip
          infoMargin={isMobile ? "" : "18px 0 0"}
          placement={isMobile ? "mobile" : "bottom-left"}
        >
          <Typography
            fontFamily="body"
            fontSize={12}
            fontWeight={700}
            lineHeight="18px"
            margin="0 0 8px"
          >
            Turn on Discovery Mode!
          </Typography>
          <Typography
            color="secondaryLightGray"
            fontFamily="body"
            fontSize={12}
            fontWeight={400}
            lineHeight="18px"
          >
            Keep exploring Heart 2 Heart while our matchmaking wizards work
            tirelessly in the background. We will notify you once we’ve found a
            match, and you can join the conversation in a chatroom.
          </Typography>
        </Tooltip>
        <Typography
          fontFamily="body"
          fontSize={16}
          fontWeight={400}
          lineHeight="22px"
          margin={!isMobile ? "0 12px 0 0" : ""}
        >
          Discovery Mode
        </Typography>
        <ToggleSwitchWrapper checked={checked} isMobile={isMobile}>
          <ToggleSwitchInput
            type="checkbox"
            checked={checked}
            onChange={handleChange}
          />
          <ToggleSwitchSlider checked={checked} />
        </ToggleSwitchWrapper>
      </DiscoveryToggleContainer>
    </>
  );
};

export default DiscoveryToggle;
