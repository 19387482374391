import React, { useState } from "react";
import styled from "@emotion/styled";
import Icon from "../Icon";

interface FaqCollapseProps {
  question: string;
  answer: string;
  isMobile: boolean;
}

const FaqContainer = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile}) => isMobile ? "24px 20px" : "24px 40px"};
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => `${theme.colors.white}20`};
  width: 100%;
`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Question = styled.p`
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 16px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.accentCyan};
  flex-shrink: 0;
`;

const StyledIcon = styled(Icon)<{ open?: boolean }>`
  transition: transform 0.2s ease-in-out;
  ${({ open }) => open && "transform: scaleY(-1);"}
`;

const AnswerContainer = styled.div<{ open: boolean }>`
  max-height: ${({ open }) => (open ? "300px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

const Answer = styled.p`
  color: ${({ theme }) => `${theme.colors.white}70`};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 20px;
  font-weight: 400;
  margin: 0;
`;

const FaqCollapse: React.FC<FaqCollapseProps> = ({ question, answer, isMobile }) => {
	const [open, setOpen] = useState(false);

	return (
		<FaqContainer isMobile={isMobile}>
			<QuestionContainer onClick={() => setOpen(prev => !prev)}>
				<ContentContainer>
					<Question>
						{question}
					</Question>
					<AnswerContainer open={open}>
						<Answer>
							{answer}
						</Answer>
					</AnswerContainer>
				</ContentContainer>
				<IconContainer>
					<StyledIcon color="white" icon="caret-down" open={open} />
				</IconContainer>
			</QuestionContainer>
		</FaqContainer>
	);
};

export default FaqCollapse;
