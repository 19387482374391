import React, { useState, useMemo } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../Icon";
import Popover from "../Shared/Popover";
import Typography from "../Typography";
import { logoutUser } from "../../redux/actions/userActions";
import { avatarProfileData, areAvatarProfileDataEqual } from "../../redux/selectors/userSelectors";

import type { AppDispatch } from "../../redux/store";

const StyledButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 0;
`;

const Avatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 10px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.primaryPink};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  width: 32px;
  border-radius: 10px;
  aspect-ratio: 1 / 1;
`;

const StyledIcon = styled(Icon)<{ open?: boolean }>`
  margin-left: 10px;
  ${({ open }) => open && "transform: rotate(180deg);"}
`;

const ContentContainer = styled.div`
  padding: 12px 0;
`;

const ContentButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  padding: 4px 16px;
  margin-bottom: 8px;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primaryPink};
  }
`;

const ProfileButton = () => {
	const dispatch: AppDispatch  = useDispatch();
	const userData = useSelector(avatarProfileData, areAvatarProfileDataEqual);
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);

	const avatar = userData.cropped_image_url;

	const getUserInitials = useMemo(() => {
		const firstInitial = userData.first_name?.charAt(0) as string;
		const lastInitial = userData.last_name?.charAt(0) as string;
		return firstInitial + lastInitial;
	}, [userData.first_name, userData.last_name]);

	const handleTogglePopover = () => setIsOpen(!isOpen);
	const handleClosePopover = () => setIsOpen(false);

	return (
		<Popover
			trigger={
				<StyledButton>
					<Avatar>
						{avatar ? (
							<StyledImg src={avatar} />
						) : (
							<Typography
								color="white"
								fontFamily="body"
								fontSize={14}
								fontWeight={400}
							>
								{getUserInitials}
							</Typography>
						)}
					</Avatar>
					<StyledIcon icon="caret-down" color="white" open={isOpen} />
				</StyledButton>
			}
			content={
				<ContentContainer>
					<ContentButton onClick={() => navigate("/settings?tab=profile")}>
            Edit Profile
					</ContentButton>
					<ContentButton onClick={() => navigate("/settings?tab=account")}>
            My Account
					</ContentButton>
					<ContentButton
						onClick={() => {
							dispatch(logoutUser());
							navigate("/");
						}}
					>
            Log Out
					</ContentButton>
				</ContentContainer>
			}
			placement="bottom-left"
			isOpen={isOpen}
			onToggle={handleTogglePopover}
			onClose={handleClosePopover}
		/>
	);
};

export default ProfileButton;
