import React, { useState } from "react";
import styled from "@emotion/styled";
import Button from "../../Buttons/Button";
import ButtonText from "../../Buttons/ButtonText";
import Icon from "../../Icon";
import Modal from "../../Shared/Modal";
import PaymentSection from "./PaymentSection";
import SubscriptionSection from "./SubscriptionSection";
import Typography from "../../Typography";
import { CustomTheme } from "../../../theme";

const AccountTabContainer = styled.div`
  padding: 40px 32px;
  box-sizing: border-box;
	max-width: 1027px;
	width: calc(100vw - 358px);

	@media (max-width: 787px) {
		width: auto;
	}
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const IconWrapper = styled.div<{ color: keyof CustomTheme["colors"] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  background: ${({ color, theme }) => theme.colors[color]}20;
  border-radius: 50%;
  border: 1px solid ${({ color, theme }) => theme.colors[color]};
`;

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  width: 100%;
  height: 1px;
`;

const DeleteButton = styled(ButtonText)`
	padding: 0;
`;

const ButtonGroup = styled.div`
  margin-top: 8px;
`;

const CancelButton = styled(ButtonText)`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  width: 100%;
  text-align: center;
  margin-top: 12px;
  padding: 14px 40px 10px;
`;

const AccountTab = () => {
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<AccountTabContainer>
			<Typography
				fontSize={24}
				fontWeight={700}
			>
        My Account
			</Typography>

			<SubscriptionSection />
			<PaymentSection />
			<Modal
				isOpen={deleteModalOpen}
				setIsOpen={setDeleteModalOpen}
				trigger={
					<DeleteButton>
						<Typography
							color="errorRed"
							fontFamily="body"
							fontWeight={500}
							margin="40px 0 0"
						>
							Delete Account
						</Typography>
					</DeleteButton>
				}
				content={
					<ModalContainer>
						<IconWrapper color="errorRed">
							<Icon color="errorRed" icon="trash" />
						</IconWrapper>
						<Typography
							fontSize={24}
							fontWeight={500}
							lineHeight="110%"
							textAlign="center"
						>
              Are you sure you want to delete your account?
						</Typography>
						<Divider />
						<Typography
							color="secondaryLightGray"
							fontFamily="body"
							fontSize={14}
							fontWeight={400}
							textAlign="center"
						>
              Deleting your account will permanently erase chat history and
              contact card information.
						</Typography>
						<ButtonGroup>
							<Button fullWidth>Confirm Delete</Button>
							<CancelButton onClick={() => setDeleteModalOpen(false)}>
                Cancel
							</CancelButton>
						</ButtonGroup>
					</ModalContainer>
				}
			/>
		</AccountTabContainer>
	);
};

export default AccountTab;
