import React from "react";
import styled from "@emotion/styled";
import Button from "../../../Buttons/Button";
import Checkbox from "../../../Inputs/Checkbox";
import Typography from "../../../Typography";
import { getOrdinalSuffix } from "../../../../utils/formatDate";
import { formatPrice } from "../../../../utils/formatPrice";
import { SubscriptionPlan } from "../../../../types/stripeTypes";

const Logo = styled.img`
  height: 26px;
`;

const StyledUl = styled.ul`
  margin: 0;
  padding: 0;
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: start;
  margin: 16px 0;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  width: 100%;
  height: 1px;
`;

const ButtonGroup = styled.div`
  margin-top: 8px;
`;

interface SubscriptionStepOneProps {
  plan: SubscriptionPlan;
  onNext: () => void;
}

const SubscriptionStepOne: React.FC<SubscriptionStepOneProps> = ({ plan, onNext }) => {
	const cost = `${formatPrice(plan.price || 0)}`;
	const today = new Date();
	const day = today.getDate();
	const ordinalSuffix = getOrdinalSuffix(today);

	return (
		<>
			<Typography
				fontSize={18}
				fontWeight={500}
				lineHeight="110%"
				textAlign="center"
			>
        Start your monthly subscription for the full Heart 2 Heart experience!
			</Typography>
			<Typography
				color="secondaryLightGray"
				fontSize={14}
				fontWeight={400}
				textAlign="center"
			>
				{plan?.description}
			</Typography>
			<Logo src={plan.images[0]} />
			<Typography fontSize={36} fontWeight={500}>
				{cost}
				<Typography
					variant="span"
					color="secondaryLightGray"
					fontSize={20}
					fontWeight={400}
					isInline
				>
          /month
				</Typography>
			</Typography>
			<StyledUl>
				{plan.features.map((feature, index) => (
					<StyledListItem key={index}>
						<Checkbox disabled checked onChange={() => null} value="benefit" />
						<Typography fontFamily="body" fontSize={18} fontWeight={400}>
							{feature.name}
						</Typography>
					</StyledListItem>
				))}
			</StyledUl>
			<Divider />
			<Typography
				color="secondaryLightGray"
				fontFamily="body"
				fontSize={14}
				fontWeight={400}
				textAlign="center"
			>
				{`You will be charged today and then monthly on the ${day}${ordinalSuffix}. Cancel anytime under Settings > My Account. By tapping the button below you agree to the details above, our Terms of Use and Privacy Policy.`}
			</Typography>
			<ButtonGroup>
				<Button fullWidth onClick={onNext}>
					{`Start today for ${cost}/month`}
				</Button>
			</ButtonGroup>
		</>
	);
};

export default SubscriptionStepOne;
