import React from "react";
import styled from "@emotion/styled";
import Icon from "../Icon";
import Link from "../Links/Link";
import { CustomTheme } from "../../theme";

export interface CardInfoProps {
  icon?: string;
  iconColor?: keyof CustomTheme["colors"];
  link?: string;
  subtitle?: string;
  title?: string;

  height?: string;
  width?: string;
}

interface StyledCardInfoProps {
  width?: string;
  height?: string;
}

const StyledCardInfo = styled.div<StyledCardInfoProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => `${theme.colors.containerBase}30`};
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 12px;
  padding: 32px;

  width: ${({ width }) => (width ? `${width}` : "100%")};
  height: ${({ height }) => (height ? `${height}` : "auto")};
`;

const StyledTitle = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 20px;
  font-weight: 500;
  margin: 12px 0;
`;

const StyledSubtitle = styled.p`
  color: ${({ theme }) => theme.colors.lightGray};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 20px;
`;

const IconWrapper = styled.div`
  width: 100%;
  min-height: 32px;
`;

const LinkWrapper = styled.div`
  margin-left: auto;
`;

const CardInfo = ({
	icon,
	iconColor,
	link,
	subtitle,
	title,
	width,
	height,
	...rest
}: CardInfoProps) => {
	return (
		<StyledCardInfo width={width} height={height} {...rest}>
			<IconWrapper>
				<Icon
					icon={icon || "heart"}
					width={32}
					height={32}
					color={iconColor || "primaryPink"}
				/>
			</IconWrapper>
			<StyledTitle>{title}</StyledTitle>
			<StyledSubtitle>{subtitle}</StyledSubtitle>
			{link && (
				<LinkWrapper>
					<Link icon="arrow-right" to={link || ""} text="Learn more" />
				</LinkWrapper>
			)}
		</StyledCardInfo>
	);
};

export default CardInfo;
