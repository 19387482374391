import React from "react";
import styled from "@emotion/styled";
import CardInfo from "../Cards/CardInfo";
import ScrollFadeIn from "../Animations/ScrollFadeIn";
import { useViewport } from "../../hooks/ViewportProvider";

const SecondSectionContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ isMobile }) => (isMobile ? "60px 24px" : "120px 112px")};
`;

const Headline = styled.h1`
  color: ${({ theme }) => theme.colors.primaryPink};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  white-space: nowrap;
`;

const SubHeadline = styled.h2<{ viewport: any }>`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ viewport }) =>
		viewport === "mobile" || viewport === "tablet" ? "24px" : "32px"};
  font-weight: 400;
  margin: ${({ viewport }) =>
		viewport === "mobile" || viewport === "tablet"
			? "20px 0px 24px"
			: "20px 0px 48px"};
  max-width: 624px;
  text-align: center;
`;

const CardGroup = styled.div<{ viewport: any }>`
  display: flex;
  flex-direction: ${({ viewport }) =>
		viewport === "largeDesktop" ? "row" : "column"};
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const calculateCardHeight = (viewport: any) => {
	switch (viewport) {
	case "largeDesktop":
		return "300px";
	case "desktop":
	case "laptop":
	case "tablet":
		return "224px";
	case "mobile":
	default:
		return "288px";
	}
};

const StyledCard = styled(CardInfo)<{ viewport: any }>`
  height: ${({ viewport }) => calculateCardHeight(viewport)};
  max-width: ${({ viewport }) =>
		viewport === "largeDesktop" ? "389px" : "100%"};
  box-sizing: border-box;
  text-align: center;
`;

const SecondSection = () => {
	const viewport = useViewport();
	const isLargeDesktop = viewport === "largeDesktop";
	const isMobile = viewport === "mobile" || viewport === "tablet";
	const isMobileSmall = viewport === "mobile";

	return (
		<SecondSectionContainer isMobile={isMobile}>
			<Headline>HOW IT WORKS</Headline>
			<SubHeadline viewport={viewport}>
        Speak Your Heart: Form a Connection the Way You Were Meant To.
			</SubHeadline>
			<CardGroup viewport={viewport}>
				<ScrollFadeIn
					delay={0}
					direction={isLargeDesktop ? "down" : "left"}
					disableOnMobile={isMobileSmall}
					once
				>
					<StyledCard
						viewport={viewport}
						icon="user"
						iconColor="accentCyan"
						title="Be Your Unapologetic Self"
						subtitle="We've curated a thoughtfully designed onboarding questionnaire, delving deep into your interests and values. Let your personality shine and set the stage for an upcoming journey filled with authenticity and compatibility."
					/>
				</ScrollFadeIn>
				<ScrollFadeIn
					delay={isLargeDesktop ? 500 : 0}
					direction={isLargeDesktop ? "down" : "left"}
					disableOnMobile={isMobileSmall}
					once
				>
					<StyledCard
						viewport={viewport}
						icon="heart"
						iconColor="accentCyan"
						title="Get Matched Based on Compatibility"
						subtitle="After onboarding, you can 'Go Live' and begin searching for your match. Through a careful analysis of your personality profile, interests, and values, our sophisticated algorithm handpicks potential matches with whom you're highly likely to be compatible with."
					/>
				</ScrollFadeIn>
				<ScrollFadeIn
					delay={isLargeDesktop ? 1000 : 0}
					direction={isLargeDesktop ? "down" : "left"}
					disableOnMobile={isMobileSmall}
					once
				>
					<StyledCard
						viewport={viewport}
						icon="video"
						iconColor="accentCyan"
						title="Reveal the Mystery"
						subtitle="Once we find your match, you'll both be placed into one of our state of the art chatrooms to spark up a conversation without the cameras on. When the time is right, you can enable your video and add an exciting new layer to your journey."
					/>
				</ScrollFadeIn>
			</CardGroup>
		</SecondSectionContainer>
	);
};

export default SecondSection;
