import { Stripe, StripeElements } from "@stripe/stripe-js";

type SaveFunction = (setupIntent: any) => void;

export const handleCardSubmission = async (
	stripe: Stripe | null,
	elements: StripeElements | null,
	clientSecret: string,
	onSave: SaveFunction,
) => {
	if (!stripe || !elements) {
		console.error("Stripe.js has not loaded, or CardElement does not exist.");
		return;
	}

	const cardElement = elements.getElement("card");
	if (!cardElement) {
		console.error("CardElement does not exist.");
		return;
	}

	try {
		const result = await stripe.confirmCardSetup(clientSecret, {
			payment_method: {
				card: cardElement,
			},
		});

		if (result.error) {
			console.error(result.error.message);
		} else {
			onSave(result.setupIntent);
			cardElement.clear();
		}
	} catch (error) {
		console.error("Error submitting card:", error);
	}
};
