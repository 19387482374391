import React from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";

interface ContentProps {
  currentStep: number;
  isSticky: boolean;
}

const Content: React.FC<ContentProps> = ({ currentStep, ...props }) => {

	switch (currentStep) {
	case 1:
		return <Step1 {...props} />;
	case 2:
		return <Step2 {...props} />;
	case 3:
		return <Step3 {...props} />;
	case 4:
		return <Step4 {...props} />;
	case 5:
		return <Step5 {...props} />;
	case 6:
		return <Step6 {...props} />;
	case 7:
		return <Step7 {...props} />;
	default:
		return null;
	}
};

export default Content;