import React from "react";
import styled from "@emotion/styled";

const StyledHeart = styled.div<{ scale: number | undefined }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background: conic-gradient(from 140deg at 50.42% 51.28%, #B6D0F7 0deg, #EEF4E1 82.50000178813934deg, #E2C1F9 155.6249964237213deg, #BFD1F9 209.30935621261597deg, #FAFCFE 252.6490044593811deg, #BAE4E2 288.7499928474426deg, #8C6BE3 341.2500071525574deg, #C0D7F3 360deg);
  clip-path: path("M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z");

  ${({ scale }) => scale && `transform: scale(${scale});`}
`;

const GradientHeart: React.FC<{ scale?: number }> = ({ scale, ...props }) => {
	return (
		<StyledHeart scale={scale} {...props}/>
	);
};

export default GradientHeart;
