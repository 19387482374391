import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PaymentMethod } from "../../types/stripeTypes";

export const paymentMethodsApi = createApi({
	reducerPath: "paymentMethodsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_URL,
		credentials: "include",
		prepareHeaders: (headers) => {
			const token = localStorage.getItem("token");
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ["PaymentMethod"],
	endpoints: (builder) => ({
		fetchPaymentMethods: builder.query<PaymentMethod[], void>({
			query: () => "/payment_methods",
			providesTags: ["PaymentMethod"],
		}),
		createSetupIntent: builder.mutation<{ client_secret: string }, void>({
			query: () => ({
				url: "/setup_intents",
				method: "POST",
			}),
			invalidatesTags: ["PaymentMethod"],
		}),
		setDefaultPaymentMethod: builder.mutation<void, string>({
			query: (paymentMethodId) => ({
				url: "/set_default_payment_method",
				method: "POST",
				body: { payment_method_id: paymentMethodId },
			}),
			invalidatesTags: ["PaymentMethod"],
		}),
		deletePaymentMethod: builder.mutation<void, string>({
			query: (paymentMethodId) => ({
				url: "/delete_payment_method",
				method: "POST",
				body: { payment_method_id: paymentMethodId },
			}),
			invalidatesTags: ["PaymentMethod"],
		}),
	}),
});

export const {
	useFetchPaymentMethodsQuery,
	useCreateSetupIntentMutation,
	useSetDefaultPaymentMethodMutation,
	useDeletePaymentMethodMutation,
} = paymentMethodsApi;
