import React from "react";
import styled from "@emotion/styled";
import theme, { CustomTheme } from "../theme";

type Variant = "h1" | "h2" | "h3" | "h4" | "h5" | "body1" | "body2" | "span";
export interface TypographyProps {
  variant?: Variant;
  color?: keyof CustomTheme["colors"];
  fontSize?: number;
  fontWeight?: string | number;
  fontFamily?: keyof CustomTheme["fonts"];
  isInline?: boolean;
  lineHeight?: string | number;
  margin?: string;
  textAlign?: "left" | "center" | "right" | "justify" | "initial" | "inherit";
  whiteSpace?:
    | "normal"
    | "nowrap"
    | "pre"
    | "pre-line"
    | "pre-wrap"
    | "break-spaces"
    | "initial"
    | "inherit";
  children: React.ReactNode;
}

const variantMapping: { [key in Variant]: keyof JSX.IntrinsicElements } = {
	h1: "h1",
	h2: "h2",
	h3: "h3",
	h4: "h4",
	h5: "h5",
	body1: "p",
	body2: "div",
	span: "span",
} as const;

const StyledTypography = styled.div<{
  color: string;
  fontSize: number | undefined;
  fontWeight: string | number;
  fontFamily: string;
  isInline: boolean;
  lineHeight: string | number;
  margin: string;
  textAlign: string;
  whiteSpace: string;
}>`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "inherit")};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-family: ${({ fontFamily }) => fontFamily};
  margin: ${({ margin }) => margin};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: ${({ textAlign }) => textAlign};
  white-space: ${({ whiteSpace }) => whiteSpace};

  ${({ isInline }) => isInline && "display: inline-block;"}
`;

const Typography: React.FC<TypographyProps> = ({
	variant,
	color = "white",
	fontFamily = "primary",
	fontSize,
	fontWeight = 700,
	isInline = false,
	lineHeight = "110%",
	margin = "0",
	textAlign = "inherit",
	whiteSpace = "normal",
	children,
	...props
}) => {
	const resolvedVariant = variant || "body1";

	return (
		<StyledTypography
			as={(variantMapping[resolvedVariant] as React.ElementType) || "p"}
			color={theme.colors[color] || theme.colors.white}
			fontFamily={theme.fonts[fontFamily]}
			fontSize={fontSize}
			fontWeight={fontWeight}
			isInline={isInline}
			lineHeight={lineHeight}
			margin={margin}
			textAlign={textAlign}
			whiteSpace={whiteSpace}
			{...props}
		>
			{children}
		</StyledTypography>
	);
};

export default Typography;
