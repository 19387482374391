import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import Button from "../../Buttons/Button";
import ButtonText from "../../Buttons/ButtonText";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import LoadingSpinner from "../../Animations/LoadingSpinner";
import RenewSubscriptionModal from "./RenewSubscriptionModal";
import SubscriptionModal from "./SubscriptionModal/SubscriptionModal";
import Typography from "../../Typography";
import { formatDate } from "../../../utils/formatDate";
import { formatPrice } from "../../../utils/formatPrice";
import { selectUser } from "../../../redux/reducers/userReducer";
import {
	useFetchSubscriptionPlansQuery,
	useFetchCurrentSubscriptionQuery
} from "../../../redux/features/subscriptionsApi";

const SubscriptionSectionContainer = styled.div`
  padding: 24px 0;
  margin-left: -32px;
  margin-right: -32px;
  box-sizing: border-box;
  min-height: 112px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 32px;

	@media (max-width: 992px) {
		flex-direction: column;
	}
`;

const LogoWrapper = styled.div`
	display: flex;
	gap: 8px;
`;

const Logo = styled.img`
  height: 22px;
`;

const BlueRoundedContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryCyan};
  color: white;
  border-radius: 4px;
  padding: 4px 8px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 14px;
  font-weight: 600;
`;

const SubscribeButton = styled(Button)`
	align-self: start;
	font-size: 16px;
	padding: 12px 32px;

	@media (max-width: 992px) {
		margin-top: 24px;
		margin-right: auto;
	}
`;

const CancelButton = styled(ButtonText)`
	font-family: ${({ theme }) => theme.fonts.body};
	font-size: 16px;
	margin-bottom: auto;

	@media (max-width: 992px) {
		margin-top: 24px;
		padding-left: 0;
		margin-right: auto;
	}
`;

const SubscriptionSection = () => {
	const user = useSelector(selectUser);
	const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
	const [isRenewModalOpen, setIsRenewModalOpen] = useState(false);
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

	const { data: currentSubscription, isLoading: loadingCurrent, refetch } = useFetchCurrentSubscriptionQuery();
	const { data: subscriptionPlans, isLoading: loadingPlans } = useFetchSubscriptionPlansQuery();

	useEffect(() => {
		refetch();
	}, []);

	if (!user || loadingCurrent || loadingPlans) return <LoadingSpinner />;
	if (!subscriptionPlans || !currentSubscription) return null;

	const noSubscription = currentSubscription?.message === "No active subscription found.";
	const subscriptionSetToCancel = currentSubscription?.cancel_at_period_end;
	const canceledSubscription = currentSubscription?.status === "canceled";

	const productPlan = subscriptionPlans.find(plan => plan.id === currentSubscription?.plan?.product);
	const currentPrice = formatPrice(currentSubscription?.plan?.amount || 0);

	return (
		<SubscriptionSectionContainer>
			{noSubscription && (
				<Typography
					fontFamily="body"
					fontSize={20}
					fontWeight={400}
					margin="12px 32px 32px"
				>
					You’re currently using the free version of Heart 2 Heart. Upgrade below for more features!
				</Typography>
			)}
			<Content>
				<div>
					{productPlan?.images[0] ? (
						<LogoWrapper>
							<Logo src={productPlan?.images[0]} alt={productPlan?.name} />
							{subscriptionSetToCancel && currentSubscription?.current_period_end && (
								<BlueRoundedContainer>
									Ending {formatDate(currentSubscription.current_period_end)}
								</BlueRoundedContainer>
							)}
						</LogoWrapper>
					) : (
						<Typography
							fontSize={20}
							fontWeight={700}
						>
							{productPlan?.name}
						</Typography>
					)}
					<Typography
						fontFamily="primary"
						fontSize={20}
						fontWeight={700}
						margin="12px 0 0"
					>
						{noSubscription ? "Heart 2 Heart Free Plan" : (
							canceledSubscription ? "Previous Monthly Subscription" : "Monthly Subscription"
						)}
					</Typography>
					<Typography
						fontFamily="body"
						fontSize={20}
						fontWeight={400}
						margin="4px 0 12px"
					>
						{`${currentPrice}/${currentSubscription?.plan?.interval || "month"}`}
					</Typography>
					{!canceledSubscription && currentSubscription?.current_period_end && (
						<Typography
							fontFamily="body"
							fontSize={16}
							fontWeight={400}
							margin="4px 0 0"
							color="secondaryLightGray"
						>
							{subscriptionSetToCancel ? (
								`Your plan will be available until ${formatDate(currentSubscription.current_period_end)}`
							) : (
								`Your plan will renew on ${new Date(currentSubscription?.current_period_end).toLocaleDateString()}`
							)}
						</Typography>
					)}
				</div>
				{(noSubscription || canceledSubscription) && subscriptionPlans ? (
					<SubscribeButton
						onClick={() => setIsUpgradeModalOpen(true)}
					>
						Upgrade Subscription
					</SubscribeButton>
				) : subscriptionSetToCancel ? (
					<SubscribeButton
						onClick={() => setIsRenewModalOpen(true)}
					>
						Renew Subscription
					</SubscribeButton>
				) : (
					<CancelButton
						onClick={() => setIsCancelModalOpen(true)}
						textColor="errorRed"
					>
						Cancel Subscription
					</CancelButton>
				)}
			</Content>
			<SubscriptionModal
				isOpen={isUpgradeModalOpen}
				setIsOpen={setIsUpgradeModalOpen}
				plan={subscriptionPlans[0] || {}}
				refetch={() => {}}
			/>
			<RenewSubscriptionModal
				isOpen={isRenewModalOpen}
				setIsOpen={setIsRenewModalOpen}
				refetch={() => {}}
			/>
			<CancelSubscriptionModal
				isOpen={isCancelModalOpen}
				setIsOpen={setIsCancelModalOpen}
				refetch={() => {}}
			/>
		</SubscriptionSectionContainer>
	);
};

export default SubscriptionSection;
