import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Background from "../components/Shared/Background";
import ButtonText from "../components/Buttons/ButtonText";
import Content from "../components/Onboarding/Content";
import Icon from "../components/Icon";
import Stepper from "../components/Shared/Stepper";
import { selectStep, setStep } from "../redux/reducers/onboardingReducer";
import { logoutUser } from "../redux/actions/userActions";

import type { AppDispatch } from "../redux/store";

const STEPS = [
	{ step: 1, label: "Info" },
	{ step: 2, label: "Gender" },
	{ step: 3, label: "Location" },
	{ step: 4, label: "Preferences" },
	{ step: 5, label: "Interests" },
	{ step: 6, label: "Headline" },
	{ step: 7, label: "Terms" },
];

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`;

const LogoutButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`;

const LogoutButton = styled(ButtonText)`
  &:hover {
    color: ${({ theme }) => theme.colors.primaryPink};
  }
`;

const LogoTitle = styled.div`
  display: flex;
  margin: 85px 0 36px;
`;

const ContentWrapper = styled.div<{ isSticky: boolean }>`
  transition: margin-top 0.5s ease;
  margin-top: ${({ isSticky }) => (isSticky ? "0" : "48px")};
  max-width: 724px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding: 0 24px;
`;

const OnboardingPage: React.FC<{ userStep: number }> = ({ userStep }) => {
	const dispatch: AppDispatch = useDispatch();
	const step = useSelector(selectStep);
	const defaultStep = userStep + 1;

	const [isSticky, setIsSticky] = useState(false);
	const logoRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsSticky(!entry.isIntersecting);
			},
			{ threshold: 0 }
		);

		if (logoRef.current) {
			observer.observe(logoRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, []);

	const setCurrentStep = (step: number) => dispatch(setStep(step));

	return (
		<PageContainer>
			<Background />
			<LogoutButtonWrapper>
				<LogoutButton onClick={() => dispatch(logoutUser())} textColor="lightGray">
          Log out
				</LogoutButton>
			</LogoutButtonWrapper>
			<LogoTitle ref={logoRef}>
				<Icon icon="logo" />
			</LogoTitle>
			{step <= 7 && (
				<>
					<Stepper
						isSticky={isSticky}
						steps={STEPS}
						currentStep={step}
						furthestStep={defaultStep}
						setCurrentStep={setCurrentStep}
					/>
					<ContentWrapper isSticky={isSticky}>
						<Content
							currentStep={step}
							isSticky={isSticky}
						/>
					</ContentWrapper>
				</>
			)}
		</PageContainer>
	);
};

export default OnboardingPage;
