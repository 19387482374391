import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Buttons/Button";
import ButtonText from "../Buttons/ButtonText";
import Typography from "../Typography";
import Link from "../../components/Links/Link";
import Checkbox from "../Inputs/Checkbox";
import { StepProps } from "../../types/Step";
import { useTheme } from "@emotion/react";
import useViewport from "../../hooks/ViewportProvider";
import { selectStep, setStep } from "../../redux/reducers/onboardingReducer";
import { selectUser } from "../../redux/reducers/userReducer";
import { updateUserData } from "../../redux/actions/userActions";
import type { AppDispatch } from "../../redux/store";

const Step7Container = styled.div``;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  width: 100%;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
`;

const MobileSubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  gap: 32px;
`;

const SubmitButton = styled(Button)<{ isMobile?: boolean; accepted?: boolean }>`
  ${({ isMobile }) => (isMobile ? "width: 100%;" : "margin-left: 24px;")};
  opacity: ${({ accepted }) => (!accepted ? 0.5 : 1)};
`;

const Step7: React.FC<StepProps> = () => {
  const user = useSelector(selectUser);
  const step = useSelector(selectStep);
  const dispatch: AppDispatch = useDispatch();
  const viewport = useViewport();
  const isMobile = viewport === "mobile";
  const currentStep = user?.onboarding_step || 0;
  const theme = useTheme();
  const [accepted, setAccepted] = useState(false);
  let newStep = 7;
  if (currentStep > 7) {
    newStep = currentStep;
  }

  const handleSubmit = async () => {
    if (!user || !user.id) return;
    const data = {
      onboarding_step: newStep,
    };

    dispatch(updateUserData({ data }))
      .unwrap()
      .then(() => {
        dispatch(setStep(step + 1));
      })
      .catch((error) => {
        console.error("Error updating user: ", error);
      });
  };

  return (
    <Step7Container>
      <Typography
        color="white"
        fontSize={20}
        fontWeight={700}
        variant="h2"
        margin={"0 0 22px 0"}
      >
        Agree to Terms and Conditions
      </Typography>
      <Typography
        color="lightGray"
        fontFamily="body"
        fontSize={18}
        fontWeight={400}
        margin={"0 0 22px 0"}
      >
        At Heart 2 Heart, we’re committed to creating a safe, fun, enjoyable,
        and inclusive experience for everyone. Before you start connecting with
        amazing people, please take a moment to agree to our{" "}
        <StyledLink color={theme.colors.primaryPink} to="/terms-of-service">
          Terms of Service.
        </StyledLink>
      </Typography>
      <Typography
        color="lightGray"
        fontFamily="body"
        fontSize={18}
        fontWeight={400}
        margin={"0 0 22px 0"}
      >
        By using our app, you agree to be respectful, follow community
        guidelines, and respect privacy and data policies. Once you agree, you
        can dive into a world of meaningful connections. If you have any
        questions or concerns, feel free to contact us at{" "}
        <StyledLink color={theme.colors.primaryPink} to="/contact">
          support@heart-2-heart.app
        </StyledLink>
      </Typography>
      <Checkbox
        label="By checking this box, I certify that I have read and accept the Terms of Service and that I am at least 18 years of age or older."
        value="male"
        checked={accepted}
        onChange={() => setAccepted(!accepted)}
      />
      {isMobile ? (
        <MobileSubmitButtonWrapper>
          <SubmitButton
            isMobile={isMobile}
            onClick={handleSubmit}
            accepted={accepted}
          >
            I Agree
          </SubmitButton>
          <ButtonText onClick={() => dispatch(setStep(6))}>
            <Typography color="primaryPink" fontFamily="body" fontWeight={500}>
              Go Back
            </Typography>
          </ButtonText>
        </MobileSubmitButtonWrapper>
      ) : (
        <SubmitButtonWrapper>
          <ButtonText onClick={() => dispatch(setStep(6))}>
            <Typography color="primaryPink" fontFamily="body" fontWeight={500}>
              Go Back
            </Typography>
          </ButtonText>
          <SubmitButton
            disabled={!accepted}
            onClick={handleSubmit}
            accepted={accepted}
          >
            I Agree
          </SubmitButton>
        </SubmitButtonWrapper>
      )}
    </Step7Container>
  );
};

export default Step7;
