import React from "react";
import styled from "@emotion/styled";
import ButtonText from "../../Buttons/ButtonText";
import LoadingSpinner from "../../Animations/LoadingSpinner";
import RadioButton from "../../Inputs/RadioButton";
import Typography from "../../Typography";
import { useFetchPaymentMethodsQuery } from "../../../redux/features/paymentMethodsApi";

const SavedMethodTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const SavedPaymentMethodButton = styled(ButtonText)<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 12px;
  padding: 8px 12px;
  ${({ isActive, theme }) => isActive && `border: 1px solid ${theme.colors.secondaryLightGray};`}
`;

interface SavedPaymentMethodsProps {
  activePaymentMethod: string | null;
  setActivePaymentMethod: React.Dispatch<React.SetStateAction<string | null>>;
}

const SavedPaymentMethods: React.FC<SavedPaymentMethodsProps> = ({
	activePaymentMethod,
	setActivePaymentMethod,
}) => {
	const { data: savedPaymentMethods, error, isLoading } = useFetchPaymentMethodsQuery();

	if (isLoading) return <LoadingSpinner />;
	if (error) return <div>Error loading payment methods</div>;

	return (
		<>
			{savedPaymentMethods && savedPaymentMethods?.length > 0 ? savedPaymentMethods.map(({ id, card }) => {
				const { brand, exp_month, exp_year, last4 } = card;
				const capitalizedBrand = brand.toUpperCase();
				const formattedExpYear = exp_year.toString().slice(-2);
				const isActive = activePaymentMethod === id;

				return (
					<SavedPaymentMethodButton
						key={id}
						isActive={isActive}
						onClick={() => setActivePaymentMethod(id)}
					>
						<SavedMethodTextWrapper>
							<Typography color="secondaryLightGray" fontFamily="body" fontSize={18} fontWeight={400}>
								{`${capitalizedBrand} ****${last4}`}
							</Typography>
							<Typography color="secondaryLightGray" fontFamily="body" fontSize={18} fontWeight={400}>
								{`Exp. ${exp_month}/${formattedExpYear}`}
							</Typography>
						</SavedMethodTextWrapper>
						<RadioButton checked={isActive} />
					</SavedPaymentMethodButton>
				);
			}) : (
				<Typography
					color="secondaryLightGray"
					fontFamily="body"
					fontWeight={400}
				>
					You have no saved payment methods. Click <ButtonText>here</ButtonText> to add one.
				</Typography>
			)}
		</>
	);
};

export default SavedPaymentMethods;
