import React, { useRef } from "react";
import styled from "@emotion/styled";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

export interface PopoverProps {
  content: React.ReactNode;
  trigger: React.ReactNode;
  placement?: "top" | "right" | "bottom" | "left" | "bottom-left" | "bottom-right";
  isOpen: boolean;
  onToggle: () => void;
	onClose?: () => void;
}

const PopoverContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ContentContainer = styled.div<{ placement: string }>`
  position: absolute;
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.containerBase};
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 6px;
  box-shadow: 0px 4px 30px 0px rgba(204, 28, 210, 0.5);
  width: 163px;

  ${({ placement }) => {
		switch (placement) {
		case "top":
			return "bottom: 100%; left: 50%; transform: translateX(-50%);";
		case "right":
			return "left: 100%; top: 50%; transform: translateY(-50%);";
		case "left":
			return "right: 100%; top: 50%; transform: translateY(-50%);";
		case "bottom-left":
			return "top: 100%; left: -25%; transform: translateX(-50%);";
		case "bottom-right":
			return "top: 100%; left: 90%; transform: translateX(-50%);";
		case "bottom":
		default:
			return "top: 100%; left: 50%; transform: translateX(-50%);";
		}
	}}
`;

const AnimationContainer = styled.div<{ isOpen: boolean }>`
	position: relative;
	z-index: 99;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.7);

  ${({ isOpen }) => isOpen &&  `
    animation: popoverbounce 0.2s forwards;
    visibility: visible;
  `}

  @keyframes popoverbounce {
    0% {
      transform: scale(0.7);
      opacity: 0;
    }
    60% {
      transform: scale(1.05);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const Popover: React.FC<PopoverProps> = ({
	content,
	trigger,
	placement = "bottom",
	isOpen,
	onToggle,
	onClose,
	...rest
}) => {
	const popoverRef = useRef(null);

	useOnClickOutside(popoverRef, () => {
		if (isOpen && onClose) onClose();
	});

	const handleClick = () => {
		onToggle();
	};

	return (
		<PopoverContainer ref={popoverRef} onClick={handleClick} {...rest}>
			{trigger}
			<AnimationContainer isOpen={isOpen}>
				<ContentContainer placement={placement}>
					{content}
				</ContentContainer>
			</AnimationContainer>
		</PopoverContainer>
	);
};

export default Popover;
