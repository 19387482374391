import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Buttons/Button";
import ButtonText from "../Buttons/ButtonText";
import TextArea from "../Inputs/TextArea";
import Typography from "../Typography";
import { StepProps } from "../../types/Step";
import useViewport from "../../hooks/ViewportProvider";
import { selectStep, setStep } from "../../redux/reducers/onboardingReducer";
import { selectUser } from "../../redux/reducers/userReducer";
import { updateUserData } from "../../redux/actions/userActions";
import type { AppDispatch } from "../../redux/store";

const Step6Container = styled.div``;

const TextAreaContainer = styled.div`
  margin-top: 48px;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  width: 100%;
`;

const MobileSubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  gap: 32px;
`;

const SubmitButton = styled(Button)<{ isMobile?: boolean }>`
  ${({ isMobile }) => isMobile ? "width: 100%;" : "margin-left: 24px;"};
`;

const Step6: React.FC<StepProps> = () => {
	const user = useSelector(selectUser);
	const step = useSelector(selectStep);
	const dispatch: AppDispatch = useDispatch();
	const viewport = useViewport();
	const isMobile = viewport === "mobile";
	const defaultHeadline = user?.headline;
	const [headline, setHeadline] = useState(defaultHeadline || "");
	const currentStep = user?.onboarding_step || 0;
	let newStep = 6;
	if (currentStep > 6) {
		newStep = currentStep;
	}

	const handleSubmit = async () => {
		if (!user || !user.id) return;

		const data = {
			headline: headline,
			onboarding_step: newStep,
		};

		dispatch(updateUserData({ data }))
			.unwrap()
			.then(() => {
				dispatch(setStep(step + 1));
			})
			.catch(error => {
				console.error("Error updating user: ", error);
			});
	};

	return (
		<Step6Container>
			<Typography
				color="white"
				fontSize={20}
				fontWeight={700}
				variant="h2"
			>
        Add a headline
			</Typography>
			<Typography 
				color="lightGray" 
				fontFamily="body" 
				fontSize={18}
				fontWeight={400}
				margin="4px 0 0 0"
			>
        Introduce yourself with a brief headline.
			</Typography>
			<TextAreaContainer>
				<TextArea
					maxLength={200}
					onChange={(e) => setHeadline(e.target.value)}
					rows={4}
					value={headline}
				/>
			</TextAreaContainer>
			{isMobile ? (
				<MobileSubmitButtonWrapper>
					<SubmitButton
						isMobile={isMobile}
						onClick={handleSubmit}
					>
						{(currentStep === 5 && !headline) ? "Skip" : "Continue"}
					</SubmitButton>
					<ButtonText onClick={() => dispatch(setStep(5))}>
						<Typography
							color="primaryPink"
							fontFamily="body"
							fontWeight={500}
						>
              Go Back
						</Typography>
					</ButtonText>
				</MobileSubmitButtonWrapper>
			) : (
				<SubmitButtonWrapper>
					<ButtonText onClick={() => dispatch(setStep(5))}>
						<Typography
							color="primaryPink"
							fontFamily="body"
							fontWeight={500}
						>
              Go Back
						</Typography>
					</ButtonText>
					<SubmitButton
						onClick={handleSubmit}
					>
						{(currentStep === 5 && !headline) ? "Skip" : "Continue"}
					</SubmitButton>
				</SubmitButtonWrapper>
			)}
		</Step6Container>
	);
};

export default Step6;