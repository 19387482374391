import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { selectUser } from "./redux/reducers/userReducer";
import AuthSuccess from "./utils/AuthSuccess";
import AboutPage from "./pages/AboutPage";
import ChatHistoryPage from "./pages/ChatHistoryPage";
import ComingSoonPage from "./pages/ComingSoonPage";
import CongratsPage from "./pages/CongratsPage";
import ContactCardPage from "./pages/ContactCardPage";
import ContactPage from "./pages/ContactPage";
import DashboardPage from "./pages/DashboardPage";
import FAQPage from "./pages/FAQPage";
import Header from "./components/Shared/Header";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import Navbar from "./components/Dashboard/Navbar";
import OnboardingPage from "./pages/OnboardingPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import SettingsPage from "./pages/SettingsPage";
import SignupPage from "./pages/SignupPage";
import TermsPage from "./pages/TermsPage";
import UnsubscribePage from "./pages/UnsubscribePage";
import VerifyPage from "./pages/VerifyPage";

interface HeaderComponentProps {
  isLoggedIn: boolean;
  onboarding_step?: number;
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

const StoriesPage = () => (
	<PageContainer>
		<h2>Stories</h2>
	</PageContainer>
);

const SupportPage = () => (
	<PageContainer>
		<h2>Support Page</h2>
	</PageContainer>
);

const DownloadPage = () => (
	<PageContainer>
		<h2>Download Page</h2>
	</PageContainer>
);

const authenticatedRoutes = [
	"settings",
	"chat-history",
	"contact-card",
	"congrats",
];

const unauthenticatedRoutes = ["login", "sign-up"];

const unauthenticatedRedirects = () => {
	return authenticatedRoutes.map((path) => (
		<Route key={path} path={path} element={<Navigate to="/login" />} />
	));
};

const authenticatedRedirects = () => {
	return unauthenticatedRoutes.map((path) => (
		<Route key={path} path={path} element={<Navigate to="/" />} />
	));
};

const HeaderComponent: React.FC<HeaderComponentProps> = ({
	isLoggedIn,
	onboarding_step,
}) => {
	const location = useLocation();
	const shouldNotRenderHeader = location.pathname.startsWith("/unsubscribe") ||
                                location.pathname.startsWith("/auth") ||
                                (onboarding_step !== undefined && onboarding_step < 7);
	if (shouldNotRenderHeader) {
		return null;
	}

	if (isLoggedIn && onboarding_step === 7) {
		return <Navbar />;
	}

	return <Header />;
};

const AppRouter = () => {
	const navigate = useNavigate();
	const user = useSelector(selectUser, shallowEqual);
	const [token, setToken] = useState<string | null>(localStorage.getItem("token"));

	useEffect(() => {
		if (token) {
			if (!user) {
				navigate("/auth/success");
			}
		}
	}, [token, user]);

	const isLoggedIn = !!token && !!user;
	const { onboarding_step } = user || {};
	const userStep = onboarding_step !== undefined ? onboarding_step : 0;

	{/* Authenticated Routes */}
	const AuthenticatedRoutes = (
		<div>
			<HeaderComponent isLoggedIn={true} onboarding_step={userStep} />
			<Routes>
				<Route
					path="/"
					element={
						onboarding_step === 7 ? (
							<DashboardPage />
						) : (
							<OnboardingPage userStep={userStep} />
						)
					}
				/>
				<Route path="/auth/success" element={<AuthSuccess setToken={setToken} />} />
				<Route path="/chat-history" element={<ChatHistoryPage />} />
				<Route path="/congrats" element={<CongratsPage />} />
				<Route path="/contact" element={<ContactPage />} />
				<Route path="/contact-card" element={<ContactCardPage />} />
				<Route path="/terms-of-service" element={<TermsPage />} />
				<Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
				<Route path="/settings" element={<SettingsPage />} />
				<Route
					path="/unsubscribe/:unsubscribeToken"
					element={<UnsubscribePage />}
				/>
				{authenticatedRedirects()}
			</Routes>
		</div>
	);

	{/* Unauthenticated Routes */}
	const UnauthenticatedRoutes = (
		<div>
			<HeaderComponent isLoggedIn={false} />
			<Routes>
				<Route path="/" element={<LandingPage />} />
				<Route path="/about" element={<AboutPage />} />
				<Route path="/coming-soon" element={<ComingSoonPage />} />
				<Route path="/contact" element={<ContactPage />} />
				<Route path="/stories" element={<StoriesPage />} />
				<Route path="/support" element={<SupportPage />} />
				<Route path="/download" element={<DownloadPage />} />
				<Route path="/faqs" element={<FAQPage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/sign-up" element={<SignupPage />} />
				<Route path="verify" element={<VerifyPage />} />
				<Route path="/terms-of-service" element={<TermsPage />} />
				<Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
				<Route path="/auth/success" element={<AuthSuccess setToken={setToken} />} />
				<Route
					path="/unsubscribe/:unsubscribeToken"
					element={<UnsubscribePage />}
				/>
				{unauthenticatedRedirects()}
			</Routes>
		</div>
	);

	return isLoggedIn ? AuthenticatedRoutes : UnauthenticatedRoutes;
};

export default AppRouter;
