import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Buttons/Button";
import ButtonText from "../Buttons/ButtonText";
import Pill from "../Inputs/Pill";
import { StepProps } from "../../types/Step";
import Typography from "../Typography";
import { selectStep, setStep } from "../../redux/reducers/onboardingReducer";
import { selectUser } from "../../redux/reducers/userReducer";
import { selectInterests } from "../../redux/reducers/interestsReducer";
import { fetchInterests } from "../../redux/actions/interestsActions";
import { updateUserData } from "../../redux/actions/userActions";
import type { AppDispatch } from "../../redux/store";

const Step5Container = styled.div`
  width: 100%;
`;

const InterestsWrapper = styled.div`
  position: relative;
`;

const PillGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 676px;
  width: 100%;
  margin: -4px;
`;

const InterestError = styled.div`
  position: absolute;
`;

const SubmitButtonWrapper = styled.div<{ isSticky?: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  padding: 36px 0 24px;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: linear-gradient(180deg, transparent, rgba(3, 5, 18, 0.8) 24px);
  transition: visibility 0s, opacity 0.5s linear;

  ${({ isSticky }) =>
		isSticky &&
    `
    visibility: visible;
    opacity: 1;
  `}
`;

const SubmitButton = styled(Button)<{ invalidQty: boolean }>`
  margin-left: 24px;
  transition: opacity 0.3s ease;

  opacity: ${({ invalidQty }) => (invalidQty ? 0.5 : 1)};
`;

const Step5: React.FC<StepProps> = ({ isSticky }) => {
	const user = useSelector(selectUser);
	const step = useSelector(selectStep);
	const categories = useSelector(selectInterests);
	const dispatch: AppDispatch = useDispatch();
	const [selectedInterests, setSelectedInterests] = useState<number[]>(
		user?.interests?.map(interest => interest.id) || []
	);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		dispatch(fetchInterests());
	}, []);

	const invalidQty = selectedInterests.length > 20 || selectedInterests.length < 5;

	const onPillClick = (interestId: number, selected: boolean) => {
		setSelectedInterests(prev => selected ? [...prev, interestId] : prev.filter(id => id !== interestId));
	};

	const currentStep = user?.onboarding_step || 0;
	let newStep = 5;
	if (currentStep > 5) {
		newStep = currentStep;
	}

	const handleSubmit = async () => {
		if (!user || !user.id) return;
		if (selectedInterests.length < 5) {
			setError("Please select at least 5 interests");
			return;
		} else if (selectedInterests.length > 20) {
			setError("Please select no more than 20 interests");
			return;
		}

		const data = {
			interest_ids: selectedInterests,
			onboarding_step: newStep,
		};

		dispatch(updateUserData({ data }))
			.unwrap()
			.then(() => {
				dispatch(setStep(step + 1));
			})
			.catch(error => {
				console.error("Error updating user: ", error);
			});
	};

	return (
		<Step5Container>
			<Typography color="white" fontSize={20} fontWeight={700} variant="h2">
        What are your interests?
			</Typography>
			<Typography
				color="lightGray"
				fontFamily="body"
				fontSize={18}
				fontWeight={400}
				margin="4px 0 0 0"
			>
        (Pick 5 to 20)
			</Typography>
			<InterestsWrapper>
				{categories.map((category) => (
					<div key={category.id}>
						<Typography
							fontFamily="body"
							fontSize={16}
							margin="32px 0 22px"
							variant="h3"
						>
							{category.name}
						</Typography>
						<PillGroup>
							{category.interests.map((interest) => (
								<Pill
									key={interest.id}
									label={interest.name}
									value={interest.id.toString()}
									selected={selectedInterests.includes(interest.id)}
									onClick={() => onPillClick(interest.id, !selectedInterests.includes(interest.id))}
									disabled={selectedInterests.length >= 20 && !selectedInterests.includes(interest.id)}
								/>
							))}
						</PillGroup>
					</div>
				))}
				<InterestError>
					{error && (
						<Typography
							color="errorRed"
							fontFamily="secondary"
							fontSize={14}
							fontWeight={400}
							margin="12px 0 0"
						>
							{error}
						</Typography>
					)}
				</InterestError>
			</InterestsWrapper>
			<SubmitButtonWrapper isSticky={isSticky}>
				<ButtonText onClick={() => dispatch(setStep(4))}>
					<Typography color="primaryPink" fontFamily="body" fontWeight={500}>
            Go Back
					</Typography>
				</ButtonText>
				<SubmitButton onClick={handleSubmit} invalidQty={invalidQty}>
          Continue {selectedInterests.length}/20
				</SubmitButton>
			</SubmitButtonWrapper>
		</Step5Container>
	);
};

export default Step5;
