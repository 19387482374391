import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import BikingImage from "../assets/images/biking-image.webp";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import Icon from "../components/Icon";
import Button from "../components/Buttons/Button";
import Link from "../components/Links/Link";
import Typography from "../components/Typography";
import TextArea from "../components/Inputs/TextArea";
import { useViewport } from "../hooks/ViewportProvider";

const UnsubscribeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledLink = styled(Link)`
  margin-bottom: 64px;
`;

const StyledImage = styled.img`
  height: 100vh;
  width: 50%;
  object-fit: cover;
`;

const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  box-sizing: border-box;
`;

const ConfirmationTitle = styled.h2`
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 12px;
`;

const ConfirmationSubtitle = styled.p<{
  isMobile: boolean;
}>`
  color: ${({ theme }) => `${theme.colors.white}70`};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 18px;
  font-weight: 400;
`;

const LeftHalf = styled.div<{ isMobile: boolean; viewport: any }>`
  display: flex;
  height: 100vh;
  flex-direction: column;
  box-sizing: border-box;
  width: ${({ isMobile }) => (isMobile ? "100%" : "50%")};
  padding: ${({ viewport }) =>
		viewport === "largeDesktop" ? "80px 112px" : "48px 24px"};
`;

const ButtonWrapper = styled.div<{
  isMobile: boolean;
  unsubscribeConfirmed: boolean;
}>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column-reverse" : "row")};
  align-items: center;
  justify-content: ${({ isMobile, unsubscribeConfirmed }) =>
		isMobile || !unsubscribeConfirmed ? "center" : "flex-end"};
  margin-top: 24px;
`;

const StyledButton = styled(Button)``;

const LegalLinkSection = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "flex-start")};
  margin-top: auto;
`;

const PinkText = styled.span`
  color: ${({ theme }) => theme.colors.primaryPink};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 400;
`;

const Divider = styled.span`
  color: ${({ theme }) => theme.colors.lightGray};
  margin: 0 8px;
`;

const OuterSubmittedContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const SubmittedContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 390px;
`;

const UnsubscribePage = () => {
	const viewport = useViewport();
	const { unsubscribeToken } = useParams();
	const isMobile = viewport === "mobile" || viewport === "tablet";
	const [unsubscribeConfirmed, setUnsubscribeConfirmed] = useState(false);
	const [formData, setFormData] = useState({
		message: "",
		token: unsubscribeToken,
	});
	const [formErrors, setFormErrors] = useState({});
	const [submitError, setSubmitError] = useState("");
	const [loading, setLoading] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const theme = useTheme();

	const confirmUnsubscribe = async () => {
		setLoading(true);
		try {
			await axios.get(
				`${process.env.REACT_APP_API_URL}/unsubscribe/${unsubscribeToken}`,
				{ withCredentials: true }
			);
			setLoading(false);
			setUnsubscribeConfirmed(true);
			localStorage.removeItem("hasSubscribed");
		} catch (error: any) {
			setLoading(false);
			setSubmitError(error.message);
			console.error("Error sending sending message: ", error);
		}
	};

	const handleSubmit = async () => {
		setSubmitError("");

		setLoading(true);
		try {
			await axios.post(
				`${process.env.REACT_APP_API_URL}/unsubscribe/feedback`,
				formData,
				{ withCredentials: true }
			);

			setLoading(false);
			setIsFormSubmitted(true);
		} catch (error: any) {
			setLoading(false);
			setSubmitError(error.message || "There was an issue unsubscribing.");
			console.error("Error unsubscribing: ", error);
		}
	};

	return (
		<UnsubscribeContainer>
			<LeftHalf viewport={viewport} isMobile={isMobile}>
				{unsubscribeConfirmed && !isFormSubmitted ? (
					<>
						<StyledLink icon="logo" iconPosition="left" to="/" />
						<Typography
							fontSize={isMobile ? 24 : 32}
							fontWeight={700}
							margin="0px 0px 16px 0px"
						>
              You’re Unsubscribed
						</Typography>
						<Typography
							color="secondaryLightGray"
							fontSize={16}
							fontWeight={400}
							fontFamily="body"
							lineHeight="22px"
							margin="0px 0px 24px 0px"
						>
              Remember, we’re like that old flame you can’t forget. Whenever
              you’re ready, we’ll be here, ready to rekindle the sparks. We’re
              cheering you on in your quest for love, wherever it takes you.
						</Typography>
						<Typography
							color="secondaryLightGray"
							fontSize={16}
							fontWeight={400}
							fontFamily="body"
							lineHeight="22px"
							margin={
								isMobile || viewport === "laptop"
									? "0px 0px 40px 0px"
									: "0px 0px 64px 0px"
							}
						>
              If you ever have a change of heart, don’t be shy – our support
              team is just an email away at{" "}
							<Link color={theme.colors.primaryPink} to="/contact">
                support@heart-to-heart.app
							</Link>
						</Typography>
						<Typography
							fontSize={isMobile ? 20 : 24}
							fontWeight={700}
							margin="0px 0px 16px 0px"
						>
              Feedback (optional)
						</Typography>
						<Typography
							color="secondaryLightGray"
							fontSize={16}
							fontWeight={400}
							fontFamily="body"
							lineHeight="22px"
							margin="0px 0px 16px 0px"
						>
              We’re always up for a bit of self-improvement. If you’ve got a
              minute, we’d love to hear why you’re saying goodbye or any ideas
              to make your experience better.
						</Typography>
						<TextArea
							error={submitError}
							maxLength={500}
							onChange={(e) => {
								setFormData({ ...formData, message: e.target.value });
								setFormErrors({ ...formErrors, message: undefined });
								setSubmitError("");
							}}
							placeholder="Message"
							rows={5}
							value={formData.message}
						/>
						<ButtonWrapper
							isMobile={isMobile}
							unsubscribeConfirmed={unsubscribeConfirmed}
						>
							<StyledButton
								loading={loading}
								onClick={handleSubmit}
								fullWidth={viewport === "mobile"}
							>
                Send Message
							</StyledButton>
						</ButtonWrapper>
						<LegalLinkSection isMobile={isMobile}>
							<Link color={theme.colors.primaryPink} to="/contact">
								<PinkText>Contact Us</PinkText>
							</Link>
							<Divider>|</Divider>
							<Link color={theme.colors.primaryPink} to="/privacy-policy">
								<PinkText>Privacy Policy</PinkText>
							</Link>
						</LegalLinkSection>
					</>
				) : isFormSubmitted && unsubscribeConfirmed ? (
					<>
						<ConfirmationContainer>
							<Icon icon="complete-check" color="accentCyan" />
							<ConfirmationTitle>Message Sent</ConfirmationTitle>
							<ConfirmationSubtitle isMobile={isMobile}>
                Thank you, your feedback is valuable to us.
							</ConfirmationSubtitle>
						</ConfirmationContainer>
					</>
				) : (
					<>
						<StyledLink icon="logo" iconPosition="left" to="/" />
						<OuterSubmittedContainer>
							<SubmittedContainer>
								<Typography
									fontSize={32}
									fontWeight={700}
									margin="0px 0px 16px 0px"
								>
                  Sorry to see you go.
								</Typography>
								<Typography
									color="secondaryLightGray"
									fontSize={16}
									fontWeight={400}
									fontFamily="body"
									lineHeight="22px"
									margin="0px 0px 24px 0px"
								>
                  If you’re absolutely sure you want to part ways, no hard
                  feelings – well, maybe a little heartache. Just click the
                  button below to confirm.
								</Typography>
								<ButtonWrapper
									isMobile={isMobile}
									unsubscribeConfirmed={unsubscribeConfirmed}
								>
									<StyledButton
										loading={loading}
										onClick={() => confirmUnsubscribe()}
										fullWidth={viewport === "mobile"}
									>
                    Confirm
									</StyledButton>
								</ButtonWrapper>
							</SubmittedContainer>
						</OuterSubmittedContainer>
						<LegalLinkSection isMobile={isMobile}>
							<Link color={theme.colors.primaryPink} to="/contact">
								<PinkText>Contact Us</PinkText>
							</Link>
							<Divider>|</Divider>
							<Link color={theme.colors.primaryPink} to="/privacy-policy">
								<PinkText>Privacy Policy</PinkText>
							</Link>
						</LegalLinkSection>
					</>
				)}
			</LeftHalf>
			{!isMobile && <StyledImage src={BikingImage} />}
		</UnsubscribeContainer>
	);
};

export default UnsubscribePage;
