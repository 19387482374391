import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Category } from "../../types/Interest";

interface InterestsState {
  categories: Category[];
  error: string | null;
  isLoading: boolean;
}

const initialState: InterestsState = {
	categories: [],
	error: null,
	isLoading: false
};

export const interestsSlice = createSlice({
	name: "interests",
	initialState,
	reducers: {
		setInterests: (state, action: PayloadAction<Category[]>) => {
			state.categories = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setError: (state, action: PayloadAction<string | null>) => {
			state.error = action.payload;
		}
	}
});

export const { setInterests, setLoading, setError } = interestsSlice.actions;

export const selectInterests = (state: RootState) => state.interests.categories;
export const selectLoading = (state: RootState) => state.interests.isLoading;
export const selectError = (state: RootState) => state.interests.error;

export default interestsSlice.reducer;
