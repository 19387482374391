import React from "react";
import styled from "@emotion/styled";
import ScrollFadeIn from "../Animations/ScrollFadeIn";
import AiImage from "../../assets/images/ai-image1.webp";
import { useViewport } from "../../hooks/ViewportProvider";

const ThirdSectionContainer = styled.div<{ viewport: any }>`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport === "largeDesktop" ? "row" : "column"};
  padding: ${({ viewport }) =>
    viewport === "mobile" || viewport === "tablet" ? "32px" : "112px"};
  position: relative;
  margin-top: 64px;
  justify-content: space-between; // ensures the children are spread out
`;

const getAspectRatio = (viewport: any) => {
  switch (viewport) {
    case "desktop":
      return 22 / 9;
    case "laptop":
      return 25 / 13;
    case "tablet":
      return 16 / 9;
    case "largeDesktop":
    case "mobile":
    default:
      return 1 / 1;
  }
};

const StyledImage = styled.img<{ viewport: any }>`
  align-self: stretch;
  border-radius: 12px;
  width: 100%;
  aspect-ratio: ${({ viewport }) => getAspectRatio(viewport)};
  margin-right: ${({ viewport }) => (viewport === "largeDesktop" ? "60px" : 0)};
  margin-bottom: ${({ viewport }) =>
    viewport === "largeDesktop"
      ? 0
      : viewport === "laptop" || viewport === "desktop"
      ? "48px"
      : "24px"};
  object-fit: cover;
`;

const TextSection = styled.div<{ viewport: any }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-left: ${({ viewport }) => (viewport === "largeDesktop" ? "60px" : 0)};
`;

const Headline = styled.h3`
  color: ${({ theme }) => theme.colors.primaryPink};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 12px;
  white-space: nowrap;
`;

const SubHeadline = styled.h2<{ viewport: any }>`
  font-size: ${({ viewport }) =>
    viewport === "mobile" || viewport === "tablet" ? "24px" : "32px"};
  font-weight: 500;
  margin: 0;
`;

const BodyText = styled.p`
  color: ${(props) => props.theme.colors.lightGray};
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 16px;
  font-weight: 400;
  margin: 12px 0 24px;
`;

const Glow = styled.div`
  border-radius: 300px;
  opacity: 0.8;
  background: ${({ theme }) =>
    `radial-gradient(circle, ${theme.colors.accentCyan}60, ${theme.colors.accentCyan}25 25%, transparent 70%)`};
  position: absolute;
  top: 50%;
  left: 75%;
  width: calc(min(85vw, 85vh));
  height: calc(min(85vw, 85vh));
  transform: translate(-50%, -50%);
  filter: blur(50px);
  z-index: -2;
`;

const ThirdSection = () => {
  const viewport = useViewport();
  const isMobile = viewport === "mobile";
  return (
    <ThirdSectionContainer viewport={viewport}>
      <Glow />
      <ScrollFadeIn
        delay={0}
        direction="left"
        disableOnMobile={isMobile}
        once
        width="100%"
      >
        <StyledImage
          src={AiImage}
          alt="Woman looking at man with futuristic background"
          viewport={viewport}
        />
      </ScrollFadeIn>
      <ScrollFadeIn
        delay={200}
        direction="right"
        disableOnMobile={isMobile}
        once
        width="100%"
      >
        <TextSection viewport={viewport}>
          <Headline>WHY WE’RE DIFFERENT</Headline>
          <SubHeadline viewport={viewport}>
            Get to know your match before you see their face.
          </SubHeadline>
          <BodyText>
            Our cutting-edge dating app is transforming the quest for love. Gone
            are the days of swiping and boring text exchanges. Through our
            innovative voice-first approach, we focus on transcending
            surface-level judgments and forming genuine connections. Take your
            search for your perfect match to the next level with us.
          </BodyText>
        </TextSection>
      </ScrollFadeIn>
    </ThirdSectionContainer>
  );
};

export default ThirdSection;
