import React from "react";
import styled from "@emotion/styled";
import Icon from "../Icon";
import Button from "../Buttons/Button";
import Typography from "../Typography";
import theme from "../../theme";
import useViewport from "../../hooks/ViewportProvider";

const AuthButtonGroup = styled.div<{ isMobile?: boolean }>`
  display: flex;
  margin: 48px 0;

  ${({ isMobile }) =>
		isMobile &&
    `
    flex-direction: column;
    align-items: center;
    width: 100%;
  `}
`;

const StyledButton = styled(Button)<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  ${({ isMobile }) =>
		isMobile
			? `
    width: 100%;
    margin-bottom: 16px;
  `
			: `
    &:nth-of-type(-n + 2) {
      margin-right: 16px;
    }
  `}
`;

const socialLoginButtons = [
	{ icon: "apple-logo", text: "Apple", urlSuffix: "" },
	{ icon: "google-logo", text: "Google", urlSuffix: "_oauth2" },
	{ icon: "facebook-logo", text: "Facebook", urlSuffix: "" },
];

const AuthButtons = () => {
	const viewport = useViewport();
	const isMobile = viewport === "mobile";

	const handleRedirect = (provider: string, suffix: string) => {
		window.location.href = `${process.env.REACT_APP_API_URL}/auth/${provider}${suffix}`;
	};

	return (
		<AuthButtonGroup isMobile={isMobile}>
			{socialLoginButtons.map((btn, index) => (
				<StyledButton
					key={index}
					backgroundColor={theme.colors.white}
					borderColor={theme.colors.white}
					isMobile={isMobile}
					onClick={() => handleRedirect(btn.text.toLowerCase(), btn.urlSuffix)}
					textColor={theme.colors.black}
				>
					<Icon icon={btn.icon} />
					<Typography
						variant="body1"
						color="black"
						fontFamily="body"
						fontSize={16}
						fontWeight={500}
						margin="0 0 0 4px"
					>
						{btn.text}
					</Typography>
				</StyledButton>
			))}
		</AuthButtonGroup>
	);
};

export default AuthButtons;
