import React from "react";
import styled from "@emotion/styled";
import Typography from "../components/Typography";
import Footer from "../components/Shared/Footer";
import { useViewport } from "../hooks/ViewportProvider";

const PageContainer = styled.div<{ isMobile: boolean; isLaptop: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: ${({ isMobile, isLaptop }) =>
    isMobile ? "0px 24px 112px 24px" : isLaptop ? "0px 64px" : "0px 112px"};
  max-width: 1200px;
`;

const StyledUL = styled.ul`
  padding-left: 20px;
  margin: 0;
  list-style-position: outside;
  text-align: start;
`;

const StyledTypography = styled(Typography)`
  vertical-align: top;
`;

const StyledLI = styled.li``;

const TermsPage = () => {
  const viewport = useViewport();
  const isMobile = viewport === "mobile" || viewport === "tablet";
  const isLaptop = viewport === "laptop";
  return (
    <PageContainer isMobile={isMobile} isLaptop={isLaptop}>
      <Typography fontSize={32} fontWeight={500} margin={"0px 0px 0p 20px"}>
        Heart 2 Heart Terms & Conditions
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        OVERVIEW
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        Welcome to “Heart 2 Heart” a platform designed to facilitate meaningful
        connections and relationships. These Terms of Service (“TOS”) govern
        your use of our online dating platform. By accessing or using Heart 2
        Heart, you agree to abide by these terms and our Privacy Policy.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        Our mission is to prioritize authentic conversations and connections
        over traditional swiping and superficial interactions. These TOS outline
        the rules, guidelines, and responsibilities that govern your use of our
        platform.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        Please take the time to read these TOS carefully. They cover important
        aspects of your interaction with Heart 2 Heart, including user
        eligibility, account management, content guidelines, privacy practices,
        and more. Your continued use of our platform signifies your consent to
        these terms.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        We are committed to protecting your privacy and data security.
        Therefore, these TOS also reference our Privacy Policy, which explains
        how we collect, use, and protect your personal information.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        If you have any questions or concerns about these TOS or any other
        aspect of Heart 2 Heart, please feel free to contact us. We are
        dedicated to providing a safe and enjoyable experience for all our
        users.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        Thank you for choosing Heart 2 Heart as your platform for building
        meaningful connections. We look forward to being part of your journey
        towards genuine relationships.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        DEFINITIONS
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        “Heart 2 Heart”, “we”, “us”, or “our” refer to Heart 2 Heart, the
        operator of this dating platform.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        “App” refers to the Heart 2 Heart mobile application and its associated
        services.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        “User”, “you”, or “your” pertains to individuals who access and use the
        Heart 2 Heart app.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        “Terms” refers to these Terms of Service.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        “Privacy Policy” designates our policy regarding the collection and use
        of user information, available separately on our app.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        “Digital Contact Card” refers to the unique feature allowing users to
        customize and share contact information with their matches.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        “Chat Rooms” are virtual spaces within the app where users engage in
        voice and video conversations.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        “Code of Conduct” outlines expected behavior, including respect,
        non-abuse, and no sharing of explicit content.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        “Dispute Resolution” outlines the process for addressing issues and
        contacting support.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        ACCEPTANCE OF TERMS
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        By accessing or using the “Heart 2 Heart” platform, including its
        website and mobile application (collectively referred to as the
        “Service”), you agree to be bound by these Terms of Service (“TOS”). If
        you do not agree to these terms, please refrain from using our Service.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        Your use of the Service constitutes your understanding and acceptance of
        these TOS, which form a legally binding agreement between you and “Heart
        to Heart.” These terms govern your use of the Service, including any
        updates, new features, and additional services that may be added over
        time.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        You acknowledge that these TOS may be updated from time to time, and it
        is your responsibility to review them periodically. Your continued use
        of the Service after any modifications to the TOS indicates your
        acceptance of the updated terms. If you do not agree with the revised
        terms, please discontinue your use of the Service.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        If you are using the Service on behalf of an organization or entity, you
        affirm that you have the authority to bind that organization or entity
        to these TOS, and “you” in these terms will refer to both you
        individually and the organization or entity you represent.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        By agreeing to these TOS, you also agree to adhere to our Privacy
        Policy, which outlines how we collect, use, and protect your personal
        information. If you do not agree to the terms of our Privacy Policy,
        please do not use the Service.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        APP FUNCTIONALITY AND FEATURES
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        We want you to get the most out of “Heart 2 Heart.” This section
        highlights what you can expect and enjoy while using our app, all
        designed with your experience in mind.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          User Profiles:
        </Typography>{" "}
        Create your personalized user profile to showcase your personality.
        Share information such as interests, location, gender, and more to
        provide insights about yourself to other users.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Matching Algorithm:
        </Typography>{" "}
        Our advanced matching algorithm uses the data you provide to connect you
        with potential matches who align with your interests, passions, values,
        and preferences.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          One-on-One Chat Rooms:
        </Typography>{" "}
        Engage in real, authentic conversations through our one-on-one chat
        rooms. Utilize both voice and video communication options to connect on
        a deeper level.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Share your Contact Information:
        </Typography>{" "}
        Share your contact information with our Digital Contact Card feature to
        stay in touch with users outside the app.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Unique Features:
        </Typography>{" "}
        “Heart 2 Heart” is committed to innovation. Look out for special
        features and updates that make our app stand out.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        USER REGISTRATION AND ONBOARDING
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        This clause outlines how users can create an account on “Heart to
        Heart.” Users can sign up using their Google, Facebook, or Apple
        credentials for convenience. During registration, users consent to the
        platform accessing basic profile information. To enhance their
        experience, users are encouraged to share their interests and
        preferences, which helps the platform connect them with like-minded
        individuals.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Account Creation:
        </Typography>{" "}
        To access certain features of the “Heart 2 Heart” platform, you may
        choose to create a user account using your Google, Facebook, or Apple
        credentials. By agreeing to sign up with your Facebook, Google, or Apple
        ID, you also hereby agree to be bound by their respective terms of
        service. Your use of these sign-up options implies your acceptance of
        our Terms of Service.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Authentication:
        </Typography>{" "}
        When using the sign-up options via Google, Facebook, or Apple, you will
        be asked to grant necessary permissions and provide consent to access
        your account information, such as your email address, phone number, and
        basic profile information. We will only collect the information
        necessary to create and enhance your experience on our platform.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Onboarding Process:
        </Typography>{" "}
        As part of the onboarding process, we may ask you to provide additional
        information, including your interests, passions, values, location
        preferences, and age preferences. You agree to provide accurate and
        truthful information during this process.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Matching Algorithm:
        </Typography>{" "}
        We use the information provided during the onboarding process to improve
        your experience on our platform. Our matching algorithm uses this data
        to pair you with other users who share similar interests and values.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Accuracy of Information:
        </Typography>{" "}
        You acknowledge that the accuracy of the information you provide during
        the onboarding process is crucial for the effectiveness of our platform.
        Any inaccurate or misleading information may result in a less
        satisfactory user experience and, in some cases, account suspension or
        termination.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Third-Party Account Connection:
        </Typography>{" "}
        If you choose to connect your “Heart 2 Heart” account to a third-party
        service (e.g., Google, Facebook, or Apple), you are responsible for
        complying with the terms and policies of that third-party service.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Account Deactivation:
        </Typography>{" "}
        You have the option to deactivate your account at any time. Deactivating
        your account will remove your profile and associated information from
        public view, but we may retain certain data for legal, analytical, and
        operational purposes in accordance with our Privacy Policy.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Termination of Accounts:
        </Typography>{" "}
        “Heart 2 Heart” reserves the right to suspend or terminate user accounts
        that violate these Terms of Service or engage in prohibited conduct, as
        outlined in this agreement.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        USER RESPONSIBILITIES
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        This clause outlines the expectations and conduct we require from users
        to maintain a respectful, safe, and positive environment within the
        “Heart 2 Heart” community. By using the Heart 2 Heart app, you
        acknowledge and agree to these user responsibilities. Responsible
        conduct and adherence to our guidelines contribute to the creation of a
        secure and enjoyable Heart 2 Heart community for all users.
      </Typography>
      <Typography
        color="white"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        At “Heart 2 Heart”, we believe that building meaningful connections is a
        shared responsibility. To ensure a positive and respectful environment
        for all users, we have outlined the following responsibilities for our
        users:
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Compliance with Terms:
        </Typography>{" "}
        By using the Heart 2 Heart app, you agree to comply with these Terms of
        Service, the Code of Conduct, and all applicable laws and regulations.
        Failure to do so may result in account suspension or termination.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Account Deactivation:
        </Typography>{" "}
        You have the option to deactivate your Heart 2 Heart account at any
        time. Deactivation will suspend your account and remove your profile
        from the app. Your information may be retained for a certain period as
        described in our Privacy Policy.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Account Security:
        </Typography>{" "}
        It is your responsibility to maintain the security of your Heart to
        Heart account. Safeguard your login credentials, such as your email
        address and password, or the login method associated with your chosen
        sign-up option (e.g., Google, Facebook, Apple ID, or phone number). Do
        not share your login credentials with others.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Account Termination:
        </Typography>{" "}
        Heart 2 Heart reserves the right to suspend or terminate accounts that
        violate these Terms of Service, the Code of Conduct, or any applicable
        laws and regulations. Account termination may occur in response to
        violations, reports of abusive behavior, or other breaches of our
        policies.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Accuracy of Information:
        </Typography>{" "}
        When providing information during registration or while using the app’s
        features, you are responsible for ensuring the accuracy and truthfulness
        of the information you share. Misrepresentation of your identity or
        intentions is not permitted.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Age Verification:
        </Typography>{" "}
        By creating a Heart 2 Heart account, you confirm that you are of legal
        age to use the app in your jurisdiction. Users under the age of 18 may
        not use the Heart 2 Heart app.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Compliance with Laws:
        </Typography>{" "}
        Ensure that your use of the Heart 2 Heart app complies with all
        applicable local, state, and national laws and regulations.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Data Portability:
        </Typography>{" "}
        If you choose to deactivate your account, you may have the option to
        request a copy of your data in accordance with our data retention
        policies.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Feedback:
        </Typography>{" "}
        We value user feedback and suggestions for improving the app and user
        experience. Please use the designated channels to provide feedback
        constructively and help us enhance Heart 2 Heart for all users.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Lost or Stolen Credentials:
        </Typography>{" "}
        If you suspect that your Heart 2 Heart account credentials have been
        lost, stolen, or compromised, please notify us immediately to prevent
        unauthorized access.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          No Explicit Content:
        </Typography>{" "}
        Users must refrain from sharing explicit, offensive, or inappropriate
        content. Explicit, innapropriate, or offensive content such as nudity,
        violence, gore, etc, is strictly prohibited within the app.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Reporting Violations:
        </Typography>{" "}
        If you come across users who violate our Code of Conduct or these Terms
        of Service, please report them to us promptly. Your feedback is crucial
        in maintaining a safe and enjoyable community.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Respect for Privacy:
        </Typography>{" "}
        You are responsible for respecting the privacy of others. Do not share
        personal information, including contact details, without the explicit
        consent of the other party.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Respectful Behavior:
        </Typography>{" "}
        As a user, it is your responsibility to maintain a respectful and
        courteous demeanor when interacting with others on the Heart 2 Heart
        platform. Harassment, bullying, or any form of abusive behavior will not
        be tolerated.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Safety:
        </Typography>{" "}
        While Heart 2 Heart aims to provide a safe environment, you are
        ultimately responsible for your safety when using the app. Exercise
        caution when sharing personal information, including contact details,
        with other users.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        PRIVACY AND DATA PROTECTION
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        This clause emphasizes Heart 2 Heart’s commitment to user privacy and
        data protection and It explains how we collect, use, and protect your
        information. By using the Heart 2 Heart app, you acknowledge and agree
        to the terms and practices outlined in this Privacy and Data Protection
        clause, as well as the policies described in our Privacy Policy.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          User Data Collection:
        </Typography>{" "}
        Heart 2 Heart is committed to safeguarding user privacy. During the
        registration process and while using the app’s features, we may collect
        and store certain information, including but not limited to:
      </Typography>
      <StyledUL>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Personal Information:
            </StyledTypography>{" "}
            This may include your email address, phone number, birthday, first
            name, location, gender, sexual orientation, and dating goals.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Optional Information:
            </StyledTypography>{" "}
            You have the choice to provide additional, optional information such
            as politics, religion, interests, and more.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Digital Contact Card:
            </StyledTypography>{" "}
            Users can create and share digital contact cards, which may include
            optional information such as email, phone number, full name, social
            media links, star sign, and other details chosen by the user.
          </StyledTypography>
        </StyledLI>
      </StyledUL>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Data Usage:
        </Typography>{" "}
        The information collected is used for the following purposes:
      </Typography>
      <StyledUL>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              User Profile:
            </StyledTypography>{" "}
            Your information is used to create and personalize your user
            profile, enhancing your experience on the Heart 2 Heart app.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 48px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Matching:
            </StyledTypography>{" "}
            We use the information provided during registration to match you
            with other users who share your preferences and interests,
            facilitating meaningful connections.
          </StyledTypography>
        </StyledLI>
        <StyledLI>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            isInline
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            <StyledTypography
              fontSize={16}
              fontWeight={400}
              fontFamily="body"
              isInline
              lineHeight="22px"
            >
              Digital Contact Card:
            </StyledTypography>{" "}
            Users can customize and share digital contact cards with their
            matches, allowing for easy post-chat communication if desired.
          </StyledTypography>
        </StyledLI>
      </StyledUL>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Data Protection:
        </Typography>{" "}
        Heart 2 Heart employs industry-standard security measures to protect
        user data. However, no method of data transmission over the internet is
        completely secure, and we cannot guarantee absolute security. Users are
        encouraged to exercise caution and ensure responsible handling of
        personal information.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Third-Party Services:
        </Typography>{" "}
        Heart 2 Heart may integrate with third-party services such as Google,
        Facebook, and Apple for account sign-up and login. Please review the
        privacy policies of these services for information on their data
        collection and usage practices.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Sharing Data:
        </Typography>{" "}
        Heart 2 Heart will never share your personal information with third
        parties without your explicit consent. However, users may choose to
        share their information through the digital contact card feature with
        their matches. This sharing is optional and at the discretion of the
        user.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Data Retention:
        </Typography>{" "}
        Heart 2 Heart retains user data for as long as necessary to provide the
        app’s services and as required by applicable laws. Users who deactivate
        their accounts may request data portability or the deletion of their
        data in accordance with our data retention policies.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Email List:
        </Typography>{" "}
        Heart 2 Heart may maintain an email list for users to sign up for
        updates and feedback requests. This email list is strictly for keeping
        in touch and gathering feedback from users during the app’s development
        stages.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Children’s Privacy:
        </Typography>{" "}
        Heart 2 Heart is not intended for users under the age of 18. We do not
        knowingly collect or store information from users under this age. If we
        become aware of such information, we will take steps to delete it
        promptly.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Privacy Policy:
        </Typography>{" "}
        For more detailed information on how Heart 2 Heart collects, uses, and
        protects user data, please refer to our Privacy Policy, which is an
        integral part of these Terms of Service.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        INTELLECTUAL PROPERTY RIGHTS
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        The “Intellectual Property Rights” clause aims to clarify the ownership
        of intellectual property within the app, the rights users grant to the
        platform regarding their user-generated content, and the restrictions on
        unauthorized use.
      </Typography>
      <Typography
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        By using the Heart 2 Heart app, users acknowledge and agree to respect
        the intellectual property rights of the app and other users. Heart to
        Heart respects the intellectual property rights of all parties and will
        take appropriate action in response to reports of infringement.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Ownership:
        </Typography>{" "}
        Heart 2 Heart (referred to as “the App” or “we”) retains all
        intellectual property rights associated with the app, including but not
        limited to trademarks, logos, software, design elements, and proprietary
        technology. Users acknowledge that the app is protected by copyright,
        trademark, and other laws.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Use of the App:
        </Typography>{" "}
        By using the Heart 2 Heart app, users are granted a limited,
        non-exclusive, non-transferable, and revocable license to access and use
        the app for personal, non-commercial purposes. This license does not
        grant any rights to modify, distribute, reproduce, publicly display, or
        create derivative works from the app or its contents.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          User-Generated Content:
        </Typography>{" "}
        Users retain intellectual property rights to any content they create and
        share on the app, including but not limited to user profiles, messages,
        digital contact cards, and other user-generated content. By sharing
        content on the app, users grant Heart 2 Heart a non-exclusive,
        worldwide, royalty-free license to use, display, and distribute their
        content within the app’s functionality.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Prohibited Use:
        </Typography>{" "}
        Users are prohibited from engaging in any activity that may infringe
        upon the intellectual property rights of the app or any third party.
        This includes, but is not limited to, copying, reverse engineering, or
        attempting to extract the app’s source code.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Reporting Intellectual Property Infringement:
        </Typography>{" "}
        Heart 2 Heart respects the intellectual property rights of others. If
        you believe that your intellectual property rights have been infringed
        upon by a user on the app, please notify us promptly by providing
        detailed information to support@heart-2-heart.app. We will investigate
        and take appropriate action as required by law..
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Feedback and Suggestions:
        </Typography>{" "}
        We welcome user feedback and suggestions for improving the app. By
        providing feedback, you acknowledge that Heart 2 Heart may use and
        incorporate your ideas without any obligation, including any
        intellectual property rights associated with such feedback.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Third-Party Rights:
        </Typography>{" "}
        Users are expected to respect the intellectual property rights of third
        parties when using the app. Do not share or distribute copyrighted
        material or infringe upon trademarks or other proprietary rights in your
        interactions on the app.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Termination of Access:
        </Typography>{" "}
        Heart 2 Heart reserves the right to suspend or terminate access to the
        app for users who engage in intellectual property infringement or other
        violations of these Terms of Service.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        ACCOUNT TERMINATION AND SUSPENSION
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        This clause outlines the conditions and procedures related to the
        termination or suspension of user accounts on the Heart 2 Heart app. By
        using the Heart 2 Heart app, users acknowledge and agree to these
        account termination and suspension provisions. Heart 2 Heart reserves
        the right to take action to maintain a safe and enjoyable community for
        all users.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Right to Terminate or Suspend:
        </Typography>{" "}
        Heart 2 Heart (referred to as “the App” or “we”) reserves the right to,
        at our sole discretion, terminate or suspend user accounts for any
        reason, including but not limited to violations of these Terms of
        Service, the Code of Conduct, or any applicable laws and regulations.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Notice of Termination or Suspension:
        </Typography>{" "}
        In the event of account termination or suspension, we will make
        reasonable efforts to provide notice to the affected user via the email
        address associated with their account. However, we reserve the right to
        terminate or suspend accounts without notice when such actions are
        deemed necessary for the safety and security of the app’s community or
        to comply with legal obligations.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Consequences of Termination:
        </Typography>{" "}
        Upon account termination, the user will lose access to the Heart to
        Heart app, including their user profile and chat history. Any remaining
        subscriptions or in-app purchases may be forfeited, and the user may
        lose access to any content or data associated with their account.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Appeals and Dispute Resolution:
        </Typography>{" "}
        Users who believe their account was terminated or suspended in error or
        wish to appeal the decision may contact our support team at
        support@heart-2-heart.app. We will review the appeal and take
        appropriate action as warranted.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          No Obligation to Restore Account:
        </Typography>{" "}
        Heart 2 Heart is not obligated to restore or reactivate terminated or
        suspended accounts. Decisions regarding account reinstatement are made
        at our sole discretion.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Account Security:
        </Typography>{" "}
        It is the user’s responsibility to maintain the security of their Heart
        to Heart account. Users must safeguard their login credentials, such as
        email and password, and report any suspected unauthorized access
        promptly.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Effect of Termination on User Content:
        </Typography>{" "}
        Upon account termination, users may lose access to user-generated
        content, including messages, digital contact cards, and other data
        associated with their account. Users are encouraged to back up any
        content they wish to retain before requesting account termination.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Data Retention:
        </Typography>{" "}
        Heart 2 Heart may retain certain user data, including but not limited to
        registration information and chat history, in accordance with our data
        retention policies and legal obligations.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        DISPUTE RESOLUTION
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        This clause outlines how disputes related to the Heart 2 Heart app will
        be resolved. By using the Heart 2 Heart app, users acknowledge and agree
        to the terms of this Dispute Resolution clause, which outlines the
        process for resolving disputes through arbitration rather than
        traditional litigation. Users also have the option to opt out of
        arbitration within the specified time frame.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Informal Resolution:
        </Typography>{" "}
        Heart 2 Heart encourages users to attempt to resolve any disputes,
        concerns, or issues related to the app by contacting our support team at
        support@heart-2-heart.app. We are committed to addressing user concerns
        promptly and amicably.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Arbitration Agreement:
        </Typography>{" "}
        In the event that an informal resolution is not possible or
        satisfactory, users and Heart 2 Heart agree to resolve any disputes,
        claims, or controversies arising from or related to these Terms of
        Service, the app, or any interactions therein through binding
        arbitration. This agreement to arbitrate waives the right to pursue
        claims through traditional litigation or class action lawsuits.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Arbitration Process:
        </Typography>{" "}
        Arbitration shall be conducted in accordance with the rules and
        procedures of a recognized arbitration organization chosen by mutual
        agreement. The arbitration shall take place in a location mutually
        agreed upon or as determined by the chosen arbitration organization. The
        decision of the arbitrator(s) will be final and binding.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Exception:
        </Typography>{" "}
        Users and Heart 2 Heart agree that the arbitration requirement does not
        apply to disputes or claims related to intellectual property rights,
        which may be resolved through legal action in a court of competent
        jurisdiction.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Class Action Waiver:
        </Typography>{" "}
        Users and Heart 2 Heart agree that any disputes shall be arbitrated on
        an individual basis, and users waive the right to participate in any
        class actions, class arbitrations, or consolidated claims.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Governing Law:
        </Typography>{" "}
        These Terms of Service and any disputes arising from or related to them
        shall be governed by and interpreted in accordance with the laws of the
        State of California, United States, without regard to its conflict of
        law principles.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Opt-Out:
        </Typography>{" "}
        Users have the option to opt out of the arbitration agreement by
        providing written notice to Heart 2 Heart within 30 days of accepting
        these Terms of Service. Opting out will not affect the user’s other
        rights and obligations under these Terms.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Severability:
        </Typography>{" "}
        If any portion of this Dispute Resolution clause is found to be
        unenforceable or unlawful, the remaining provisions shall remain in full
        force and effect.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        UPDATES AND CHANGES
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        This clause informs users about the possibility of updates and changes
        to the Heart 2 Heart app and its Terms of Service. By using the Heart to
        Heart app, users acknowledge and agree to the potential for updates and
        changes to these Terms of Service. It is the user’s responsibility to
        stay informed about any modifications or updates by regularly reviewing
        the Terms.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Modification of Terms:
        </Typography>{" "}
        Heart 2 Heart reserves the right to modify, amend, or update these Terms
        of Service at any time. Users will be notified of such changes through
        the app or via email. It is the user’s responsibility to review and
        understand the revised terms. Continued use of the app following any
        updates constitutes acceptance of the modified Terms.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          App Updates:
        </Typography>{" "}
        Heart 2 Heart may release periodic updates to the app to improve
        functionality, security, and user experience. Users are encouraged to
        keep their app version current by installing updates from the respective
        app stores.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Service Interruptions:
        </Typography>{" "}
        Heart 2 Heart may need to temporarily interrupt or suspend app services
        for maintenance, updates, or other operational reasons. Users will be
        notified of scheduled service interruptions whenever possible.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Notification of Changes:
        </Typography>{" "}
        Users are encouraged to ensure that their contact information, including
        email addresses, is accurate and up to date to receive notifications of
        important changes and updates.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          User Feedback:
        </Typography>{" "}
        Heart 2 Heart values user feedback and suggestions for app improvements.
        Users are encouraged to provide feedback and suggestions through the app
        or by contacting our support team.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Termination of Services:
        </Typography>{" "}
        Heart 2 Heart reserves the right to terminate or discontinue any or all
        app services, features, or functionalities at any time, with or without
        notice.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 48px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Legal Compliance:
        </Typography>{" "}
        Any changes to these Terms of Service will be made in compliance with
        applicable laws and regulations.
      </Typography>
      <Typography fontSize={20} fontWeight={500} margin="0px 0px 20px 0px">
        CONTACT INFORMATION
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        This clause provides users with essential contact information for
        communicating with Heart 2 Heart. By using the Heart 2 Heart app, users
        acknowledge and agree to the contact information provided in this clause
        for various types of communication, including support, feedback, and
        legal notices.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Support and Communication:
        </Typography>{" "}
        For inquiries, support, or communication regarding the Heart 2 Heart app
        or these Terms of Service, users can contact us via email at
        support@heart-2-heart.app.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Feedback and Suggestions:
        </Typography>{" "}
        We welcome user feedback and suggestions for app improvement. Users can
        provide feedback through the app or by contacting our support team via
        email.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Legal Notices:
        </Typography>{" "}
        Legal notices, including those related to disputes, should be sent using
        the contact form page on the Heart 2 Heart app’s website. Legal notices
        sent through other means may not be considered valid.
      </Typography>
      <Typography
        color="secondaryLightGray"
        fontSize={16}
        fontWeight={400}
        fontFamily="body"
        isInline
        lineHeight="22px"
        margin="0px 0px 20px 0px"
      >
        <Typography
          fontSize={16}
          fontWeight={400}
          fontFamily="body"
          isInline
          lineHeight="22px"
        >
          Response Time:
        </Typography>{" "}
        While we strive to respond to user inquiries and support requests
        promptly, response times may vary depending on the nature and volume of
        requests. Users are encouraged to provide detailed information when
        contacting us for more efficient assistance.
      </Typography>
      <Footer isVisible />
    </PageContainer>
  );
};

export default TermsPage;
