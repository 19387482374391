import React, { useEffect, useState, useMemo } from "react";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../components/Icon";
import AccountTab from "../components/Settings/Account/AccountTab";
import AppearanceTab from "../components/Settings/AppearanceTab";
import NotificationTab from "../components/Settings/NotificationTab";
import PreferencesTab from "../components/Settings/PreferencesTab";
import ProfileTab from "../components/Settings/ProfileTab";
import Typography from "../components/Typography";
import useViewport from "../hooks/ViewportProvider";

const TABS = [
	{ title: "My Account", tab: "account", component: AccountTab },
	{ title: "Edit Profile", tab: "profile", component: ProfileTab },
	{ title: "Dating Preferences", tab: "preferences", component: PreferencesTab },
	{ title: "Notifications", tab: "notifications", component: NotificationTab },
	{ title: "Appearance", tab: "appearance", component: AppearanceTab },
];

const SettingsContainer = styled.div`
  display: flex;
`;

const SettingsPanel = styled.div`
  padding: 40px 32px;
  height: calc(100vh - 150px);
`;

const PanelHeader = styled.h2<{ isMobile: boolean }>`
  width: 294px;
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 20px;
  ${({ isMobile }) => isMobile && "padding: 24px 24px 0;"}
`;

const PanelTab = styled.div<{ isActive?: boolean, isMobile?: boolean }>`
  width: ${({ isMobile }) => isMobile ? "calc(100vw - 40px)" : "254px"};
  padding: 20px;
  color: ${({ theme }) => theme.colors.secondaryLightGray};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryPink};
  }

  ${({ isActive, theme }) => isActive && `
    background: ${theme.colors.white}20;
    color: ${theme.colors.white}
  `}
`;

const StyledIcon = styled(Icon)`
  transform: scale(0.8334) rotate(270deg);
`;

const SettingsContent = styled.div`
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.borderGray};
`;

const BackToSettingsButton = styled.button`
  width: 100%;
  border: none;
  outline: none;
  margin: 0;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.containerBase};
  cursor: pointer;
  text-align: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.primaryPink};
    }
  }
`;

const getTabFromURL = (search: string): string | null => {
	const params = new URLSearchParams(search);
	return params.get("tab");
};

const SettingsPage = () => {
	const viewport = useViewport();
	const isMobile = viewport === "mobile" || viewport === "tablet";
	const navigate = useNavigate();
	const location = useLocation();
	const [activeTab, setActiveTab] = useState(getTabFromURL(location.search) || (isMobile ? null : "account"));

	useEffect(() => {
		setActiveTab(getTabFromURL(location.search) || (isMobile ? null : "account"));
	}, [location.search]);

	const handleTabChange = (tab: string) => {
		navigate(`/settings?tab=${tab}`);
	};

	const ActiveTabComponent = useMemo(() => {
		const Component = TABS.find(tab => tab.tab === activeTab)?.component;
		return Component ? <Component /> : null;
	}, [activeTab]);

	const renderPanelContent = () => {
		return (
			<>
				<PanelHeader isMobile={isMobile}>
          Settings
				</PanelHeader>
				{TABS.map((tab, idx) => {
					const isActive = tab.tab === activeTab;
					return (
						<PanelTab
							isActive={isActive}
							isMobile={isMobile}
							key={`${tab}-${idx}`} 
							onClick={() => handleTabChange(tab.tab)}
						>
							{tab.title}
							<StyledIcon
								color={isActive ? "white" : "primaryPink"}
								icon="caret-down"
							/>
						</PanelTab>
					);
				})}
			</>
		);
	};

	const backToSettings = () => {
		const params = new URLSearchParams(location.search);
		if (params.get("preference")) {
			navigate("/settings?tab=preferences");
		} else {
			navigate("/settings");
		}
	};

	if (isMobile) {
		return (
			<>
				{ActiveTabComponent ? (
					<>
						<BackToSettingsButton onClick={backToSettings}>
							<Icon icon="arrow-left" />
							<Typography
								fontFamily="body"
								fontSize={16}
								fontWeight={500}
								margin="0 0 0 4px"
							>
                Back
							</Typography>
						</BackToSettingsButton>
						{ActiveTabComponent}
					</>
				) : (
					renderPanelContent()
				)}
			</>
		);
	}

	return (
		<SettingsContainer>
			<SettingsPanel>
				{renderPanelContent()}
			</SettingsPanel>
			<SettingsContent>
				{ActiveTabComponent}
			</SettingsContent>
		</SettingsContainer>
	);
};

export default SettingsPage;