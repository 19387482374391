import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Subscription, ProductWithPrice } from "../../types/stripeTypes";

export const subscriptionsApi = createApi({
	reducerPath: "subscriptionsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_URL,
		credentials: "include",
		prepareHeaders: (headers) => {
			const token = localStorage.getItem("token");
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ["Subscription"],
	endpoints: (builder) => ({
		fetchSubscriptionPlans: builder.query<ProductWithPrice[], void>({
			query: () => "/subscriptions",
		}),
		fetchCurrentSubscription: builder.query<Subscription | null, void>({
			query: () => "/subscription",
			providesTags: ["Subscription"],
		}),
		upgradeSubscription: builder.mutation<Subscription, { plan_id: string, payment_method?: string, payment_intent_id?: string }>({
			query: (body) => ({
				url: "/subscription/upgrade",
				method: "POST",
				body,
			}),
			invalidatesTags: ["Subscription"],
		}),
		renewSubscription: builder.mutation<Subscription, void>({
			query: () => ({
				url: "/subscription/renew",
				method: "POST",
			}),
			invalidatesTags: ["Subscription"],
		}),
		cancelSubscription: builder.mutation<{ status: string }, void>({
			query: () => ({
				url: "/subscription/cancel",
				method: "DELETE",
			}),
			invalidatesTags: ["Subscription"],
		}),
	}),
});

export const {
	useFetchSubscriptionPlansQuery,
	useFetchCurrentSubscriptionQuery,
	useUpgradeSubscriptionMutation,
	useRenewSubscriptionMutation,
	useCancelSubscriptionMutation,
} = subscriptionsApi;