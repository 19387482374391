import React from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Preference1 from "./Preferences/Preference1";
import Preference2 from "./Preferences/Preference2";
import Preference3 from "./Preferences/Preference3";
import Preference4 from "./Preferences/Preference4";
import { StepProps } from "../../types/Step";
import { selectSubStep, setSubStep, setStep } from "../../redux/reducers/onboardingReducer";

const Step4Container = styled.div``;

const Step4: React.FC<StepProps> = () => {
	const dispatch = useDispatch();
	const subStep = useSelector(selectSubStep);

	let preferenceComponent;
	switch (subStep) {
	case 1:
		preferenceComponent = (
			<Preference1 
				next={() => dispatch(setSubStep(2))}
				back={() => dispatch(setStep(3))}
			/>
		);
		break;
	case 2:
		preferenceComponent = (
			<Preference2 
				next={() => dispatch(setSubStep(3))}
				back={() => dispatch(setSubStep(1))}
			/>
		);
		break;
	case 3:
		preferenceComponent = (
			<Preference3
				next={() => dispatch(setSubStep(4))}
				back={() => dispatch(setSubStep(2))}
			/>
		);
		break;
	case 4:
		preferenceComponent = (
			<Preference4
				next={() => dispatch(setStep(5))}
				back={() => dispatch(setSubStep(3))}
			/>
		);
		break;
	default:
		preferenceComponent = (
			<Preference1 
				next={() => dispatch(setSubStep(2))}
				back={() => dispatch(setStep(3))}
			/>
		);
		break;
	}

	return (
		<Step4Container>
			{preferenceComponent}
		</Step4Container>
	);
};

export default Step4;