import React from "react";
import styled from "@emotion/styled";
import Icon from "../Icon";
import Typography from "../Typography";
import Countdown from "../Timers/Countdown";
import prepurchase from "../../assets/images/prepurchaseBackground.webp";
import { useViewport } from "../../hooks/ViewportProvider";

const launchDate = "2024-12-31";

const PrepurchaseContainer = styled.div<{ isMobile: boolean }>`
  height: auto;
`;

const CountdownContainer = styled.div`
  margin-bottom: 32px;
`;

const MobileContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  margin-bottom: 32px;
`;

const Background = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ isMobile }) => (isMobile ? "24px" : 0)};
  background-image: url(${prepurchase});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${({ isMobile }) => (isMobile ? "contain" : "cover")};
  width: 100vw; /* Add a width property */
  height: 100vh;
  max-height: 100%;
`;

const StyledTypography = styled(Typography)`
  text-align: center;
  line-height: 120%;
`;

const CrownContainer = styled.div`
  width: 80px;
  height: 80px;
  background-color: rgba(31, 185, 250, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const CongratsContainer = styled.div<{
  viewport: any;
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(28, 30, 42, 0.8);
  border: 1px solid ${({ theme }) => theme.colors.white}70;
  border-radius: 12px;
  box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
    0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  max-width: ${({ viewport, isMobile }) =>
		viewport === "laptop" || viewport === "tablet" || isMobile
			? "calc(100vw - 48px)"
			: "800px"};
  padding: 64px;
  width: 100%;
  box-sizing: border-box;
  margin: ${({ viewport }) => (viewport === "laptop" ? "0px 24px" : 0)};
`;

const CountdownSection = () => {
	const viewport = useViewport();
	const isMobile = viewport === "mobile" || viewport === "tablet";

	return (
		<PrepurchaseContainer isMobile={isMobile}>
			{!isMobile ? (
				<Background isMobile={isMobile}>
					<CongratsContainer isMobile={isMobile} viewport={viewport}>
						<CrownContainer>
							<Icon color="accentCyan" icon="crown" />
						</CrownContainer>
						<StyledTypography
							fontSize={48}
							fontWeight={700}
							color="white"
							margin={"0px 0px 12px 0px"}
							variant="h1"
						>
              Heart 2 Heart is Coming!
						</StyledTypography>
						<StyledTypography
							fontSize={40}
							fontWeight={700}
							color="primaryPink"
							margin={"0px 0px 32px 0px"}
							variant="h2"
						>
              Join the Voice Revolution
						</StyledTypography>
						<CountdownContainer>
							<Countdown
								targetDate={launchDate}
								fontSize={64}
								colonSpacing="48px"
							/>
						</CountdownContainer>
						{/* <StyledTypography fontSize={20} fontWeight={700} color="white">
              Experience the leading voice dating app designed to foster genuine
              connections and meaningful relationships in a secure and engaging
              environment.
						</StyledTypography> */}
					</CongratsContainer>
				</Background>
			) : (
				<>
					<Background isMobile={isMobile} />
					<MobileContentContainer>
						<CrownContainer>
							<Icon color="accentCyan" icon="crown" />
						</CrownContainer>
						<StyledTypography
							fontSize={32}
							fontWeight={700}
							color="white"
							margin={"0px 0px 12px 0px"}
						>
              Heart 2 Heart is Coming!
						</StyledTypography>
						<StyledTypography
							fontSize={24}
							fontWeight={700}
							color="primaryPink"
							margin={"0px 0px 32px 0px"}
						>
              Join the Voice Revolution
						</StyledTypography>
						<CountdownContainer>
							<Countdown
								targetDate={launchDate}
								fontSize={32}
								colonSpacing="16px"
							/>
						</CountdownContainer>
						{/* <StyledTypography
							fontSize={16}
							fontWeight={700}
							color="white"
							margin={"0px 0px 32px 0px"}
						>
              Sign up and subscribe to our premium plan to lock in a discounted
              rate on Heart 2 Heart, plus exclusive benefits!
						</StyledTypography> */}
					</MobileContentContainer>
				</>
			)}
		</PrepurchaseContainer>
	);
};

export default CountdownSection;
