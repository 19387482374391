import React, { useState } from "react";
import styled from "@emotion/styled";
import Modal from "../../../Shared/Modal";
import SubscriptionStepOne from "./SubscriptionStepOne";
import SubscriptionStepTwo from "./SubscriptionStepTwo";
import { SubscriptionPlan } from "../../../../types/stripeTypes";

interface SubscriptionModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  plan: SubscriptionPlan;
  refetch: () => void;
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
	isOpen,
	setIsOpen,
	plan,
	refetch,
}) => {
	const [step, setStep] = useState(1);

	const handleNextStep = () => setStep(2);
	const handlePreviousStep = () => setStep(1);

	return (
		<Modal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			content={
				<ModalContainer>
					{step === 1 ? (
						<SubscriptionStepOne plan={plan} onNext={handleNextStep} />
					) : (
						<SubscriptionStepTwo plan={plan} onBack={handlePreviousStep} refetch={refetch} setIsOpen={setIsOpen} />
					)}
				</ModalContainer>
			}
		/>
	);
};

export default SubscriptionModal;
