import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import GenderDropdown from "../Shared/GenderDropdown";
import GradientHeart from "../Shared/GradientHeart";
import { useViewport } from "../../hooks/ViewportProvider";

const FirstSectionContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: ${({ isMobile }) => (isMobile ? "0" : "60px")};
  margin-right: 32px;
  margin-left: 32px;
  position: relative;
  height: ${({ isMobile }) => (isMobile ? "calc(100vh - 42px)" : "100vh")};
`;

const ContentContainer = styled.div<{ isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-left: 16px;

  ${({ isMobile }) =>
    !isMobile &&
    `
    transform: translateY(-50%);
  `}
`;

const Headline = styled.h1<{ isMobile: boolean; viewport: string }>`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: ${({ isMobile, viewport }) =>
    isMobile ? "40px" : viewport === "laptop" ? "48px" : "64px"};
  font-weight: 700;
  margin: 0;
  text-align: center;
`;

const HighlightedText = styled.span`
  color: ${({ theme }) => theme.colors.accentViolet};
  text-align: center;
`;

const SubHeadline = styled.h2<{ isMobile: boolean }>`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: ${({ isMobile }) => (isMobile ? "16px" : "20px")};
  font-weight: 400;
  margin: 20px 0 48px;
  text-align: center;
`;

const FirstHeart = styled.div`
  position: absolute;
  top: 5%;
  left: 10%;
`;

const SecondHeart = styled.div`
  position: absolute;
  top: 10%;
  right: 12%;
`;

const ThirdHeart = styled.div`
  position: absolute;
  bottom: 40%;
  left: 5%;
`;

const FourthHeart = styled.div`
  position: absolute;
  bottom: 30%;
  right: 10%;
`;

const FifthHeart = styled.div`
  position: absolute;
  bottom: 8%;
  left: 20%;
`;

const SixthHeart = styled.div`
  position: absolute;
  bottom: 3%;
  right: 15%;
`;

const FirstSection = () => {
  const navigate = useNavigate();
  const viewport = useViewport();
  const isMobile = viewport === "mobile" || viewport === "tablet";

  return (
    <FirstSectionContainer isMobile={isMobile}>
      <FirstHeart>
        <GradientHeart scale={1.2} />
      </FirstHeart>
      <SecondHeart>
        <GradientHeart scale={2.7} />
      </SecondHeart>
      {!isMobile && (
        <ThirdHeart>
          <GradientHeart scale={2} />
        </ThirdHeart>
      )}
      {!isMobile && (
        <FourthHeart>
          <GradientHeart scale={1.3} />
        </FourthHeart>
      )}
      <FifthHeart>
        <GradientHeart scale={1} />
      </FifthHeart>
      <SixthHeart>
        <GradientHeart scale={2} />
      </SixthHeart>
      <ContentContainer isMobile={isMobile}>
        <Headline isMobile={isMobile} viewport={viewport}>
          Welcome to <HighlightedText>Heart 2 Heart</HighlightedText>
        </Headline>
        <SubHeadline isMobile={isMobile}>
          The world's first voice-only dating app. No more swiping: real
          conversations, real connections.
        </SubHeadline>
        <GenderDropdown navigate={navigate} />
      </ContentContainer>
    </FirstSectionContainer>
  );
};

export default FirstSection;
