import React from "react";
import styled from "@emotion/styled";
import Footer from "../components/Shared/Footer";
import AboutUsImage from "../assets/images/aboutus.webp";
import AboutUsImageMobile from "../assets/images/aboutus-mobile.webp";
import Typography from "../components/Typography";
import { useViewport } from "../hooks/ViewportProvider";

const Container = styled.div<{ isMobile: boolean; isLaptop: boolean }>`
  background: radial-gradient(
    circle at 100% 30%,
    ${({ theme }) => `${theme.colors.accentCyan}25`},
    transparent 50%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: ${({ isMobile }) => (isMobile ? "77px" : "94.5px")};
`;

const PageContainer = styled.div<{
  isMobile: boolean;
  isLaptop: boolean;
  viewport: any;
}>`
  display: flex;
  flex-direction: ${({ isMobile, isLaptop }) =>
    isMobile || isLaptop ? "column" : "row"};
  padding: ${({ isMobile, isLaptop, viewport }) =>
    isMobile || isLaptop
      ? "0px"
      : viewport === "desktop"
      ? "0 24px 24px 0px"
      : "0 112px 112px 0px"};
`;

const ImageContainer = styled.div<{
  isMobile: boolean;
  viewport: any;
  isLaptop: boolean;
}>`
  margin-right: ${({ isMobile, isLaptop, viewport }) =>
    isMobile || isLaptop ? 0 : viewport === "desktop" ? "48px" : "96px"};
`;

const StyledImage = styled.img<{ isMobile: boolean; isLaptop: boolean }>`
  aspect-radio: ${({ isMobile, isLaptop }) =>
    isMobile || isLaptop ? "80/63" : "168/269"};
  width: ${({ isMobile, isLaptop }) =>
    isMobile || isLaptop ? "100%" : "auto"};
  height: auto;
`;

const TextContainer = styled.div<{ isMobile: boolean; isLaptop: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: ${({ isMobile, isLaptop }) =>
    (isMobile || isLaptop) && "0px 24px 48px 24px"};
`;

const StyledTypography = styled(Typography)`
  vertical-align: top;
`;

const StyledUL = styled.ul`
  padding-left: 20px;
  margin: 0;
  list-style-position: outside;
  text-align: start;
`;

const StyledLI = styled.li``;

const AboutPage = () => {
  const viewport = useViewport();
  const isMobile = viewport === "mobile" || viewport === "tablet";
  const isLaptop = viewport === "laptop";

  return (
    <Container isMobile={isMobile} isLaptop={isLaptop}>
      <PageContainer
        isMobile={isMobile}
        viewport={viewport}
        isLaptop={isLaptop}
      >
        <ImageContainer
          isMobile={isMobile}
          isLaptop={isLaptop}
          viewport={viewport}
        >
          <StyledImage
            isMobile={isMobile}
            isLaptop={isLaptop}
            src={isMobile || isLaptop ? AboutUsImageMobile : AboutUsImage}
            alt="Heart 2 Heart man and woman holding hands"
          />
        </ImageContainer>
        <TextContainer isMobile={isMobile} isLaptop={isLaptop}>
          <StyledTypography
            fontSize={32}
            fontWeight={500}
            margin={"20px 0px 20px"}
            variant="h2"
          >
            Discover Authentic Relationships with Heart 2 Heart
          </StyledTypography>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            In a digital era ruled by instant choices, Heart 2 Heart stands
            apart. We’ve moved beyond the superficial and crafted a space for
            profound interactions, heralding a fresh approach to online dating.
          </StyledTypography>
          <StyledTypography
            fontSize={20}
            fontWeight={500}
            margin="0px 0px 20px 0px"
            variant="h3"
          >
            Why Choose Heart 2 Heart?
          </StyledTypography>
          <StyledUL>
            <StyledLI>
              <StyledTypography
                color="secondaryLightGray"
                fontSize={16}
                fontWeight={400}
                fontFamily="body"
                isInline
                lineHeight="22px"
                margin="0px 0px 20px 0px"
              >
                <StyledTypography
                  fontSize={16}
                  fontWeight={400}
                  fontFamily="body"
                  isInline
                  lineHeight="22px"
                  variant="span"
                >
                  Depth over Surface:
                </StyledTypography>{" "}
                Dive into curated chatrooms that emphasize mutual passions and
                thought-provoking prompts. By initially concealing physical
                appearances, we prioritize the essence of communication,
                breathing life into online interactions.
              </StyledTypography>
            </StyledLI>
            <StyledLI>
              <StyledTypography
                color="secondaryLightGray"
                fontSize={16}
                fontWeight={400}
                fontFamily="body"
                isInline
                lineHeight="22px"
                margin="0px 0px 20px 0px"
              >
                <StyledTypography
                  fontSize={16}
                  fontWeight={400}
                  fontFamily="body"
                  isInline
                  lineHeight="22px"
                  variant="span"
                >
                  Voice the Connection:
                </StyledTypography>{" "}
                Experience more than just texts and icons. Listen to the joy,
                the giggles, and the genuine emotions in each other’s tones.
                With us, communication regains its vibrancy, bridging hearts and
                minds.
              </StyledTypography>
            </StyledLI>
            <StyledLI>
              <StyledTypography
                color="secondaryLightGray"
                fontSize={16}
                fontWeight={400}
                fontFamily="body"
                isInline
                lineHeight="22px"
                margin="0px 0px 20px 0px"
              >
                <StyledTypography
                  fontSize={16}
                  fontWeight={400}
                  fontFamily="body"
                  isInline
                  lineHeight="22px"
                  variant="span"
                >
                  Precision Matches:
                </StyledTypography>{" "}
                Here, you are unique. Our advanced algorithm considers your
                interests, locale, and preferences to pair you with your ideal
                match. With us, every conversation has potential.
              </StyledTypography>
            </StyledLI>
          </StyledUL>
          <StyledTypography
            fontSize={20}
            fontWeight={500}
            margin="0px 0px 20px 0px"
            variant="h3"
          >
            Why now?
          </StyledTypography>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            lineHeight="22px"
            margin="0px 0px 20px 0px"
          >
            In the vast world of dating apps, we are a beacon of change.
            Recognizing the gaps in today’s digital love scene, we’ve
            meticulously crafted a solution that places you at the forefront. At
            Heart 2 Heart, our commitment is to elevate your dating journey,
            making it not just memorable but truly special.
          </StyledTypography>
          <StyledTypography
            color="secondaryLightGray"
            fontSize={16}
            fontWeight={400}
            fontFamily="body"
            lineHeight="22px"
            margin="0px 0px 42px 0px"
          >
            Join us on this exciting journey, as we carve out a new path in the
            world of dating. Let Heart 2 Heart be your guide as you navigate
            this transformative chapter in your life.
          </StyledTypography>
        </TextContainer>
      </PageContainer>
      <Footer isVisible={false} />
    </Container>
  );
};

export default AboutPage;
