import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { User } from "../../types/User";

interface UserState {
  user: User | null;
	userInterests: string[] | []
  error: string | null;
  isLoading: boolean;
}

const initialState: UserState = {
	user: null,
	userInterests: [],
	error: null,
	isLoading: false
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User | null>) => {
			state.user = action.payload;
		},
		setUserInterests: (state, action: PayloadAction<string[] | []>) => {
			state.userInterests = action.payload;
		},
		setError: (state, action: PayloadAction<string | null>) => {
			state.error = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		}
	},
});

export const { setUser, setUserInterests, setError, setLoading } = userSlice.actions;
export const selectUser = (state: RootState) => state.user.user;
export const selectError = (state: RootState) => state.user.error;
export const selectIsLoading = (state: RootState) => state.user.isLoading;

export default userSlice.reducer;
