import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useDispatch } from "react-redux";
import axiosInstance from "../../utils/axiosInstance";
import Button from "../Buttons/Button";
import Icon from "../Icon";
import LoadingSpinner from "../Animations/LoadingSpinner";
import Slider from "./Slider";
import DefaultAvatar from "../../assets/images/avatar.png";
import { setUser } from "../../redux/reducers/userReducer";
import { User } from "../../types/User";
import { AppDispatch } from "../../redux/store";

type ReactCropperElement = HTMLImageElement & {
  getCroppedCanvas(options?: any): HTMLCanvasElement;
};

const Avatar = styled.div<{ img: string, isLoading?: boolean }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  ${({ img }) => img ? 
		`background-image: url(${img});`
		:
		`background-image: url(${DefaultAvatar});`
}
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;

  ${({ isLoading }) => isLoading ? "filter: blur(2px);" : ""}

`;

const AvatarOverlay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;

  svg {
    height: 100%;
  }

  &:hover {
    opacity: 1;
  }
`;

const UploadButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primaryPink};
  position: absolute;
  bottom: 15px;
  right: 15px;
  transform: translate(50%, 50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Overlay = styled.div<{ isModalOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1005;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.22s;

  ${({ isModalOpen }) => isModalOpen &&  `
    visibility: visible;
    opacity: 1;
  `}
`;

const ImageModal = styled.div<{ isModalOpen: boolean }>`
  position: fixed;
  z-index: 1006;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.containerBase};
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 12px;
  max-width: 600px;
  max-height: 600px;
  padding: 40px 20px;

  img {
    max-width: 600px;
    max-height: 540px;
  }

  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.7);

  @keyframes modalbounce {
    0% {
      transform: translate(-50%, -50%) scale(0.7);
      opacity: 0;
    }
    60% {
      transform: translate(-50%, -50%) scale(1.05);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  ${({ isModalOpen }) => isModalOpen &&  `
    animation: modalbounce 0.2s forwards;
    visibility: visible;
  `}
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledSlider = styled(Slider)`
  margin-top: 32px;
`;

const StyledCropper = styled.div`
  .cropper-crop-box {
    border-radius: 50%;
    pointer-events: none;

    &:before, 
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: solid 4px ${({ theme }) => theme.colors.white};
      box-sizing: border-box;
      border-radius: 50%;
      pointer-events: none;
    }
  }

  .cropper-center {
    display: none;
  }

  .cropper-view-box {
    border-radius: 50%;
  }
`;

const urlToBlob = async (url: string): Promise<Blob> => {
	const response = await fetch(url);
	const blob = await response.blob();
	return blob;
};

const AvatarUpload: React.FC<{ user: User }> = ({ user }) => {
	if (!user) return null;
	const dispatch: AppDispatch = useDispatch();
	const { profile_avatar } = user || {};
	const [currentImageUrl, setCurrentImageUrl] = useState<string>(profile_avatar?.original_image_url || DefaultAvatar);
	const [croppedImageUrl, setCroppedImageUrl] = useState<string | undefined>(profile_avatar?.cropped_image_url || undefined);
	const [currentImageFile, setCurrentImageFile] = useState<File | null>(null);
	const [croppedImageFile, setCroppedImageFile] = useState<File | null>(null);
	const [cropperInstance, setCropperInstance] = useState<Cropper | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [zoomValue, setZoomValue] = useState(0);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const imgRef = useRef<ReactCropperElement | null>(null);
  
	useEffect(() => {
		async function convertInitialImages() {
			// // Handle the setting of the image URLs first
			// setCroppedImageUrl(profile_avatar?.cropped_image_url);
			// setCurrentImageUrl(profile_avatar?.original_image_url || DefaultAvatar);
  
			// Convert initialOriginalImage URL to File
			if (currentImageUrl) {
				const originalBlob = await urlToBlob(currentImageUrl);
				const originalFile = new File([originalBlob], "original.jpeg", {
					type: "image/jpeg",
				});
				setCurrentImageFile(originalFile);
			}
  
			// Convert initialCroppedImage URL to File
			if (croppedImageUrl) {
				const croppedBlob = await urlToBlob(croppedImageUrl);
				const croppedFile = new File([croppedBlob], "cropped.jpeg", {
					type: "image/jpeg",
				});
				setCroppedImageFile(croppedFile);
			}
		}
  
		convertInitialImages();
	}, []);

	const handleAvatarClick = () => {
		cropperInstance?.zoomTo(0);
		setZoomValue(0);
		setIsModalOpen(true);
	};

	const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			setCurrentImageFile(file);
			setCroppedImageFile(null);
			const reader = new FileReader();
			reader.onload = (e) => {
				setCurrentImageUrl(e.target!.result as string);
				setZoomValue(0);
				setIsModalOpen(true);
			};
			reader.readAsDataURL(file);
		}
	};
  
	const generateCroppedImage = async () => {
		if (!currentImageUrl) {
			return null;
		}
    
		return await getCroppedImg(currentImageUrl, `${Date.now()}.jpeg`);
	};
  
	const getCroppedImg = (imageSrc: string, fileName: string): Promise<File | null> => {
		if (!cropperInstance) return Promise.reject(new Error("No cropper instance"));
  
		return new Promise((resolve, reject) => {
			cropperInstance.getCroppedCanvas().toBlob((blob: Blob | null) => {
				if (!blob) {
					reject(new Error("Canvas is empty"));
					return;
				}
				const newFile = new File([blob], fileName, { type: "image/jpeg" });
				resolve(newFile);
			}, "image/jpeg");
		});
	};

	const handleImageUpload = async (newCroppedImageFile: File | null) => {
		try {
			setIsLoading(true); 
			if (currentImageFile || newCroppedImageFile) {
				await handleAvatarUpload(currentImageFile, newCroppedImageFile);

				if (!croppedImageFile) {
					setCroppedImageUrl(currentImageUrl);
				} else if (newCroppedImageFile) {
					setCroppedImageUrl(URL.createObjectURL(newCroppedImageFile));
				}

				setIsModalOpen(false);
			}
		} catch (error) {
			console.error("Error uploading image: ", error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleConfirmSelection = async () => {
		const newCroppedImageFile = await generateCroppedImage();
		if (newCroppedImageFile) {
			handleImageUpload(newCroppedImageFile);
		}
	};

	const handleAvatarUpload = async (newOriginalImage: File | null, newCroppedImage: File | null) => {
		try {
			if (!user?.id) {
				console.error("User ID is missing");
				return;
			}
  
			const formData = new FormData();
			if (newOriginalImage) formData.append("profile_avatar[original_image]", newOriginalImage);
			if (newCroppedImage) formData.append("profile_avatar[cropped_image]", newCroppedImage);
      
			const response = await axiosInstance.patch(
				`${process.env.REACT_APP_API_URL}/profile`,
				formData,
				{ withCredentials: true }
			);
  
			const data = response.data;
  
			const cacheBustedCroppedUrl = `${data?.profile_avatar.cropped_image_url}?v=${Date.now()}`;
			setCroppedImageUrl(cacheBustedCroppedUrl);
      
			const updatedUser = {
				...user,
				profile_avatar: {
					cropped_image_url: cacheBustedCroppedUrl,
					original_image_url: data?.profile_avatar?.original_image_url
				}
			};
  
			// Check if originalUrl exists in the response
			if (data.originalUrl) {
				const cacheBustedOriginalUrl = `${data.originalUrl}?v=${Date.now()}`;
				setCurrentImageUrl(cacheBustedOriginalUrl);
  
				// Update the user object with the original URL as well
				updatedUser.profile_avatar.original_image_url = cacheBustedOriginalUrl;
			}
  
			dispatch(setUser(updatedUser));
		} catch (error) {
			console.error("Error updating user: ", error);
		}
	};

	return (
		<>
			<Avatar 
				// img={`${initialCroppedImage}?t=${new Date().getTime()}` || ''}
				img={croppedImageUrl|| ""}
				isLoading={isLoading}
				onClick={handleAvatarClick}
			>
				{isLoading && <LoadingSpinner />}
				<AvatarOverlay>
					<Icon color="white" icon="edit" width={24} height={24} />
				</AvatarOverlay>
				<UploadButton 
					onClick={(e) => {
						e.stopPropagation();
						fileInputRef.current?.click();
					}}
				>
					<Icon color="white" icon="camera" />
				</UploadButton>
				<input
					type="file"
					accept="image/*"
					style={{ display: "none" }}
					ref={fileInputRef}
					onClick={(e) => e.stopPropagation()}
					onChange={handleImageChange}
				/>
			</Avatar>
			<>
				<Overlay isModalOpen={isModalOpen} onClick={() => setIsModalOpen(false)} />
				<ImageModal isModalOpen={isModalOpen}>
					<StyledCropper>
						<Cropper
							ref={imgRef}
							key={currentImageUrl}
							src={currentImageUrl}
							aspectRatio={1}
							highlight={false}
							style={{ width: "100%", height: 400 }}
							guides={false}
							dragMode="move"
							scalable={true}
							zoomable={true}
							wheelZoomRatio={0.1}
							cropBoxResizable={false}
							viewMode={1}
							background={false}
							autoCropArea={1}
							checkOrientation={false}
							onInitialized={(instance) => { setCropperInstance(instance); }}
							zoom={(event: any) => {
								const updatedZoomValue = event.detail.ratio;
								setZoomValue(updatedZoomValue);
							}}
						/>
					</StyledCropper>
					<StyledSlider
						showValues={false}
						step={0.01} 
						min={0}
						max={4}
						value={zoomValue}
						onChange={(value: any) => {
							setZoomValue(value);
							if (cropperInstance) {
								cropperInstance.zoomTo(value);
							}
						}}
					/>
					<ButtonWrapper>
						<Button onClick={handleConfirmSelection}>Confirm Selection</Button>
					</ButtonWrapper>
				</ImageModal>
			</>
		</>
	);
};

export default React.memo(AvatarUpload);