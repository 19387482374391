import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface OnboardingState {
  step: number;
  subStep: number;
}

const initialState: OnboardingState = {
	step: 1,
	subStep: 1,
};

export const onboardingSlice = createSlice({
	name: "onboarding",
	initialState,
	reducers: {
		setStep: (state, action: PayloadAction<number>) => {
			state.step = action.payload;
		},
		setSubStep: (state, action: PayloadAction<number>) => {
			state.subStep = action.payload;
		},
	},
});

export const { setStep, setSubStep } = onboardingSlice.actions;
export const selectStep = (state: RootState) => state.onboarding.step;
export const selectSubStep = (state: RootState) => state.onboarding.subStep;

export default onboardingSlice.reducer;
