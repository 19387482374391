import React, { useState } from "react";
import styled from "@emotion/styled";
import { Appearance } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Button from "../../Buttons/Button";
import ButtonText from "../../Buttons/ButtonText";
import LoadingSpinner from "../../Animations/LoadingSpinner";
import Icon from "../../Icon";
import Modal from "../../Shared/Modal";
import Popover from "../../Shared/Popover";
import SetupForm from "./SetupForm";
import Typography from "../../Typography";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../../redux/reducers/userReducer";
import stripePromise from "../../../utils/stripePromise";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import theme, { CustomTheme } from "../../../theme";
import { useFetchPaymentMethodsQuery, useCreateSetupIntentMutation, useSetDefaultPaymentMethodMutation, useDeletePaymentMethodMutation } from "../../../redux/features/paymentMethodsApi";
import { showSnackbar } from "../../../redux/reducers/snackbarReducer";

const fonts = [
	{
		cssSrc: "https://fonts.googleapis.com/css2?family=Ubuntu"
	},
	{
		cssSrc: "https://fonts.googleapis.com/css2?family=Exo"
	}
];

const appearance: Appearance = {
	theme: "stripe",
	variables: {
		colorPrimary: theme.colors.primaryPink,
		colorBackground: theme.colors.white,
		colorDanger: theme.colors.errorRed,
		fontFamily: "Ubuntu, system-ui, sans-serif",
		spacingUnit: "2px",
		borderRadius: "4px",
	}
};

const PaymentSectionContainer = styled.div`
	padding: 24px 0;
	border-top: ${({ theme }) => `1px solid ${theme.colors.borderGray}`};
	border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderGray}`};
	box-sizing: border-box;
	min-height: 112px;
	max-width: 963px;
`;

const HeaderSection = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledButtonText = styled(ButtonText)`
	display: flex;
	align-items: center;
`;

const StyledIcon = styled(Icon)`
	cursor: pointer;
`;

const StyledList = styled.ul`
	list-style-type: none;
	margin: 0;
	padding: 0;
`;

const StyledListItem = styled.li`
	display: flex;
	align-items: center;
	gap: 24px;
`;

const PaymentFormWrapper = styled.div`
	border-radius: 6px;
	border: ${({ theme }) => `solid 1px ${theme.colors.secondaryLightGray}`};
	padding: 0 20px;
	margin-top: 24px;
`;

const MethodPopover = styled.div`
	padding: 16px;
`;

const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
`;

const IconWrapper = styled.div<{ color: keyof CustomTheme["colors"] }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 72px;
	height: 72px;
	background: ${({ color, theme }) => theme.colors[color]}20;
	border-radius: 50%;
	border: 1px solid ${({ color, theme }) => theme.colors[color]};
`;

const Divider = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
	width: 100%;
	height: 1px;
`;

const ButtonGroup = styled.div`
	margin-top: 8px;
`;

const CancelButton = styled(ButtonText)`
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.body};
	font-size: 16px;
	width: 100%;
	text-align: center;
	margin-top: 12px;
	padding: 14px 40px 10px;
`;

interface DeleteModalProps {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	paymentMethodId: string;
	handleDelete: (paymentMethodId: string) => Promise<void>;
}

const DeleteMethodModal: React.FC<DeleteModalProps> = ({
	isOpen,
	setIsOpen,
	paymentMethodId,
	handleDelete,
}) => (
	<Modal
		isOpen={isOpen}
		setIsOpen={setIsOpen}
		content={
			<ModalContainer>
				<IconWrapper color="errorRed">
					<Icon color="errorRed" icon="trash" />
				</IconWrapper>
				<Typography
					fontSize={24}
					fontWeight={500}
					lineHeight="110%"
					textAlign="center"
				>
					Delete payment method
				</Typography>
				<Divider />
				<Typography
					color="secondaryLightGray"
					fontFamily="body"
					fontSize={14}
					fontWeight={400}
					textAlign="center"
				>
					This will permanently delete your payment method.
				</Typography>
				<ButtonGroup>
					<Button fullWidth onClick={() => handleDelete(paymentMethodId)}>Confirm Delete</Button>
					<CancelButton onClick={() => setIsOpen(false)}>
						Cancel
					</CancelButton>
				</ButtonGroup>
			</ModalContainer>
		}
	/>
);

const PaymentSection = () => {
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const { data: paymentMethods, error: paymentMethodsError, isLoading: isLoadingMethods, refetch } = useFetchPaymentMethodsQuery();
	const [createSetupIntent, { data: setupIntent, isLoading: isLoadingSetupIntent, error: setupIntentError }] = useCreateSetupIntentMutation();
	const [setDefaultPaymentMethod] = useSetDefaultPaymentMethodMutation();
	const [deletePaymentMethod] = useDeletePaymentMethodMutation();
	const [paymentFormOpen, setPaymentFormOpen] = useState(false);
	const [openedPopoverId, setOpenedPopoverId] = useState<string | null>(null);
	const [activePaymentMethodId, setActivePaymentMethodId] = useState<string | null>(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

	if (!user) return null;

	if (isLoadingMethods) return <LoadingSpinner />;
	if (paymentMethodsError || setupIntentError) return <div>Error: {getErrorMessage(paymentMethodsError || setupIntentError)}</div>;

	const handleOpenPaymentForm = async () => {
		if (!paymentFormOpen && !setupIntent?.client_secret) {
			await createSetupIntent();
		}
		setPaymentFormOpen(prev => !prev);
	};

	const handleSavePaymentMethod = () => {
		dispatch(showSnackbar({ message: "New payment method added successfully!", duration: 3000 }));
		setPaymentFormOpen(false);
		refetch();
	};

	const handleSetDefault = async (paymentMethodId: string) => {
		await setDefaultPaymentMethod(paymentMethodId);
		dispatch(showSnackbar({ message: "Default payment method set successfully!", duration: 3000 }));
		setOpenedPopoverId(null);
	};

	const showDeleteModal = (paymentMethodId: string) => {
		setActivePaymentMethodId(paymentMethodId);
		setIsDeleteModalOpen(true);
	};

	const handleDeleteConfirmed = async () => {
		if (activePaymentMethodId) {
			await deletePaymentMethod(activePaymentMethodId);
			dispatch(showSnackbar({ message: "Payment method deleted successfully!", duration: 3000 }));
			setActivePaymentMethodId(null);
			setIsDeleteModalOpen(false);
		}
	};

	return (
		<>
			<PaymentSectionContainer>
				<HeaderSection>
					<Typography
						fontSize={20}
						fontWeight={700}
						margin="0 0 12px"
					>
					Payment Method
					</Typography>
					<StyledButtonText
						onClick={handleOpenPaymentForm}
					>
						<Typography
							fontSize={14}
							fontFamily="body"
							fontWeight={400}
							margin="0 4px 0 0"
						>
						Add Payment Method
						</Typography>
						<Icon
							icon={paymentFormOpen ? "minus" : "plus"}
							height={20}
							width={20}
						/>

					</StyledButtonText>
				</HeaderSection>
				{paymentMethods && paymentMethods?.length > 0 ? (
					<StyledList>
						{paymentMethods.map(({ id, card, is_default }) => {
							const { brand, exp_month, exp_year, last4 } = card;
							const capitalizedBrand = brand.toUpperCase();
							const formattedExpYear = exp_year.toString().slice(-2);

							return (
								<StyledListItem key={id}>
									<Typography
										color="secondaryLightGray"
										fontFamily="body"
										fontWeight={400}
									>
										{`${capitalizedBrand} ****${last4}`}
									</Typography>
									<Typography
										color="secondaryLightGray"
										fontFamily="body"
										fontWeight={400}
									>
										{`Exp. ${exp_month}/${formattedExpYear}`}
									</Typography>
									<Popover
										trigger={
											<StyledIcon
												icon="more"
											/>
										}
										content={
											<MethodPopover>
												{!is_default && (
													<ButtonText onClick={() => handleSetDefault(id)}>
														<Typography fontFamily="body" fontSize={14} margin="0 0 8px">
															Make Default
														</Typography>
													</ButtonText>
												)}
												<ButtonText onClick={() => showDeleteModal(id)}>
													<Typography fontFamily="body" fontSize={14} margin={!is_default ? "8px 0 0" : "0"}>
														Delete
													</Typography>
												</ButtonText>
											</MethodPopover>
										}
										isOpen={openedPopoverId === id}
										onToggle={() => {
											if (openedPopoverId === id) {
												setOpenedPopoverId(null);
											} else {
												setOpenedPopoverId(id);
											}
										}}
										onClose={() => setOpenedPopoverId(null)}
										placement="bottom-right"
									/>
								</StyledListItem>
							);
						})}
					</StyledList>
				) : (
					<p>No saved payment methods.</p>
				)}
				{paymentFormOpen && !isLoadingSetupIntent && setupIntent?.client_secret && (
					<PaymentFormWrapper>
						<Elements 
							stripe={stripePromise}
							options={{ appearance, clientSecret: setupIntent.client_secret, fonts }}
						>
							<SetupForm
								clientSecret={setupIntent.client_secret}
								onSave={handleSavePaymentMethod}
							/>
						</Elements>
					</PaymentFormWrapper>
				)}
				{isDeleteModalOpen && activePaymentMethodId && (
					<DeleteMethodModal
						isOpen={isDeleteModalOpen}
						setIsOpen={setIsDeleteModalOpen}
						paymentMethodId={activePaymentMethodId}
						handleDelete={handleDeleteConfirmed}
					/>
				)}
			</PaymentSectionContainer>
		</>
	);
};

export default PaymentSection;
