import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Buttons/Button";
import ButtonText from "../../Buttons/ButtonText";
import Checkbox from "../../Inputs/Checkbox";
import InputText from "../../Inputs/InputText";
import Slider from "../../Inputs/Slider";
import Typography from "../../Typography";
import ToggleSwitch from "../../Inputs/Toggle";
import { PreferenceProps } from "../../../types/Step";
import useViewport from "../../../hooks/ViewportProvider";
import { selectUser } from "../../../redux/reducers/userReducer";
import { updateUserPreferenceData } from "../../../redux/actions/userActions";

import type { AppDispatch } from "../../../redux/store";

const PreferenceWrapper = styled.div``;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin: 24px 0 33px;

  ${({ isMobile }) =>
		isMobile &&
    `
    flex-direction: column; 
    justify-content: flex-start;
    gap: 24px;
  `}
`;

const AgeInput = styled(InputText)<{ isMobile: boolean }>`
  width: 302px;

  ${({ isMobile }) => isMobile && "width: 100%; margin: 0;"};
`;

const Dealbreaker = styled.div`
  margin-top: 32px;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  width: 100%;
`;

const MobileSubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  gap: 32px;
`;

const SubmitButton = styled(Button)<{ isMobile?: boolean }>`
  ${({ isMobile }) => (isMobile ? "width: 100%;" : "margin-left: 24px;")};
`;

const Preference2: React.FC<PreferenceProps> = ({ next, back }) => {
	const user = useSelector(selectUser);
	const dispatch: AppDispatch = useDispatch();
	const viewport = useViewport();
	const isMobile = viewport === "mobile";
	const defaultMinAge = user?.preferences?.ageRange?.min;
	const defaultMaxAge = user?.preferences?.ageRange?.max;
	const defaultDealbreaker = user?.preferences?.ageRange?.dealbreaker;
	const [ageRange, setAgeRange] = useState<[number, number]>([
		defaultMinAge || 18,
		defaultMaxAge || 99,
	]);
	const [prevAgeRange, setPrevAgeRange] = useState<[number, number]>([18, 99]);
	const [openToAny, setOpenToAny] = useState(false);
	const [dealbreaker, setDealbreaker] = useState(defaultDealbreaker || false);

	const handleSubmit = async () => {
		if (!user || !user.id) return;
		const data = {
			...user?.preferences,
			ageRange: {
				min: ageRange[0],
				max: ageRange[1],
				dealbreaker: dealbreaker,
			},
		};

		dispatch(updateUserPreferenceData({ data }))
			.unwrap()
			.then(() => {
				next();
			})
			.catch((error) => {
				console.error("Error updating user preferences: ", error);
			});
	};

	return (
		<PreferenceWrapper>
			<Typography
				color="white"
				fontSize={20}
				fontWeight={700}
				margin="0 0 4px"
				variant="h2"
			>
        What age range are you comfortable dating within?
			</Typography>
			<Typography
				color="secondaryLightGray"
				fontFamily="body"
				fontSize={16}
				fontWeight={400}
				margin="0 0 32px"
			>
        Select age range (years)
			</Typography>
			<ToggleContainer>
				<ToggleSwitch
					checked={openToAny}
					onChange={() => {
						setOpenToAny((prev) => {
							setAgeRange((prevRange) => {
								if (!prev) {
									setPrevAgeRange(prevRange);
									return [18, 99];
								} else {
									return prevAgeRange;
								}
							});
							return !prev;
						});
					}}
				/>
				<Typography
					color="secondaryLightGray"
					fontFamily="body"
					fontWeight={400}
					margin="0 16px"
				>
          I’m open to all ages
				</Typography>
			</ToggleContainer>
			<InputWrapper isMobile={isMobile}>
				<AgeInput
					disabled={openToAny}
					isMobile={isMobile}
					isNumeric
					label="Minimum age"
					min={18}
					max={99}
					onChange={(event) => {
						const value = event.target.value;
						setAgeRange((prev) => [Number(value), prev[1]]);
					}}
					value={ageRange[0]}
				/>
				<AgeInput
					disabled={openToAny}
					isMobile={isMobile}
					isNumeric
					label="Maximum age"
					min={ageRange[0] + 1}
					max={99}
					onChange={(event) => {
						const value = event.target.value;
						setAgeRange((prev) => [prev[0], Number(value)]);
					}}
					value={ageRange[1]}
				/>
			</InputWrapper>
			<Slider
				disabled={openToAny}
				min={18}
				max={99}
				minRange={5}
				value={ageRange}
				onChange={(value: number | readonly number[]) => {
					setAgeRange(value as [number, number]);
				}}
			/>
			<Dealbreaker>
				<Checkbox
					checked={dealbreaker}
					label="This is a deal breaker"
					onChange={() => setDealbreaker((prev) => !prev)}
					value="dealbreaker"
				/>
			</Dealbreaker>
			{isMobile ? (
				<MobileSubmitButtonWrapper>
					<SubmitButton isMobile={isMobile} onClick={handleSubmit}>
            Continue (2/4)
					</SubmitButton>
					<ButtonText onClick={back}>
						<Typography color="primaryPink" fontFamily="body" fontWeight={500}>
              Go Back
						</Typography>
					</ButtonText>
				</MobileSubmitButtonWrapper>
			) : (
				<SubmitButtonWrapper>
					<ButtonText onClick={back}>
						<Typography color="primaryPink" fontFamily="body" fontWeight={500}>
              Go Back
						</Typography>
					</ButtonText>
					<SubmitButton onClick={handleSubmit}>Continue (2/4)</SubmitButton>
				</SubmitButtonWrapper>
			)}
		</PreferenceWrapper>
	);
};

export default Preference2;
