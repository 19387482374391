import React, { useState, useRef } from "react";
import styled from "@emotion/styled";
import Button from "../Buttons/Button";
import Icon from "../Icon";
import Typography from "../Typography";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { GENDER_OPTIONS as options } from "../../data/options";
import { useViewport } from "../../hooks/ViewportProvider";

const options2 = [...options, { label: "All", value: "all" }];

const GenderDropdownWrapper = styled.div<{ isMobile: boolean }>`
  display: inline-flex;
  align-items: flex-start;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  width: ${({ isMobile }) => (isMobile ? "100%" : "auto")};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  background: ${({ theme }) => `${theme.colors.containerBase}`};
  padding: 15px 24px;
`;

const StyledSelect = styled.div<{ isMobile: boolean }>`
  cursor: pointer;
  position: relative;
  width: ${({ isMobile }) => (isMobile ? "100%" : "165px")};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.containerBase};
  padding: 16px 12px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 500;
  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.accentViolet};
    box-shadow: 0px 0px 30px 0px rgba(204, 28, 210, 0.5);
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`;

const DropdownItems = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? "51px" : "68px")};
  left: ${({ isMobile }) => (isMobile ? 0 : "-24px")};
  max-height: 210px;
  width: ${({ isMobile }) => (isMobile ? "96.3%" : "213px")};
  border-radius: 0px 0px 6px 6px;
  border: 1px solid ${({ theme }) => theme.colors.accentViolet};
  background-color: ${({ theme }) => theme.colors.containerBase};
  box-shadow: 0px 4px 14px 0px rgba(255, 255, 255, 0.1);
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  overflow-y: auto;
  z-index: 999;
`;

const DropdownItem = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 400;
  &:hover {
    background-color: ${({ theme }) => theme.colors.borderGray};
    font-weight: 700;
  }
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};

  &:last-child {
    border-bottom: none;
  }
`;

const Divider = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "100%" : 0)};
  border-right: 1px solid ${({ theme }) => theme.colors.primaryPink};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primaryPink};
  height: ${({ isMobile }) => (isMobile ? 0 : "52px")};
  margin-right: ${({ isMobile }) => (isMobile ? 0 : "24px")};
  margin-bottom: ${({ isMobile }) => (isMobile ? "24px" : 0)};
`;

const StyledButton = styled(Button)<{ isMobile: boolean }>`
  margin-top: ${({ isMobile }) => (isMobile ? "24px" : 0)};
`;

const GenderDropdown: React.FC<{ navigate: any }> = ({ navigate }) => {
	const [gender, setGender] = useState("");
	const [genderPreference, setGenderPreference] = useState("");
	const [isGenderOpen, setIsGenderOpen] = useState(false);
	const [isPreferenceOpen, setIsPreferenceOpen] = useState(false);
	const viewport = useViewport(); // this will return the string 'mobile', 'tablet', 'laptop', 'desktop', 'largeDesktop'
	const isMobile = viewport === "mobile" || viewport === "tablet";

	const ref = useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, () => {
		setIsGenderOpen(false);
		setIsPreferenceOpen(false);
	});

	const toggleGenderDropdown = () => {
		if (isPreferenceOpen) setIsPreferenceOpen(!isPreferenceOpen);
		setIsGenderOpen(!isGenderOpen);
	};
	const togglePreferenceDropdown = () => {
		if (isGenderOpen) setIsGenderOpen(!isGenderOpen);
		setIsPreferenceOpen(!isPreferenceOpen);
	};

	const handleGenderSelection = (val: string) => {
		setGender(val);
		setIsGenderOpen(false);
	};

	const handlePreferenceSelection = (val: string) => {
		setGenderPreference(val);
		setIsPreferenceOpen(false);
	};

	const selectedGenderLabel = options.find(
		(option) => option.value === gender
	)?.label;
	const selectedPreferenceLabel = options2.find(
		(option) => option.value === genderPreference
	)?.label;

	const handleSubmit = () => {
		localStorage.setItem("gender", gender);
		localStorage.setItem("genderPreference", genderPreference);
		navigate("/sign-up");
	};

	return (
		<GenderDropdownWrapper isMobile={isMobile} ref={ref}>
			<StyledSelect onClick={toggleGenderDropdown} isMobile={isMobile}>
				<Typography fontFamily="body" fontWeight={400}>
					{selectedGenderLabel || "I am..."}
				</Typography>
				<IconWrapper>
					<Icon icon="caret-down" />
				</IconWrapper>
				<DropdownItems isMobile={isMobile} isOpen={isGenderOpen}>
					{options.map((option, idx) => (
						<DropdownItem
							key={`${option.value}-${idx}`}
							onClick={() => handleGenderSelection(option.value)}
						>
							{option.label}
						</DropdownItem>
					))}
				</DropdownItems>
			</StyledSelect>
			<Divider isMobile={isMobile} />
			<StyledSelect onClick={togglePreferenceDropdown} isMobile={isMobile}>
				<Typography fontFamily="secondary" fontWeight={400}>
					{selectedPreferenceLabel || "Looking for..."}
				</Typography>
				<IconWrapper>
					<Icon icon="caret-down" />
				</IconWrapper>
				<DropdownItems isMobile={isMobile} isOpen={isPreferenceOpen}>
					{options2.map((option, idx) => (
						<DropdownItem
							key={`${option.value}-${idx}`}
							onClick={() => handlePreferenceSelection(option.value)}
						>
							{option.label}
						</DropdownItem>
					))}
				</DropdownItems>
			</StyledSelect>
			<Divider isMobile={isMobile} />
			<StyledButton
				isMobile={isMobile}
				onClick={handleSubmit}
				fullWidth={isMobile}
			>
        Continue
			</StyledButton>
		</GenderDropdownWrapper>
	);
};

export default GenderDropdown;
